<div class="config-title">{{ language?.export }}</div>
<ng-container *ngIf="!this.exporting">
  <form method="POST" [formGroup]="exportForm" (ngSubmit)="exportSubmit()">
    <span style="color: red; font-size: 0.875rem; margin: 1rem 0; display: block;"
      [style.visibility]="exportForm.invalid && exportForm.dirty ? 'visible' : 'none'">
      {{ exportForm.getError('error') }}
    </span>
    <div style="display: flex; flex-wrap: wrap; gap: .5rem 2.5rem;">
      <dl style="width: 45%;">
        <dt>{{ language?.start }}</dt>
        <dd style="display: flex; flex-direction: column;">
          <mat-form-field appearance="outline" style="width: 10.5rem;">
            <input matInput #startDate id="startDate" formControlName="startDate" type="datetime-local"
              [max]="endDate.value || this.maxDate" />
          </mat-form-field>
        </dd>
      </dl>
      <dl style="width: 45%;">
        <dt>{{ language?.end }}</dt>
        <dd style="display: flex; flex-direction: column;">
          <mat-form-field appearance="outline" style="width: 10.5rem;">
            <input matInput #endDate id="endDate" formControlName="endDate" type="datetime-local"
              [min]="startDate.value" [max]="this.maxDate" />
          </mat-form-field>
        </dd>
      </dl>
    </div>
    <dl>
      <dt>{{ language?.Thing }}</dt>
      <dd>
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-select id="thingKey" formControlName="thingKey" name="thingKey" value="">
            <mat-option value="" hidden>{{ language?.select_thing }}</mat-option>
            <ng-container *ngFor="let thing of this.things">
              <mat-option [value]="thing.name">{{ thing.name }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </dd>
    </dl>

    <div class="form-group d-flex gap-4">
      <label for="deleteData" style="align-content: center;" class="form-check-label">
        {{ language?.delete_on_export}}
      </label>
      <input type="checkbox" class="form-check m-2" id="deleteData" formControlName="deleteData" />
    </div>

    <dt style="margin-top: 1rem;">
      <button mat-flat-button color="logi" type="submit" [disabled]="this.exportForm.invalid">
        {{ language?.save_button }}
      </button>
    </dt>
  </form>
</ng-container>
<ng-container *ngIf="this.exporting">
  <div class="d-flex flex-column align-items-center my-3" style="gap: 5px;">
    <strong>{{ language?.exporting_to_csv }}</strong>
    <div class="spinner-border" role="status" aria-hidden="true"></div>
  </div>
</ng-container>

<app-dialog [(isOpened)]="showDeleteModal" (isOpenedChange)="showDeleteModal = false;">
  <div title>{{ language?.delete_tag_values }}</div>
  <div body>
    <ng-container *ngIf="!this.deleting && !this.deleted">
      <form method="POST" id="deleteForm" [formGroup]="deleteForm" (ngSubmit)="deleteSubmit()"
        class="mx-4 my-2 d-flex flex-column">
        <span style="color: red; font-size: 13px; margin: 10px 0;" *ngIf="deleteForm.invalid">
          {{ deleteForm.getError('error') }}
        </span>
        <dl>
          <dt>{{ language?.how_delete_tag_values }}</dt>
          <dd>
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-select formControlName="deleteType" value="">
                <mat-option value="" disabled hidden>{{ language?.select_action}}</mat-option>
                <mat-option value="range">{{ language?.delete_exported_range}}</mat-option>
                <mat-option value="beforeEnd">
                  {{ language?.delete_all_before_to }} {{ this.exportForm.value.endDate | date:'dd-MM-yyyy, HH:mm' }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <span style="font-size: 75%; color: rgba(0, 0, 0, .6);">
              {{ language?.range_includes_start_end }}
            </span>
          </dd>
        </dl>
      </form>
    </ng-container>
    <ng-container *ngIf="this.deleting && !this.deleted">
      <div class="d-flex flex-column align-items-center my-3" style="gap: 5px;">
        <strong>{{ language?.deleting_tag_values }}...</strong>
        <div class="spinner-border" role="status" aria-hidden="true"></div>
      </div>
    </ng-container>
    <ng-container *ngIf="!this.deleting && this.deleted">
      <p>{{ this.deletedCount }} {{ language?.tag_values_deleted }}.</p>
    </ng-container>
  </div>
  <div footer>
    <button mat-button (click)="showDeleteModal = false;">
      {{ language?.cancel }}
    </button>
    <button mat-button form="deleteForm" type="submit"
      [disabled]="this.deleteForm.invalid || (!this.deleting && this.deleted)">
      {{ language?.delete }}
    </button>
  </div>
</app-dialog>
