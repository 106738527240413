import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfigService } from 'src/app/service/system/config.service';
import { LanguageService } from 'src/app/service/system/language.service';

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.scss'],
})
export class SystemComponent implements OnInit {
  config: any;
  configData: FormGroup = new FormGroup({});
  controls: any[] = [];
  language: any;

  constructor(
    private configServ: ConfigService,
    private langServ: LanguageService
  ) {}

  async ngOnInit(): Promise<void> {
    this.language = (await this.langServ.getVal()).option;
    this.config = await this.configServ.getConfig();

    for (const [key, value] of Object.entries(this.config)) {
      this.configData.addControl(
        key,
        new FormControl(value, [
          Validators.pattern(
            /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/
          ),
        ])
      );
      this.controls.push({ key });
    }
  }

  async configSubmit() {
    const newConfig: any = {};
    for (const [key, value] of Object.entries(this.configData.controls)) {
      newConfig[key] = this.configData.value[key];
    }

    this.configServ.setConfig(newConfig);
  }
}
