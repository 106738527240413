<div class="options col-2 pt-2" id="options">
  <button type="button" class="close" aria-label="Close" (click)="closeOption()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="container" id="option-container">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" *ngFor="let tab of tabs">
        <a class="nav-link" id="{{ tab }}-tab" [ngClass]="{ active: tab === 'general' }" data-toggle="tab"
          href="#{{ tab }}" role="tab" aria-controls="home" aria-selected="true">{{ language[tab] }}</a>
      </li>
      <li class="nav-item" *ngIf="reportItem">
        <a class="nav-link" id="expert-tab" [ngClass]="{ active: tab === 'expert' }" data-toggle="tab" href="#expert"
          role="tab" aria-controls="home" aria-selected="true">{{ language.expert }}</a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade mt-3" *ngFor="let contentTab of tabs"
        [ngClass]="{ 'show active': contentTab === 'general' }" [id]="contentTab" role="tabpanel"
        [aria-labelledby]="contentTab - tab">
        <h3>{{ language[optionItem?.type.name] }}</h3>
        <div *ngIf="connectionError" class="alert alert-danger" role="alert">
          {{ connectionError }}
        </div>
        <hr />
        <form [formGroup]="optionsForm" (ngSubmit)="optionSubmit()" method="POST">
          <!-- If general tab -->
          <ng-container *ngIf="contentTab === 'general'">
            <ng-container *ngFor="let generalKey of general">
              <ng-container *ngFor="let key of generalKey">
                <div class="form-group" [ngStyle]="{
                    display:
                      parameters[contentTab][key]?.display === 'hidden'
                        ? 'none'
                        : ''
                  }">
                  <!-- run script -->
                  <ng-container *ngIf="parameters[contentTab][key]?.type === 'script'">
                    <label [for]="key + contentTab">{{ language?.code_editor_label }}</label>

                    <ngx-codemirror [options]="codeMirrorOptions" [id]="key + contentTab" [formControlName]="key">
                    </ngx-codemirror>
                  </ng-container>

                  <!-- Input -->
                  <ng-container *ngIf="parameters[contentTab][key]?.type === 'input'">
                    <label style="width:100px;" [for]="key + contentTab">{{
                      language[parameters[contentTab][key]?.label]
                      }}</label>
                    <input [type]="parameters[contentTab][key]?.input" [id]="key + contentTab" [formControlName]="key"
                      class="form" autocomplete="off" style="width: 190px;" />
                  </ng-container>
                  <!-- Texarea -->
                  <ng-container *ngIf="parameters[contentTab][key]?.type === 'textarea'">
                    <label [for]="key + contentTab">{{
                      language[parameters[contentTab][key]?.label]
                      }}</label>
                    <!-- If user has value in item -->
                    <textarea [id]="key + contentTab" [formControlName]="key" rows="4" class="form-control"></textarea>
                  </ng-container>
                  <!-- Select -->
                  <ng-container *ngIf="parameters[contentTab][key]?.type === 'select'">
                    <label [for]="key + contentTab">{{
                      language[parameters[contentTab][key]?.label]
                      }}</label>
                    <select class="form-control" [id]="key + contentTab" [formControlName]="key"
                      (change)="changeSelect($event)">
                      <ng-container *ngFor="
                          let option of parameters[contentTab][key]?.options
                        ">
                        <option [value]="option.key" [id]="option.key">
                          {{ option.value }}
                        </option>
                      </ng-container>
                    </select>
                  </ng-container>
                  <!-- Checkbox -->
                  <ng-container *ngIf="parameters[contentTab][key]?.type === 'checkbox'">
                    <div class="col-12">
                      <input
                        [checked]="optionsForm.value[key] == true || optionsForm.value[key] == 'true'  || optionsForm.value[key] == 'on'"
                        [type]="parameters[contentTab][key]?.type" class="form-check-input" [id]="key + contentTab"
                        (change)="checkBoxValue($event, key)" />
                      <label class="form-check-label" [for]="key + contentTab">{{
                        language[parameters[contentTab][key]?.label]
                        }}</label>
                    </div>
                  </ng-container>
                  <!-- Date -->
                  <ng-container *ngIf="parameters[contentTab][key]?.type === 'date'">
                    <label [for]="key + contentTab">{{
                      language[parameters[contentTab][key]?.label]
                      }}</label>
                    <input [type]="parameters[contentTab][key]?.type" [id]="key + contentTab" [formControlName]="key" />
                    <small class="form-text text-muted">{{
                      language[parameters[contentTab][key]?.small]
                      }}</small>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="protocol">
              <div class="form-group">
                <a class="btn btn-primary btn-block text-light" (click)="tagsControl()">{{ language?.tag_list }}</a>
              </div>
            </ng-container>
            <ng-container *ngIf="thing">
              <div class="form-group">
                <a class="btn btn-primary btn-block text-light" (click)="viewValues()">{{ language?.values_view }}</a>
              </div>
              <div class="form-group">
                <a class="btn btn-primary btn-block text-light" (click)="viewAlarms()">Alarmas</a>
              </div>
            </ng-container>
          </ng-container>
          <!-- Conexion -->

          <button type="submit" class="btn btn-primary float-right">
            {{ language.save_button }}
          </button>
          <button type="button" class="btn btn-danger" *ngIf="paramsDone || !reportItem" data-toggle="modal"
            data-target=".bd-modal-deleteItem" (click)="confirmItemToDelete($event)">
            {{ language.delete }}
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Modal delete item -->
<div class="modal fade bd-modal-deleteItem mt-5" tabindex="-1" role="dialog" aria-labelledby="Eliminar item"
  aria-hidden="true" id="deleteItem">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalDeleteItemTitle">
          {{ language?.confirmation }}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3>
          {{ language?.confirmation_message }}<span id="itemToDelete"></span> ?
        </h3>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ language?.close }}
        </button>
        <button type="button" class="btn btn-danger" (click)="deleteItem($event)">
          {{ language?.delete }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal viewValues -->
<div class="modal fade bd-modal-viewValues mt-8" tabindex="-1" role="dialog" aria-labelledby="Eliminar item"
  aria-hidden="true" id="viewValues">

  <div class="modal-dialog mw-100" style="width: 95%; height: 100%">
    <div class="modal-content" style="height: 90%">

      <div class="modal-header">
        <h5 class="modal-title" id="amodalviewValuesTitle">
          {{ language?.values_list }}
        </h5>

        <span class="close">
          <select (change)="showValues()">
            <option id="none" value="none">Document</option>
            <ng-container *ngFor="let option of DocProt">
              <option [value]="option.id" [id]="option.id">
                {{ option.name }}
              </option>
            </ng-container>
          </select>&nbsp;&nbsp;
          <select>
            <option>{{ language?.select_placeholder }}</option>
          </select>
        </span>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="height: 90%;">
        <div *ngIf="tagAlredyInUse" class="alert alert-danger" role="alert">
          {{ language?.tag_in_use }} <b>({{ tagInUse }})</b>
        </div>

        <div id="divRegs" style="position:relative; height: 100%">
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal alarms -->
<div class="modal fade bd-modal-alarms mt-8" style="overflow-y: hidden;" tabindex="-1" role="dialog"
  aria-labelledby="Eliminar item" aria-hidden="true" id="alarms">
  <div class="modal-dialog mw-100" style="width: 50%; height: 104%">
    <div class="modal-content" style="height: 90%; overflow: hidden;">
      <div class="modal-header p-4">
        <h4 class="modal-title" id="modalAlarmsTitle">Alarmas</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-4 h-100">
        <app-alarm-form [tags]="tags" [alarms]="alarms"></app-alarm-form>
      </div>
    </div>
  </div>
</div>

<!-- Modal tags control -->
<div class="modal fade bd-modal-tagsList mt-5" tabindex="-1" role="dialog" aria-labelledby="Eliminar item"
  aria-hidden="true" id="tagsList">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modaltagsListTitle">
          {{ language?.tags_list }}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="tagAlredyInUse" class="alert alert-danger" role="alert">
          {{ language?.tag_in_use }} <b>({{ tagInUse }})</b>
        </div>
        <table class="table table-hover">
          <tr>
            <th>{{ language?.alias_label }}</th>
            <th>{{ language?.type }}</th>

            <th *ngFor="
                let column of item?.tagColumns[
                  optionsForm?.value['protocoltype']
                ] | keyvalue: asIs
              ">
              {{ column.key }}
            </th>

            <th>{{ language?.actions }}</th>
          </tr>

          <tr *ngFor="let tag of tags">
            <td [id]="tag?.name + '_name'">
              {{ tag?.name }}
            </td>

            <td [id]="tag?.name + '_type'">
              {{ tagTypesKeys[tag?.type] }}
            </td>

            <td *ngFor="
                let column of item?.tagColumns[
                  optionsForm?.value['protocoltype']
                ] | keyvalue: asIs
              ">
              {{ tag?.parameters[column.key] }}
            </td>

            <td>
              <button [attr.data-tagname]="tag?.name" (click)="editTag($event)">
                <img src="assets/img/action/pencil.svg" class="img-thumbnail" [attr.data-tagname]="tag?.name" alt="" />
              </button>
              <button [attr.data-tagname]="tag?.name" (click)="removeTag($event)">
                <img src="assets/img/action/trash.svg" class="img-thumbnail" [attr.data-tagname]="tag?.name" alt="" />
              </button>
            </td>
          </tr>
        </table>
        <button class="btn btn-primary btn-block" (click)="showTagForm()" id="addTag_btn">
          {{ language?.add_tag }}
        </button>
        <button class="btn btn-danger btn-block hidden" (click)="cancelAddTag()" id="cancelTag_btn">
          {{ language?.close }}
        </button>

        <div class="hidden" id="tagsForm">
          <form [formGroup]="tagForm" action="POST">
            <div class="form-group hidden">
              <input type="text" id="tagPos" />
            </div>
            <div class="form-group">
              <label for="name">{{ language?.alias_label }}</label>
              <input type="text" formControlName="name" class="form-control" id="name" />
            </div>
            <div class="form-group">
              <label for="type">{{ language?.type }}</label>
              <select name="type" formControlName="type" id="type" (change)="checkTagType($event)" class="form-control">
                <ng-container *ngFor="let option of tagTypes">
                  <option [value]="option._id" [id]="option.key">
                    {{ option.name }}
                  </option>
                </ng-container>
              </select>
            </div>

            <ng-container *ngFor="
                let column of item?.tagColumns[
                  optionsForm?.value['protocoltype']
                ] | keyvalue: asIs
              ">
              <div class="form-group">
                <label for="column.key">{{ column.key }}</label>
                <input name="column.key" [formControlName]="column.key" [id]="column.key" class="form-control" />
              </div>
            </ng-container>

            <div class="form-group">
              <button type="submit" class="btn btn-primary btn-block" id="addTag_submit_btn" (click)="addTag()">
                {{ language?.add }}
              </button>
              <button type="submit" class="btn btn-primary btn-block hidden" id="editTag_submit_btn"
                (click)="editTagSubmit()">
                {{ language?.modify }}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ language?.close }}
        </button>
        <button type="button" class="btn btn-primary" (click)="addTagListToItem()">
          {{ language?.modify }}
        </button>
      </div>
    </div>
  </div>
</div>
