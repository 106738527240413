import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/service/system/language.service';

@Component({
  selector: 'app-dashboard-component',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit{
  title = 'dashboard-component';
  languages: any;
  dashboardText: any;

  constructor(
    private langServ: LanguageService
  ) { }

  async ngOnInit() {
    // get language
    this.languages = await this.langServ.getVal();
    // get language to dashboard
    this.dashboardText = this.languages['left-sidebar'];
  }

}
