<div *ngIf="(tag.parameters.titlegeneral != null && tag.parameters.titlegeneral != '') ||
     tag.parameters.searchgeneral === true || tag.parameters.hot_refreshgeneral === true" class="item-header"
  [ngClass]="{ 'tiny': tag.parameters.widthgeneral <= 450 }">
  <div [style.width.%]="100" class="item-header-filter">
    <mat-form-field appearance="outline" *ngIf="tag.parameters.searchgeneral === true" class="no-input-padding"
      floatLabel="never" [style.fontSize.px]="12" [style.width.%]="100" [style.minWidth.px]="125">
      <mat-label>{{ language?.item_search_label || 'Search' }}</mat-label>
      <input matInput type="text" [formControl]="filter" [readonly]="isLoading" (change)="applyFilter()">
      <button *ngIf="filter.value" mat-button matSuffix mat-icon-button aria-label="Clear"
        (click)="clearSearch(); applyFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="item-header-title">
    <h3 *ngIf="tag.parameters.titlegeneral != null && tag.parameters.titlegeneral != ''">
      {{ tag.parameters.titlegeneral}}
    </h3>
  </div>
  <div class="item-header-refresh" [ngClass]="{ 'no-search': tag.parameters.searchgeneral !== true }">
    <button *ngIf="tag.parameters.hot_refreshgeneral === true" mat-icon-button
      [matTooltip]="language?.refresh_table || 'Refresh table'" [disabled]="isLoading" (click)="refreshDataTable()">
      <mat-icon *ngIf="!isRefreshed" [ngClass]="{ 'spinner': isRefreshing }">sync</mat-icon>
      <mat-icon *ngIf="isRefreshed">check</mat-icon>
    </button>
  </div>
</div>
<div *ngIf="type === 'DataDesign'" class="item-options" [style.min-width.px]="350">
  <button mat-button class="button-list" [disabled]="!isPlayer" (click)="valueDialogOpen = true">
    <mat-icon>add</mat-icon><span>{{ language?.new || 'New' }}</span>
  </button>
  <button mat-button class="button-list" (click)="valueDialogOpen = true; isEditing = true;"
    [disabled]="!isPlayer || tableElement.selection.selected.length !== 1">
    <mat-icon>edit</mat-icon><span>{{ language?.edit || 'Edit' }}</span>
  </button>
  <button mat-button class="button-list" (click)="deleteDialogOpen = true;"
    [disabled]="!isPlayer || tableElement.selection.selected.length < 1">
    <mat-icon>delete</mat-icon><span>{{ language?.delete || 'Delete' }}</span>
  </button>
</div>
<div *ngIf="!isLoading && type === 'TagValues' && tag.parameters.date_filterconnect === true" class="item-options"
  [formGroup]="filterForm" [style.min-width.px]="550">
  <mat-form-field appearance="outline" class="no-input-padding">
    <mat-label>{{ language?.connect_from_date || 'From date' }}</mat-label>
    <input matInput type="datetime-local" formControlName="fromDate" [style.height.px]="10">
  </mat-form-field>
  <mat-form-field appearance="outline" class="no-input-padding">
    <mat-label>{{ language?.connect_to_date || 'To' }}</mat-label>
    <input matInput type="datetime-local" formControlName="toDate" [style.height.px]="10">
  </mat-form-field>
  <button mat-button class="button-list" (click)="applyFilter()">
    <mat-icon>search</mat-icon><span>{{ language?.filter || 'Filter' }}</span>
  </button>
</div>
<app-table #tableElement [ngStyle]="isLoading ? { display: 'none' } : { display: 'unset' }"
  [ngClass]="{ 'responsive-paginator': tag.parameters.widthgeneral <= 550 }" [headers]="tableHeaders" [data]="tableData"
  [sortable]="true" [paginable]="true" [pageSizeOptions]="[tag.parameters.rowsgeneral]"
  [showFirstLastButtons]="tag.parameters.first_end_buttonsgeneral === true" [filter]="filterValue"
  [sortDefault]="type === 'TagValues' ? { header: 'datetime', direction: 'desc' } : null"
  [filterFunction]="type === 'TagValues' ? filterDate : undefined"></app-table>

<div *ngIf="isLoading" class="item-loading"
  [style.min-width.px]="type === 'TagValues' && tag.parameters.date_filterconnect === true ? 550 : 350">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>
<ng-container *ngIf="isPlayer">
  <app-dialog [(isOpened)]="valueDialogOpen" (isOpenedChange)="addValueForm.reset(); valueForm.resetForm();">
    <div title>{{ isEditing ? 'Editar valores' : 'Añadir valores' }}</div>
    <div body>
      <form id="valueForm" #valueForm="ngForm" [formGroup]="addValueForm"
        (ngSubmit)="formSubmit(); addValueForm.reset(); valueForm.resetForm();"
        style="display: flex; flex-direction: column; min-width: 225px; gap: 15px;">
        <ng-container *ngFor="let field of dataDesign?.fields">
          <ng-container [ngSwitch]="field.type">
            <ng-container *ngSwitchCase="'boolean'">
              <mat-checkbox class="mat-logi" [formControlName]="field._id" [value]="isEditing ? '' : '0'"
                [checked]="isEditing ? tableElement.selection.selected[0][field._id] : false">
                {{ field?.name }}
              </mat-checkbox>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <mat-form-field>
                <mat-label>{{ field?.name }}</mat-label>
                <input matInput [type]="field.type" [required]="!isEditing" [formControlName]="field._id"
                  [value]="isEditing ? tableElement.selection.selected[0][field._id] : null">
              </mat-form-field>
            </ng-container>
          </ng-container>
        </ng-container>
      </form>
    </div>
    <div footer>
      <button mat-button
        (click)="valueDialogOpen = false; isEditing = false; addValueForm.reset(); valueForm.resetForm();">
        {{ language?.cancel || 'Cancel' }}
      </button>
      <button mat-button form="valueForm" type="submit" (click)="addValueForm.updateValueAndValidity()"
        [disabled]="addValueForm.pristine || !addValueForm.valid">
        {{ isEditing ? 'Guardar' : language?.add }}
      </button>
    </div>
  </app-dialog>

  <app-dialog [(isOpened)]="deleteDialogOpen">
    <div title>{{ language?.delete_values || 'Delete values' }}</div>
    <div body>
      <p>{{ language?.delete_values_confirm || 'Are you sure you want to delete the selected values?' }}</p>
    </div>
    <div footer>
      <button mat-button (click)="deleteDialogOpen = false;">
        {{ language?.cancel || 'Cancel' }}
      </button>
      <button mat-button (click)="deleteDialogOpen = false; deleteValues(tableElement.selection.selected)">
        {{ language?.delete || 'Delete' }}
      </button>
    </div>
  </app-dialog>
</ng-container>
