import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as CodeMirror from 'codemirror';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { DataDesignService } from 'src/app/service/data-design.service';
import { ModelizerItemsService } from 'src/app/service/modelizer/modelizer-items.service';
import { ModuleIotService } from 'src/app/service/modelizer/module-iot.service';
import { SharedService } from 'src/app/service/modelizer/shared.service';
import { LanguageService } from 'src/app/service/system/language.service';
import { SystemService } from 'src/app/service/system/system.service';
import { TagService } from 'src/app/service/tags/tag.service';

var pars = {};
var tInt = {};
function setPar(par, value) {
  pars[par] = value;
}

@Component({
  selector: 'app-optionsdyn_v2',
  templateUrl: './optionsdyn_v2.component.html',
  styleUrls: ['./optionsdyn_v2.component.scss']
})
export class OptionsDynComponentV2 implements OnInit, OnDestroy {
  private subscriptions: Subscription[];

  propAssign: any = {};

  content: any;
  codeMirrorOptions: any;
  alias: any = [];
  // variables: any = [];

  // para contener lo que se necesite
  globals: any = {};
  tools: any = {};

  htmlTags: any = {};

  modalVisor: {
    title: string,
    message: string,
    button: string
  } = { title: 'visortitle', message: 'visormessage', button: 'visorbutton' };

  tags: any = [];
  virtualHTML: HTMLElement;

  scriptsTypes: string = 'script_scriptClick_scriptDown_scriptUp';

  scriptsElements: any = {};

  modalAlertMsg: any = {};

  multiples: any = {};

  clone: boolean = false;

  component: string;

  synoptics: any = [];
  reports: any = [];

  itemsInSynoptic: any = {};

  parameters: any = {};
  tabs: any = [];

  general: any = [];
  expert: any = [];
  connect: any = [];
  values: any = {};
  getvalues: any = {};

  dynHid: any = {};

  lineas: any;
  things: any;
  fields: any;

  imagePath: string;

  language: any;
  optionItem: any;
  paramsItem: any;
  paramsDone: boolean;
  color: any;
  optionsDynForm: FormGroup;
  thingTypeForm: FormGroup;
  isReport = false;
  isSynoptics = false;

  itemName = '';
  itemDescription = '';
  reportItem: boolean;
  documentDefinitions: any = [];

  fieldItems: any = [];

  navcaption = '';

  idItem: string = '';

  props_code = {};

  constructor(
    private elementRef: ElementRef,
    private shaServ: SharedService,
    private langServ: LanguageService,
    private systemServ: SystemService,
    private moduleServ: ModuleIotService,
    private itemsServ: ModelizerItemsService,
    private tagServ: TagService,
    private renderer: Renderer2,
    private datadesignServ: DataDesignService
  ) {
    this.itemsInSynoptic = {};
    this.multiples = {};
    this.subscriptions = [];

    this.codeMirrorOptions = { //theme: 'elegant',
      mode: 'javascript',
      lineNumbers: true,
      theme: 'lesser-dark',

      extraKeys: {
        'Shift-Space': (cm) => {
          this.snippet(cm);
        },
        F11: (cm) => {
          if (!cm.getOption('fullScreen')) {
            $('ngx-codemirror').addClass('CodeMirror-fullscreen');
            $('.CodeMirror').css('height', '100%');
            cm.setOption('fullScreen', true);
            setTimeout(() => {
              cm.refresh();
            }, 100);
          } else {
            cm.setOption('fullScreen', false);
            $('ngx-codemirror').removeClass('CodeMirror-fullscreen');
            $('.CodeMirror').css('height', '500px');
          }
        },
        Esc: (cm) => {
          if (cm.getOption('fullScreen')) {
            cm.setOption('fullScreen', false);
            $('ngx-codemirror').removeClass('CodeMirror-fullscreen');
            $('.CodeMirror').css('height', '500px');
          }
        }
      }
    };
    this.content = 'document';

    // 5f994d5908425a1078a1b7b2

    this.optionsDynForm = new FormGroup({});
    this.thingTypeForm = new FormGroup({});
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
    this.elementRef.nativeElement.remove();
    this.unsuscribe();
  }

  propCreateTab(nametab) {
    if (this.parameters.hasOwnProperty(nametab) === false) {
      this.parameters[nametab] = {};
    }
  }

  propCreateTab2(type, nametab, func) {
    let existe = true;
    if (this.parameters.hasOwnProperty(nametab) === false) {
      this.parameters[nametab] = {};
      existe = false;
    }

    if (existe === false) {
      const rr = document.createElement("div");
      rr.id = "div" + nametab;

      pars['propTable'] = "tbl" + nametab;
      const trs = this.getTag({ tag: "tableProp.html", get: "obj" });
      rr.appendChild(trs.cloneNode(true));

      this.globals[type + nametab + 'interval'] = setInterval(() => {
        try {
          const dd = document.getElementById(nametab).childNodes[0];
          clearInterval(this.globals[type + nametab + 'interval']);
          dd.appendChild(rr);
          func();

        } catch (error) {
        }
      }, 50);
    } else {
      this.globals[type + nametab + 'interval'] = setInterval(() => {
        try {
          const dd = document.getElementById(nametab).childNodes[0];
          clearInterval(this.globals[type + nametab + 'interval']);
          func();

        } catch (error) {
        }
      }, 50);
    }
  }

  propCreateTab4(type, nametab, func) {
    let existe = true;

    const existDelete = document.getElementById('tbl' + nametab);
    if (existDelete) {

      existDelete.parentElement.removeChild(existDelete);
      this.parameters[nametab] = {};
    }

    if (this.parameters.hasOwnProperty(nametab) === false) {
      this.parameters[nametab] = {};
      existe = false;
    }
    this.parameters[nametab] = {};
    existe = false;

    if (this.tabs.some(each => each.key === nametab) === false) {
      this.tabs.push({ key: nametab, controls: [[]] });
    }

    if (existe === false) {

      // const pars = {};
      pars['propTable'] = "tbl" + nametab;
      setPar('propTable', "tbl" + nametab);
      this.getTagH({
        html: "tableProp.html", get: "obj", fnc: function (trs) {

          const rr = document.createElement("div");
          rr.id = "div" + nametab;
          rr.appendChild(trs.cloneNode(true));

          tInt[type + nametab + 'interval'] = setInterval(() => {
            try {
              const dd = document.getElementById(nametab).childNodes[0];
              clearInterval(tInt[type + nametab + 'interval']);
              dd.appendChild(rr);
              func();

            } catch (error) {
              // console.log(error);
            }
          }, 50);

        }
      });

    } else {
      console.log('ya existe');


      tInt[type + nametab + 'interval'] = setInterval(() => {
        console.log('tt');
        try {
          const dd = document.getElementById(nametab).childNodes[0];
          console.log(dd);
          clearInterval(tInt[type + nametab + 'interval']);
          func();

        } catch (error) {
        }
      }, 50);
    }
  }

  subscribe(subs: any) {
    this.subscriptions.push(subs);
  }

  unsuscribe() {
    this.subscriptions.forEach(subs => {
      try {
        subs.unsubscribe();
      } catch (error) {
      }
    });

  }

  getHtmlTag(tag) {
    const tagToEval = "html = `" + this.htmlTags[tag] + "`;";
    return tagToEval;
  }

  getTagF(info) {

    //info: { tag: "prop_layer", get: "innerHTML"  }
    const doc = document.createElement("lp");

    let evcode = "";
    if (info.hasOwnProperty("tag")) {
      evcode = "doc.innerHTML = `" + this.htmlTags[info.tag] + "`;";
    }

    if (info.hasOwnProperty("html")) {
      evcode = "doc.innerHTML = `" + this.htmlTags[info.html] + "`;";

      fetch('/assets/html/' + info.html)
        .then(function (response) {
          // When the page is loaded convert it to text
          return response.text()
        })
        .then(function (html) {
          // Initialize the DOM parser

          const parserH = new DOMParser();

          info.fnc(parserH.parseFromString(html, "text/html"));

        })
        .catch(function (err) {
          console.log('Failed to fetch page: ', err);
        });
    }
    // eval(evcode);

  }

  getTagH(info) {

    try {
      // setPar('typeLineas', '');
      // setPar('typeBarras', '');
      for (const each of Object.entries(info.pars)) {
        setPar(each[0], each[1]);
      }
    } catch (error) {

    }
    // debugger;
    //info: { tag: "prop_layer", get: "innerHTML"  }
    const doc = document.createElement("lp");

    let evcode = "";
    if (info.hasOwnProperty("tag")) {
      evcode = "doc.innerHTML = `" + this.htmlTags[info.tag] + "`;";
    }

    if (info.hasOwnProperty("html")) {
      evcode = "doc.innerHTML = `" + this.htmlTags[info.html] + "`;";

      fetch('/assets/html/' + info.html)
        .then(function (response) {
          // When the page is loaded convert it to text
          return response.text()
        })
        .then(function (html) {

          // const pars = {};
          // pars['propTable'] = "tbl"

          // const pars = {};
          // pars['typeBarras'] = 'selected';

          const doc = document.createElement("lp");
          const evcode = "doc.innerHTML = `" + html + "`;";
          eval(evcode);
          // doc.innerHTML = html;

          // const parserH = new DOMParser();
          // const dd = parserH.parseFromString(html, "text/html");
          // console.log(dd);

          setTimeout((doc, fnc) => {
            fnc(doc);
          }, 1, doc, info.fnc);


        })
        .catch(function (err) {
          console.log('Failed to fetch page: ', err);
        });
    }
    // eval(evcode);

  }

  getTag(info) {
    //info: { tag: "prop_layer", get: "innerHTML"  }
    const doc = document.createElement("lp");

    let evcode = "";
    if (info.hasOwnProperty("tag")) {
      evcode = "doc.innerHTML = `" + this.htmlTags[info.tag] + "`;";
    }

    if (info.hasOwnProperty("html")) {
      evcode = "doc.innerHTML = `" + this.htmlTags[info.html] + "`;";
    }
    eval(evcode);

    let dd: any;

    if (info.hasOwnProperty("tags")) {
      const tt = doc.children;

      dd = doc.getElementsByTagName("tr");
    }

    if (info.hasOwnProperty("get")) {
      if (info.get === "innerHTML") {
        dd = doc.innerHTML;
      }

      if (info.get === "children") {
        dd = doc.children;
      }

      if (info.get === "obj") {
        dd = doc;
      }
    }
    return dd;
  }

  assignProp(prop, types) {
    for (const type of types) {
      if (this.propAssign.hasOwnProperty(type) === false) {
        this.propAssign[type] = [];
      }
      if (this.propAssign[type].includes(prop) === false) {
        this.propAssign[type].push(prop);
      }
    }
  }

  properties_newTab(keytab) {
    //comprobar si el tab está, si no está... añadirlo

  }

  async ngOnInit() {
    this.renderItemComponent();

    try {
      this.scriptsElements = await this.systemServ.getScripting(['6309cd00a4b87ec387153779', '6309bb4ea4b87ec387153451', '630774ee6d342f36852044f7']);
      this.htmlTags = {};
    } catch (error) {
      // console.log(error);
    }
    this.language = await this.langServ.getVal();
    this.language = this.language.option;
    this.globals.dataDesigns = await this.datadesignServ.findAll();

    document.getElementById('optionsdyn')['app'] = this;
  }

  /**
   * Get all lines created in modelizer
   */
  async getLineas(): Promise<any> {
    const items = await this.itemsServ.getModelizerItems();
    this.lineas = await this.itemsServ.getItemsTypes('linea', items);
    this.lineas.map((elem, cont) => {
      if (!elem.hasOwnProperty('parameters')) {
        this.lineas.splice(cont, 1);
      }
    });
  }

  selectChange(event) {

  }

  /**
   * When thing was selected
   * @param event event
   */
  thingSelectedOld(event) {

    const thingId = event.target.value;
    this.things.map(async elem => {
      if (elem.externalId.toString() === thingId) {
        const thingType: any = await this.moduleServ.getThingType(elem.type.id);
        this.fields = [];
        thingType.documents[0].fields.map((elem) => {
          if (elem.tag !== 'id' && elem.tag !== 'THING_TIME') {
            this.fields.push(elem);
          }
        });
        // document.getElementById('documentconnect')['value'] = thingType.documents[0].tag;
      }
    });
  }

  tabSelect(key) {
    if (key.key === 'expert') {

      const script_actives = Object.keys(this.optionsDynForm.controls).filter(type => type.includes('_script'));

      let scripts = {};
      for (const keys of script_actives) {
        try {
          const tags = keys.split('_');
          scripts[tags[0]] = true;
        } catch (error) {
        }
      }

      for (const active of Object.keys(scripts)) {
        try {
          if (this.values[active + '_active'] === '-') {
            setTimeout((act) => {
              this.values[act + '_active'] = '1';
              setTimeout((idd) => {
                const tt = document.getElementById(idd);
                this.scriptSelect(tt);
              }, 250, act + '_event_type');
            }, 250, active);
          }

        } catch (error) {

        }
      }

      /*
      try {
        if (this.values['runscript_active'] === '-') {
          setTimeout(() => {
            this.values['runscript_active'] = '1';

          }, 300);
        }
      } catch (error) {

      }
      */
    }
  }

  thingSelected(event) {
    const thingId = event.target.value;
    this.globals['tags'] = this.globals['alltags'].filter(tag => tag.thing?._id === thingId);
  }

  scriptSelect(target) {
    let scriptType = target.id.replace('event_type', '');
    const scripts = Object.keys(this.optionsDynForm.controls).filter(type => type.includes(scriptType + 'script'));

    for (const script of scripts) {
      try {
        document.getElementById(script).style.display = 'none';
      } catch (error) {
      }
    }
    try {
      document.getElementById(scriptType + 'script' + target.value).style.display = '';
    } catch (error) {
    }
  }

  scriptControl(target) {
  }

  renderItemComponent() {



    this.clone = false;
    // Subscribe to shared service that emit items with his options
    this.subscribe(this.shaServ.changeEmitted$.subscribe(async data => {

      this.tags = [];
      if (data.hasOwnProperty('component')) {

        $('#optionsdyn').show();

        if (data.item.hasOwnProperty("items_tags") && data.item.hasOwnProperty("items_tags")) {
          this.loadTags(data.item.items_tags, data.item.items_things);
        }

        if (data.item.hasOwnProperty('tag')) {
          const opd = document.getElementById('optionsdyn');
          opd.style.left = "";
          if (data.item?.tag?.offsetParent?.offsetLeft + ((data.item?.tag?.offsetLeft + data.item?.tag?.offsetWidth) / data.item?.zoom) > (opd.offsetLeft - 10)) {
            opd.style.left = ((data.item?.tag?.offsetLeft - opd?.offsetWidth + data.item?.tag?.offsetParent?.offsetLeft - 12) / data.item?.zoom) + 'px';
          }
        }

        this.idItem = data.item.id;

        if (data.item.hasOwnProperty('clone')) {
          this.clone = data.item['clone'];
        }

        if (data.item.hasOwnProperty('globals')) {
          if (data.item.globals !== undefined) {
            for (const ky of Object.keys(data.item.globals)) {
              this.globals[ky] = data.item.globals[ky];
            }
          }
        }

        try {
          this.globals['keyboards'] = [];
          if (data.item.hasOwnProperty('keyboards')) {
            if (data.item.keyboards.length > 0) {
              this.globals['keyboards'] = data.item.keyboards;
            }
          }
        } catch (error) {
        }

        this.component = data.component;
        this.values = { ...data.item.values };
        this.getvalues = { ...data.item.values };

        this.parameters = data.item.type.parameters;

        this.itemsInSynoptic = data.item.synItems;

        let jsGlobal = "";
        let codeStart = "const app = this;";

        try {

          // const app = this;
          if (document.getElementById("code") === null) {
            var script = document.createElement("script");  // create a script DOM node
            script.onload = function () {
              const ths = document.getElementById('optionsdyn')['app'];
              ths.tools.prevGlobal();

              if (ths.propAssign.hasOwnProperty(data.item.typeName) === true) {
                for (const prop of ths.propAssign[data.item.typeName]) {
                  try {
                    const script2 = document.createElement("script");  // create a script DOM node
                    script2.setAttribute('type', 'text/javascript');
                    script2.id = 'code_' + prop;
                    script2['app'] = ths;
                    script2.onload = function () {
                      const ths2 = document.getElementById('optionsdyn')['app'];
                      ths2.props_code[prop]['prev']();
                      document.getElementById("code_" + prop).parentElement.removeChild(document.getElementById("code_" + prop));
                    };
                    script2['src'] = '/assets/js/properties/' + prop + '.js';
                    document.head.appendChild(script2);
                  } catch (error) {
                    console.log(error);
                  }
                }
              }
              document.getElementById("code").parentElement.removeChild(document.getElementById("code"));

            };
            script.setAttribute('type', 'text/javascript');
            script.id = "code";
            // script['app'] = this;
            document.head.appendChild(script);
          }
          document.getElementById("code")['src'] = '/assets/js/properties/properties_global.js';

          // console.log('glob', 'fff');
          jsGlobal = this.scriptsElements.scriptProperties.find(scr => scr.name === '__global.js').content;

          // eval(codeStart + jsGlobal + ";prevGlobal();");
        } catch (error) {
          console.log(error);
        }

        if (data.item.typeName === undefined) {
          //es un synoptic, report, synoptic_template, report_template

          switch (data.item.synopticType) {
            case '5f900543c7c2a908ade7b828':
              data.item.typeName = 'synoptic';
              break;
            case '61cd6cd59d6538846bb61e8e':
              data.item.typeName = 'report';
              break;
            case '44':
              data.item.typeName = 'synoptic_template';
              break;
            case '55':
              data.item.typeName = 'report_template';
              break;
          }
        }

        this.navcaption = data.item.caption;
        this.optionItem = data.item;
        this.synoptics = data.item.synoptics;
        this.reports = data.item.reports;

        this.imagePath = 'images/no-image.png'; // data.item.imagePath;
        this.tabs = [];
        this.general = [];
        this.expert = [];
        this.documentDefinitions = [];
        this.fields = [];
        this.things = [];
        this.fieldItems = [];
        this.optionsDynForm = new FormGroup({});
        this.thingTypeForm = new FormGroup({});

        this.reportItem = false;
        this.paramsItem = data.item.parameters;
        this.itemName = data.item.name;
        this.itemDescription = data.item.description;

        // this.language['Parameters'] = "Parámetros";
        // this.tabs.push({ key: "Parameters" });

        /*
        try {
          const jsType = this.scriptsElements.scriptProperties.find(scr => scr.name === (data.item.typeName + '.js')).content;

          // this.props_code[data.item.typeName]['prev']();

          const app = this;
          if (document.getElementById("code_" + data.item.typeName) === null) {
            var script = document.createElement("script");  // create a script DOM node
            script.setAttribute('type', 'text/javascript');
            script.id = "code_" + data.item.typeName;
            script['app'] = this;
            document.head.appendChild(script);
          }
          document.getElementById("code_" + data.item.typeName)['src'] = "/assets/js/" + data.item.typeName + ".js";

          console.log(data.item.typeName);
          setTimeout(() => {
            console.log(Object.keys(this.props_code));
            // this.props_code[data.item.typeName]['prev']();
          }, 500);


          // eval(codeStart + jsGlobal + jsType + ";prev();");
        } catch (error) {
          console.log(error);
        }
        */

        let haveConnect: boolean = false;
        //this.paramsItem = data.parameters;
        if (data.item.hasOwnProperty('values') && true) {
          this.paramsDone = true;
          // if exists expert.color save value in color variable

          // if (data.parameters.expert.hasOwnProperty('color')) {
          //  this.color = data.parameters.expert.color;
          // }
        } else {
          this.paramsDone = false;
        }
        // tslint:disable-next-line: forin
        // for (const key in data.item.type.parameters) {
        for (const key in this.parameters) {
          const tab: any = {};
          tab.key = key;
          tab.controls = [];

          if (key === 'connect') {
            haveConnect = true;
          }
          const params: any = Object.keys(data.item.type.parameters[key]);
          /*
          params.forEach(param => {
            // this.values[param+key] = 'wow';
            if (this.language.hasOwnProperty(data.item.type.parameters[key][param].label)) {
              data.item.type.parameters[key][param].label = this.language[data.item.type.parameters[key][param].label];
            } else {
              data.item.type.parameters[key][param].label = data.item.type.parameters[key][param].label;
            }

            if (data.item.type.parameters[key][param].hasOwnProperty('title')) {
              if (this.language.hasOwnProperty(data.item.type.parameters[key][param].title)) {
                data.item.type.parameters[key][param].title = this.language[data.item.type.parameters[key][param].title];
                // data.item.type.parameters[key][param].title = data.item.type.parameters[key][param].title.replace('(nl)','<br>');
              } else {
                data.item.type.parameters[key][param].title = data.item.type.parameters[key][param].title;
              }
            }

            // Función añadida que traduce los textos de las option de los expert elements.
            if (data.item.type.parameters[key][param].hasOwnProperty('options')) {
              data.item.type.parameters[key][param].options.forEach(
                (option) => {
                  if (this.language.hasOwnProperty(option.value)) {
                    option.value = this.language[option.value];
                  }
                }
              );
            }
          });
          */

          tab.controls.push(params);
          if (this.paramsDone) {
            // tslint:disable-next-line: forin
            for (const itemKey in data.item.type.parameters[key]) {
              let extra = data.item.type.parameters[key][itemKey]?.extra;
              let disabledParameter = data.item.type.parameters[key][itemKey]?.disabled;

              if (data.item.type.parameters[key][itemKey].hasOwnProperty('vars')) {
                for (const namevar of data.item.type.parameters[key][itemKey]['vars']) {
                  let valueControl = this.getvalues[itemKey + '_' + namevar];

                  /*
                  if (namevar === 'variableconnect') {
                    const aliasVar = this.alias.find(al => al._id === valueControl);
                    if (aliasVar === undefined) {
                      valueControl = '';
                    } else {
                      valueControl = aliasVar.name;
                    }
                  }
                  */

                  if (namevar === 'variableconnect') {
                    const aliasVar = this.tags.find(al => al._id === valueControl);
                    if (aliasVar === undefined) {
                      valueControl = '';
                    } else {
                      valueControl = aliasVar.name;
                    }
                  }

                  if (namevar === 'synoptic_id') {
                    const synVar = this.synoptics.find(syn => syn._id === valueControl);
                    if (synVar === undefined) {
                      valueControl = '';
                    } else {
                      valueControl = synVar.name;
                    }
                  }

                  if (namevar === 'report_id') {
                    if (this.reports !== undefined) {
                      const reportVar = this.reports.find(report => report._id === valueControl);
                      if (reportVar === undefined) {
                        valueControl = '';
                      } else {

                        valueControl = reportVar.name;
                      }
                    }
                  }

                  if (namevar.includes('script')) {
                    //this.values['runscript_active'] = '0';
                    if (this.values[itemKey + '_active'] === '1') {
                      this.values[itemKey + '_active'] = '-';
                    }
                  }

                  this.optionsDynForm.addControl(itemKey + '_' + namevar, new FormControl(valueControl, Validators.required));
                }
              } else {
                if (extra === 'multiple') {
                  this.multiples[itemKey + key] = [];
                  let ntx: number = 0;
                  this.getvalues[itemKey + key].forEach(item => {
                    ntx++;
                    this.multiples[itemKey + key].push(ntx);
                    for (const [keyItem, valItem] of Object.entries(item)) {
                      this.optionsDynForm.addControl(`${itemKey}${key}_${keyItem}_${ntx}`, new FormControl(valItem, Validators.required));
                    }
                  });
                } else {
                  //control SynopticList
                  let valueControl = this.getvalues[itemKey + key];
                  switch (itemKey + key) {
                    case "synopticgeneral":
                      const synVar = this.synoptics.find(syn => syn._id === valueControl);
                      if (synVar === undefined) {
                        valueControl = '';
                      } else {
                        valueControl = synVar.name;
                      }
                      break;
                  }

                  switch (data.item.type.parameters[key][itemKey].type) {
                    case 'textList':
                      let itemVar = undefined
                      try { //cpcdoc hago esto para evitar errores si no hay elementos Text
                        itemVar = this.itemsInSynoptic?.Text.find(it => it.id === valueControl);
                      } catch (error) {
                      }
                      if (itemVar === undefined) {
                        valueControl = '';
                      } else {
                        valueControl = itemVar.parameters.namegeneral;
                      }
                      break;
                  }

                  let disabled = false;
                  if (disabledParameter === 'value') {
                    disabled = (valueControl !== '');
                  }

                  if (this.optionItem?.isClone && (itemKey + key === 'namegeneral' || itemKey + key === 'descriptiongeneral' || itemKey + key === 'xgeneral' || itemKey + key === 'ygeneral' || itemKey + key === 'widthgeneral' || itemKey + key === 'heightgeneral')) {
                    disabled = true;
                  }

                  this.optionsDynForm.addControl(itemKey, new FormControl({ value: valueControl, disabled }, Validators.required));
                }
              }
            }
            // this.optionsDynForm.addControl('evtta', new FormControl( 'down', Validators.required));
          } else {
            // tslint:disable-next-line: forin
            for (const itemKey in data.item.type.parameters[key]) {
              if (itemKey.hasOwnProperty('vars')) {
                for (const namevar in itemKey['vars']) {
                  this.optionsDynForm.addControl(itemKey + '_' + namevar, new FormControl('', Validators.required));
                }
              } else {
                this.optionsDynForm.addControl(itemKey, new FormControl('', Validators.required));
              }
            }
          }

          //this.tabs.push(key);
          this.tabs.push(tab);

          if (haveConnect) {
            try {
              const event = { target: { value: this.getvalues.thingconnect } };
              await this.thingSelected(event);
            } catch (error) {

            }
          }

          if (haveConnect && this.getvalues.lineconnect !== 'default') {

            // const event = { target: { value: this.getvalues.lineconnect } };

            // await this.lineSelected(event);
            // event.target.value = this.getvalues.thingconnect;
            // await this.thingSelected(event);
            // setTimeout(() => {
            // this.setParametersValue(this.getvalues);
            // }, 200);
            // document.getElementById('thingconnect')['value'] = this.getvalues.thingconnect;
          } else {
            setTimeout(() => {
              this.setParametersValue(this.getvalues);
            }, 200);
          }
        }
      }
    }));
  }

  setParametersValue(parValues: object): void {

    const params = this.parameters;
    Object.keys(params).forEach(tab => {
      let tabKey = tab;
      Object.keys(params[tab]).forEach(field => {
        if (params[tab][field].hasOwnProperty('condition')) {
          if (document.getElementById(params[tab][field].condition.parameter)['value'] === params[tab][field].condition.option) {
            document.getElementById(field + tab).parentElement.style.display = '';
          } else {
            document.getElementById(field + tab).parentElement.style.display = 'none';
          }
        }
      });
    });

    // this.values = parValues; // this.this.getvalues;
    // const keys = Object.keys(parValues);
    // keys.forEach(key => {
    //  document.getElementById(key)['value'] = parValues[key];
    // });

    // Object.keys(data.type.parameters[key])
    // return ret;
  }

  /**
   * Updates range value input
   * @param val Range value
   */
  updateRange(val): void {
    const newValue = ($(`#${val}`) as any)[0].value;
    ($(`#range_${val}`) as any)[0].value = newValue;
  }

  checkBoxValue(event, controlName): void {
    this.optionsDynForm.controls[controlName].setValue(event.target.checked);
  }

  visor(event) {
    this.globals[event.target.id]();
  }

  controlselect(event): void {
    const params = this.parameters;
    Object.keys(params).forEach(tab => {
      let tabKey = tab;
      Object.keys(params[tab]).forEach(field => {
        if (params[tab][field].hasOwnProperty('condition')) {
          if (document.getElementById(params[tab][field].condition.parameter)['value'] === params[tab][field].condition.option) {
            document.getElementById(field + tab).parentElement.style.display = '';
          } else {
            document.getElementById(field + tab).parentElement.style.display = 'none';
          }
        }
      });
    });
  }

  async cloneSubmit(): Promise<any> {
  }

  async cancelClone(): Promise<any> {
  }

  async optionSubmit(): Promise<any> {

    this.optionItem.parameters = {};
    this.optionItem.name = this.optionsDynForm.value.name;
    this.optionItem.description = this.optionsDynForm.value.description;
    if (this.optionsDynForm.value.hasOwnProperty('thingtype')) {
      this.optionItem.thingtype = this.optionsDynForm.value.thingtype;
    }
    this.tabs.forEach(tab => {
      tab.controls[0].forEach(control => {

        let extra = this.optionItem.type.parameters[tab.key][control]?.extra;

        switch (extra) {
          case 'multiple':
            this.optionItem.parameters[control + tab.key] = [];
            const firstRow = this.values[control + tab.key][0];

            this.multiples[control + tab.key].forEach(element => {
              const newRow = {};
              Object.keys(firstRow).forEach(valueKey => {
                newRow[valueKey] = this.optionsDynForm.value[`${control}${tab.key}_${valueKey}_${element}`];
              });
              this.optionItem.parameters[control + tab.key].push(newRow);
            });

            break;
          default:


            if (this.optionItem.type.parameters[tab.key][control].hasOwnProperty('vars')) {
              if (this.values.hasOwnProperty(control + '_active')) {
                this.optionItem.parameters[control + '_active'] = '1';
                const varsCtrl = this.optionItem.type.parameters[tab.key][control].vars;
                varsCtrl.forEach(varCtrl => {
                  let valueControl = '';
                  try {
                    valueControl = this.optionsDynForm.value[control + '_' + varCtrl];
                  } catch (error) {
                    valueControl = document.getElementById(control + '_' + varCtrl)['value'];
                  }

                  /*
                  if (varCtrl === 'variableconnect') {
                    const aliasVar = this.alias.find(al => al.name === valueControl);
                    if (aliasVar === undefined) {
                      valueControl = '';
                    } else {
                      valueControl = aliasVar._id;
                    }
                  }
                  */

                  if (varCtrl === 'variableconnect') {
                    const aliasVar = this.tags.find(al => al.name === valueControl);
                    if (aliasVar === undefined) {
                      valueControl = '';
                    } else {
                      valueControl = aliasVar._id;
                    }
                  }

                  if (varCtrl === 'synoptic_id') {
                    const synVar = this.synoptics.find(syn => syn.name === valueControl);
                    if (synVar === undefined) {
                      valueControl = '';
                    } else {
                      valueControl = synVar._id;
                    }
                  }

                  if (varCtrl === 'report_id') {
                    if (this.reports !== undefined) {
                      const reportVar = this.reports.find(syn => syn.name === valueControl);
                      if (reportVar === undefined) {
                        valueControl = '';
                      } else {
                        valueControl = reportVar._id;
                      }
                    }
                  }

                  this.optionItem.parameters[control + '_' + varCtrl] = valueControl; //document.getElementById(control + '_' + varCtrl)['value'];
                });
              }
            } else {
              if (document.getElementById(control + tab.key) !== null) {

                let valueControl = document.getElementById(control + tab.key)['value'];
                valueControl = this.optionsDynForm.value[control];

                switch (control + tab.key) {
                  case "synopticgeneral":
                    const synVar = this.synoptics.find(syn => syn.name === valueControl);
                    if (synVar === undefined) {
                      valueControl = '';
                    } else {
                      valueControl = synVar._id;
                    }
                    break;
                }

                switch (this.optionItem.type.parameters[tab.key][control].type) {
                  case 'textList':
                    let itemVar = undefined;
                    try { //cpcdoc hago esto para evitar errores si no hay elementos Text
                      itemVar = this.itemsInSynoptic?.Text.find(it => it.parameters.namegeneral === valueControl);
                    } catch (error) {

                    }
                    if (itemVar === undefined) {
                      valueControl = '';
                    } else {
                      valueControl = itemVar.id;
                    }
                    break;
                }
                if (valueControl === false) {
                  valueControl = 'false';
                }
                this.optionItem.parameters[control + tab.key] = valueControl;
              }
            }
            break;
        }
      });
    });

    // (<any>$('#modalAlert')).modal('show');
    let okSubmit = true;
    let itemFind: [any];
    switch (this.optionItem.mode) {
      case 'item':
        if (this.optionItem.name === '') {
          this.modalAlert(this.language['form_error'], this.language['form_error_empty_name'], this.language['form_error_buttonclose'], '#namegeneral');
          okSubmit = false;
        } else {
          itemFind = await this.systemServ.getItemBy({ _id: this.optionItem.id });
          if (itemFind.length > 0 && !this.optionItem?.isClone) {
            const elem = itemFind.find(item => item._id !== this.optionItem.id);
            if (elem !== undefined) {
              this.modalAlert(this.language['form_error'], this.language['form_error_exist_name_item'], this.language['form_error_buttonclose'], '#namegeneral');
              okSubmit = false;
            }
          }
        }

        if (this.propAssign.hasOwnProperty(this.optionItem.typeName) === true) {
          for (const prop of this.propAssign[this.optionItem.typeName]) {

            this.props_code[prop]['submit']();
            /*
            try {
              const jsGlobal = this.scriptsElements.scriptProperties.find(scr => scr.name === (prop + '.js')).content;
              const codeProp = "const app = this;" + jsGlobal + ";prop_submit();";
              eval(codeProp);
            } catch (error) {
              //console.log(error);
            }
            */
          }
        }

        break;
      case 'report_template':
      case 'report':
      case 'synoptic':
        if (this.optionItem.name === '') {
          this.modalAlert(this.language['form_error'], this.language['form_error_empty_name'], this.language['form_error_buttonclose'], '#namegeneral');
          okSubmit = false;
        } else {
          const itemFind: [any] = await this.systemServ.getItemBy({ name: this.optionItem.name, type: this.optionItem.synopticType });
          if (itemFind.length > 0) {
            const elem = itemFind.find(item => item._id !== this.optionItem.id);
            if (elem !== undefined || this.clone) {
              this.modalAlert(this.language['form_error'], this.language['form_error_exist_name_synoptic'], this.language['form_error_buttonclose'], '#namegeneral');
              okSubmit = false;
            }
          }
        }

        if (this.propAssign.hasOwnProperty(this.optionItem.typeName) === true) {
          for (const prop of this.propAssign[this.optionItem.typeName]) {

            this.props_code[prop]['submit']();
            /*
            try {
              const jsGlobal = this.scriptsElements.scriptProperties.find(scr => scr.name === (prop + '.js')).content;
              const codeProp = "const app = this;" + jsGlobal + ";prop_submit();";
              eval(codeProp);
            } catch (error) {
              //console.log(error);
            }
            */
          }
        }
        break;
    }

    if (okSubmit) {
      try {
        this.optionItem.parameters = {...itemFind[0].parameters, ...this.optionItem.parameters};
      } catch (error) {}
      this.shaServ.emitItem('action', this.optionItem);
      this.closeOption();
    }
  }

  closeAlert() {
    setTimeout(() => {
      const element = this.renderer.selectRootElement(this.modalAlertMsg.inputDoFocus);
      element.focus();
    }, 200);

  }

  visorShow() {
    //(<any>$('#modalVisor')).modal('show');
  }

  visorHide() {
    (<any>$('#modalVisor')).modal('hide');
  }

  confirmItemToDelete($event) {
    $('#itemToDelete').html(this.optionItem.name);
  }

  deleteItem($event) {
    this.shaServ.emitDeleteItem('delete', this.optionItem);
    this.closeOption();

    (<any>$('#deleteItem')).modal('hide');
  }

  modalAlert(title, message, button, inputDoFocus) {
    this.modalAlertMsg.title = title;
    this.modalAlertMsg.message = message;
    this.modalAlertMsg.button = button;
    this.modalAlertMsg.inputDoFocus = inputDoFocus;
    (<any>$('#modalAlert')).modal('show');
  }

  deleteItem2($event) {
    this.shaServ.emitDeleteItem('delete2', this.optionItem);
    this.closeOption();
    (<any>$('#deleteItem2')).modal('hide');
  }

  closeOption() {
    $('#optionsdyn').hide();
  }

  addThingType($event) {
    $('#fields').hide();
    $('#add_field_btn').show();
  }

  addThingTypeSubmit() {
    if (this.thingTypeForm.status !== 'INVALID') {
      const thingType = {
        name: this.thingTypeForm.value.t_name,
        description: this.thingTypeForm.value.t_description
      };
      this.shaServ.emitItem('thingType', thingType);
      const documentDefinition = {
        name: this.thingTypeForm.value.d_name,
        tag: this.thingTypeForm.value.d_tag,
        enabled: true,
        persistData: true,
        externalId: this.thingTypeForm.value.d_externalID,
        thingType: [{ id: thingType.name }],
        fields: this.fieldItems
      };
      this.shaServ.emitItem('documentDefinition', documentDefinition);
    }
  }

  addFieldItem() {
    const field = {
      name: this.thingTypeForm.value.f_name,
      tag: this.thingTypeForm.value.f_tag,
      type: this.thingTypeForm.value.f_type,
      containsThingTime: false,
      containsExternalThingId: false,
      containsDocumentDefinitionId: false,
      nullable: true,
      fields: null,
      fieldAccessor: this.thingTypeForm.value.f_accessor
    };
    if ($('#arrayPos').val() !== 'undefined') {
      const pos: any = $('#arrayPos').val();
      this.fieldItems[pos] = field;
    } else {
      this.fieldItems.push(field);
    }
    this.changeFieldForm('');
    this.cancelAddField();
  }

  addField() {
    $('#fields').show();
    $('#add_field_btn').hide();
    $('#cancel_add_field_btn').show();
    $('#addFieldButton').show();
    $('#modifyFieldButton').hide();
    $('#arrayPos').val('undefined');
    this.changeFieldForm('');
  }

  cancelAddField() {
    $('#fields').hide();
    $('#add_field_btn').show();
    $('#addFieldButton').hide();
    $('#cancel_add_field_btn').hide();
    this.changeFieldForm('');
  }

  changeFieldForm(val) {
    const idFieldForm = ['f_name', 'f_tag', 'f_type', 'f_accessor'];
    idFieldForm.forEach(element => {
      if (val === '') {
        $(`#${element}`).val(val);
        this.thingTypeForm.value[element] = val;
      } else {
        const key: any = element.split('_')[1];
        if (key === 'accessor') {
          $(`#${element}`).val(val.fieldAccessor);
          this.thingTypeForm.value[element] = val.fieldAccessor;
        } else {
          $(`#${element}`).val(val[key]);
          this.thingTypeForm.value[element] = val[key];
        }
      }
    });
  }

  editField(event) {
    this.addField();
    const fieldName = event.target.dataset.fieldname;
    const fieldItem = this.fieldItems.filter((element, index) => {
      if (element.name === fieldName) {
        $('#arrayPos').val(index);
        return element;
      }
    });

    $('#addFieldButton').hide();
    $('#modifyFieldButton').show();
    this.changeFieldForm(fieldItem[0]);
  }

  removeField(event) {
    const fieldName = event.target.dataset.fieldname;
    const indexField = this.fieldItems.map(element => element.name).indexOf(fieldName);
    this.fieldItems.splice(indexField, 1);
  }

  /**
   * When a line was selected
   * @param event event
   */
  async lineSelected(event): Promise<any> {

    const linea: any = await this.itemsServ.findItem(event.target.value);
    try {
      await this.moduleServ.setBaseUrl(linea.parameters.general.ip);
      await this.moduleServ.loginModule();
      const things: any = await this.moduleServ.getThings();

      await this.getThingsOfLine(event.target.value, things.entities);
    } catch (error) {

    }
    this.fields = [];
  }

  async getThingsOfLine(idLine, allThings) {
    this.things = [];
    const items = await this.itemsServ.getModelizerItems();
    const things = await this.itemsServ.getItemsTypes('thing', items);
    things.map(elem => {
      if (elem.parent === idLine) {
        allThings.map(e => {
          if (parseInt(elem.thingId) === e.id) {
            this.things.push(e);
          }
        });
      }
    });
  }

  shide(type) {
    // this.values[type + 'expert'] = '';
    this.dynHid[type] = true;
  }

  sshow(type) {
    // this.values[type + 'expert'] = '';
    delete this.dynHid[type];

    if (type === 'runscript') {

      if (this.values.hasOwnProperty('runscript_event_type') === false) {
        setTimeout((tget) => {
          this.scriptSelect(tget);
        }, 200, { id: 'runscript_event_type', value: '' });
      }
    }
  }

  ctrlSelected(event) {
    Object.keys(this.tabs).forEach(keytab => {
      const found: number = this.tabs[keytab].controls[0].indexOf(event.target.value);
      if (found !== -1) {
        this.tabs[keytab].controls[0].splice(found, 1);
        this.tabs[keytab].controls[0].splice(this.tabs[keytab].controls[0].length - 1, 0, event.target.value);
        this.sshow(event.target.value);
        // this.tabs[keytab].controls[0].push(event.target.value);
      }
    });

    this.values[event.target.value + '_active'] = '1';
    event.target['value'] = 'default';

  }

  closeDyn(type) {
    // this.values[type + '_active'] = '0';
    delete this.values[type + '_active'];
    delete this.dynHid[type];
  }

  async loadVariables() {
    const variables: any = await this.tagServ.findAll();
    variables.map(item => {
      const name = `${item.thing.name}.${item.name}`;
      const variable = { name, _id: item._id };
      this.alias.push(variable);
    });
  }

  loadTags(tags_, things) {

    // const tags: any = await this.tagServ.findAll();

    this.globals['alltags'] = tags_;
    this.globals['things'] = things; //await this.systemServ.getItemBy({ type: '5f86d624821fd6593dd8806e' });

    this.globals['alltags'].map(item => {
      try {
        const name = `${item.thing?.name}.${item.name}`;
        const tag = { name, _id: item._id };
        this.tags.push(tag);
      } catch (error) {
        console.log(item.name, error);
      }

    });
  }

  async uploadImage(e) {
    const id = this.optionItem.id;
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      document.getElementById('imagePathgeneral')['value'] = 'images/' + file.name;
      this.optionsDynForm.value['imagePath'] = 'images/' + file.name;
      this.itemsServ.updateFile(id, file);
      // this.sharedServ.emitReport('upload', this.reportItem);
    }
  }

  /*
  updateFile(id, file) {
    const fd = new FormData();
    fd.append('image', file);
    return this.http.put(`${this.BASE_URL}/v1/api/report_items/file/${id}`, fd,
      { headers: this.createHeaders.getTokenHeader() }).toPromise();
  }
  */

  addMultiple(tag, key) {
    // get elements for new (first row of values)
    const firstRow = this.values[tag][0];
    const newKey = [...this.multiples[tag]].sort((a, b) => b - a)[0] + 1;
    Object.keys(firstRow).forEach(valueKey => {
      this.optionsDynForm.addControl(`${tag}_${valueKey}_${newKey}`, new FormControl('', Validators.required));
    });
    this.multiples[tag].splice(this.multiples[tag].indexOf(key) + 1, 0, newKey);
    return false;
  }

  delMultiple(tag, key) {
    if (this.multiples[tag].length > 1) {
      // get elements for new (first row of values)
      const firstRow = this.values[tag][0];
      Object.keys(firstRow).forEach(valueKey => {
        this.optionsDynForm.removeControl(`${tag}_${valueKey}_${key}`);
      });
      this.multiples[tag].splice(this.multiples[tag].indexOf(key), 1);
    }

    return false;
  }

  snippet(cm) {

    console.log(this.globals);

    var snippets = [];
    snippets.push({ text: 'var1', displayText: 'var1' });
    snippets.push({ text: 'var2', displayText: 'var2' });
    snippets.push({ text: 'var3', displayText: 'var3' });
    snippets.push({ text: 'var4', displayText: 'var4' });
    snippets.push({ text: 'par5', displayText: 'par5' });

    var cursor = cm.getCursor();
    var token = cm.getTokenAt(cursor);
    var start = token.start;
    var end = cursor.ch;
    var line = cursor.line;
    var currentWord = token.string;
    console.log(currentWord, start, end);

    const pfrom = CodeMirror.Pos(line, start);
    const pto = CodeMirror.Pos(line, end);
    // alert(currentWord)
    var tokenPrev = cm.getTokenAt({ line: cursor.line, ch: cursor.ch - 1 });

    var list = snippets.filter(function (item) {
      return item.text.indexOf(currentWord) >= 0;
    });

    var options = {

      hint: function () {
        return {
          list: list.length ? list : snippets,
          from: pfrom,
          to: pto
        }
      }
    };
    cm.showHint(options);
  }

  bsnippet(cm) {

    var snippets = [];
    snippets.push({ text: 'var1', displayText: 'var1' });
    snippets.push({ text: 'var2', displayText: 'var2' });
    snippets.push({ text: 'var3', displayText: 'var3' });
    snippets.push({ text: 'var4', displayText: 'var4' });
    snippets.push({ text: 'var5', displayText: 'var5' });

    var options = {
      hint: function () {
        return {
          from: cm.getDoc().getCursor(),
          to: cm.getDoc().getCursor(),
          list: snippets
        }
      }
    };
    cm.showHint(options);
  }

  asnippet(cm) {


    var cursor = cm.getCursor();
    var token = cm.getTokenAt(cursor);
    var start = token.start;
    var end = cursor.ch;
    var line = cursor.line;
    var currentWord = token.string;
    console.log(currentWord);
    // alert(currentWord)
    var tokenPrev = cm.getTokenAt({ line: cursor.line, ch: cursor.ch - 1 });

    cm.showHint(cm, function () {
      // var snippets = [{ text: 'prospero', displayText: 'prospero' }];

      var snippets = [];
      snippets.push({ text: 'var1', displayText: 'var1' });
      snippets.push({ text: 'var2', displayText: 'var2' });
      snippets.push({ text: 'var3', displayText: 'var3' });
      snippets.push({ text: 'var4', displayText: 'var4' });
      snippets.push({ text: 'var5', displayText: 'var5' });

      console.log(444);
      var list = snippets.filter(function (item) {
        return item.text.indexOf(currentWord) >= 0;
      });

      return {
        list: list.length ? list : snippets,
        from: cm.Pos(line, start),
        to: cm.Pos(line, end)
      };
    }, { completeSingle: false });


  }

}

CodeMirror.defineOption('inlineTags', [], function (cm, value, prev) {
  cm.on('inputRead', function (cm1, change) {

    if (change.text[0] === '.') {
      gsnippet(cm1);
      // console.log(lp.globals);
    }
  });
});

function gsnippet(cm) {

  var snippets = [];
  snippets.push({ text: 'var1', displayText: 'var1' });
  snippets.push({ text: 'var2', displayText: 'var2' });
  snippets.push({ text: 'var3', displayText: 'var3' });
  snippets.push({ text: '["boton arranque"]', displayText: 'boton arranque' });
  snippets.push({ text: 'par5', displayText: 'par5' });

  var cursor = cm.getCursor();
  var token = cm.getTokenAt(cursor);
  var start = token.start;
  var end = cursor.ch;
  var line = cursor.line;
  var currentWord = token.string;
  console.log(currentWord, start, end);

  const pfrom = CodeMirror.Pos(line, start);
  const pto = CodeMirror.Pos(line, end);
  // alert(currentWord)
  var tokenPrev = cm.getTokenAt({ line: cursor.line, ch: cursor.ch - 1 });

  var list = snippets.filter(function (item) {
    return item.text.indexOf(currentWord) >= 0;
  });

  var options = {

    hint: function () {
      return {
        list: list.length ? list : snippets,
        from: pfrom,
        to: pto
      }
    }
  };
  cm.showHint(options);
}
