<ng-container *ngFor="let syn of items.Synoptic">
  <ng-container *ngFor="let tag of syn.flexPanel">
    <div style="position: absolute" [id]="'it' + tag.id" [style]="getStyle(tag).styles"
      [style.left]="tag.parameters.xgeneral + 'px'" [style.width]="tag.parameters.widthgeneral + 'px'"
      [attr.name]="tag.parameters.namegeneral" class="noselect" [style.height]="tag.parameters.heightgeneral + 'px'"
      [style.top]="tag.parameters.ygeneral + 'px'" [attr.iditem]="tag.id"></div>
  </ng-container>

  <ng-container *ngFor="let tag of syn.Keyboard">
    <div *ngIf="player === false || false" style="position: absolute" [id]="'it' + tag.id"
      [style]="getStyle(tag).styles" [style.left]="tag.parameters.xgeneral + 'px'"
      [style.width]="tag.parameters.widthgeneral + 'px'" class="noselect"
      [style.height]="tag.parameters.heightgeneral + 'px'" [style.top]="tag.parameters.ygeneral + 'px'"
      [attr.iditem]="tag.id"><span class="material-icons">keyboard</span><span><br>{{ tag.parameters.kbtypegeneral
        }}</span><span *ngIf="tag.parameters.popupgeneral === true "><br>Modal popup</span></div>
  </ng-container>
  <ng-container *ngFor="let tag of syn.Keyboard">
    <canvas *ngIf="player === true && false" style="position: absolute" [id]="'it' + tag.id"
      [style]="getStyle(tag).styles" [style.left]="tag.parameters.xgeneral + 'px'"
      [style.width]="tag.parameters.widthgeneral + 'px'" class="noselect"
      [style.height]="tag.parameters.heightgeneral + 'px'" [style.top]="tag.parameters.ygeneral + 'px'"
      [attr.iditem]="tag.id">
    </canvas>
  </ng-container>
  <ng-container *ngFor="let tag of syn.Synoptic">
    <div style="position: absolute" [id]="'it' + tag.id" [style]="getStyle(tag).styles"
      [style.left]="tag.parameters.xgeneral + 'px'" [style.width]="'50px'" class="noselect material-icons synopticLink"
      [style.height]="'50px'" [style.top]="tag.parameters.ygeneral + 'px'" [attr.iditem]="tag.id">
      link
    </div>
  </ng-container>

  <ng-container *ngFor="let tag of syn.Field">
    <input type="text" style="position: absolute;  z-index: 2;" (click)="field_click($event)"
      [style.zIndex]="tag.parameters.zIndexgeneral" ondragend="console.log(44);" (focus)="field_Focus($event)"
      (blur)="field_Blur(tag)" (change)="field_Change($event,tag)"
      [attr.description]="tag.parameters.descriptiongeneral" name='{{ tag.parameters.namegeneral }}'
      [id]="'it' + tag.id" [style]="getStyle(tag).styles" [style.left]="tag.parameters.xgeneral + 'px'"
      [style.top]="tag.parameters.ygeneral + 'px'" [style.width]="tag.parameters.widthgeneral + 'px'"
      [style.height]="tag.parameters.heightgeneral + 'px'" [attr.iditem]="tag.id" [value]="valueSocket[tag.thingconnect + tag.variableconnect] === undefined || valueSocket[tag.thingconnect + tag.variableconnect] === '...'
          ? ''
          : tag.parameters?.variable_numdecimals
          ? (valueSocket[tag.thingconnect + tag.variableconnect] * 1).toFixed(
              tag.parameters.variable_numdecimals
            )
          : valueSocket[tag.thingconnect + tag.variableconnect]
      " />
  </ng-container>
  <ng-container *ngFor="let tag of syn.Combo">
    <select type="text" style="position: absolute" [id]="'it' + tag.id" [style]="getStyle(tag).styles"
      name='{{ tag.parameters.namegeneral }}' [style.left]="tag.parameters.xgeneral + 'px'"
      (change)="select_Change($event,tag)" [style.top]="tag.parameters.ygeneral + 'px'"
      [style.width]="tag.parameters.widthgeneral + 'px'" [style.height]="tag.parameters.heightgeneral + 'px'"
      [attr.iditem]="tag.id">
      <ng-container *ngFor="
                        let option of tag.parameters.optionsgeneral
                      ">
        <option [value]="option.value">
          {{ option.text }}
        </option>
      </ng-container>
    </select>
  </ng-container>
  <ng-container *ngFor="let tag of syn.Button">
    <button (mouseup)="mouseUp($event)" (mouseout)="mouseOut($event)" type="button" [style]="getStyle(tag).styles"
      (click)="mouseClick($event)" (mousedown)="mouseDown($event)" [attr.name]="tag.parameters.namegeneral"
      style="position: absolute; z-index: 2;" [id]="'it' + tag.id" class="buttonnew"
      [style.zIndex]="tag.parameters.zIndexgeneral" [style.left]="tag.parameters.xgeneral + 'px'"
      [style.width]="tag.parameters.widthgeneral + 'px'" [style.height]="tag.parameters.heightgeneral + 'px'"
      [style.top]="tag.parameters.ygeneral + 'px'" [attr.iditem]="tag.id">
      {{ tag.parameters.textgeneral }}
    </button>
  </ng-container>
  <ng-container *ngFor="let tag of syn.Text">
    <label style="position: absolute; z-index: 2;" [id]="'it' + tag.id" [style]="getStyle(tag).styles"
      [style.zIndex]="tag.parameters.zIndexgeneral" [style.left]="tag.parameters.xgeneral + 'px'"
      [style.width]="tag.parameters.widthgeneral + 'px'" [attr.name]="tag.parameters.namegeneral" class="noselect"
      [style.height]="tag.parameters.heightgeneral + 'px'" [style.top]="tag.parameters.ygeneral + 'px'"
      [attr.iditem]="tag.id">{{ tag.parameters.textgeneral }}</label>
  </ng-container>
  <ng-container *ngFor="let tag of syn.RP_Text">
    <label style="position: absolute" [id]="'it' + tag.id" [style]="getStyle(tag).styles"
      [style.zIndex]="tag.parameters.zIndexgeneral" [style.left]="tag.parameters.xgeneral + 'px'"
      [style.width]="tag.parameters.widthgeneral + 'px'" [attr.name]="tag.parameters.namegeneral" class="noselect"
      [style.height]="tag.parameters.heightgeneral + 'px'" [style.top]="tag.parameters.ygeneral + 'px'"
      [attr.iditem]="tag.id">{{ tag.parameters.textgeneral }}</label>
  </ng-container>
  <ng-container *ngFor="let tag of syn.GaugeNew">
    <ngx-gauge style="position: absolute" [id]="'it' + tag.id" [attr.iditem]="tag.id"
      [size]="tag.parameters.sizegeneral" [style]="getStyle(tag).styles" class="noselect"
      [style.left]="tag.parameters.xgeneral + 'px'" [attr.name]="tag.parameters.namegeneral"
      [style.top]="tag.parameters.ygeneral + 'px'" [type]="tag.parameters.typegauge"
      [value]="valueSocket[tag.thingconnect + tag.variableconnect]" [avalue]="tag.parameters.valuegauge"
      [label]="tag.parameters.labelgauge" [append]="tag.parameters.appendgauge">
    </ngx-gauge>
  </ng-container>
  <ng-container *ngFor="let tag of syn.linearGauge">
    <linear-gauge style="position: absolute" [id]="'it' + tag.id" [attr.iditem]="tag.id"
      [style.left]="tag.parameters.xgeneral + 'px'" [options]="tag.options" [style]="getStyle(tag).styles"
      [attr.name]="tag.parameters.namegeneral" [style.top]="tag.parameters.ygeneral + 'px'"
      [attr.width]="tag.parameters.widthgeneral" [attr.height]="tag.parameters.heightgeneral"
      [value]="valueSocket[tag.thingconnect + tag.variableconnect]"></linear-gauge>
  </ng-container>
  <ng-container *ngFor="let tag of syn.Image">
    <img style="position: absolute;" [id]="'it' + tag.id" [attr.iditem]="tag.id" class="noselect"
      name='{{ tag.parameters.namegeneral }}' [style.left]="tag.parameters.xgeneral + 'px'"
      [style.zIndex]="tag.parameters.zIndexgeneral" [style.top]="tag.parameters.ygeneral + 'px'"
      [attr.width]="tag.parameters.widthgeneral" [attr.height]="tag.parameters.heightgeneral"
      [src]="BASE_URL + '/' + tag.parameters.imagePathgeneral" />
  </ng-container>
  <ng-container *ngFor="let tag of syn.radialGauge">
    <radial-gauge style="position: absolute" [id]="'it' + tag.id" [attr.iditem]="tag.id"
      [style.left]="tag.parameters.xgeneral + 'px'" [options]="tag.options" [attr.name]="tag.parameters.namegeneral"
      [style]="getStyle(tag).styles" [onchange]="" class="noselect" [style.top]="tag.parameters.ygeneral + 'px'"
      [attr.width]="tag.parameters.widthgeneral" [attr.height]="tag.parameters.heightgeneral"
      [(value)]="valueSocket[tag.thingconnect + tag.variableconnect]"></radial-gauge>
  </ng-container>
  <ng-container *ngFor="let tag of syn.Slider">
    <ngx-slider style="position: absolute" [id]="'it' + tag.id" [attr.iditem]="tag.id"
      [style.left]="tag.parameters.xgeneral + 'px'" [style]="getStyle(tag).styles"
      (valueChange)="change_slider($event, tag)" [style.top]="tag.parameters.ygeneral + 'px'"
      [attr.name]="tag.parameters.namegeneral" [style.width]="tag.parameters.widthgeneral + 'px'"
      [style.height]="tag.parameters.heightgeneral + 'px'" [options]="{
        floor: tag.parameters.mingeneral,
        ceil: tag.parameters.maxgeneral
      }" [value]="valueSocket[tag.thingconnect + tag.variableconnect]"></ngx-slider>
  </ng-container>
  <ng-container *ngFor="let tag of syn.aCheck">
    <label style="position: absolute" [id]="'it' + tag.id" [attr.iditem]="tag.id"
      [style.left]="tag.parameters.xgeneral + 'px'" [style.top]="tag.parameters.ygeneral + 'px'"
      name='{{ tag.parameters.namegeneral }}' [style.width]="tag.parameters.widthgeneral + 'px'"
      [style.height]="tag.parameters.heightgeneral + 'px'">
      <input type="checkbox" (change)="check_Change($event,tag)" [attr.iditem]="tag.id"
        name='{{ tag.parameters.namegeneral }}' />
      <span></span>
    </label>
  </ng-container>

  <ng-container *ngFor="let tag of syn.Chart">
    <ngx-chartjs *ngIf="tag.parameters.stylegeneral === 'none'" style="position: absolute" [type]="tag.typeChart"
      [style]="getStyle(tag).styles" [id]="'it' + tag.id" [attr.iditem]="tag.id"
      [style.left]="tag.parameters.xgeneral + 'px'" name='{{ tag.parameters.namegeneral }}'
      [style.paddingTop]="tag.parameters.paddingTop" [style.top]="tag.parameters.ygeneral + 'px'"
      [width]="tag.parameters.widthgeneral" [height]="tag.parameters.heightgeneral" [options]="tag.options"
      [data]="tag.data"></ngx-chartjs>
  </ng-container>

  <ng-container *ngFor="let tag of syn.Table">
    <app-item-table [tag]="tag" [isPlayer]="player"></app-item-table>
  </ng-container>

  <ng-container *ngFor="let tag of syn.Shape">
    <div style="position: absolute" [id]="'it' + tag.id" [style]="getStyle(tag).styles"
      [style.zIndex]="tag.parameters.zIndexgeneral" [style.left]="tag.parameters.xgeneral + 'px'"
      [style.width]="tag.parameters.widthgeneral + 'px'" [attr.name]="tag.parameters.namegeneral" class="noselect"
      [style.height]="tag.parameters.heightgeneral + 'px'" [style.top]="tag.parameters.ygeneral + 'px'"
      [attr.iditem]="tag.id"></div>
  </ng-container>
</ng-container>
