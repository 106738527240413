<div class="container dashboard-container mt-lg-3">
    <div class="container mt-lg-3">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/administration">{{ otherText?.user_management }}</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">{{ otherText?.others }}</li>
            </ol>
        </nav>
    </div>


    <!-- Grid menu -->

    <div class="container grid-menu-container">
        <div class="row grid-menu-row">
            <div class="col-12 col-lg-3 col-md-6 grid-menu-elem mt-5 " routerLink="/administration/audittrail">
                <img src="assets/img/dashboard/new-project.svg" />
                <div class="grid-menu-title">Audit Trail</div>
            </div>
        </div>
    </div>
</div>
