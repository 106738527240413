import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as $ from 'jquery';
import { UsersService } from 'src/app/service/auth/users.service';
import { DatatableService } from 'src/app/service/datatable.service';
import { LanguageService } from 'src/app/service/system/language.service';
import { AuditTrailService } from 'src/app/service/auth/audit-trail.service';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.scss']
})
export class AuditTrailComponent implements OnInit, OnDestroy {
  @ViewChild('filterContainer') filterContainer: ElementRef;
  logs: any = [];
  $: any;
  table: any;
  users: any = [];
  filter: FormGroup;
  statusSelect: string;
  language: any = [];
  otherText: any;
  searchPlus = true;
  constructor(
    private auditServ: AuditTrailService,
    private dataServ: DatatableService,
    private userServ: UsersService,
    private langServ: LanguageService
  ) {
    this.filter = new FormGroup({
      username: new FormControl(null),
      action: new FormControl(null),
      status: new FormControl(null),
      date: new FormControl(null),
      dateTo: new FormControl(null)
    });
  }

  async ngOnInit(): Promise<any> {
    this.getUsers();
    await this.getAllLogs();
    this.initTable();
    const language: any = await this.langServ.getVal();
    this.language = language.auditTrail;
    this.otherText = language.administration.others;

  }
  ngOnDestroy(): void {
    (<any>$('#auditTable')).dataTable().fnDestroy();
  }

  initTable() {
    (<any>$('#auditTable')).dataTable().fnDestroy();
    setTimeout(async () => {
      // Get options from datatable service
      const options = await this.dataServ.optionsDatatable();
      // Execute datatable function to load it

      (<any>$('#auditTable')).dataTable(options);

    }, 250);
  }
  async getUsers(): Promise<any> {
    this.users = await this.userServ.getUsers();
  }
  async getAllLogs(): Promise<any> {
    this.logs = await this.auditServ.getAllLogs();
  }

  async filterLogs() {
    (<any>$('#auditTable')).dataTable().fnDestroy();


    const filter: any = {};

    // tslint:disable-next-line: forin
    for (const value in this.filter.value) {

      if (!(this.filter.value[value] === null)) {
        filter[`${value}`] = this.filter.value[value];
      }
    }

    // Set date filter
    if (!(this.filter.value.date === null)) {
      let end;
      const start = this.filter.value.date + 'T00:00:00.000Z';
      if (this.filter.value.dateTo === null) {
        end = this.filter.value.date + 'T23:59:00.000Z';
      } else {
        end = this.filter.value.dateTo + 'T23:59:00.000Z';
        delete filter.dateTo;
      }

      filter.date = { $gte: start, $lte: end };
    }
    this.logs = await this.auditServ.filterLogs(filter);

    this.initTable();


  }

  changeSelect(status) {
    this.statusSelect = status;
  }

  resetFilter(): void {
    this.filter.reset();
    this.ngOnInit();
  }

  // toggle form filter display
}
