import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from 'src/app/service/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  /**
   * Constructor
   * @param authServ auth service
   * @param router router
   */
  constructor(private authServ: AuthService, private router: Router) {}

  /**
   * Validates if the the user is logged
   */
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    try {
      const targetURL = state.url.toLowerCase();
      const isTokenValid = this.authServ.getToken() && this.authServ.isLogged();
      const isLoginPage = targetURL.includes('login');

      if (isTokenValid) {
        if (isLoginPage) {
          this.router.navigate(['']);
        }
        return true;
      }

      if (!isTokenValid && !isLoginPage) {
        this.router.navigate(['login']);
        return false;
      }
      return true;
    } catch (e) {
      console.error(
        'Error when trying to authenticate the user session.\n\n',
        e
      );
      return false;
    }
  }
}
