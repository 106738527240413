<app-header-component></app-header-component>

<div class="body-container">
  <mat-list style="grid-row: 1 / 2; overflow-y: auto;">
    <div mat-subheader>Data Designs</div>
    <mat-list-item *ngIf="!isCreatingDataDesign" (click)="isCreatingDataDesign = true">
      <mat-icon mat-list-icon>add</mat-icon>
      <div mat-line>{{ language?.create || 'Create' }}</div>
    </mat-list-item>
    <mat-list-item *ngIf="isCreatingDataDesign" [style.min-height.px]="75" [style.align-content]="'center'">
      <div mat-line>
        <form id="dataDesignForm" #dataDesignForm="ngForm" [formGroup]="createDataDesignForm"
          (ngSubmit)="createDataDesign(); createDataDesignForm.reset(); dataDesignForm.resetForm()">
          <mat-form-field>
            <mat-label>{{ language?.data_design_name || 'Data Design Name' }}</mat-label>
            <input matInput formControlName="name" type="text"
              [placeholder]="language?.data_design_name_placeholder || 'Ex. Controles'"
              (blur)="isCreatingDataDesign = false; createDataDesignForm.reset(); dataDesignForm.resetForm()">
          </mat-form-field>
        </form>
      </div>
      <mat-icon mat-button (click)="isCreatingDataDesign = false">check</mat-icon>
    </mat-list-item>
    <mat-list-item *ngFor="let dataDesign of dataDesigns" (click)="selectDataDesign(dataDesign._id)"
      [ngStyle]="{ backgroundColor: dataDesignSelected?._id === dataDesign._id ? 'rgb(0, 0, 0, 0.08)' : '' }">
      <mat-icon mat-list-icon>storage</mat-icon>
      <div mat-line>{{ dataDesign.name}}</div>
      <button mat-icon-button class="button-list-delete"
        [ngStyle]="{ visibility: dataDesignSelected?._id === dataDesign._id ? 'visible' : '' }"
        (click)="dialogDeleteDataDesign = true; dataDesignToDelete = dataDesign; $event.stopPropagation();">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-list-item>
  </mat-list>
  <div class="body-container-main">
    <div *ngIf="dataDesignSelected != null" class="data-design">
      <h2 class="data-design-title">{{ dataDesignSelected.name }}</h2>
      <div class="options-container">
        <button mat-button class="button-list" (click)="dialogCreateField = true;">
          <mat-icon>add</mat-icon><span>{{ language?.new || 'New' }}</span>
        </button>
        <button mat-button class="button-list" [disabled]="tableElement.selection.selected.length !== 1"
          (click)="dialogCreateField = true; isEditing = true;">
          <mat-icon>edit</mat-icon><span>{{ language?.edit || 'Edit' }}</span>
        </button>
        <button mat-button class="button-list" [disabled]="tableElement.selection.selected.length < 1"
          (click)="dialogDeleteField = true;">
          <mat-icon>delete</mat-icon><span>{{ language?.delete || 'Delete' }}</span>
        </button>
      </div>
      <app-table #tableElement [headers]="tableHeaders" [data]="tableData" [sortable]="false"
        [sortDefault]="{ header: 'name', direction: 'asc' }" [paginable]="true" [pageSizeOptions]="[10]"
        rowStyleStatic="font-size: 16px;" headerRowStyleStatic="font-size: 16px; font-weight: bold;"></app-table>
    </div>
  </div>
</div>

<app-dialog [(isOpened)]="dialogDeleteField">
  <div title>{{ language?.delete_fields || 'Delete Fields' }}</div>
  <div body>
    <p>{{ language?.delete_fields_confirm || 'Are you sure you want to delete the selected fields?' }}</p>
  </div>
  <div footer>
    <button mat-button (click)="dialogDeleteField = false;">{{ language?.cancel || 'Cancel' }}</button>
    <button mat-button
      (click)="dialogDeleteField = false; deleteFields(dataDesignSelected!, tableElement.selection.selected)">
      {{ language?.delete || 'Delete' }}
    </button>
  </div>
</app-dialog>

<app-dialog [(isOpened)]="dialogDeleteDataDesign">
  <div title>{{ language?.delete_data_design || 'Delete DataDesign' }}</div>
  <div body>
    <p>{{ language?.delete_data_design_confirm || 'Are you sure you want to delete the DataDesign?' }}</p>
  </div>
  <div footer>
    <button mat-button (click)="dataDesignToDelete = null; dialogDeleteDataDesign = false;">
      {{ language?.cancel || 'Cancel' }}
    </button>
    <button mat-button (click)="dialogDeleteDataDesign = false; deleteDataDesign(dataDesignToDelete?._id || null)">
      {{ language?.delete || 'Delete' }}
    </button>
  </div>
</app-dialog>

<app-dialog [(isOpened)]="dialogCreateField"
  (isOpenedChange)="isEditing = false; createFieldForm.reset(); fieldForm.resetForm();">
  <div title>{{ language?.add_field || 'Add Field' }}</div>
  <div body>
    <form id="fieldForm" #fieldForm="ngForm" [formGroup]="createFieldForm"
      (ngSubmit)="fieldFormSubmit(); createFieldForm.reset(); fieldForm.resetForm()"
      style="display: flex; flex-direction: column; min-width: 225px; gap: 15px;">
      <mat-form-field>
        <mat-label>{{ language?.field_name || 'Field Name' }}</mat-label>
        <input matInput [required]="!isEditing" formControlName="name" type="text" placeholder="Ex. Valor"
          [value]="isEditing ? tableElement.selection.selected[0]?.name : ''">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ language?.data_type || 'Data Type' }}</mat-label>
        <mat-select [value]="isEditing ? tableElement.selection.selected[0]?.type : null" formControlName="type"
          [disabled]="isEditing" [required]="!isEditing">
          <mat-option selected value="number">{{ language?.number || 'Number' }}</mat-option>
          <mat-option value="text">{{ language?.text || 'Text' }}</mat-option>
          <mat-option value="boolean">{{ language?.boolean || 'True/False' }}</mat-option>
          <mat-option value="date">{{ language?.date || 'Date' }}</mat-option>
          <mat-option value="datetime-local">{{ language?.datetime || 'Date and Time' }}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div footer>
    <button mat-button
      (click)="dialogCreateField = false; isEditing = false; createFieldForm.reset(); fieldForm.resetForm();">
      {{ language?.cancel || 'Cancel' }}
    </button>
    <button mat-button form="fieldForm" type="submit" [disabled]="!createFieldForm.valid">
      {{ language?.add || 'Add' }}
    </button>
  </div>
</app-dialog>
