import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

interface Field {
  _id: string;
  name: string;
  type: 'text' | 'number' | 'date' | 'datetime-local' | 'boolean';
}

interface DataDesign {
  _id: string;
  name: string;
  fields: Field[];
}

interface Value {
  field_id: string;
  value: string | number | boolean | Date | null;
}

interface DataDesignValue {
  _id: string;
  datadesign_id: string;
  values: Value[];
}

interface QueryParams {
  name?: string;
  name_sort?: 'asc' | 'desc';
  limit?: number;
}

interface DataDesignResponse {
  message: string;
  data: DataDesign;
}

interface DataDesignValueResponse {
  message: string;
  data: DataDesignValue;
}

@Injectable({
  providedIn: 'root',
})
export class DataDesignService {
  private BASE_URL: string = environment.base_url;
  private API_URL: string = `${this.BASE_URL}/v1/api/datadesign`;
  private API_URL_VALUES: string = `${this.BASE_URL}/v1/api/datadesign/values`;

  constructor(private http: HttpClient) {}

  findOne(id: string): Promise<DataDesign> {
    return this.http.get<DataDesign>(`${this.API_URL}/${id}`).toPromise();
  }

  findAll(params?: QueryParams): Promise<DataDesign[]> {
    const stringParams = '?' + new URLSearchParams(params as any).toString();
    return this.http.get<DataDesign[]>(this.API_URL + stringParams).toPromise();
  }

  create(dataDesign: Omit<DataDesign, '_id'>): Promise<DataDesignResponse> {
    return this.http
      .post<DataDesignResponse>(this.API_URL, dataDesign)
      .toPromise();
  }

  update(dataDesign: DataDesign): Promise<DataDesignResponse> {
    return this.http
      .put<DataDesignResponse>(`${this.API_URL}/${dataDesign._id}`, dataDesign)
      .toPromise();
  }

  delete(id: string): Promise<{ message: string }> {
    return this.http
      .delete<{ message: string }>(`${this.API_URL}/${id}`)
      .toPromise();
  }

  findAllValues(dataDesign_id: string): Promise<DataDesignValue[]> {
    return this.http
      .get<DataDesignValue[]>(
        `${this.API_URL_VALUES}?datadesign_id=${dataDesign_id}`
      )
      .toPromise();
  }

  createValue(
    dataDesign: Omit<DataDesignValue, '_id'>
  ): Promise<DataDesignValueResponse> {
    return this.http
      .post<DataDesignValueResponse>(this.API_URL_VALUES, dataDesign)
      .toPromise();
  }

  updateValue(
    dataDesignValue: DataDesignValue
  ): Promise<DataDesignValueResponse> {
    return this.http
      .put<DataDesignValueResponse>(
        `${this.API_URL_VALUES}/${dataDesignValue._id}`,
        dataDesignValue
      )
      .toPromise();
  }

  deleteValue(id: string): Promise<{ message: string }> {
    return this.http
      .delete<{ message: string }>(`${this.API_URL_VALUES}/${id}`)
      .toPromise();
  }
}
