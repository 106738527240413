<div class="container mt-lg-3">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/administration">{{ permText?.user_management }}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{ permText?.permissions }}</li>
        </ol>
    </nav>
    <div class="row">
        <div class="col-12" id="divPerms" style="visibility: hidden;">
            <table id="permTable" class="table row-border order-column hover">
                <thead>
                    <tr>
                        <th>{{ permText?.name }}</th>
                        <th>{{ permText?.description }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let permit of permits">
                        <td>{{ permit.name }}</td>
                        <td>{{ permit.description }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- Toast -->
    <div role="alert" aria-live="assertive" aria-atomic="true" class="toast">
        <div class="toast-header">
            <img src="assets/img/logo.png" class="logo-toast" alt="Logo">
        </div>
        <div class="toast-body"></div>
    </div>
    <!-- Modal add permit -->
    <div class="modal fade bd-modal-addPermit" tabindex="-1" role="dialog" aria-labelledby="Añadir un permiso"
        aria-hidden="true" id="addPermitModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalAddPermitTitle">{{ permText?.add_permission }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form class="m-4" [formGroup]="addPermitForm" (ngSubmit)="addPermitSubmit()" method="POST">
                        <div class="form-group">
                            <label for="name">{{ permText?.name }}</label>
                            <input type="text" class="form-control" id="name" formControlName="name"
                                placeholder="{{ permText?.name_placeholder }}">
                        </div>
                        <div class="form-group">
                            <label for="description">{{ permText?.description }}</label>
                            <textarea class="form-control" id="description" formControlName="description"
                                placeholder="{{ permText?.description_placeholder }}" rows="5"></textarea>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ modalText?.close
                                }}</button>
                            <button type="submit" class="btn btn-primary" [disabled]="!addPermitForm.valid">{{
                                modalText?.add }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal edit permit -->
    <div class="modal fade bd-modal-editPermit" tabindex="-1" role="dialog" aria-labelledby="Editar un permiso"
        aria-hidden="true" id="editPermitModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalEditPermitTitle">{{ permText?.edit_permission }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form class="m-4" [formGroup]="editPermitForm" (ngSubmit)="editPermitSubmit()" method="POST">
                        <div class="form-group oculto">
                            <label for="id">Id</label>
                            <input type="text" class="form-control" id="id" formControlName="id" placeholder="Id">
                        </div>
                        <div class="form-group">
                            <label for="name">{{ permText?.name }}</label>
                            <input type="text" class="form-control" id="nameEdit" formControlName="name"
                                placeholder="{{ permText?.name_placeholder }}">
                        </div>
                        <div class="form-group">
                            <label for="description">{{ permText?.description }}</label>
                            <textarea class="form-control" id="description" formControlName="description"
                                placeholder="{{ permText?.description_placeholder }}" rows="5"></textarea>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ modalText?.close
                                }}</button>
                            <button type="submit" class="btn btn-primary" [disabled]="!editPermitForm.valid">{{
                                modalText?.modify }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal delete permit -->
    <div class="modal fade bd-modal-deletePermit" tabindex="-1" role="dialog" aria-labelledby="Eliminar permiso"
        aria-hidden="true" id="deletePermitConfirm">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalDeletePermitTitle">{{ permText?.confirmation }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <h3>{{ permText?.confirmation_message }}<span id="permitToDelete"></span> ?</h3>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ modalText?.close }}</button>
                    <button type="button" class="btn btn-danger" (click)="deletePermit()">{{ modalText?.delete
                        }}</button>
                </div>
            </div>
        </div>
    </div>
</div>
