<div class="container-fluid">
  <div class="row">
    <div class="col-6">
      <canvas #canvas id="canvas" width="400" height="400"></canvas>

    </div>
  </div>
</div>

<div class="container-fluid">
  <input type="date" [(ngModel)]="timeDate" (input)="calculateTime()">
  <input type="time" [(ngModel)]="time" (input)="calculateTime()">
  <input type="submit" class="btn btn-primary ml-5">
  <table class="table" id="task_sequence">
    <thead>
      <tr>
        <th>Machine performed</th>
        <th>Start</th>
        <th>End</th>
        <th>Task identification</th>
        <th>Cip task</th>
        <th>Regular task</th>
        <th>Order satisfies</th>
        <th>Quantity</th>
        <th>Task cost</th>
        <th>Step</th>
      </tr>
    </thead>

    <tbody #tableBody>
      <tr *ngFor="let log of taskSequence">
        <td>{{log?.mid}}</td>
        <td>{{log?.st | date: 'dd/MM/yyy HH:mm'}}</td>
        <td>{{log?.end | date: 'dd/MM/yyy HH:mm'}}</td>
        <td>{{log?.tid}}</td>
        <td>{{log?.cip}}</td>
        <td>{{log?.oid}}</td>
        <td>{{log?.foid}}</td>
        <td>{{log?.q}}</td>
        <td>{{log?.c}}</td>
        <td>{{log?.stp}}</td>
      </tr>
    </tbody>
  </table>
</div>
