import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private BASE_URL = environment.base_url;
  constructor(private http: HttpClient) {}

  async getConfig(): Promise<any> {
    return this.http.get(this.BASE_URL + '/v1/api/config').toPromise();
  }

  setConfig(config) {
    return this.http.post(this.BASE_URL + '/v1/api/config', config).toPromise();
  }

  async getSupportStatus(): Promise<{ status: boolean }> {
    return this.http
      .get<any>(this.BASE_URL + '/v1/api/config/support/status')
      .toPromise();
  }

  async supportEnable(): Promise<void> {
    return this.http
      .post<any>(this.BASE_URL + '/v1/api/config/support/enable', {})
      .toPromise();
  }

  async supportDisable(): Promise<void> {
    return this.http
      .post<any>(this.BASE_URL + '/v1/api/config/support/disable', {})
      .toPromise();
  }
}
