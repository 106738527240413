import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BackupsService {
  BASE_URL: string;
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.base_url;
  }

  getBackup() {
    return this.http
      .get(this.BASE_URL + '/v1/api/backup', { responseType: 'arraybuffer' })
      .toPromise();
  }

  restoreFromZip(selectedFile) {
    const fd = new FormData();
    fd.append('zip', selectedFile);
    return this.http
      .put(this.BASE_URL + '/v1/api/backup/zip/zip', fd, {
        responseType: 'arraybuffer',
      })
      .toPromise();
  }

  async export(param) {
    return this.http
      .get(this.BASE_URL + '/v1/api/backup/param/' + param, {
        responseType: 'arraybuffer',
      })
      .toPromise();
  }
}
