<div class="optionsdyn col-2 pt-2" id="optionsdyn">
  <div class="navcaption" id="navcaption">{{ navcaption }}</div>
  <button type="button" class="close closedyn" aria-label="Close" (click)="closeOption()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="container contdyn" id="option-container">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" *ngFor="let tab of tabs">
        <a class="nav-link" id="{{ tab.key }}-tab" [ngClass]="{ active: tab.key === 'general' }" data-toggle="tab"
          href="#{{ tab.key }}" (click)="tabSelect(tab)" role="tab" aria-controls="home" aria-selected="true">
          {{ language[tab.key] || tab.key }}
        </a>
      </li>
      <li class="nav-item" *ngIf="reportItem">
        <a class="nav-link" id="variable-tab" [ngClass]="{ active: tab === 'variable' }" data-toggle="tab"
          href="#variable" role="tab" aria-controls="home" aria-selected="true">{{ language.variable }}</a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade mt-3" *ngFor="let contentTab of tabs"
        [ngClass]="{ 'show active': contentTab.key === 'general' }" [id]="contentTab.key" role="tabpanel"
        [aria-labelledby]="contentTab - tab">
        <form [formGroup]="optionsDynForm" (ngSubmit)="optionSubmit()" method="POST">

          <ng-container *ngFor="let generalKey of contentTab.controls">

            <ng-container *ngFor="let key of generalKey">
              <div class="form-group dynfg" [ngStyle]="{
                  display:
                    parameters[contentTab.key][key]?.display === 'hidden'
                      ? 'none'
                      : ''
                }">
                <!-- Hidden -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'hidden'">
                  <input type="hidden" [id]="key + contentTab.key" />
                </ng-container>

                <!-- Hightligths -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type === 'highlightsgauge'
                  ">
                  <table style="margin: 8% 0;">
                    <tr>
                      <th colspan="4" style="text-align: center; background: #aaaaaa">
                        {{ language?.synoptic_gauge_highlights }}
                      </th>
                    </tr>
                    <tr>
                      <th style="text-align: center">{{ language?.from }}</th>
                      <th style="text-align: center">{{ language?.to }}</th>
                      <th style="text-align: center">Color</th>
                      <th>&nbsp;</th>
                    </tr>
                    <ng-container *ngFor="
                        let ikey of multiples[
                          parameters[contentTab.key][key].type
                        ]
                      ">
                      <tr>
                        <td>
                          <input type="text" size="3" [formControlName]="'highlightsgauge_from_' + ikey" />
                        </td>
                        <td>
                          <input type="text" size="3" [formControlName]="'highlightsgauge_to_' + ikey" />
                        </td>
                        <td>
                          <input type="color" size="2" [formControlName]="'highlightsgauge_color_' + ikey" />
                        </td>
                        <td nowrap>
                          <button (click)="
                              addMultiple(
                                parameters[contentTab.key][key].type,
                                ikey
                              )
                            ">
                            +
                          </button>
                          <button (click)="
                              delMultiple(
                                parameters[contentTab.key][key].type,
                                ikey
                              )
                            ">
                            -
                          </button>
                        </td>
                      </tr>
                    </ng-container>
                  </table>
                </ng-container>

                <!-- Majorticks -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type === 'majorticksgauge'
                  ">
                  <span>Major Ticks</span>
                  <div class="flex-container">
                    <ng-container *ngFor="
                        let ikey of multiples[
                          parameters[contentTab.key][key].type
                        ]
                      ">
                      <div class="flex-container_inside">
                        <input type="text" size="2" style="width: 40px"
                          [formControlName]="'majorticksgauge_tick_' + ikey" />
                        <button class="boton_mas" (click)="
                            addMultiple(
                              parameters[contentTab.key][key].type,
                              ikey
                            )
                          ">
                          +
                        </button>
                        <button class="boton_menos" (click)="
                            delMultiple(
                              parameters[contentTab.key][key].type,
                              ikey
                            )
                          ">
                          -
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>

                <!-- Input -->

                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'input'">
                  <label style="display: inline" [for]="key + contentTab.key">
                    {{ language[parameters[contentTab.key][key]?.label] || parameters[contentTab.key][key]?.label }}
                  </label>
                  <!-- <label class="material-icons lbc">subdirectory_arrow_left</label> -->
                  <input autofocus [type]="parameters[contentTab.key][key]?.input" [id]="key + contentTab.key"
                    [formControlName]="key" class="form-control" />
                  <small class="form-text text-muted">
                    {{ language[parameters[contentTab.key][key]?.small] || parameters[contentTab.key][key]?.small }}
                  </small>
                </ng-container>

                <!-- Texarea -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'textarea'">
                  <label style="display: inline" [for]="key + contentTab">
                    {{ language[parameters[contentTab.key][key]?.label] || parameters[contentTab.key][key]?.label }}
                  </label>
                  <!-- If user has value in item -->
                  <textarea [id]="key + contentTab.key" [formControlName]="key" rows="4"
                    class="form-control"></textarea>
                  <small class="form-text text-muted">
                    {{ language[parameters[contentTab.key][key]?.small] || parameters[contentTab.key][key]?.small }}
                  </small>
                </ng-container>

                <!-- SynopticList -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'synopticList'">
                  <label>
                    {{ language[parameters[contentTab.key][key]?.label] || parameters[contentTab.key][key]?.label }}
                  </label>
                  <input autocomplete="off" list="synopticList" [id]="key + contentTab.key" [formControlName]="key"
                    class="form-control" value="{{ parameters[contentTab.key][key]?.value }}" />
                </ng-container>

                <!-- TextsList -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'textList'">
                  <label [for]="key + contentTab.key">
                    {{ language[parameters[contentTab.key][key]?.label] || parameters[contentTab.key][key]?.label }}
                  </label>
                  <input autocomplete="off" list="textList" [id]="key + contentTab.key" [formControlName]="key"
                    class="form-control" value="{{ parameters[contentTab.key][key]?.value }}" />
                  <small class="form-text text-muted">
                    {{ language[parameters[contentTab.key][key]?.small] || parameters[contentTab.key][key]?.small }}
                  </small>
                </ng-container>

                <!-- Visor -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'visor'">
                  <button type="button" [id]="parameters[contentTab.key][key]?.idkey" class="btn btn-secondary"
                    data-toggle="modal" data-target=".bd-modal-visor" (click)="visor($event)">
                    {{ language[parameters[contentTab.key][key]?.label] || parameters[contentTab.key][key]?.label }}
                  </button>
                </ng-container>

                <!-- Select -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'select'">
                  <label style="display: inline" [for]="key + contentTab.key">
                    {{ language[parameters[contentTab.key][key].label] || parameters[contentTab.key][key].label }}
                  </label>
                  <select class="form-control" [id]="key + contentTab.key" [formControlName]="key"
                    [value]="parameters[contentTab.key][key]?.value" (change)="selectChange($event)">
                    <ng-container *ngFor="
                        let option of parameters[contentTab.key][key]?.options
                      ">
                      <option *ngIf="option.key === 'disabled'" [value]="option.key" [attr.selected]="true" disabled>
                        {{ language[option.value] || option.value }}
                      </option>
                      <option *ngIf="option.key !== 'disabled'" [value]="option.key" (click)="controlselect($event)">
                        {{ language[option.value] || option.value }}
                      </option>
                      <ng-container *ngIf="parameters[contentTab.key][key]?.extra === 'dataDesign'">
                        <option *ngFor="let dataDesign of globals.dataDesigns" [value]="dataDesign._id">
                          {{ dataDesign.name }}
                        </option>
                      </ng-container>
                    </ng-container>
                  </select>
                </ng-container>

                <!-- Select Multiple -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'select_multiple'">
                  <label style="display: inline" [for]="key + contentTab.key">
                    {{ language[parameters[contentTab.key][key].label] || parameters[contentTab.key][key].label }}
                  </label>
                  <select multiple class="form-control" [id]="key + contentTab.key" [formControlName]="key"
                    [value]="parameters[contentTab.key][key]?.value" (change)="selectChange($event)">
                    <ng-container *ngFor="let option of parameters[contentTab.key][key]?.options">
                      <option *ngIf="option.key === 'disabled'" [value]="option.key" [attr.selected]="true" selected disabled>
                        {{ language[option.value] || option.value }}
                      </option>
                      <option *ngIf="option.key !== 'disabled'" [value]="option.key">
                        {{ language[option.value] || option.value }}
                      </option>
                    </ng-container>
                    <ng-container *ngIf="parameters[contentTab.key][key]?.extra === 'thing'">
                      <option *ngFor="let field of globals.things" [value]="field.name">
                        {{ field.name }}
                      </option>
                    </ng-container>
                    <ng-container *ngIf="parameters[contentTab.key][key]?.extra === 'tag'">
                      <option *ngFor="let field of globals?.tags" [value]="field.name">
                        {{ field.name }}
                      </option>
                    </ng-container>
                  </select>
                </ng-container>

                <!-- Connect -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'connect'">
                  <ng-container *ngIf="parameters[contentTab.key][key]?.extra === 'line'">
                    <label style="display: inline" [for]="key + contentTab.key">
                      {{ language[parameters[contentTab.key][key]?.label] || parameters[contentTab.key][key]?.label }}
                    </label>
                    <select class="form-control" [id]="key + contentTab.key" [formControlName]="key"
                      [value]="parameters[contentTab.key][key]?.value" (change)="lineSelected($event)">
                      <ng-container *ngFor="
                          let option of parameters[contentTab.key][key]?.options
                        ">
                        <option value="default" selected>
                          Selecciona una linea
                        </option>
                        <option *ngFor="let line of lineas" [value]="line._id">
                          {{ line.name }}
                        </option>
                      </ng-container>
                    </select>
                  </ng-container>
                  <ng-container *ngIf="parameters[contentTab.key][key]?.extra === 'thing'">
                    <label style="display: inline" [for]="key + contentTab.key">
                      {{ language[parameters[contentTab.key][key]?.label] || parameters[contentTab.key][key]?.label }}
                    </label>
                    <select class="form-control" [id]="key + contentTab.key" [formControlName]="key"
                      [value]="parameters[contentTab.key][key]?.value" (change)="thingSelected($event)">
                      <ng-container *ngFor="
                          let option of parameters[contentTab.key][key]?.options
                        ">
                        <option value="default" selected disabled>
                          Selecciona una máquina
                        </option>
                        <option *ngFor="let thing of globals.things" [value]="thing._id">
                          {{ thing.name }}
                        </option>
                      </ng-container>
                    </select>
                  </ng-container>
                  <ng-container *ngIf="
                      parameters[contentTab.key][key]?.extra === 'tag'
                    ">
                    <label style="display: inline" [for]="key + contentTab.key">
                      {{ language[parameters[contentTab.key][key]?.label] || parameters[contentTab.key][key]?.label }}
                    </label>
                    <select class="form-control" [id]="key + contentTab.key" [formControlName]="key"
                      [value]="parameters[contentTab.key][key]?.value">
                      <ng-container *ngFor="
                          let option of parameters[contentTab.key][key]?.options
                        ">
                        <option value="default" selected>
                          Selecciona una variable
                        </option>
                        <option *ngFor="let field of globals['tags']" [value]="field._id">
                          {{ field.name }}
                        </option>
                      </ng-container>
                    </select>
                  </ng-container>
                  <ng-container *ngIf="
                      parameters[contentTab.key][key]?.extra === 'variable'
                    ">
                    <label style="display: inline" [for]="key + contentTab.key">
                      {{ language[parameters[contentTab.key][key]?.label] || parameters[contentTab.key][key]?.label }}
                    </label>
                    <select class="form-control" [id]="key + contentTab.key" [formControlName]="key"
                      [value]="parameters[contentTab.key][key]?.value" (change)="thingSelected($event)">
                      <ng-container *ngFor="
                          let option of parameters[contentTab.key][key]?.options
                        ">
                        <option value="default" selected>
                          Selecciona una variable
                        </option>
                        <option *ngFor="let field of fields" [value]="field.tag">
                          {{ field.name }}
                        </option>
                      </ng-container>
                    </select>
                  </ng-container>
                </ng-container>

                <!-- Checkbox -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'checkbox'">
                  <div class="col-12">
                    <input [type]="parameters[contentTab.key][key]?.type" class="form-check-input"
                      [id]="key + contentTab.key"
                      [checked]="optionsDynForm.value[key] === true || optionsDynForm.value[key] == 'true' || optionsDynForm.value[key] == 'on'"
                      (change)="checkBoxValue($event, key)" />
                    <label title="{{
                        parameters[contentTab.key][key]?.title?.replace(
                          '(nl)',
                          '&#10;'
                        )
                      }}" class="form-check-label" [for]="key + contentTab.key">
                      {{ language[parameters[contentTab.key][key]?.label] || parameters[contentTab.key][key]?.label }}
                    </label>
                  </div>
                </ng-container>

                <!-- Date -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'date'">
                  <label style="display: inline" [for]="key + contentTab.key">
                    {{ language[parameters[contentTab.key][key]?.label] || parameters[contentTab.key][key]?.label }}
                  </label>
                  <input [type]="parameters[contentTab.key][key]?.type" [id]="key + contentTab.key"
                    [formControlName]="key" />
                  <small class="form-text text-muted">
                    {{ language[parameters[contentTab.key][key]?.small] || parameters[contentTab.key][key]?.small }}
                  </small>
                </ng-container>

                <!-- Time -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'time'">
                  <!-- <label style="display: inline" [for]="key + contentTab.key">
                    {{ language[parameters[contentTab.key][key]?.label] || parameters[contentTab.key][key]?.label }}
                  </label> -->
                  <input [type]="parameters[contentTab.key][key]?.type" [id]="key + contentTab.key"
                    [formControlName]="key" />
                  <small class="form-text text-muted">
                    {{ language[parameters[contentTab.key][key]?.small] || parameters[contentTab.key][key]?.small }}
                  </small>
                </ng-container>

                <!-- Color picker -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'color'">
                  <label style="display: inline" [for]="key + contentTab.key">
                    {{ language[parameters[contentTab.key][key]?.label] || parameters[contentTab.key][key]?.label }}
                  </label><br />
                  <input [type]="parameters[contentTab.key][key]?.type" [id]="key + contentTab.key"
                    [formControlName]="key" />
                </ng-container>

                <!-- Range -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'range'">
                  <label style="display: inline" [for]="key + contentTab.key">
                    {{ language[parameters[contentTab.key][key]?.label] || parameters[contentTab.key][key]?.label }}
                  </label>
                  <input [type]="parameters[contentTab.key][key]?.type" class="custom-range"
                    (change)="updateRange(key + contentTab.key)" [min]="parameters[contentTab.key][key]?.min"
                    [max]="parameters[contentTab.key][key]?.max" [step]="parameters[contentTab.key][key]?.step"
                    [formControlName]="key" [id]="key + contentTab.key" />
                  <input type="text" id="range_{{ key + contentTab.key }}" [value]="
                      paramsDone ? paramsItem[contentTab.key][key] : null
                    " />
                </ng-container>

                <!-- Free -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'separator'">
                  <hr>
                </ng-container>

                <!-- Free -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'free'">
                  <div [id]="key + contentTab.key"></div>
                </ng-container>

                <!--script -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'codescript'">
                  <ngx-codemirror [(ngModel)]="parameters[contentTab.key][key].value" [id]="key + contentTab.key"
                    [options]="codeMirrorOptions" [formControlName]="key"
                    value="{{ parameters[contentTab.key][key]?.value }}">
                  </ngx-codemirror>
                </ng-container>

                <!-- show/hidden -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type === 'ashowhidden'
                  ">
                  <button type="button" (click)="textit($event)">test</button>
                  <button type="button" (click)="textit2($event)">test2</button>
                  <label style="display: inline" [for]="key + contentTab.key">esto</label>
                  <input [type]="parameters[contentTab.key][key]?.input" [id]="key + contentTab.key"
                    [formControlName]="key" class="form-control" />
                </ng-container>

                <!-- variable -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type === 'variable' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">
                      {{ language?.type_dynamization_variable }}&nbsp;
                    </label><label class="material-icons" style="line-height: 18px" (click)="closeDyn(parameters[contentTab.key][key].type)">
                      clear
                    </label>
                  </div>
                  <div [ngStyle]="{
                      display:
                        dynHid[parameters[contentTab.key][key].type] !==
                        undefined
                          ? 'none'
                          : ''
                    }">
                    <div style="display: flex; align-items: center">
                      <input autocomplete="off" list="connect_tags" [id]="'variable_variableconnect'" [formControlName]="'variable_variableconnect'" class="form-control" />
                    </div>
                    <div style="display: flex; align-items: center">
                      <label style="line-height: 18px">{{ language?.dynamization_variable_numdecimals }}:&nbsp;</label>
                      <input autocomplete="off" type="text" [id]="'variable_numdecimals'" [formControlName]="'variable_numdecimals'" class="form-control" placeholder="(1-9)"/>
                    </div>
                  </div>
                  <hr />
                </ng-container>

                <!-- setvariable -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type === 'setvariable' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key]?.type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key]?.type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">{{ language?.set_variable }}&nbsp;</label><label class="material-icons"
                      style="line-height: 18px" (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display:
                        dynHid[parameters[contentTab.key][key].type] !==
                        undefined
                          ? 'none'
                          : ''
                    }">
                    <ng-container *ngIf="optionItem.typeName === 'Button'">
                      <div style="display: flex; align-items: center">
                        <label style="line-height: 18px">Event:&nbsp;</label>
                        <select class="form-control" style="width: auto" [id]="'setvariable_eventbutton'"
                          [formControlName]="'setvariable_eventbutton'">
                          <option value="click">On Click</option>
                          <option value="down">On Down</option>
                          <option value="up">On Up</option>
                        </select>
                      </div>
                    </ng-container>

                    <div style="display: flex; align-items: center">
                      <label style="line-height: 18px">Variable:&nbsp;</label>
                      <input autocomplete="off" list="connect_tags" [id]="'setvariable_variableconnect'"
                        [formControlName]="'setvariable_variableconnect'" class="form-control" />
                    </div>
                    <div style="display: flex; align-items: center">
                      <label style="line-height: 18px">{{ language?.value }}:&nbsp;</label>
                      <input autocomplete="off" type="text" [id]="'setvariable_setvalue'"
                        [formControlName]="'setvariable_setvalue'" class="form-control" />
                    </div>
                  </div>
                  <hr />
                </ng-container>

                <!-- visible -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type === 'visible' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">
                      {{ language?.type_dynamization_visible }}&nbsp;
                    </label><label class="material-icons" style="line-height: 18px" (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display: dynHid['visible'] !== undefined ? 'none' : ''
                    }">
                    <ng-container *ngIf="optionItem.typeName === 'Button'">
                      <div style="display: flex; align-items: center">
                        <label style="line-height: 18px">Event:&nbsp;</label>
                        <select class="form-control" style="width: auto" [id]="key + contentTab.key"
                          [formControlName]="key + 'ev'">
                          <option value="click">On Click</option>
                          <option value="down">On Down</option>
                          <option value="up">On Up</option>
                        </select>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="optionItem.typeName !== 'Button'">
                      <div style="display: flex; align-items: center">
                        <label style="line-height: 18px">Variable:&nbsp;</label>
                        <input autocomplete="off" list="connect_tags" [id]="key + contentTab.key"
                          [formControlName]="key" class="form-control" />
                      </div>
                    </ng-container>
                  </div>
                  <hr />
                </ng-container>

                <!-- itemmove -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type === 'itemmove' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">{{ language?.type_dynamization_item_move }}&nbsp;</label><label class="material-icons"
                      style="line-height: 18px" (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display: dynHid['itemmove'] !== undefined ? 'none' : ''
                    }">
                    <ng-container *ngIf="optionItem.typeName === 'Button'">
                      <div style="display: flex; align-items: center">
                        <label style="line-height: 18px">Event:&nbsp;</label>
                        <select class="form-control" style="width: auto" [id]="key + contentTab.key"
                          [formControlName]="key + 'ev'">
                          <option value="click">On Click</option>
                          <option value="down">On Down</option>
                          <option value="up">On Up</option>
                        </select>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="optionItem.typeName !== 'Button'">
                      <div style="display: flex; align-items: center">
                        <label style="line-height: 18px">Variable:&nbsp;</label>
                        <input autocomplete="off" list="connect_tags" [id]="key + contentTab.key"
                          [formControlName]="key" class="form-control" />
                      </div>
                    </ng-container>
                  </div>
                  <hr />
                </ng-container>

                <!-- itemsize -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type === 'itemsize' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px" (click)="sshow('itemsize')" [ngStyle]="{
                        display: dynHid['itemsize'] === undefined ? 'none' : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px" (click)="shide('itemsize')" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">{{ language?.type_dynamization_item_size }}&nbsp;</label><label class="material-icons"
                      style="line-height: 18px" (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display: dynHid['itemsize'] !== undefined ? 'none' : ''
                    }">
                    <ng-container *ngIf="optionItem.typeName === 'Button'">
                      <div style="display: flex; align-items: center">
                        <label style="line-height: 18px">Event:&nbsp;</label>
                        <select class="form-control" style="width: auto" [id]="key + contentTab.key"
                          [formControlName]="key + 'ev'">
                          <option value="click">On Click</option>
                          <option value="down">On Down</option>
                          <option value="up">On Up</option>
                        </select>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="optionItem.typeName !== 'Button'">
                      <div style="display: flex; align-items: center">
                        <label style="line-height: 18px">Variable:&nbsp;</label>
                        <input autocomplete="off" list="connect_tags" [id]="key + contentTab.key"
                          [formControlName]="key" class="form-control" />
                      </div>
                    </ng-container>
                  </div>
                  <hr />
                </ng-container>

                <!-- itemcolor -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type === 'itemcolor' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px" (click)="sshow('itemcolor')" [ngStyle]="{
                        display: dynHid['itemcolor'] === undefined ? 'none' : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px" (click)="shide('itemcolor')" [ngStyle]="{
                        display: dynHid['itemcolor'] !== undefined ? 'none' : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">Color&nbsp;</label><label class="material-icons"
                      style="line-height: 18px" (click)="closeDyn('itemcolor')">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display: dynHid['itemcolor'] !== undefined ? 'none' : ''
                    }">
                    <ng-container *ngIf="optionItem.typeName === 'Button'">
                      <div style="display: flex; align-items: center">
                        <label style="line-height: 18px">Event:&nbsp;</label>
                        <select class="form-control" style="width: auto" [id]="key + contentTab.key"
                          [formControlName]="key + 'ev'">
                          <option value="click">On Click</option>
                          <option value="down">On Down</option>
                          <option value="up">On Up</option>
                        </select>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="optionItem.typeName !== 'Button'">
                      <div style="display: flex; align-items: center">
                        <label style="line-height: 18px">Variable:&nbsp;</label>
                        <input autocomplete="off" list="connect_tags" [id]="key + contentTab.key"
                          [formControlName]="key" class="form-control" />
                      </div>
                    </ng-container>
                  </div>
                  <hr />
                </ng-container>

                <!-- itemstyle -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type === 'itemstyle' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">{{ language?.type_dynamization_item_style }}&nbsp;</label><label class="material-icons"
                      style="line-height: 18px" (click)="closeDyn('itemstyle')">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display: dynHid['itemstyle'] !== undefined ? 'none' : ''
                    }">
                    <ng-container *ngIf="optionItem.typeName === 'Button'">
                      <div style="display: flex; align-items: center">
                        <label style="line-height: 18px">Event:&nbsp;</label>
                        <select class="form-control" style="width: auto" [id]="key + contentTab.key"
                          [formControlName]="key + 'ev'">
                          <option value="click">On Click</option>
                          <option value="down">On Down</option>
                          <option value="up">On Up</option>
                        </select>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="optionItem.typeName !== 'Button'">
                      <div style="display: flex; align-items: center">
                        <label style="line-height: 18px">Variable:&nbsp;</label>
                        <input autocomplete="off" list="connect_tags" [id]="key + contentTab.key"
                          [formControlName]="key" class="form-control" />
                      </div>
                    </ng-container>
                  </div>
                  <hr />
                </ng-container>

                <!-- kbtype -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'kbtype'">
                  <label [for]="key + contentTab.key">
                    {{ language[parameters[contentTab.key][key]?.label] || parameters[contentTab.key][key]?.label }}
                  </label>
                  <select [id]="key + contentTab.key" [formControlName]="key" class="form-control">
                    <option value="none">select type</option>
                    <option *ngFor="let keyb of globals.keyboards | keyvalue" [value]="keyb.key">
                      {{ keyb.key }}
                    </option>
                  </select>
                </ng-container>

                <!-- template -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'template'">
                  <label [for]="key + contentTab.key">
                    {{ language[parameters[contentTab.key][key]?.label] || parameters[contentTab.key][key]?.label }}
                  </label>
                  <input autocomplete="off" list="synopticList" [id]="key + contentTab.key" [formControlName]="key"
                    class="form-control" value="{{ parameters[contentTab.key][key]?.value }}" />
                  <small class="form-text text-muted">
                    {{ language[parameters[contentTab.key][key]?.small] || parameters[contentTab.key][key]?.small }}
                  </small>
                </ng-container>

                <!-- uploadimage -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type === 'uploadimage'
                  ">
                  <form action="POST">
                    <div class="form-group">
                      <input type="file" id="imagePath" (change)="uploadImage($event)" />
                    </div>
                  </form>
                  <input autofocus type="hidden" [id]="key + contentTab.key" [formControlName]="key"
                    class="form-control" />
                </ng-container>

                <!-- gosynoptic -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type === 'gosynoptic' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">{{ language?.type_dynamization_go_synoptic }}&nbsp;</label><label class="material-icons"
                      style="line-height: 18px" (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display: dynHid['gosynoptic'] !== undefined ? 'none' : ''
                    }">
                    <ng-container *ngIf="optionItem.typeName === 'Button'">
                      <div style="display: flex; align-items: center">
                        <label style="line-height: 18px">Event:&nbsp;</label>
                        <select class="form-control" style="width: auto" [id]="'gosynoptic_event_type'"
                          [formControlName]="'gosynoptic_event_type'">
                          <option value="click">On Click</option>
                          <option value="down">On Down</option>
                          <option value="up">On Up</option>
                        </select>
                      </div>
                    </ng-container>

                    <div style="display: flex; align-items: center">
                      <label style="line-height: 18px">Synoptic:&nbsp;</label>
                      <input autocomplete="off" list="synopticList" [id]="
                          parameters[contentTab.key][key].type + '_synoptic_id'
                        " [formControlName]="
                          parameters[contentTab.key][key].type + '_synoptic_id'
                        " class="form-control" />
                    </div>
                  </div>
                  <hr />
                </ng-container>

                <!-- printReport -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type === 'printReport' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px"> print report&nbsp;</label><label class="material-icons"
                      style="line-height: 18px" (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display: dynHid['printReport'] !== undefined ? 'none' : ''
                    }">
                    <ng-container *ngIf="optionItem.typeName === 'Button'">
                      <div style="display: flex; align-items: center">
                        <label style="line-height: 18px">Event:&nbsp;</label>
                        <select class="form-control" style="width: auto" [id]="'printReport_event_type'"
                          [formControlName]="'printReport_event_type'">
                          <option value="click">On Click</option>
                          <option value="down">On Down</option>
                          <option value="up">On Up</option>
                        </select>
                      </div>
                    </ng-container>

                    <div style="display: flex; align-items: center">
                      <label style="line-height: 18px">Report:&nbsp;</label>
                      <input autocomplete="off" list="reportList" [id]="
                          parameters[contentTab.key][key].type + '_report_id'
                        " [formControlName]="
                          parameters[contentTab.key][key].type + '_report_id'
                        " class="form-control" />
                    </div>
                  </div>
                  <hr />
                </ng-container>

                <!-- export PDF -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type === 'exportPDF' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">{{ language?.export_pdf }}&nbsp;</label><label class="material-icons"
                      style="line-height: 18px" (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display: dynHid['exportPDF'] !== undefined ? 'none' : ''
                    }">
                    <ng-container *ngIf="optionItem.typeName === 'Button'">
                      <div style="display: flex; align-items: center">
                        <label style="line-height: 18px">Event:&nbsp;</label>
                        <select class="form-control" style="width: auto" [id]="'exportPDF_event_type'"
                          [formControlName]="'exportPDF_event_type'">
                          <option value="click">On Click</option>
                          <option value="down">On Down</option>
                          <option value="up">On Up</option>
                        </select>
                      </div>
                    </ng-container>

                    <div style="display: flex; align-items: center">
                      <label style="line-height: 18px">Event:&nbsp;</label>
                      <select class="form-control" style="width: auto" [id]="'exportPDF_pdf_format'"
                        [formControlName]="'exportPDF_pdf_format'">
                        <option value="portrait">Portrait</option>
                        <option value="landscape">Landscape</option>
                        <option value="auto">Auto</option>
                      </select>
                    </div>
                  </div>
                  <hr />
                </ng-container>

                <!-- blink -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type === 'blink' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">{{ language?.type_dynamization_item_blink }}&nbsp;</label><label class="material-icons"
                      style="line-height: 18px" (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display: dynHid['blink'] !== undefined ? 'none' : ''
                    }">
                    <ng-container *ngIf="optionItem.typeName === 'Button'">
                      <div style="display: flex; align-items: center">
                        <label style="line-height: 18px">Event:&nbsp;</label>
                        <select class="form-control" style="width: auto" [id]="key + contentTab.key"
                          [formControlName]="key + 'ev'">
                          <option value="click">On Click</option>
                          <option value="down">On Down</option>
                          <option value="up">On Up</option>
                        </select>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="optionItem.typeName !== 'Button'">
                      <div style="display: flex; align-items: center">
                        <label style="line-height: 18px">Variable:&nbsp;</label>
                        <input autocomplete="off" list="connect_tags" [id]="key + contentTab.key"
                          [formControlName]="key" class="form-control" />
                      </div>
                    </ng-container>
                  </div>
                  <hr />
                </ng-container>

                <!-- changeimage -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type === 'changeimage' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">{{ language?.type_dynamization_image_change }}&nbsp;</label><label class="material-icons"
                      style="line-height: 18px" (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display: dynHid['changeimage'] !== undefined ? 'none' : ''
                    }">
                    <ng-container *ngIf="optionItem.typeName === 'Button'">
                      <div style="display: flex; align-items: center">
                        <label style="line-height: 18px">Event:&nbsp;</label>
                        <select class="form-control" style="width: auto" [id]="key + contentTab.key"
                          [formControlName]="key + 'ev'">
                          <option value="click">On Click</option>
                          <option value="down">On Down</option>
                          <option value="up">On Up</option>
                        </select>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="optionItem.typeName !== 'Button'">
                      <div style="display: flex; align-items: center">
                        <label style="line-height: 18px">Variable:&nbsp;</label>
                        <input autocomplete="off" list="connect_tags" [id]="key + contentTab.key"
                          [formControlName]="key" class="form-control" />
                      </div>
                    </ng-container>
                  </div>
                  <hr />
                </ng-container>

                <!-- changetext -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type === 'changetext' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">{{ language?.type_dynamization_text_change }}&nbsp;</label><label class="material-icons"
                      style="line-height: 18px" (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display: dynHid['changetext'] !== undefined ? 'none' : ''
                    }">
                    <ng-container *ngIf="optionItem.typeName === 'Button'">
                      <div style="display: flex; align-items: center">
                        <label style="line-height: 18px">Event:&nbsp;</label>
                        <select class="form-control" style="width: auto" [id]="key + contentTab.key"
                          [formControlName]="key + 'ev'">
                          <option value="click">On Click</option>
                          <option value="down">On Down</option>
                          <option value="up">On Up</option>
                        </select>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="optionItem.typeName !== 'Button'">
                      <div style="display: flex; align-items: center">
                        <label style="line-height: 18px">Variable:&nbsp;</label>
                        <input autocomplete="off" list="connect_tags" [id]="key + contentTab.key"
                          [formControlName]="key" class="form-control" />
                      </div>
                    </ng-container>
                  </div>
                  <hr />
                </ng-container>

                <!-- run script object events -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type ===
                      'runscriptObject' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] === '1'
                  ">
                  <div [ngStyle]="{ display: 'flex', 'align-items': 'center' }">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">{{ language?.script_run_label }}&nbsp;</label><label class="material-icons"
                      style="line-height: 18px" (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>

                  <div [ngStyle]="{
                      display:
                        dynHid['runscriptObject'] !== undefined ? 'none' : ''
                    }">
                    <ng-container>
                      <div style="display: flex; align-items: center">
                        <label style="line-height: 18px">Object event:&nbsp;</label>
                        <select class="form-control" style="width: auto" [id]="'runscriptObject_event_type'"
                          [formControlName]="'runscriptObject_event_type'">
                          <option value="change">On Change</option>
                          <option value="load">{{ language?.script_run_load}}</option>
                        </select>
                      </div>
                    </ng-container>

                    <ngx-codemirror [(ngModel)]="parameters[contentTab.key][key].value" [id]="'runscriptObject_script'"
                      [options]="codeMirrorOptions" [formControlName]="'runscriptObject_script'"
                      value="{{ parameters[contentTab.key][key]?.value }}"></ngx-codemirror>
                  </div>
                  <hr />
                </ng-container>

                <!-- run script -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type === 'runscript' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] === '1'
                  ">
                  <div [ngStyle]="{ display: 'flex', 'align-items': 'center' }">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">{{ language?.script_run_label }}&nbsp;</label><label class="material-icons"
                      style="line-height: 18px" (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>

                  <div [ngStyle]="{
                      display: dynHid['runscript'] !== undefined ? 'none' : ''
                    }">

                    <div style="display: flex; align-items: center">
                      <label style="line-height: 18px">{{ language?.script_event_label }}:&nbsp;</label>
                      <select class="form-control" style="width: auto" [id]="'runscript_event_type'"
                        [attr.scripts]="key" [formControlName]="'runscript_event_type'"
                        (change)="scriptSelect($event.target)">
                        <ng-container *ngFor="let option of parameters[contentTab.key][key]?.options">
                          <option [value]="option.value">{{ language[option.text] || option.text }}</option>
                        </ng-container>
                      </select>
                    </div>

                    <ng-container *ngFor="let option of parameters[contentTab.key][key]?.options">
                      <ngx-codemirror [options]="codeMirrorOptions" [id]="'runscript_script' + option.value"
                        [formControlName]="'runscript_script' + option.value">
                      </ngx-codemirror>
                    </ng-container>
                    <ng-container *ngIf="optionsDynForm.value['runscript_event_type'] == 'Cycle'">
                      <label style="width:100px;" style="line-height: 18px">Interval:&nbsp;</label>
                      <input autocomplete="off" type="text" [id]="'runscript_interval'"
                        [formControlName]="'runscript_interval'" class="form" style="width: 190px;" />
                    </ng-container>
                  </div>
                  <hr />
                </ng-container>

                <!-- MultipleOptions -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'controlsel'">
                  <div style="display: flex; align-items: baseline">
                    <label [for]="key + contentTab.key">
                      {{ language[parameters[contentTab.key][key]?.label] || parameters[contentTab.key][key]?.label }}
                    </label>&nbsp;
                    <select class="form-control" style="width: auto" (change)="ctrlSelected($event)">
                      <ng-container *ngFor="
                          let option of parameters[contentTab.key][key]?.options
                        ">
                        <option *ngIf="option.key === 'default'" [value]="option.key" selected>
                          {{ language[option.value] || option.value }}
                        </option>
                        <option *ngIf="option.key !== 'default'" [value]="option.key">
                          {{ language[option.value] || option.value }}
                        </option>
                      </ng-container>
                    </select>
                  </div>
                  <hr />
                </ng-container>

                <!-- fontsize -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type === 'fontsize' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                    <label style="line-height: 18px; margin-left: 3%;">
                      {{ language?.style_fontsize }}&nbsp;
                    </label>
                    <input autocomplete="off" type="text" [id]="'fontsize_size'" [formControlName]="'fontsize_size'" class="form-control"
                      style="margin-left: 4%;" required />
                  </div>
                  <hr />
                </ng-container>

                <!-- disabled -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type === 'disabled' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: flex-start">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                    <label style="line-height: 18px; margin-left: 3%;">
                      {{ language?.style_disabled }}&nbsp;
                    </label>
                    <input autocomplete="off" type="checkbox" [id]="'disabled_disabled'" [formControlName]="'disabled_disabled'"
                      class="form-control" style="width: 20px; height: 20px; margin-left: 4%;" />
                  </div>
                  <hr />
                </ng-container>

                <!-- stylecolor -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type === 'stylecolor' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                    <label style="line-height: 18px; margin-left: 3%;">
                      Color&nbsp;
                    </label>
                    <input type="color" [id]="'stylecolor_color'" [formControlName]="'stylecolor_color'" class="form-control"
                      style="margin-left: 4%;" />
                  </div>
                  <hr />
                </ng-container>

                <!-- stylebackcolor -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type ===
                      'stylebackcolor' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                    <label style="line-height: 18px; margin-left: 3%;">
                      {{ language?.style_background }}&nbsp;</label>
                    <input type="color" [id]="'stylebackcolor_color'" [formControlName]="'stylebackcolor_color'" class="form-control"
                      style="margin-left: 4%;" />
                  </div>
                  <hr />
                </ng-container>

                <!-- style_border_style -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type ===
                      'style_border_style' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <label class="material-icons" style="line-height: 18px" (click)="closeDyn(parameters[contentTab.key][key].type)">
                    clear
                  </label>
                  <label style="line-height: 18px; margin-left: 3%;">
                    {{ language?.style_border_style }}&nbsp;
                  </label>
                  <div [ngStyle]="{
                      display:
                        dynHid[parameters[contentTab.key][key].type] !==
                        undefined
                          ? 'none'
                          : ''
                    }">
                    <div style="display: flex; align-items: center">
                      <label style="line-height: 18px">{{ language?.style }}:&nbsp;</label>
                      <select [id]="parameters[contentTab.key][key].type + '_style'" [formControlName]="
                          parameters[contentTab.key][key].type + '_style'
                        " class="form-control">
                        <option value="solid">{{ language?.style_border_solid }}</option>
                        <option value="dotted">{{ language?.style_border_dotted }}</option>
                        <option value="dashed">{{ language?.style_border_dashed }}</option>
                        <option value="double">{{ language?.style_border_double }}</option>
                        <option value="groove">{{ language?.style_border_groove }}</option>
                        <option value="ridge">{{ language?.style_border_ridge }}</option>
                        <option value="inset">{{ language?.style_border_inset }}</option>
                        <option value="outset">{{ language?.style_border_outset }}</option>
                        <option value="none">{{ language?.style_border_none }}</option>
                        <option value="hidden">{{ language?.style_border_hidden }}</option>
                      </select>
                    </div>
                  </div>
                  <hr />
                </ng-container>

                <!-- style_border_left_style -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type ===
                      'style_border_left_style' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">
                      {{ language?.style_border_style }}&nbsp;</label><label class="material-icons" style="line-height: 18px"
                      (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display:
                        dynHid[parameters[contentTab.key][key].type] !==
                        undefined
                          ? 'none'
                          : ''
                    }">
                    <div style="display: flex; align-items: center">
                      <label style="line-height: 18px">{{ language?.style }}:&nbsp;</label>
                      <select [id]="parameters[contentTab.key][key].type + '_style'" [formControlName]="
                          parameters[contentTab.key][key].type + '_style'
                        " class="form-control">
                        <option value="solid">{{ language?.style_border_solid }}</option>
                        <option value="dotted">{{ language?.style_border_dotted }}</option>
                        <option value="dashed">{{ language?.style_border_dashed }}</option>
                        <option value="double">{{ language?.style_border_double }}</option>
                        <option value="groove">{{ language?.style_border_groove }}</option>
                        <option value="ridge">{{ language?.style_border_ridge }}</option>
                        <option value="inset">{{ language?.style_border_inset }}</option>
                        <option value="outset">{{ language?.style_border_outset }}</option>
                        <option value="none">{{ language?.style_border_none }}</option>
                        <option value="hidden">{{ language?.style_border_hidden }}</option>
                      </select>
                    </div>
                  </div>
                  <hr />
                </ng-container>

                <!-- style_border_right_style -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type ===
                      'style_border_right_style' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">
                      {{ language?.style_border_right }}&nbsp;</label><label class="material-icons" style="line-height: 18px"
                      (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display:
                        dynHid[parameters[contentTab.key][key].type] !==
                        undefined
                          ? 'none'
                          : ''
                    }">
                    <div style="display: flex; align-items: center">
                      <label style="line-height: 18px">{{ language?.style }}:&nbsp;</label>
                      <select [id]="parameters[contentTab.key][key].type + '_style'" [formControlName]="
                          parameters[contentTab.key][key].type + '_style'
                        " class="form-control">
                        <option value="solid">{{ language?.style_border_solid }}</option>
                        <option value="dotted">{{ language?.style_border_dotted }}</option>
                        <option value="dashed">{{ language?.style_border_dashed }}</option>
                        <option value="double">{{ language?.style_border_double }}</option>
                        <option value="groove">{{ language?.style_border_groove }}</option>
                        <option value="ridge">{{ language?.style_border_ridge }}</option>
                        <option value="inset">{{ language?.style_border_inset }}</option>
                        <option value="outset">{{ language?.style_border_outset }}</option>
                        <option value="none">{{ language?.style_border_none }}</option>
                        <option value="hidden">{{ language?.style_border_hidden }}</option>
                      </select>
                    </div>
                  </div>
                  <hr />
                </ng-container>

                <!-- style_border_top_style -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type ===
                      'style_border_top_style' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">
                      {{ language?.style_border_top }}&nbsp;</label><label class="material-icons" style="line-height: 18px"
                      (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display:
                        dynHid[parameters[contentTab.key][key].type] !==
                        undefined
                          ? 'none'
                          : ''
                    }">
                    <div style="display: flex; align-items: center">
                      <label style="line-height: 18px">{{ language?.style }}:&nbsp;</label>
                      <select [id]="parameters[contentTab.key][key].type + '_style'" [formControlName]="
                          parameters[contentTab.key][key].type + '_style'
                        " class="form-control">
                        <option value="solid">{{ language?.style_border_solid }}</option>
                        <option value="dotted">{{ language?.style_border_dotted }}</option>
                        <option value="dashed">{{ language?.style_border_dashed }}</option>
                        <option value="double">{{ language?.style_border_double }}</option>
                        <option value="groove">{{ language?.style_border_groove }}</option>
                        <option value="ridge">{{ language?.style_border_ridge }}</option>
                        <option value="inset">{{ language?.style_border_inset }}</option>
                        <option value="outset">{{ language?.style_border_outset }}</option>
                        <option value="none">{{ language?.style_border_none }}</option>
                        <option value="hidden">{{ language?.style_border_hidden }}</option>
                      </select>
                    </div>
                  </div>
                  <hr />
                </ng-container>

                <!-- style_border_bottom_style -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type ===
                      'style_border_bottom_style' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">
                      {{ language?.style_border_bottom }}&nbsp;</label><label class="material-icons" style="line-height: 18px"
                      (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display:
                        dynHid[parameters[contentTab.key][key].type] !==
                        undefined
                          ? 'none'
                          : ''
                    }">
                    <div style="display: flex; align-items: center">
                      <label style="line-height: 18px">{{ language?.style }}:&nbsp;</label>
                      <select [id]="parameters[contentTab.key][key].type + '_style'" [formControlName]="
                          parameters[contentTab.key][key].type + '_style'
                        " class="form-control">
                        <option value="solid">{{ language?.style_border_solid }}</option>
                        <option value="dotted">{{ language?.style_border_dotted }}</option>
                        <option value="dashed">{{ language?.style_border_dashed }}</option>
                        <option value="double">{{ language?.style_border_double }}</option>
                        <option value="groove">{{ language?.style_border_groove }}</option>
                        <option value="ridge">{{ language?.style_border_ridge }}</option>
                        <option value="inset">{{ language?.style_border_inset }}</option>
                        <option value="outset">{{ language?.style_border_outset }}</option>
                        <option value="none">{{ language?.style_border_none }}</option>
                        <option value="hidden">{{ language?.style_border_hidden }}</option>
                      </select>
                    </div>
                  </div>
                  <hr />
                </ng-container>

                <!-- style_border_width -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type ===
                      'style_border_width' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">{{ language?.style_border_width }}&nbsp;</label><label class="material-icons"
                      style="line-height: 18px" (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display:
                        dynHid['style_border_width'] !== undefined ? 'none' : ''
                    }">
                    <div style="display: flex; align-items: center">
                      <label style="line-height: 18px">{{ language?.item_width_label }}:&nbsp;</label>
                      <input type="text" [id]="'style_border_width_width'"
                        [formControlName]="'style_border_width_width'" class="form-control" />
                    </div>
                  </div>
                  <hr />
                </ng-container>

                <!-- style_border_color -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type ===
                      'style_border_color' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">{{ language?.style_border_color }}&nbsp;</label><label class="material-icons"
                      style="line-height: 18px" (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display:
                        dynHid['style_border_color'] !== undefined ? 'none' : ''
                    }">
                    <div style="display: flex; align-items: center">
                      <label style="line-height: 18px">Color:&nbsp;</label>
                      <input type="color" [id]="'style_border_color_color'"
                        [formControlName]="'style_border_color_color'" class="form-control" />
                    </div>
                  </div>
                  <hr />
                </ng-container>

                <!-- style_border_left_width -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type ===
                      'style_border_left_width' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">
                      {{ language?.style_border_left_width }}&nbsp;</label><label class="material-icons" style="line-height: 18px"
                      (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display:
                        dynHid['style_border_left_width'] !== undefined
                          ? 'none'
                          : ''
                    }">
                    <div style="display: flex; align-items: center">
                      <label style="line-height: 18px">{{ language?.item_width_label }}:&nbsp;</label>
                      <input type="text" [id]="'style_border_left_width_width'"
                        [formControlName]="'style_border_left_width_width'" class="form-control" />
                    </div>
                  </div>
                  <hr />
                </ng-container>

                <!-- style_border_left_width -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type ===
                      'style_border_left_color' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">
                      {{ language?.style_border_left_width }}&nbsp;</label><label class="material-icons" style="line-height: 18px"
                      (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display:
                        dynHid['style_border_left_color'] !== undefined
                          ? 'none'
                          : ''
                    }">
                    <div style="display: flex; align-items: center">
                      <label style="line-height: 18px">Color:&nbsp;</label>
                      <input type="color" [id]="'style_border_left_color_color'"
                        [formControlName]="'style_border_left_color_color'" class="form-control" />
                    </div>
                  </div>
                  <hr />
                </ng-container>

                <!-- style_border_right_width -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type ===
                      'style_border_right_width' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">
                      {{ language?.style_border_right_width }}&nbsp;</label><label class="material-icons" style="line-height: 18px"
                      (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display:
                        dynHid['style_border_right_width'] !== undefined
                          ? 'none'
                          : ''
                    }">
                    <div style="display: flex; align-items: center">
                      <label style="line-height: 18px">{{ language?.item_width_label }}:&nbsp;</label>
                      <input type="text" [id]="'style_border_right_width_width'"
                        [formControlName]="'style_border_right_width_width'" class="form-control" />
                    </div>
                  </div>
                  <hr />
                </ng-container>

                <!-- style_border_right_color -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type ===
                      'style_border_right_color' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">
                      {{ language?.style_border_right_color }}&nbsp;</label><label class="material-icons" style="line-height: 18px"
                      (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display:
                        dynHid['style_border_right_color'] !== undefined
                          ? 'none'
                          : ''
                    }">
                    <div style="display: flex; align-items: center">
                      <label style="line-height: 18px">Color:&nbsp;</label>
                      <input type="color" [id]="'style_border_right_color_color'"
                        [formControlName]="'style_border_right_color_color'" class="form-control" />
                    </div>
                  </div>
                  <hr />
                </ng-container>

                <!-- style_border_top_width -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type ===
                      'style_border_top_width' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">
                      {{ language?.style_border_top_width }}&nbsp;</label><label class="material-icons" style="line-height: 18px"
                      (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display:
                        dynHid['style_border_top_width'] !== undefined
                          ? 'none'
                          : ''
                    }">
                    <div style="display: flex; align-items: center">
                      <label style="line-height: 18px">{{ language?.item_width_label }}:&nbsp;</label>
                      <input type="text" [id]="'style_border_top_width_width'"
                        [formControlName]="'style_border_top_width_width'" class="form-control" />
                    </div>
                  </div>
                  <hr />
                </ng-container>

                <!-- style_border_bottom_color -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type ===
                      'style_border_bottom_color' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">
                      {{ language?.style_border_bottom_color }}&nbsp;</label><label class="material-icons" style="line-height: 18px"
                      (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display:
                        dynHid['style_border_bottom_color'] !== undefined
                          ? 'none'
                          : ''
                    }">
                    <div style="display: flex; align-items: center">
                      <label style="line-height: 18px">Color:&nbsp;</label>
                      <input type="color" [id]="'style_border_bottom_color_color'"
                        [formControlName]="'style_border_bottom_color_color'" class="form-control" />
                    </div>
                  </div>
                  <hr />
                </ng-container>

                <!-- style_border_bottom_width -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type ===
                      'style_border_bottom_width' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">
                      {{ language?.style_border_bottom_width }}&nbsp;</label><label class="material-icons" style="line-height: 18px"
                      (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display:
                        dynHid['style_border_bottom_width'] !== undefined
                          ? 'none'
                          : ''
                    }">
                    <div style="display: flex; align-items: center">
                      <label style="line-height: 18px">{{ language?.item_width_label }}:&nbsp;</label>
                      <input type="text" [id]="'style_border_bottom_width_width'"
                        [formControlName]="'style_border_bottom_width_width'" class="form-control" />
                    </div>
                  </div>
                  <hr />
                </ng-container>

                <!-- style_border_top_color -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type ===
                      'style_border_top_color' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="sshow(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] ===
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_right</label>
                    <label class="material-icons" style="line-height: 18px"
                      (click)="shide(parameters[contentTab.key][key].type)" [ngStyle]="{
                        display:
                          dynHid[parameters[contentTab.key][key].type] !==
                          undefined
                            ? 'none'
                            : ''
                      }">keyboard_arrow_down</label>
                    <label style="line-height: 18px">
                      {{ language?.style_border_top_color }}&nbsp;</label><label class="material-icons" style="line-height: 18px"
                      (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                  </div>
                  <div [ngStyle]="{
                      display:
                        dynHid['style_border_top_color'] !== undefined
                          ? 'none'
                          : ''
                    }">
                    <div style="display: flex; align-items: center">
                      <label style="line-height: 18px">Color:&nbsp;</label>
                      <input type="color" [id]="'style_border_top_color_color'"
                        [formControlName]="'style_border_top_color_color'" class="form-control" />
                    </div>
                  </div>
                  <hr />
                </ng-container>

                <!-- style_horz_align -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type ===
                      'style_horz_align' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                    <label style="line-height: 18px; margin-left: 3%;">
                      {{ language?.style_horz_align }}&nbsp;
                    </label>
                    <select style="margin-left: 4%;" type="color" [id]="parameters[contentTab.key][key].type + '_align'"
                      [formControlName]="parameters[contentTab.key][key].type + '_align'" class="form-control">
                      <option value="left">{{ language?.style_align_left }}</option>
                      <option value="right">{{ language?.style_align_right }}</option>
                      <option value="center">{{ language?.style_align_center }}</option>
                    </select>
                  </div>
                  <hr />
                </ng-container>

                <!-- style_vert_align -->
                <ng-container *ngIf="
                    parameters[contentTab.key][key]?.type ===
                      'style_vert_align' &&
                    values[
                      parameters[contentTab.key][key]?.type + '_active'
                    ] !== undefined
                  ">
                  <div style="display: flex; align-items: center">
                    <label class="material-icons" style="line-height: 18px"
                      (click)="closeDyn(parameters[contentTab.key][key].type)">clear</label>
                    <label style="line-height: 18px; margin-left: 3%;">
                      {{ language?.style_vert_align }}&nbsp;
                    </label>
                    <select style="margin-left: 4%;" type="color" [id]="parameters[contentTab.key][key].type + '_align'"
                      [formControlName]="parameters[contentTab.key][key].type + '_align'" class="form-control">
                      <option value="left">{{ language?.style_align_top }}</option>
                      <option value="right">{{ language?.style_align_center }}</option>
                      <option value="center">{{ language?.style_align_bottom }}</option>
                    </select>
                  </div>
                  <hr />
                </ng-container>
              </div>
            </ng-container>
          </ng-container>

        </form>
      </div>
      <br>
      <button *ngIf="clone" type="button" (click)="optionSubmit()" class="btn btn-primary float-right">
        {{ language?.clone_button }}
      </button>
      <button *ngIf="clone" class="btn btn-danger" type="button" (click)="closeOption()">
        {{ language?.cancel_button }}
      </button>
      <button *ngIf=" !clone " type="button" (click)="optionSubmit()" class="btn btn-primary float-right">
        {{ language?.save_button }}
      </button>
      <button type="button" id="deleteItemButton" class="btn btn-danger"
        *ngIf="(paramsDone || !reportItem) && (idItem !== 'new') && (!clone) " data-toggle="modal"
        data-target=".bd-modal-deleteItem" (click)="confirmItemToDelete($event)">
        {{ language?.delete }}
      </button>
      <div class="tab-pane fade mt-3" id="variable" role="tabpanel" aria-labelledby="variable-tab">
        <p>HOLA</p>
      </div>
      <datalist id="connect_aliases">
        <option *ngFor="let variable of alias" [value]="variable.name"></option>
      </datalist>

      <datalist id="connect_tags">
        <option *ngFor="let tag of tags" [value]="tag.name"></option>
      </datalist>

      <datalist id="synopticList">
        <option *ngFor="let synoptic of synoptics" [value]="synoptic.name"></option>
      </datalist>

      <datalist id="reportList">
        <option *ngFor="let report of reports" [value]="report.name"></option>
      </datalist>

      <datalist id="textList">
        <option *ngFor="let text of itemsInSynoptic?.Text" [value]="text.parameters.namegeneral"></option>
      </datalist>
    </div>
  </div>
</div>
<!-- Modal delete item -->
<div class="modal fade bd-modal-deleteItem mt-5" tabindex="-1" role="dialog" aria-labelledby="Eliminar item"
  aria-hidden="true" id="deleteItem">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalDeleteItemTitle">
          {{ language?.confirmation }}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3>
          {{ language?.confirmation_message }}<span id="itemToDelete"></span> ?
        </h3>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ language?.close }}
        </button>
        <button type="button" class="btn btn-danger" (click)="deleteItem($event)">
          {{ language?.delete }}
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Modal delete item -->
<div class="modal fade bd-modal-deleteItem2 mt-5" tabindex="-1" role="dialog" aria-labelledby="Eliminar item"
  aria-hidden="true" id="deleteItem2">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalDeleteItemTitle">
          {{ language?.confirmation }}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3>
          {{ language?.confirmation_message }}<span id="itemToDelete"></span> ?
        </h3>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ language?.close }}
        </button>
        <button type="button" class="btn btn-danger" (click)="deleteItem2($event)">
          {{ language?.delete }}
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Modal alert -->
<div class="modal fade bd-modal-deleteItem2 mt-5" tabindex="-1" role="dialog" aria-labelledby="modalAlert"
  aria-hidden="true" id="modalAlert">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalAlertTitle">
          {{ modalAlertMsg.title }}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3>{{ modalAlertMsg.message }}<span id="itemToDelete"></span></h3>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="closeAlert()" class="btn btn-secondary" data-dismiss="modal">
          {{ modalAlertMsg.button }}
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade bd-modal-visor mt-5" tabindex="-1" role="dialog" aria-labelledby="modalAlert" aria-hidden="true"
  id="modalVisor">
  <div class="modal-dialog" id="divmodvisor">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalVisorTitle">
          {{ modalVisor.title }}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="divVisor">

      </div>
      <div class="modal-footer">
        <button type="button" (click)="closeAlert()" class="btn btn-secondary" data-dismiss="modal">
          {{ modalVisor.button }}
        </button>
      </div>
    </div>
  </div>
</div>
