import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlarmsService } from 'src/app/service/tags/alarms.service';
import { TableComponent } from 'src/app/components/table/table.component';

@Component({
  selector: 'app-alarm-form',
  templateUrl: './alarm-form.component.html',
  styleUrls: ['./alarm-form.component.scss'],
})
export class AlarmFormComponent implements OnChanges, AfterContentChecked {
  @Input() tags: any;
  @Input() alarms: any;

  @ViewChild('alarmsTable') alarmsTable!: TableComponent;

  availableTags: any;

  alarmForm: FormGroup = new FormGroup({
    tag: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9\-_]+$/),
      Validators.maxLength(25),
    ]),
    ack_tag: new FormControl('', [
      Validators.pattern(/^[a-zA-Z0-9\-_]+$/),
      Validators.maxLength(25),
    ]),
    message: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9áàéèíìóòúùÁÀÉÈÍÌÓÒÚÙ\s\-_<>.()%]+$/),
      Validators.maxLength(75),
    ]),
  });
  alarmFormAction: 'create' | 'edit' = 'create';
  alarmEditing: any;
  alarmFormExpanded: boolean = false;

  alarmsHeaders = [
    { key: 'select' },
    { key: 'tag', label: 'Tag de Alarma' },
    { key: 'ack_tag', label: 'Tag de reconocimiento' },
    { key: 'message', label: 'Mensaje' },
  ];
  alarmsData: any[] = [];

  constructor(
    private alarmServ: AlarmsService,
    private changeDetector: ChangeDetectorRef
  ) {}

  formatAlarmsData(alarms: any): any {
    return alarms.map((alarm: any) => {
      return {
        tag: alarm?.tag?.name,
        ack_tag: alarm?.ack_tag?.name,
        message: alarm?.message,
      };
    });
  }

  async getAvailableAlarmTags(): Promise<any[]> {
    const usedTagIds = this.alarms
      .flatMap((alarm: any) => [alarm.tag._id, alarm.ack_tag?._id])
      .filter(Boolean);
    const availableTags = this.tags.filter(
      (tag: any) => !usedTagIds.includes(tag._id)
    );

    return availableTags;
  }

  closeAlarmForm() {
    this.alarmForm.reset();
    this.alarmForm.controls.tag.enable();

    this.alarmForm.controls.tag.setValue(null);
    this.alarmForm.controls.ack_tag.setValue(null);
    this.alarmForm.controls.message.setValue(null);

    this.alarmFormExpanded = false;
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.closeAlarmForm();

    if (changes.alarms?.currentValue != null) {
      this.alarmsData = this.formatAlarmsData(this.alarms);
      this.getAvailableAlarmTags().then((data) => (this.availableTags = data));
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  openAddAlarmForm() {
    this.alarmFormAction = 'create';
    this.alarmFormExpanded = true;
  }

  openEditAlarmForm() {
    this.alarmFormAction = 'edit';

    const selectedAlarm = this.alarmsTable.selection.selected[0];
    this.alarmEditing = this.alarms.find(
      (alarm) => selectedAlarm.tag === alarm.tag.name
    );

    this.alarmForm.controls.tag.disable();
    this.alarmForm.controls.tag.setValue(this.alarmEditing.tag?.name ?? null);
    this.alarmForm.controls.ack_tag.setValue(
      this.alarmEditing.ack_tag?.name ?? null
    );
    this.alarmForm.controls.message.setValue(
      this.alarmEditing?.message ?? null
    );
    this.alarmFormExpanded = true;
  }

  validateAlarmForm(): {
    tag: string;
    ack_tag: string | null;
    message: string;
  } {
    const { tag, message, ack_tag } = this.alarmForm.controls;

    if (this.alarmFormAction === 'create') {
      const tagExists = this.tags.some((each) => each.name === tag.value);
      const tagUsed = this.alarms.some((each) => each.tag?.name === tag.value);

      if (!tagExists) {
        tag.setErrors({ notExists: true });
        throw new Error('Tag does not exist');
      } else if (tagUsed) {
        tag.setErrors({ notUsed: true });
        throw new Error('Tag already in use');
      }
    }

    if (ack_tag.value != null && ack_tag.value !== '') {
      if (tag.value === ack_tag.value) {
        ack_tag.setErrors({ notUnique: true });
        throw new Error('Ack tag must be different from tag');
      }

      const ackTagExists = this.tags.some(
        (each) => each.name === ack_tag.value
      );
      const ackTagUsed = this.alarms.some(
        (each) => each.ack_tag?.name === ack_tag.value
      );

      if (this.alarmEditing?.ack_tag?.name !== ack_tag.value) {
        if (!ackTagExists) {
          ack_tag.setErrors({ notExists: true });
          throw new Error('Ack tag does not exist');
        }

        if (ackTagUsed) {
          ack_tag.setErrors({ duplicate: true });
          throw new Error('Ack tag already in use');
        }
      }
    }

    if (!this.alarmForm.valid) {
      throw new Error('Form data is invalid');
    }

    return {
      tag: tag.value,
      ack_tag: ack_tag.value ?? null,
      message: message.value,
    };
  }

  async createAlarm(data: {
    tag: string;
    ack_tag: string | null;
    message: string;
  }): Promise<any> {
    const { tag, ack_tag, message } = data;
    const tagObj = this.tags.find((each) => each.name === tag);
    const ackTagObj = this.tags.find((each) => each.name === ack_tag) ?? null;

    return this.alarmServ.create({
      tag: tagObj._id,
      ack_tag: ackTagObj?._id ?? null,
      message,
    });
  }

  async editAlarm(data: {
    tag: string;
    ack_tag: string | null;
    message: string;
  }): Promise<void> {
    const { tag, ack_tag, message } = data;
    const tagObj = this.tags.find((each) => each.name === tag);
    const ackTagObj = this.tags.find((each) => each.name === ack_tag) ?? null;

    if (this.alarmEditing.tag.name !== tag) {
      this.alarmForm.controls.tag.enable();
      this.alarmForm.controls.tag.setValue(this.alarmEditing.tag.name);
      this.alarmForm.get('tag').disable();
      return;
    }

    const editedAlarm = await this.alarmServ.update({
      _id: this.alarmEditing._id,
      tag: tagObj._id,
      ack_tag: ackTagObj?._id ?? null,
      message,
    });

    const alarmIndex = this.alarms.findIndex(
      (alarm) => editedAlarm._id === alarm._id
    );
    this.alarms[alarmIndex] = editedAlarm;
  }

  async alarmFormSubmit(): Promise<void> {
    try {
      const formData = this.validateAlarmForm();
      this.alarmFormExpanded = false;

      if (this.alarmFormAction === 'create') {
        const newAlarm = await this.createAlarm(formData);
        this.alarms.push(newAlarm);
      } else if (this.alarmFormAction === 'edit') {
        await this.editAlarm(formData);
      }

      this.alarmsData = this.formatAlarmsData(this.alarms);
      this.getAvailableAlarmTags().then((data) => (this.availableTags = data));
      this.closeAlarmForm();
    } catch (error) {
      console.error('Error submitting alarm form:', error);
    }
  }

  deleteAlarm(alarms: any[]) {
    const promises: Promise<any>[] = [];
    for (const alarm of alarms) {
      const index = this.alarms.findIndex(
        (each) => each.tag?.name === alarm.tag
      );
      if (index != null) {
        const alarmObj = this.alarms[index];
        promises.push(this.alarmServ.delete(alarmObj._id));
        this.alarms.splice(index, 1);
        this.alarmsData.splice(index, 1);
      }
    }

    Promise.all(promises)
      .then(() => {
        this.alarmsData = [...this.alarmsData];
        this.getAvailableAlarmTags().then(
          (data) => (this.availableTags = data)
        );
        this.closeAlarmForm();
      })
      .catch((e) => console.error('Error on delete alarms:\n  ', e));
  }
}
