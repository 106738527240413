import { Component, OnInit } from '@angular/core';
import { ScriptsService } from 'src/app/service/scripts.service';
import { LanguageService } from 'src/app/service/system/language.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $: any;
import * as $_ from 'jquery';

@Component({
  selector: 'app-scripts',
  templateUrl: './scripts.component.html',
  styleUrls: ['./scripts.component.scss']
})
export class ScriptsComponent implements OnInit {
  content: any;
  directory: any;
  script: any;
  languages: any;
  intervalForm: FormGroup;
  codeMirrorOptions: any;

  constructor(
    private scriptServ: ScriptsService,
    private langServ: LanguageService
  ) {
    this.intervalForm = new FormGroup({
      interval: new FormControl('', Validators.required)
    });
    this.codeMirrorOptions = {
      lineNumbers: true,
      theme: 'elegant',
      extraKeys: {
        F11: (cm) => {
          if (!cm.getOption('fullScreen')) {
            cm.setOption('fullScreen', true);
            $('ngx-codemirror').addClass('CodeMirror-fullscreen');
            $('.CodeMirror').css('height', '100%');
          } else {
            cm.setOption('fullScreen', false);
            $('ngx-codemirror').removeClass('CodeMirror-fullscreen');
            $('.CodeMirror').css('height', '500px');
          }
        },
        Esc: (cm) => {
          if (cm.getOption('fullScreen')) {
            cm.setOption('fullScreen', false);
            $('ngx-codemirror').removeClass('CodeMirror-fullscreen');
            $('.CodeMirror').css('height', '500px');
          }
        }
      }
    };
  }

  ngOnInit() {
    this.loadJSTree();
    this.getLanguages();
  }

  /**
   * Get languages
   */
  async getLanguages() {
    const language = await this.langServ.getVal();
    this.languages = language.scripts;
  }

  /**
   * Return script directory
   */
  async getDirectories() {
    return await this.scriptServ.getFiles();
  }

  /**
   * Load jsTree and his functions
   */
  async loadJSTree() {
    // Change function to get selected script
    $('#jstree').on('changed.jstree', async (e, data) => {
      const j = [];
      // tslint:disable-next-line: no-shadowed-variable
      for (let i = 0, j = data.selected.length; i < j; i++) {
        if (data.instance.get_node(data.selected[i]).type === 'file') {
          const id = data.instance.get_node(data.selected[i]).original._id;
          this.script = await this.scriptServ.getScript(id);
          $('#codemirror').removeClass('hidden');
          if (this.script.hasOwnProperty('content')) {
            // this.content = this.script.content;
          } else {
            // this.content = '';
          }
          if (this.script.hasOwnProperty('interval')) {
            this.intervalForm.patchValue({
              interval: this.script.interval / 1000
            });
          }
        } else {
          $('#codemirror').addClass('hidden');
        }
      }
    });
    // Load JSTree
    $('#jstree').jstree({
      core: {
        check_callback: true,
        data: await this.getDirectories()
      },
      types: {
        folder: {
          icon: 'fa fa-folder fa-lg'
        },
        file: {
          icon: 'fa fa-file fa-lg'
        },
      },
      contextmenu: {
        items: (node) => {
          const items = $.jstree.defaults.contextmenu.items();
          items.ccp = false;
          if (node.type === 'folder') {
            items.createFile = {
              label: this.languages.create_script,
              separator_after: true,
              action: () => {
                this.addFile(node);
              }
            };
            /*
            items.createFolder = {
              label: this.languages.create_folder,
              separator_after: true,
              action: () => {
                this.addFolder(node);
              }
            };
            */
          }
          items.rename.label = this.languages.rename;
          items.remove.label = this.languages.delete;
          delete items.create;
          return items;
        }
      },
      plugins: ['types', 'contextmenu']
    }).bind('ready.jstree', (e, data) => {
      /* $(this).jstree('open_all'); */
    });
    this.jstreeFunctions();
  }

  /**
   * jsTree CRUD functions
   */
  jstreeFunctions() {
    // Rename node
    $('#jstree').on('rename_node.jstree', async (node, obj) => {
      const id = obj.node.original._id;
      if (obj.node.type === 'folder') {
        const folder: any = {};
        folder._id = id;
        folder.text = obj.node.text;
        await this.scriptServ.updateFolder(folder);
      } else {
        const file: any = {};
        file._id = id;
        file.text = obj.node.text;
        await this.scriptServ.updateFile(file);
      }
    });
    // Create node
    $('#jstree').on('create_node.jstree', async (node, obj) => {
      const tree = $('#jstree').jstree(true);
      if (obj.node.type === 'folder') {
        const folder: any = {};
        folder.text = obj.node.text;
        folder.id = obj.node.id;
        folder.parent = obj.node.parent;
        folder.type = obj.node.type;
        await this.scriptServ.createFolder(folder);
      } else {
        const file: any = {};
        file.text = obj.node.text;
        file.type = obj.node.type;
        file.parent = obj.node.parent;
        file.id = obj.node.id;
        await this.scriptServ.createFile(file);
      }
      // Reload all jstree nodes
      tree.settings.core.data = await this.getDirectories();
      tree.refresh();
      tree.redraw(true);
    });
    // Delete node
    $('#jstree').on('delete_node.jstree', (node, obj) => {
      const id = obj.node.original._id;
      if (obj.node.children.length === 0) {
        if (obj.node.type === 'folder') {
          this.scriptServ.deleteFolder(id);
        } else {
          this.scriptServ.deleteScript(id);
        }
      } else {
        const message = this.languages.delete_alert.replace('[node]', obj.node.text);
        alert(message);
        $('#jstree').jstree(true).refresh();
      }
    });
  }

  /**
   * Create a file inside folder
   * @param node parent Node
   */
  addFile(node) {
    $('#jstree').jstree().create_node(node.id, {
      text: this.languages.new_script,
      type: 'file',
      parent: node.id
    });
  }

  /**
   * Creat a folder inside folder or #
   * @param node parent Node
   */
  addFolder(node) {
    let id = '';
    if (node === '#') {
      id = node;
    } else {
      id = node.id;
    }
    $('#jstree').jstree().create_node(id, {
      text: this.languages.new_folder,
      type: 'folder',
      parent: node.id
    });
  }
  /**
   * When user submit the interval convert seconds to milliseconds
   * and add interval to script object
   */
  intervalSubmit() {
    this.script.interval = this.intervalForm.value.interval * 1000;
    ($_('#scriptInterval') as any).modal('hide');
  }

  /**
   * Send script to backend to upload it in bbdd
   * @param event event
   */
  async saveScript(event) {
    this.script.content = this.content;
    await this.scriptServ.updateFile(this.script);
  }
}
