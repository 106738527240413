import { Component, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { DatatableService } from 'src/app/service/datatable.service';


@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: [],
})
export class SchedulerComponent implements OnInit {
  @ViewChild('canvas') canvas: HTMLCanvasElement;
  solution: any = {};
  taskSequence: any = [];
  machines: any = [];
  idMachines: any = [];
  // time: string = '2020-11-20T23:00:00';
  timeDate: string;
  time: string;
  timeR: any;
  // timeTest: any;
  constructor(private dataServ: DatatableService) {}

  ngOnInit(): void {
    this.taskSequence = this.solution.task_sequence;
    this.machinesData();
    this.drawCanvasChart();
    this.initTable();
    this.calculateTime();
  }

  /*
   Save all sequences for each machine type
   Return array with all sequences for all machines
 */
  machinesData() {
    let sequencesMachines = [];

    for (const sequences of this.taskSequence) {
      if (!this.idMachines.includes(sequences.mid)) {
        this.idMachines.push(sequences.mid);
      }
    }

    //After know the number of machines, save the sequences of each machine
    for (let sequences = 0; sequences < this.idMachines.length; sequences++) {
      for (const sequence of this.taskSequence) {
        if (this.idMachines[sequences] === sequence.mid)
          sequencesMachines.push([sequence.st, sequence.end]);
      }

      this.machines.push(sequencesMachines);
      sequencesMachines = [];
    }
  }

  // Draw canvas chart method
  drawCanvasChart() {
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');

    const myChart = new Chart(ctx, {
      type: 'horizontalBar',
      data: {
        labels: this.drawCanvasLabels(), //Draw canvas labels
        datasets: this.drawCanvasDataset(), //Draw canvas dataset
      },
      options: {
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });

    // myChart.resize();
  }

  /*
    Draw charjs labels with quantity numbers of machinas
    Return array with numbers of machinas
  */
  drawCanvasLabels() {
    let labels = [];

    for (let indexID = 0; indexID < this.idMachines.length; indexID++) {
      labels[indexID] = `Máquina ${this.idMachines[indexID]}`;
    }

    return labels;
  }

  /*
    Draw charjs dataset with quantity numbers of machinas
    Return array with numbers of machinas
  */
  drawCanvasDataset() {
    let dataset = [];

    //Draw the row specific of the machine selected until selected alls
    for (let indexID = 0; indexID < this.idMachines.length; indexID++) {
      let line = {
        label: `Máquina ${this.idMachines[indexID]}`,
        data: this.drawDatasetLine(indexID), //Return of the task_sequences
        backgroundColor: '#3e95cd',
        borderColor: 'rgba(0, 0, 1.0)',
        borderWidth: 5,
      };

      //Save the row inside the all rows
      dataset.push(line);
    }

    return dataset;
  }

  /*
    Draw a specific row
    Paramaters enter: row want to draw
    Return drawLine
  */
  drawDatasetLine(line) {
    const drawLine = [];

    for (let item = 0; item < this.machines.length; item++) {
      drawLine.push(this.machines[line][item]);
    }

    return drawLine;
  }

  initTable() {
    (<any>$('#task_sequence')).dataTable().fnDestroy();
    setTimeout(async () => {
      // Get options from datatable service
      const options = await this.dataServ.optionsDatatable();
      // Execute datatable function to load it

      (<any>$('#task_sequence')).dataTable(options);
    }, 250);
  }

  calculateTime() {
    let timeStart: any;
    if (this.timeDate == undefined || this.time == undefined) return;
    timeStart = Date.parse(`${this.timeDate}T${this.time}`); // Take the time when start de production

    for (const log of this.taskSequence) {
      // take hour for calculate time start
      const timeLogStart = log.st * 3600000; // Calculate hours in millisecond 1hour = 3600000 ml
      const timeR = timeLogStart + timeStart; // Calculate the time start in ml
      log.st = new Date(timeR);

      // Calculate time end
      const timeLogEnd = log.end * 3600000;
      const timeREnd = timeLogEnd + timeStart;
      log.end = new Date(timeREnd);
    }
  }
}
