<div style="height: 90%; display: grid; grid-template-rows: max-content 32px 1fr;">
  <div>
    <div style="display: flex; padding: 15px 10px; gap: 10px;">
      <button mat-raised-button color="logi" aria-label="Add alarm button" (click)="openAddAlarmForm()"
        style="margin-right: auto;">
        Añadir alarma
      </button>
      <button mat-mini-fab color="warn" aria-label="Delete alarm button"
        [disabled]="alarmsTable.selection.selected.length < 1" (click)="deleteAlarm(alarmsTable.selection.selected)">
        <mat-icon>delete</mat-icon>
      </button>
      <button mat-mini-fab color="logi" aria-label="Edit alarm button"
        [disabled]="alarmsTable.selection.selected.length !== 1" (click)="openEditAlarmForm()">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
    <mat-expansion-panel [expanded]="alarmFormExpanded" (opened)="alarmFormExpanded = true"
      (closed)="alarmFormExpanded = false" hideToggle="true" style="box-shadow: none;">
      <mat-expansion-panel-header style="height: 1px; background-color: transparent;">
      </mat-expansion-panel-header>

      <form id="alarmForm" [formGroup]="alarmForm" #alarmNgForm="ngForm"
        (ngSubmit)="alarmFormSubmit(); alarmNgForm.resetForm();"
        style="display: flex; gap: 8%; padding: 15px 0;">
        <mat-form-field appearance="fill">
          <mat-label>Tag de alarma</mat-label>
          <input matInput formControlName="tag" list="tags_list" />
          <mat-hint>Tag al que reacciona la alarma</mat-hint>
          <datalist id="tags_list">
            <ng-container *ngFor="let tag of availableTags">
              <option [value]="tag?.name"></option>
            </ng-container>
          </datalist>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Tag de reconocimiento (Opcional)</mat-label>
          <input matInput formControlName="ack_tag" list="tags_list" />
          <mat-hint>Tag de reconocimento de alarma</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Mensaje</mat-label>
          <textarea matInput formControlName="message" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1" cdkAutosizeMaxRows="2"></textarea>
          <mat-hint>Mensaje que aparecerá al activarse la alarma</mat-hint>
        </mat-form-field>
      </form>

      <mat-action-row>
        <button mat-button color="warn" type="button" (click)="closeAlarmForm(); alarmNgForm.resetForm();">Cancelar</button>
        <button mat-raised-button color="logi" type="submit" form="alarmForm"
          [disabled]="!alarmForm.valid">Añadir</button>
      </mat-action-row>
    </mat-expansion-panel>
  </div>

  <mat-divider style="margin: 15px 0;"></mat-divider>

  <div style="overflow: auto; max-height: 100%;">
    <app-table #alarmsTable [headers]="alarmsHeaders" [data]="alarmsData" [sortable]="true" [paginable]="true"
      [pageSizeOptions]="[9]"></app-table>
  </div>
</div>
