import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TableComponent } from 'src/app/components/table/table.component';
import { DataDesignService } from 'src/app/service/data-design.service';
import { LanguageService } from 'src/app/service/system/language.service';

interface Field {
  _id: string;
  name: string;
  type: 'text' | 'number' | 'date' | 'datetime-local' | 'boolean';
}
interface DataDesign {
  _id: string;
  name: string;
  fields: Field[];
}

@Component({
  selector: 'app-datadesign',
  templateUrl: './datadesign.component.html',
  styleUrls: ['./datadesign.component.scss'],
})
export class DataDesignComponent implements OnInit {
  @ViewChild('tableElement') tableElement!: TableComponent;

  language: any;
  dataDesigns: DataDesign[] = [];
  dataDesignSelected: DataDesign | null = null;

  tableHeaders: any = [];
  tableData: Field[] = [];

  dialogDeleteField: boolean = false;
  dialogDeleteDataDesign: boolean = false;
  dialogCreateField: boolean = false;

  dataDesignToDelete: DataDesign | null = null;

  isEditing: boolean = false;
  isCreatingDataDesign: boolean = false;

  createDataDesignForm: FormGroup = new FormGroup({});

  createFieldForm: FormGroup = new FormGroup({});

  constructor(
    private langServ: LanguageService,
    private dataDesignService: DataDesignService,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<any> {
    this.createDataDesignForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.pattern(/^(?!\s*$)[a-zA-Z0-9\s\-_]+$/),
        Validators.minLength(1),
        Validators.maxLength(50),
      ]),
    });

    this.createFieldForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.pattern(/^(?!\s*$)[a-zA-Z0-9\s\-_<>.:()%]+$/),
        Validators.minLength(1),
        Validators.maxLength(50),
      ]),
      type: new FormControl('', [Validators.minLength(1)]),
    });

    this.language = (await this.langServ.getVal()).option;

    this.dataDesigns = await this.dataDesignService.findAll();

    this.tableHeaders = [
      { key: 'select' },
      { key: 'name', label: 'Nombre' },
      { key: 'type', label: 'Tipo' },
    ];
  }

  async createDataDesign() {
    this.isCreatingDataDesign = true;
    const name: string = this.createDataDesignForm.value.name;

    const newDataDesign = await this.dataDesignService.create({
      name,
      fields: [],
    });

    this.dataDesigns = [...this.dataDesigns, ...[newDataDesign.data]];

    this.isCreatingDataDesign = false;

    this.cdr.detectChanges();

    this.selectDataDesign(newDataDesign.data._id);
  }

  async deleteDataDesign(dataDesignId: string | null) {
    if (dataDesignId != null) {
      try {
        const index = this.dataDesigns.findIndex(
          (each) => each._id === dataDesignId
        );

        await this.dataDesignService.delete(dataDesignId);
        this.dataDesigns.splice(index, 1);

        this.selectDataDesign(null);
        this.cdr.detectChanges();
      } catch (error) {
        console.error('Error deleting data design');
      }
    }
  }

  selectDataDesign(id: string | null) {
    if (id != null) {
      this.dataDesignSelected =
        this.dataDesigns.find((dataDesign) => dataDesign._id === id) ?? null;
      this.tableData = this.dataDesignSelected?.fields ?? [];
    } else {
      this.dataDesignSelected = null;
      this.tableData = [];
    }

    this.cdr.detectChanges();
  }

  async fieldFormSubmit() {
    if (this.isEditing) {
      const editedField: Field = {
        ...this.tableElement.selection.selected[0],
        ...this.createFieldForm.value,
      };

      for (const property of Object.keys(editedField) as Array<keyof Field>) {
        if (editedField[property] == null || editedField[property] === '') {
          delete editedField[property];
        }
      }

      await this.editField(this.dataDesignSelected!, editedField);

      this.dialogCreateField = false;
      this.isEditing = false;
    } else {
      await this.createField(this.dataDesignSelected!, {
        ...this.createFieldForm.value,
      });
    }

    this.dialogCreateField = false;
    this.isEditing = false;
    this.cdr.detectChanges();
  }

  async createField(dataDesign: DataDesign, newField: Field): Promise<void> {
    const { _id } = dataDesign;
    const index = this.dataDesigns.findIndex((each) => each._id === _id);

    if (index === -1) {
      console.error('DataDesign not found');
      return;
    }

    dataDesign.fields.push(newField);

    const newDataDesign = await this.dataDesignService.update(dataDesign);

    this.dataDesigns[index] = newDataDesign.data;

    this.tableData = this.dataDesigns[index].fields;
  }

  async editField(dataDesign: DataDesign, editedField: Field): Promise<void> {
    const { _id } = dataDesign;
    const dataDesignIndex = this.dataDesigns.findIndex(
      (each) => each._id === _id
    );
    const fieldIndex = dataDesign.fields.findIndex(
      (each) => each._id === editedField._id
    );

    if (dataDesignIndex === -1 || fieldIndex === -1) {
      console.error('DataDesign not found');
      return;
    }

    dataDesign.fields[fieldIndex] = {
      ...dataDesign.fields[fieldIndex],
      ...editedField,
    };

    const newDataDesign = await this.dataDesignService.update(dataDesign);

    this.dataDesigns[dataDesignIndex] = newDataDesign.data;

    this.tableData = this.dataDesigns[dataDesignIndex].fields;
  }

  async deleteFields(dataDesign: DataDesign, fieldsToDelete: Field[]) {
    try {
      const { _id, fields } = dataDesign;
      const index = this.dataDesigns.findIndex((each) => each._id === _id);

      if (index === -1) {
        console.error('DataDesign not found');
        return;
      }

      const fieldsToDeleteSet = new Set(
        fieldsToDelete.map((field) => field.name)
      );

      const filteredFields = fields.filter(
        (field) => !fieldsToDeleteSet.has(field.name)
      );

      if (filteredFields.length === fields.length) {
        console.log('No fields to delete');
        return;
      }

      dataDesign.fields = filteredFields;

      const updatedDataDesign = await this.dataDesignService.update(dataDesign);
      this.dataDesigns[index] = updatedDataDesign.data;

      this.tableData = this.dataDesigns[index].fields;
      this.cdr.detectChanges();
    } catch (err) {
      console.error('Error updating data design:', err);
    }
  }
}
