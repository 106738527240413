import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth/auth.service';
import { BackupsService } from 'src/app/service/system/backups.service';
import { LanguageService } from 'src/app/service/system/language.service';

@Component({
  selector: 'app-backups-system',
  templateUrl: './backups-system.component.html',
  styleUrls: ['./backups-system.component.scss'],
})
export class BackupsSystemComponent implements OnInit {
  language: any;
  restoring: boolean = false;
  selectedFile: any = null;

  constructor(
    private backupServ: BackupsService,
    private authServ: AuthService,
    private langServ: LanguageService
  ) {}

  async ngOnInit(): Promise<void> {
    this.language = (await this.langServ.getVal()).option;
  }

  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0] ?? null;
  }

  async getBackup() {
    const newBackup = await this.backupServ.getBackup();
    let blob: any = new Blob([newBackup], { type: 'application/zip' });
    const date = new Date();
    const dateTransform = `${date.getFullYear()}/${
      date.getMonth() + 1
    }/${date.getDate()}`;
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = `LOGiPlatform-${dateTransform}.zip`;

    anchor.href = url;
    anchor.click();
  }

  async handleClick() {
    this.restoring = true;
    const selectedFile = this.selectedFile;
    await this.backupServ.restoreFromZip(selectedFile);
    setTimeout(() => {
      this.restoring = false;
      this.authServ.logOut();
    }, 4000);
  }
}
