<form action="POST">
  <input type="file" id="filePath" style="display: none;" (change)="importZip($event)" />
</form>
<div id="navoptions">
  <ng-container *ngFor="let nav of naveg">
    <button (click)="go(nav._id)">{{ nav.txt }}</button>&nbsp;&nbsp;
  </ng-container>

</div>
<div id="divItems" style="margin-top: 2%; z-index: 1; top: 5px;" (mouseup)="mUp($event)" (mousemove)="mMove($event)"
  (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragstart)="onDragStart($event)"
  (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)" [contextMenu]="basicMenu"
  [contextMenuSubject]="'panel'">

  <ng-container *ngFor="let item of itemsInScreen">
    <div *ngIf="item.tipoMenu === 'menu2'" [id]="item.id"
      [class]="'noselect dv dv' + item.type + item.self + ' ' + item.doParent"
      style="padding: 10px; position: absolute; z-index: 100" [style.left]="item.x + 'px'" [style.top]="item.y + 'px'"
      (mousedown)="mDown($event)" (click)="mClick($event)" (dblclick)="mDblClick($event)" [contextMenu]="basicMenu2"
      [contextMenuSubject]="item">
      {{ item.text }}
    </div>

    <div *ngIf="item.tipoMenu === 'menuProtocol'" [id]="item.id"
      [class]="'noselect dv dv' + item.type + item.self + ' ' + item.doParent"
      style="padding: 10px; position: absolute; z-index: 100" [style.left]="item.x + 'px'" [style.top]="item.y + 'px'"
      (mousedown)="mDown($event)" (click)="mClick($event)" (dblclick)="mDblClick($event)" [contextMenu]="basicMenuProt"
      [contextMenuSubject]="item">
      {{ item.text }}
    </div>
  </ng-container>

  <ng-container *ngFor="let connector of connectors">
    <div style="
        position: absolute;
        z-index: 200;
        border-radius: 4px;
        border: 2px solid gray;
        background: #cccccc;
        width: 11px;
        height: 11px;
        opacity: 1;
      " [style.left]="connector.x + 'px'" [style.top]="connector.y + 'px'" (mouseover)="mOver($event)"
      (mouseout)="mOut($event)"></div>
  </ng-container>

  <svg id="svgMain" xmlns="http://www.w3.org/2000/svg" style="position: absolute; top: 0px; left: 0px" width="100%"
    height="100%" (dragleave)="onDragLeave($event)">
    <ng-container *ngFor="let link of links">
      <path (mouseover)="linkOver($event)" (mouseout)="linkOut($event)" id="link" [attr.from]="link.from_id"
        [attr.to]="link.to_id" [attr.d]="
          'M' +
          link.xIn0 +
          ',' +
          link.yIn0 +
          ' C' +
          link.xIn1 +
          ',' +
          link.yIn1 +
          ' ' +
          link.xFin1 +
          ',' +
          link.yFin1 +
          ' ' +
          link.xFin0 +
          ',' +
          link.yFin0
        " stroke="gray" stroke-width="3" fill="transparent" [contextMenu]="basicMenu3" [contextMenuSubject]="'link'" />
    </ng-container>

    <path style="display: none" d="M200,50 C300,50 100,200 200,200" stroke="black" fill="transparent" />
    <path style="display: none" d="M200,150 C300,150 100,300 200,300" stroke="black" fill="transparent" />
    <path style="display: none" d="M200,350 C300,350 100,500 200,500" stroke="black" stroke-width="5"
      fill="transparent" />
  </svg>
</div>

<div></div>
<div id="jstreeNew" style="position: absolute; top: 25px; left: 0px" (drop)="onDrop($event)"
  (dragstart)="onDragStart($event)" (dragend)="onDragEnd($event)" draggable="true"></div>

<!-- Modal logs -->
<div class="modal fade bd-modal-viewLogs mt-8" tabindex="-1" role="dialog" aria-labelledby="Eliminar item"
  aria-hidden="true" id="viewLogs">

  <div class="modal-dialog mw-100" style="width: 95%; height: 100%">
    <div class="modal-content" style="height: 90%">

      <div class="modal-header">
        <h5 class="modal-title" id="amodalviewValuesTitle">
          {{ language?.logs_list }}
        </h5>

        <span class="close">
          <label>{{ modelizerText?.filter }}:&nbsp;</label><input id="toFilter" type="text" (change)="filterLogs($event.target)"
            size="30" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="checkbox"
            (click)="checkTrama($event.target)" /><label>&nbsp;&nbsp;Control Tramas</label>
        </span>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="height: 90%;">


        <div id="divLogs" style="position:relative; height: 100%; font-family: 'Courier New', monospace;">
          <ng-container *ngIf="logsFilter === 'trama:'">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;_________1_________2_________3_________4_________5_________6_________7_________8_________9_________0_________1_________2_________3
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1234567890123456789012345678901234567890123456789012345678901234567890123456789012345678901234567890123456789012345678901234567890
          </ng-container>
          <div style="height: 100%; overflow: auto;">
            <span *ngFor="let log of logsFiltered">
              {{ log }}<br>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<context-menu #basicMenu>

  <ng-template [visible]="mod_vars_options.insert" contextMenuItem [subMenu]="items">&nbsp;{{ modelizerText?.insert_item }}&nbsp;</ng-template>

  <ng-template contextMenuItem (execute)="mod_func_itemImport($event.item)">&nbsp;{{ modelizerText?.import }}&nbsp;</ng-template>
  <ng-template contextMenuItem (execute)="viewLogs()">&nbsp;{{ modelizerText?.system_logs }}&nbsp;</ng-template>
</context-menu>

<context-menu #basicMenu2>

  <ng-template [visible]="mod_vars_options.insert" contextMenuItem [subMenu]="items">&nbsp;{{ modelizerText?.insert_item }}&nbsp;</ng-template>

  <ng-template contextMenuItem (execute)="mod_func_itemEdit($event.item)">&nbsp;{{ modelizerText?.edit_item }}&nbsp;</ng-template>
  <ng-template contextMenuItem (execute)="itemRemove($event.item)">&nbsp;{{ modelizerText?.delete_item }}&nbsp;</ng-template>
  <ng-template [visible]="mod_vars_options.export" contextMenuItem (execute)="itemExport($event.item)">&nbsp;{{ modelizerText?.export_item }}&nbsp;</ng-template>
  <ng-template [visible]="mod_vars_options.clone" contextMenuItem
    (execute)="mod_func_itemClone($event.item)">&nbsp;{{ modelizerText?.clone_item }}&nbsp;</ng-template>
  <ng-template [visible]="mod_vars_options.cloneBranch" contextMenuItem
    (execute)="mod_func_itemCloneBranch($event.item)">&nbsp;{{ modelizerText?.clone_branch }}&nbsp;</ng-template>
  <ng-template [visible]="mod_vars_options.itemCut" contextMenuItem
    (execute)="mod_func_itemCut($event.item)">&nbsp;{{ modelizerText?.cut_item }}&nbsp;</ng-template>
  <ng-template [visible]="mod_vars_options.itemPaste && mod_vars_options.itemPasteIn" contextMenuItem
    (execute)="mod_func_itemPaste($event.item)">&nbsp;{{ modelizerText?.paste_item }}&nbsp;</ng-template>
</context-menu>

<context-menu #basicMenuProt>
  <ng-template contextMenuItem (execute)="itemExport($event.item)">&nbsp;{{ modelizerText?.export_item }}&nbsp;</ng-template>
  <ng-template contextMenuItem (execute)="itemExport($event.item)">&nbsp;{{ modelizerText?.edit_item }}&nbsp;</ng-template>
  <ng-template contextMenuItem (execute)="itemExport($event.item)">&nbsp;{{ modelizerText?.delete_item }}&nbsp;</ng-template>
  <ng-template contextMenuItem (execute)="itemExport($event.item)">&nbsp;{{ modelizerText?.duplicate_item }}&nbsp;</ng-template>

  <ng-template contextMenuItem (execute)="viewLogs()">&nbsp;Logs&nbsp;</ng-template>

</context-menu>

<context-menu #basicMenu3>
  <ng-template [visible]="mod_vars_options.removeLink" contextMenuItem></ng-template>
</context-menu>

<context-menu #items>
  <ng-container *ngFor="let item of mod_vars_system?.items">
    <ng-template contextMenuItem [visible]="item.insert" (execute)="mod_func_addItem(item)"><img style="width: 24px;"
        src="{{ item.icon }}">&nbsp;{{ item.name }}&nbsp;</ng-template>
  </ng-container>
</context-menu>

<!-- Modal delete item -->
<div class="modal fade bd-modal-deleteItem mt-5" tabindex="-1" role="dialog" aria-labelledby="Eliminar item"
  aria-hidden="true" id="deleteItem">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalDeleteItemTitle">
          {{ mod_vars_languageOptions?.confirmation }}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3>
          {{ mod_vars_languageOptions?.confirmation_message }}<span id="itemToDelete"></span> ?
        </h3>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ mod_vars_languageOptions?.close }}
        </button>
        <button type="button" class="btn btn-danger" (click)="mod_func_deleteItem()">
          {{ mod_vars_languageOptions?.delete }}
        </button>
      </div>
    </div>
  </div>
</div>

<app-optionscomm></app-optionscomm>
