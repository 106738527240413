import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import * as $ from 'jquery';
import jsPDF from 'jspdf';
import { Subscription } from 'rxjs';
import { Keyboard } from 'src/app/interfaces/keyboard';
import { AuthService } from 'src/app/service/auth/auth.service';
import { DatatableService } from 'src/app/service/datatable.service';
import { lpSocket } from 'src/app/service/lp-websocket.service';
import { ModelizerItemsService } from 'src/app/service/modelizer/modelizer-items.service';
import { LanguageService } from 'src/app/service/system/language.service';
import { LoginService } from 'src/app/service/system/login.service';
import { SystemService } from 'src/app/service/system/system.service';
import { TagService } from 'src/app/service/tags/tag.service';
import { environment } from 'src/environments/environment';
import { utils } from './synoptic';
import { DataDesignService } from 'src/app/service/data-design.service';

var params = {};
function setParam(par, value) {
  params[par] = value;
}

let lp_items;

@Component({
  selector: 'app-synoptic-core',
  templateUrl: './synoptic-core.component.html',
  styleUrls: ['./synoptic-core.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class SynopticCoreComponent implements OnInit, OnDestroy {
  BASE_URL: string = environment.base_url;

  view: string = '';
  config: string = '';
  private subscriptions: Subscription[]
  private util: utils;

  countForPlayer: number = 0;
  synopticName: string;

  syn: any = {};

  htmlTags: any = {};

  private ctrl_Log: boolean = false;

  freeConv: any = {
    fontsize_size: 'font-size',
    stylecolor_color: 'color',
    stylebackcolor_color: 'background-color',
    style_horz_align: 'text-align',
    style_vert_align: 'font-size',
    style_border_color: 'border-color',
    style_border_width: 'border-width',
    style_border_style: 'border-style',
    style_border_left_color: 'border-left-color',
    style_border_left_width: 'border-left-width',
    style_border_left_style: 'border-left-style',
    style_border_right_color: 'border-right-color',
    style_border_right_width: 'border-right-width',
    style_border_right_style: 'border-right-style',
    style_border_top_color: 'border-top-color',
    style_border_top_width: 'border-top-width',
    style_border_top_style: 'border-top-style',
    style_border_bottom_color: 'border-bottom-color',
    style_border_bottom_width: 'border-bottom-width',
    style_border_bottom_style: 'border-bottom-style'
  };

  selectedDate: any = new Date();

  control_rep: boolean = false;
  control_intervalChanges: any = false;

  chartLimits: any = {};

  readsControl: any = [];
  readsControl_interval: number = 5000;

  veces: number = 0;

  itemClick: any;
  itemMouseDown: any;
  itemMouseUp: any;

  code_automation: any = {};
  code_prev_player: any = {};
  code_build: any = {};
  code_update: any = {};
  code_change: any = {}
  code_input: any = {}

  minX: number = 0;
  minY: number = 0;
  maxW: number = 0;
  maxH: number = 0;

  lp_items: any = {};
  globals: any = {};
  globalScript: string = '';

  items: any = {};

  synoptics: any;
  synoptic: any;

  controlQuerys: any;

  itemFilter: any;
  itemSelected: string;
  player: boolean;
  report: boolean;
  tOut_report: any = false;

  styleSheet: HTMLStyleElement;
  dynEvents: {};
  colorr: {};

  content: any;
  codeMirrorOptions: any;

  options: any = {};

  valueSocket: any;

  keybInfo: any;

  lineas: any;
  wsConnecteds: any;
  wsItems: any;
  itemKeys: any;

  alias: any = [];
  variables: any = [];
  tags: any = [];
  lines: any = [];

  lp_comm_topics = [];
  lp_comm_id = false;
  lp_comm_ok = false;

  d111: any = {};

  lineConnecteds: any = {};
  wait: boolean;

  chartsTO: any = {};

  keyb: Keyboard;

  filter: FormGroup;

  private subscription: Subscription;
  topicname: any;
  msg: any;
  isConnected: boolean = false;
  @ViewChild('msglog', { static: true }) msglog: ElementRef;

  hdle: any;
  TO_resize: any;

  constructor(private langServ: LanguageService,
    private loginServ: LoginService,
    private elementRef: ElementRef,
    private systemServ: SystemService,
    private itemsServ: ModelizerItemsService,
    private router: Router,
    private tagServ: TagService,
    private socketService: lpSocket,
    private dataServ: DatatableService,
    private dataDesignService: DataDesignService,
    private authServ: AuthService,
  ) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    try {
      if (urlParams.get('view')) {
        this.view = urlParams.get('view');
      }

      if (urlParams.get('config')) {
        this.config = urlParams.get('config');
      }
    } catch (error) {
    }

    this.subscriptions = [];

    this.subscribe(socketService.outEven.subscribe(res => {
        try {
          this.lp_comm_ok = true;
          if (res.msg !== 'iot/internal/lp_comm' && this.player) {
            const topic = JSON.parse(res.msg);
            try {
              if (topic.view === this.view) {
                const separator = topic.synoptic.includes("?") ? "&" : "?";
                window.location.href = `${window.location.origin}/play/${topic.synoptic}${separator}view=${this.view}`;
              } else if (topic.config === this.config) {
                if (topic.synoptic === this.synoptic.namegeneral) {
                  window.location.reload();
                }
              } else if (this.config === 'new') {
                if (topic.synoptic === this.synoptic.namegeneral) {
                  window.location.href = `${window.location.href.split('=')[0]}=${
                  topic.config
                }`;
                }
              }
            } catch (error) {
              console.log(error);
            }

            if (topic.hasOwnProperty('scripting')) {
              for (const script of topic['scripting']) {
                if (script.type === "item") {
                  if (script._id === this.synoptic._id) { //es el synoptic
                    switch (script.event) {
                      case "runscript_scriptGlobal":
                      case "runscript_scriptLoad":
                        window.location.reload();
                        break;
                    }
                  } else {
                    if (this.itemKeys.hasOwnProperty('it' + script._id)) {
                      this.systemServ.getItemBy({ _id: script._id }).then((data) => {
                          this.itemKeys['it' + script._id] = data[0];
                        });
                    }
                    // const DD = await this.systemServ.getItemBy({ type: '5f994d5908425a1078a1b7b2', name: info.name });
                  }
                }
              }
            } else {
              const keysPayload = Object.keys(topic);
              const id = topic.id;

              let ok = true;
              if (topic.hasOwnProperty('timer')) {
                try {
                  if (this.synoptic.hasOwnProperty('blindIntervalgeneral')) {
                    if (parseInt(topic['timer']) > parseInt(this.synoptic.blindIntervalgeneral) * 1000) {
                      ok = false;
                    }
                  }
                } catch (error) {

              }

            }


              if (this.globals.hasOwnProperty('socket_inputs')) {
                if (this.globals.socket_inputs[id]) {
                  this.globals.socket_inputs[id].forEach(each => {
                    each.code_automation(this, each, topic);
                  });
                }
              }

              keysPayload.forEach(keyPayload => {
                if (keyPayload !== 'id' && keyPayload !== 'dd' && keyPayload !== 't') {
                  try {

                    let tpic = topic[keyPayload];

                    if (ok === false) {
                      tpic = '';
                    }
                    this.valueSocket[id + keyPayload] = tpic;
                    this.controlChange(id + keyPayload, tpic);

                  } catch (error) {
                    console.log(error);
                  }

                  if (this.player && !this.report) {
                    if (this.wsItems[id + "<>" + keyPayload] !== undefined) {

                      this.newData(id + "<>" + keyPayload, topic[keyPayload], parseInt(topic['t']));
                    }
                  }
                  try {
                    const tag = id + '.' + keyPayload;
                    if (this.globals.tags_func[tag]) {
                      const entr = Object.entries(this.globals.tags_func[tag]) as any;
                      for (const ent of entr) {
                        for (const itt of ent[1]) {
                          this.code_automation[ent[0]](itt, tag, topic[keyPayload]);
                        }

                        /*
                      if (this.globals.functionalities[ent[0] + ".js"]) {
                        const content = this.globals.functionalities[ent[0] + ".js"];

                      }
                      */
                      }
                    }
                  } catch (error) {
                    console.log(error);
                  }
                }
              });

              try {
                const evv = "lp_mqtt(topic);";
                eval(evv);
              } catch (error) {
            }
              // ff['value'] = Date.now() - tmt;
            }
          } else {
            if (res.what !== 'control') {

              if (this.lp_comm_id !== false) {
                if (this.lp_comm_id !== res.what) {
                  this.lp_comm_id = res.what;

                  this.socketService.emitEvent('subscribes',
                  {
                    topics: this.lp_comm_topics
                  });
                }
              }
              this.lp_comm_id = res.what;
            }
          }
        } catch (error) {
          console.log(error);
        }

    }));

    this.controlQuerys = [];
    this.wait = false;

    this.player = false;
    this.report = false;



    this.codeMirrorOptions = {
      mode: 'javascript',
      lineNumbers: true,
      extraKeys: {
        'Ctrl-Space': 'autocomplete',
        F11: (cm) => {
          if (!cm.getOption('fullScreen')) {
            cm.setOption('fullScreen', true);
            $('ngx-codemirror').addClass('CodeMirror-fullscreen');
            $('.CodeMirror').css('height', '100%');
          } else {
            cm.setOption('fullScreen', false);
            $('ngx-codemirror').removeClass('CodeMirror-fullscreen');
            $('.CodeMirror').css('height', '500px');
          }
        },
        F10: (cm) => {
          if (!cm.getOption('fullScreen')) {
            cm.setOption('fullScreen', true);
            $('ngx-codemirror').addClass('CodeMirror-fullscreen');
            $('.CodeMirror').css('height', '100%');
          } else {
            cm.setOption('fullScreen', false);
            $('ngx-codemirror').removeClass('CodeMirror-fullscreen');
            $('.CodeMirror').css('height', '500px');
          }
        },
        Esc: (cm) => {
          if (cm.getOption('fullScreen')) {
            cm.setOption('fullScreen', false);
            $('ngx-codemirror').removeClass('CodeMirror-fullscreen');
            $('.CodeMirror').css('height', '500px');
          }
        }
      }
    };
    this.content = 'document';

  }

  data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Chart',
        data: [65, 59, 80, 81, 56, 55, 45],
        fill: false,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(101, 103, 107, 0.2)',
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)',
        ],
        borderWidth: 1,
      },
    ],
  };

  showTable(data) {
    //setTimeout((data) => {

    const idTableD = data.itemId;
    const options = data.opts;

    console.log(idTableD);

    try {
      (<any>$('#' + idTableD)).dataTable().fnDestroy();
    } catch (error) {
    }

    var tt = (<any>$('#' + idTableD)).dataTable(options);
    tt.on('page.dt', function () {

    });

    if (data.hasOwnProperty('fnc')) {
      setTimeout(() => {

          data.fnc();
          /*
        const fw0 = document.getElementById(idTableD + '_filter');
        const fw1 = document.getElementById(idTableD + '_length');

        const h2 = document.createElement('h2');
        h2.id = idTableD + 'head';
        h2.innerHTML = 'text';
        h2.style.textAlign = 'center';
        h2.style.display = 'inherit';
        h2.style.position = 'absolute';
        h2.style.left = fw1.offsetWidth + 'px';
        h2.style.width = (fw0.parentElement.offsetWidth - fw0.offsetWidth - fw1.offsetWidth) + 'px';
        h2.style.top = '0px';
        fw0.parentElement.appendChild(h2);
        */
        }, 1, data);
    }
    //}, 1, data);

  }

  async saveUpdDDItem_2(parameters: any): Promise<any> {
    const itemUpdated = await this.systemServ.updItem(parameters);
    return itemUpdated;
  }

  async saveNewDDItem_2(parameters: any): Promise<any> {
    const itemNew = await this.systemServ.newItem(parameters);
    return itemNew;
  }

  async deleteDDItem_2(_id): Promise<any> {
    await this.systemServ.delItem(_id);
    return true;
  }

  changeStyle(item: any, styles: any, styleIni: string, styleField: string, styleTag: string): string {
    let ret: any = styles;

    let styleValue: string = '';
    if (this.itemSelected === item.id) {
      if (document.getElementById(styleIni + '_' + styleField) !== null) {
        styleValue = document.getElementById(styleIni + '_' + styleField)['value'];
      }
    } else {
      if (item.parameters.hasOwnProperty(styleIni + '_active')) {
        styleValue = item.parameters[styleIni + '_' + styleField];
      }
    }

    if (styleValue !== '') {
      switch (styleIni) {
        case 'style_vert_align':
          let fontsize: string = '';
          if (styles.hasOwnProperty('font-size')) {
            fontsize = ret['font-size'];
          }

          if (fontsize === '') {
            fontsize = '12';
          }
          if (styleValue === 'middle') {
            const pxels = parseInt(item.parameters['heightgeneral']);
            ret['line-height'] = pxels + 'px';
          }

          if (styleValue === 'bottom') {
            const pxels = (parseInt(item.parameters['heightgeneral']) * 2) - (parseInt(fontsize) * 1.5);
            ret['line-height'] = pxels + 'px';
          }

          break;
        default:
          if (styleIni === 'fontsize') {
            styleValue = styleValue.replace('px', '');
            styleValue = styleValue + 'px';
          }

          if (styleValue !== '') {
            ret[styleTag] = styleValue;
          }
          break;
      }

    }

    return ret;
  }

  async lp_db_array(infos) {
    let ret = [];
    for (const info of infos) {
      ret.push(await this.lp_db(info));
    }
    return ret;
  }

  async lp_db(info) {
    let ret: false;

    let rett = JSON.parse(JSON.stringify(info));

    if (info.hasOwnProperty('createDD')) {
      ret = await this.systemServ.lp_db({ createDD: info.createDD });
    }

    if (info.hasOwnProperty('createField')) {
      if (info.hasOwnProperty('DD')) {
        ret = await this.systemServ.lp_db({ createField: info.createField, DD: info.DD });
        for (const kk of Object.keys(rett)) {
          ret[kk] = rett[kk];
        }
      }
    }

    if (info.hasOwnProperty('listDD')) {
      ret = await this.systemServ.lp_db({ listDD: true });
      for (const kk of Object.keys(rett)) {
        ret[kk] = rett[kk];
      }
    }

    if (info.hasOwnProperty('select')) {
      if (info.hasOwnProperty('DD')) {
        ret = await this.systemServ.lp_db({ select: info.select, DD: info.DD });
        for (const kk of Object.keys(rett)) {
          ret[kk] = rett[kk];
        }
      }
    }

    if (info.hasOwnProperty('update')) {
      if (info.hasOwnProperty('DD') && info.hasOwnProperty('query')) {
        ret = await this.systemServ.lp_db({ update: info.update, DD: info.DD, query: info.query });
        for (const kk of Object.keys(rett)) {
          ret[kk] = rett[kk];
        }
      }

      if (info.hasOwnProperty('tagvalues') && info.hasOwnProperty('query')) {
        return this.tagServ.updateValues(info.query, info.update);
      }
    }

    if (info.hasOwnProperty('delete')) {
      if (info.hasOwnProperty('DD')) {
        ret = await this.systemServ.lp_db({ delete: info.delete, DD: info.DD });
        for (const kk of Object.keys(rett)) {
          ret[kk] = rett[kk];
        }
      }
    }

    if (info.hasOwnProperty('insert')) {
      if (info.hasOwnProperty('DD')) {
        ret = await this.systemServ.lp_db({ insert: info.insert, DD: info.DD });
        if (info.hasOwnProperty('params')) {
          ret['params'] = info.params;
          for (const kk of Object.keys(rett)) {
            ret[kk] = rett[kk];
          }
        }
      }
    }

    return ret;
  }

  lp_logout() {
    this.authServ.logOutSpecial();
  }

  async lp_login(info): Promise<any> {
    try {
      const data = await this.loginServ.login(info);

      this.authServ.setToken(data.accessToken);
      location.href = location.href.replace(info.urlLogin, info.urlGo);
    } catch (err) {
      alert(info.error);
      return false;
    }
  }

  async lp_queryAsync(info) {
    const data = await this.systemServ.query(info);
    return data;
  }

  async lp_set(info_) {

    if (info_.hasOwnProperty('load')) {
      const _load = info_.load;
      const _in = info_.in;

      if (this.globals.hasOwnProperty('loads') === false) {
        this.globals.loads = [];
      }

      let _inDiv = this.items['Synoptic'][0]['Free'].find(each => each.freetype === 'flexPanel' && each.parameters.namegeneral === _in);

      let iDiv;
      if (_inDiv) {
        //eliminamos contenido de _in

        iDiv = _inDiv.id;
        _inDiv = document.getElementById('it' + iDiv);
        _inDiv = _inDiv.getElementsByTagName("div")[0];

        _inDiv.innerHTML = '';
        for (const each of this.globals.loads.filter(eachLoad => eachLoad.inDiv === iDiv)) {
          try {
            if (each.tag.id) {
              this.globals.socket_inputs[each.tag.id] = this.globals.socket_inputs[each.tag.id].filter(eachItem => eachItem.id !== each.id);
            }
          } catch (error) {
        }
        }
        this.globals.loads = this.globals.loads.filter(eachLoad => eachLoad.inDiv !== iDiv);
      }

      let _idSyn = this.util.systemSynoptic.synoptics.find(item => item.name === _load);
      if (_idSyn && _inDiv) {
        _idSyn = _idSyn._id;
        const items = await this.systemServ.getItemsBy({ parent: _idSyn });

        this.minX = Infinity;
        this.minY = Infinity;
        this.maxW = 0;
        this.maxH = 0;
        for (const each of items) {
          this.minX = Math.min(this.minX, parseInt(each.parameters.xgeneral));
          this.minY = Math.min(this.minY, parseInt(each.parameters.ygeneral));

          this.maxW = Math.max(this.maxW, parseInt(each.parameters.xgeneral) + parseInt(each.parameters.widthgeneral));
          this.maxH = Math.max(this.maxH, parseInt(each.parameters.ygeneral) + parseInt(each.parameters.heightgeneral));

          const nameType = this.util.systemSynoptic.controls.find(eachType => eachType._id === each.type).name;
          each.id = iDiv + '_' + each._id;
          each.freetype = nameType;
          each.free = true;
          each.inDiv = iDiv;
          try {
            each.inMain = _inDiv;
            this.code_build[nameType](this, each);

            this.globals.loads.push(each);
          } catch (error) {
            console.error(error);
          }
        }

        if (items.length > 0) {
          const dMain = _inDiv.parentNode;

          const containerWidth = dMain.offsetWidth;
          const containerHeight = dMain.offsetHeight;
          const itemWidth = this.maxW - this.minX;
          const itemHeight = this.maxH - this.minY;

          const scale = Math.min(
            containerWidth / itemWidth,
            containerHeight / itemHeight
          );

          const translateX = (containerWidth - itemWidth * scale) / 2;
          const translateY = (containerHeight - itemHeight * scale) / 2;

          _inDiv.style.transform = `scale(${scale})`;
          _inDiv.style.transformOrigin = 'left center';
          _inDiv.style.position = 'absolute';
          _inDiv.style.left = `${translateX - this.minX * scale}px`;
          _inDiv.style.top = `${translateY - this.minY * scale}px`;
        }
      }
    }

    if (info_.hasOwnProperty("mqtt")) {
      this.socketService.emitEvent('mqtt',
        {
          "mqtt": info_.mqtt
      });
    }

    try {
      if (info_.hasOwnProperty('sleep')) {
        const sleep = parseInt(info_.sleep);
        delete info_.sleep;
        setTimeout((info_) => {
            this.lp_set(info_);
          }, sleep, info_);

      } else {
        if (info_?.type) {
          switch (info_.type) {
            case 'user':
              if (info_.action === 'add') {
                const ret_addUser = await this.systemServ.addUser(info_.info);
                return ret_addUser;
              }

              if (info_.action === 'update') {
                const ret_addUser = await this.systemServ.editUser(info_.info, info_.userId);
                return ret_addUser;
              }

              if (info_.action === 'delete') {
                const ret_addUser = await this.systemServ.deleteUser(info_.userId);
                return ret_addUser;
              }

              break;
            case 'DD':
              const msg = await this.systemServ.updateByInfo(info_);
              return msg;
            case 'item':
              let found: any = false;
              if (info_?.name) {
                found = this.items['Synoptic'][0][Object.keys(this.items['Synoptic'][0]).find(kt => this.items['Synoptic'][0][kt].find(t => t.parameters.namegeneral === info_.name))].find(t => t.parameters.namegeneral === info_.name);
              }

              if (found) {
                if (info_?.parameters) {
                  let tag: any = false;
                  if (found.free) {
                    tag = document.getElementById("it" + found.id);
                  }
                  for (const kk of Object.keys(info_.parameters)) {
                    // found.parameters[kk] = info_.parameters[kk];
                    if (found.free) {
                      tag.style[this.freeConv[kk]] = info_.parameters[kk];
                    } else {
                      found.parameters[kk] = info_.parameters[kk];
                    }
                  }
                }
              }
              break;
          }
        }

        if (info_.hasOwnProperty('items')) {
          for (const item of info_.items) {
            if (item.hasOwnProperty('item')) {
              const found = this.items['Synoptic'][0]['Field'].find(field => field.parameters.namegeneral === item.item);

              if (found) {
                if (item.hasOwnProperty('value')) {
                  document.getElementById('it' + found.id)['value'] = item.value;
                }

                if (item.hasOwnProperty('x')) {

                  found.x = 500;
                }
              }
            }
          }
        }

        if (info_.hasOwnProperty('_id')) {
          if (info_.hasOwnProperty('parameters')) {
            info_['type'] = 'item';
            const msg = await this.systemServ.updateByInfo(info_);
            return msg;
          }
        }

        if (info_.hasOwnProperty('item')) {
          let found = this.items['Synoptic'][0][Object.keys(this.items['Synoptic'][0]).find(kt => this.items['Synoptic'][0][kt].find(t => t.parameters.namegeneral === info_.item))].find(t => t.parameters.namegeneral === info_.item);

          if (found) {
            if (info_?.delete) {
              if (info_.delete === true) {
                this.items['Synoptic'][0][found.type] = this.items['Synoptic'][0][found.type].filter(itt => itt.parameters.namegeneral !== info_.item);
              }
            }

            if (info_?.duplicate) {
              const newIt = JSON.parse(JSON.stringify(found));
              newIt.parameters.namegeneral = info_.duplicate;
              newIt._id = info_.duplicate;
              newIt.id = info_.duplicate;
              this.itemKeys[`it${newIt.id}`] = newIt;
              this.items['Synoptic'][0][found.type].push(newIt);
              found = newIt; //this.items['Synoptic'][0][Object.keys(this.items['Synoptic'][0]).find(kt => this.items['Synoptic'][0][kt].find(t => t.parameters.namegeneral === info_.duplicate))].find(t => t.parameters.namegeneral === info_.duplicate);
            }

            if (info_.hasOwnProperty('value')) {
              document.getElementById('it' + found.id)['value'] = info_.value;
            }

            if (info_.hasOwnProperty('x')) {
              found.parameters.xgeneral = info_.x;
            }

            if (info_.hasOwnProperty('text')) {
              found.parameters.textgeneral = info_.text;
              if (found.free) {
                document.getElementById('it' + found.id)['innerText'] = info_.text;
              }
            }

            if (info_?.hidden) {
              if (info_.hidden) {
                document.getElementById('it' + found.id)['style']['display'] = 'none';
              } else {
                document.getElementById('it' + found.id)['style']['display'] = '';
              }
            }
          }
        }


        if (info_.hasOwnProperty('tag')) {
          try {
            const parts = info_.tag.split('.');
            if (info_.hasOwnProperty("hand")) {
              this.socketService.emitEvent('writes', [
                {
                  "thing": parts[0], "tag": parts[1], "value": info_.value, "hand": info_.hand
                }]);
            } else {
              const tag = this.globals.tags.find(each => each.thing.name === parts[0] && each.name === parts[1]);

              if (tag.type === '63e5cbee9db9e510d4b56e18') //type mqtt
{
                const topic = JSON.parse(tag.parameters.comm_register);
                const msg = topic.messages.find(each => each.value == info_.value);
                if (msg) {
                  this.socketService.emitEvent('mqtt',
                    {
                      "mqtt": { "topic": topic.topic, "message": msg.msg }
                  });
                }
              } else {
                this.socketService.emitEvent('writes', [
                  {
                    "thing": parts[0], "tag": parts[1], "value": info_.value
                  }]);
              }
            }

          } catch (error) {
          }
        }

        if (info_.hasOwnProperty('log')) {
          this.socketService.emitEvent('log',
            {
            log: info_.log
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  controlChange(id_key, value) //hago control change sólo en los fields
  {
    try {
      const tt = "readvar(id_key, value);";
      eval(tt);
    } catch (error) {
    }

  }

  getStyle(item): any {
    let info: any = { styles: {}, attributes: {} };
    info.styles = this.changeStyle(item, info.styles, 'fontsize', 'size', 'font-size');
    info.styles = this.changeStyle(item, info.styles, 'stylecolor', 'color', 'color');
    info.styles = this.changeStyle(item, info.styles, 'stylebackcolor', 'color', 'background-color');
    info.styles = this.changeStyle(item, info.styles, 'style_horz_align', 'align', 'text-align');
    info.styles = this.changeStyle(item, info.styles, 'style_vert_align', 'align', 'font-size');
    info.styles = this.changeStyle(item, info.styles, 'style_border_color', 'color', 'border-color');
    info.styles = this.changeStyle(item, info.styles, 'style_border_width', 'width', 'border-width');
    info.styles = this.changeStyle(item, info.styles, 'style_border_style', 'style', 'border-style');
    info.styles = this.changeStyle(item, info.styles, 'style_border_left_color', 'color', 'border-left-color');
    info.styles = this.changeStyle(item, info.styles, 'style_border_left_width', 'width', 'border-left-width');
    info.styles = this.changeStyle(item, info.styles, 'style_border_left_style', 'style', 'border-left-style');
    info.styles = this.changeStyle(item, info.styles, 'style_border_right_color', 'color', 'border-right-color');
    info.styles = this.changeStyle(item, info.styles, 'style_border_right_width', 'width', 'border-right-width');
    info.styles = this.changeStyle(item, info.styles, 'style_border_right_style', 'style', 'border-right-style');
    info.styles = this.changeStyle(item, info.styles, 'style_border_top_color', 'color', 'border-top-color');
    info.styles = this.changeStyle(item, info.styles, 'style_border_top_width', 'width', 'border-top-width');
    info.styles = this.changeStyle(item, info.styles, 'style_border_top_style', 'style', 'border-top-style');
    info.styles = this.changeStyle(item, info.styles, 'style_border_bottom_color', 'color', 'border-bottom-color');
    info.styles = this.changeStyle(item, info.styles, 'style_border_bottom_width', 'width', 'border-bottom-width');
    info.styles = this.changeStyle(item, info.styles, 'style_border_bottom_style', 'style', 'border-bottom-style');

    try {
      if (item.parameters.disabled_active === '1') {
        const dd = document.getElementById('it' + item.id);
        if (item.parameters.disabled_disabled) {
          info.attributes['disabled'] = true;
          dd.setAttribute("disabled", "true");
        } else {
          info.attributes['disabled'] = false;
          dd.removeAttribute("disabled");
        }
      }
    } catch (error) {
    }

    // return {};
    return info;

  }

  select_Change(event, tag_) {
    try {
      const ev = "lp_event(tag_);";
      eval(ev);
    } catch (error) {
    }
  }

  check_Change(event, tag_) {
    try {
      const ev = "lp_event(tag_);";
      eval(ev);
    } catch (error) {
    }
  }

  field_Change(event, tag_) {
    //hemos cambiado el valor
    //probamos de grabarlo

    try {
      const tag = this.globals['tags'].find(tag => tag._id === tag_.parameters.variable_variableconnect);
      this.socketService.emitEvent('writes', [
        {
          "thing": tag.thing.name, "tag": tag.name, "address": tag.parameters.plc_address, "value": event.target.value
        }]);
    } catch (error) { }

    try {
      const ev = "lp_event(tag_);";
      eval(ev);
    } catch (error) {
    }
  }

  /*
  field_Focus(tag) {
    // tag.select();
    tag['oldThingconnect'] = tag.thingconnect;
    const vall = document.getElementById('it' + tag.id)['value'];

    tag.thingconnect = '';
    setTimeout((id, vall) => {º1  1y 7ew



  }
  */

  field_Focus(event) {
    const idIt = event.target.id;
    const tag = this.itemKeys[idIt];

    tag['oldThingconnect'] = tag.thingconnect;
    const vall = document.getElementById('it' + tag.id)['value'];

    tag.thingconnect = '';
    setTimeout((id, vall) => {
        document.getElementById(id)['value'] = vall;
      }, 200, 'it' + tag.id, vall);


  }

  field_Blur(tag) {
    tag.thingconnect = tag['oldThingconnect'];
    // tag.thingconnect = '';
  }

  field_onChange(event) {

  }

  field_getValue(tag) {

    this.veces = this.veces + 1;
    let valueToRet = '...';
    valueToRet = this.valueSocket[tag.thingconnect + tag.variableconnect] === undefined ||
      this.valueSocket[tag.thingconnect + tag.variableconnect] === '...'
        ? '...'
        : tag.parameters?.variable_numdecimals
        ? (this.valueSocket[tag.thingconnect + tag.variableconnect] * 1).toFixed(
          tag.parameters.variable_numdecimals
        )
        : this.valueSocket[tag.thingconnect + tag.variableconnect];

    try {
      document.getElementById('it62188160fc14a8029a132f4b')['value'] = this.veces;
    } catch (error) {

    }



    return valueToRet;
  }

  mouseOut(event) {

  }

  mouseUp(event) {

    // mouseUp control
    const idIt = event.target.id;
    if (this.itemKeys.hasOwnProperty(idIt)) {
      try {
        eval(this.itemKeys[idIt].parameters['runscript_scriptUp']);
      } catch (error) {
      }
    }
  }

  mouseDown(event) {
    // mouseDown control

    const idIt = event.target.id;
    if (this.itemKeys.hasOwnProperty(idIt)) {
      try {
        let code = this.itemKeys[idIt].parameters['runscript_scriptDown'];
        const tags = code.split("//end imports");
        if (tags.length === 2) {
          code = tags[1];
        }
        eval(code);
      } catch (error) {
      }
    }
  }

  mouseClick(event) {

    // this.exportToPdf(event);
    const idIt = event.target.id;

    if (this.itemKeys.hasOwnProperty(idIt)) {

      this.itemClick = this.itemKeys[idIt];

      if (this.itemKeys[idIt].parameters.hasOwnProperty('printReport_active')) {
        if (this.itemKeys[idIt].parameters['printReport_event_type'] === 'click') {


          var url = window.location.href;
          var tag1 = url.split("synopticplayer");
          var tag2 = url.split("synopticPlayer");

          var url2 = '';
          if (tag1.length > 1) {
            url2 = tag1[0] + 'report/' + this.itemKeys[idIt].parameters['printReport_report_id'];
          }

          if (tag2.length > 1) {
            url2 = tag2[0] + 'report/' + this.itemKeys[idIt].parameters['printReport_report_id'];
          }
          document.getElementById('ifReport')['src'] = url2;
        }
      }

      if (this.itemKeys[idIt].parameters.hasOwnProperty('exportPDF_active')) {
        if (this.itemKeys[idIt].parameters['exportPDF_event_type'] === 'click') {
          this.exportToPdf(event);
        }
      }

      try {
        let code = this.itemKeys[idIt].parameters['runscript_scriptClick'];
        const tags = code.split("//end imports");
        if (tags.length === 2) {
          code = tags[1];
        }
        eval(code);
      } catch (error) {
      }

      if (this.itemKeys[idIt].parameters.hasOwnProperty('gosynoptic_active')) {
        if (this.itemKeys[idIt].parameters['gosynoptic_event_type'] === 'click') {
          const navigationExtras: NavigationExtras = {
            state: {
              mode: 'play',
              synopticId: this.itemKeys[idIt].parameters['gosynoptic_synoptic_id']
            }
          };

          const synname = this.util.systemSynoptic.synoptics.find(item => item._id === this.itemKeys[idIt].parameters['gosynoptic_synoptic_id']).name;
          // this.router.navigate(['synopticplayer/' + synname]);
          if (window.location.href.indexOf('synopticPlayer') === -1) {
            this.router.navigate(['synopticPlayer/' + synname]);
          } else {
            this.router.navigate(['synopticplayer/' + synname]);
          }
        }
      }

      if (this.itemKeys[idIt].parameters.hasOwnProperty('setvariable_active')) {
        if (this.itemKeys[idIt].parameters['setvariable_eventbutton'] === 'click') {
          try {
            const tag = this.globals['tags'].find(tag => tag._id === this.itemKeys[idIt].parameters['setvariable_variableconnect']);
            this.socketService.emitEvent('writes', [
              {
                "thing": tag.thing.name, "tag": tag.name, "value": this.itemKeys[idIt].parameters['setvariable_setvalue']
              }]);
          } catch (error) {
          }

        }
      }
    }
  }

  exportToPdf(evv) {

    const data = document.getElementById('main');
    const doc = new jsPDF('p', 'mm');
    const options = {
      background: 'white',
      scale: 3,
      allowTaint: true,
      useCORS: true
    };

    if (evv) {
      $(evv.target).html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> EXPORT');
    }
    $('#drag-container').css('border', '0');
    html2canvas(data, options).then((canvas) => {

        const contentDataURL = canvas.toDataURL('image/png');

        const margin = 2;
        const imgWidth = 210 - 2 * margin;
        const pageHeight = 295;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;

        let position = 0;

        doc.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight, undefined, 'FAST');

        heightLeft -= pageHeight;

        while (heightLeft >= 0) {

          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight, undefined, 'FAST');
          heightLeft -= pageHeight;
        }
        return doc;
      }).then((docResult) => {
        docResult.save('try.pdf');
        if (evv) {
          $(evv.target).html('<i class="fa fa-file"></i> try.pdf');
        }
      });
    $('#drag-container').css('border', 'dotted #ccc 2px');
  }

  butclick(event) {
    const item = `
    const elemT = this.lp_gettext(0);
    this.lp_change(0, elemT + ' ese' );
    this.lp_changeX(0,130);
    this.lp_changeY(0,130);
    `;

    const res = eval(item);

    // this.items['Button'][0]['parameters']['textgeneral'] = res;

  }

  getHtmlTag(tag) {
    const tagToEval = "html = `" + this.htmlTags[tag] + "`;";
    return tagToEval;
  }

  getTag(info) {
    //info: { tag: "prop_layer", get: "innerHTML"  }
    const doc = document.createElement("lp");

    let evcode = "";
    if (info.hasOwnProperty("tag")) {
      evcode = "doc.innerHTML = `" + this.htmlTags[info.tag] + "`;";
    }

    if (info.hasOwnProperty("html")) {
      evcode = "doc.innerHTML = `" + this.htmlTags[info.html] + "`;";
    }
    eval(evcode);

    let dd: any;

    if (info.hasOwnProperty("tags")) {
      const tt = doc.children;

      dd = doc.getElementsByTagName("tr");
    }

    if (info.hasOwnProperty("get")) {
      if (info.get === "innerHTML") {
        dd = doc.innerHTML;
      }

      if (info.get === "children") {
        dd = doc.children;
      }

      if (info.get === "obj") {
        dd = doc;
      }
    }
    return dd;
  }

  lp_gettext(nitem): string {
    const sret: string = this.items['Button'][nitem]['parameters']['textgeneral'];

    return sret;
  }

  hideModal(modal) {
    (<any>$('#' + modal)).modal('hide');
    try {
      const cvasKeyboard = document.createElement("canvas");
      cvasKeyboard.style.display = "none";
    } catch (error) {

    }
  }

  lp_change(nitem, valor) {
    this.items['Button'][nitem]['parameters']['textgeneral'] = valor;
  }

  lp_changeX(nitem, valor) {
    this.items['Button'][nitem]['parameters']['xgeneral'] = valor;
  }

  lp_changeY(nitem, valor) {
    this.items['Button'][nitem]['parameters']['ygeneral'] = valor;
  }

  ngAfterViewInit() {

  }

  controlFrees() {
    try {
      for (const item of this.items["Synoptic"][0]["Free"]) {
        if (item.copy === "") {
          item.copy = JSON.stringify(item.parameters);
        } else {

          if (item.copy !== JSON.stringify(item.parameters)) {
            let itsNew = false;
            if (item.copy === 'new') {
              itsNew = true;
            }
            item.copy = JSON.stringify(item.parameters);
            if (this.code_build.hasOwnProperty(item.freetype) === false) {
              const build = this.globals['functionalities'][item.freetype + ".js"];
              const evv = "const app=this;" + build;
              // eval(evv);
            }
            if (itsNew) {
              this.code_build[item.freetype](this, item);
            } else {
              this.code_change[item.freetype](this, item);
            }
          }
        }
      }
    } catch (error) {
      // console.log(error);
    }
  }

  removeItem(id) {
    let element: HTMLElement = document.getElementById(id);
    while (true) {
      if (element.parentElement.id === 'main') {
        element.parentElement.removeChild(element);
        break;
      } else {
        element = element.parentElement;
      }
    }
  }

  async getThis(): Promise<any> {
  }

  finishResize(): void {

  }

  onWindowLoadOrResize(): void {

    try {
      clearTimeout(this.TO_resize);
    } catch (error) {
    }

    let __maxW = this.maxW * 1;
    let __maxH = this.maxH * 1;
    let __minX = this.minX * 1;
    let __minY = this.minY * 1;

    let mL = 0;
    let mT = 0;

    const divMain = document.getElementById('main');
    divMain.style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';

    divMain.style.width = window.innerWidth + 'px';
    divMain.style.height = window.innerHeight + 'px';

    divMain.style.transform = '';

    // divMain.style.width = '100%';
    // divMain.style.height = '100%';

    let zoom = 1;

    if (divMain.offsetWidth < __maxW || divMain.offsetHeight < __maxH) {

      //portrait

      const zoomW = (divMain.offsetWidth / __maxW) * 100;
      const zoomH = (divMain.offsetHeight / __maxH) * 100;

      if (zoomW < zoomH) {
        if (zoomW < 100) {
          zoom = (zoomW / 100);
          divMain.style.transform = "scale(" + zoomW / 100 + ")"; //(50 %)
          divMain.style.transformOrigin = "left top";
          // divMain.style.width = __maxW + 'px';
          // divMain.style.height = '100%';
          __maxW = divMain.offsetWidth;
          __maxH = __maxH / (zoomW / 100);

        }
      } else {
        if (zoomH < 100) {
          zoom = (zoomH / 100);
          divMain.style.transform = "scale(" + zoomH / 100 + ")"; //(50 %)
          divMain.style.transformOrigin = "left top";
          // divMain.style.height = __maxH + 'px';

          __maxH = divMain.offsetHeight;
          __maxW = __maxW / (zoomH / 100);

        }
      }

    }

    const zoomAuto = this.synoptic.zoomAuto;

    if ((divMain.offsetWidth > __maxW || divMain.offsetHeight > __maxH) && zoomAuto) {

      document.body.style.overflow = 'hidden';

      const zoomW = (divMain.offsetWidth / __maxW) * 100;
      const zoomH = (divMain.offsetHeight / __maxH) * 100;

      if (zoomW < zoomH) {
        if (zoomW > 100) {
          zoom = zoomW / 100;
          divMain.style.transform = "scale(" + zoomW / 100 + ")"; //(50 %)
          divMain.style.transformOrigin = "left top";
          // divMain.style.width = document.getElementById("tblDSDiv_63ea4b7934f6c0b1d6d4cf7f").style.height = "1000px"; + 'px';
          // divMain.style.height = '100%';
          // divMain.style.width = this.maxW + 'px';
          // this.maxW = divMain.offsetWidth;

          __maxW = divMain.offsetWidth;
          __maxH = __maxH * zoom;

        }
      } else {
        if (zoomH > 100) {
          zoom = zoomH / 100;
          divMain.style.transform = "scale(" + zoomH / 100 + ")"; //(50 %)
          divMain.style.transformOrigin = "left top";
          // divMain.style.height = this.maxH + 'px';

          __maxH = divMain.offsetHeight;
          __maxW = __maxW * zoom;

        }
      }
      mL = (((divMain.offsetWidth) - __maxW) - (__minX * zoom)) / 2;
      mT = (((divMain.offsetHeight) - __maxH) - (__minY * zoom)) / 2;

    }

    divMain.style.marginLeft = mL + 'px';
    divMain.style.marginTop = mT + 'px';

  }

  async ngOnInit(): Promise<any> {

    this.globals['functionalities'] = {};
    lp_items = this.lp_items;
    if (window.location.href.indexOf('?rep=0') !== -1) {
      this.control_rep = true;
    }

    this.lineConnecteds = { 'none': false };
    await this.render();

    this.styleSheet = document.createElement("style");
    this.styleSheet.appendChild(document.createTextNode(""));
    document.head.appendChild(this.styleSheet);

    this.util = new utils(this.systemServ);

    var head = document.getElementsByTagName('head')[0];
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = '/assets/css/__global.css';
    link.media = 'all';
    head.appendChild(link);
  }

  async ngOnDestroy(): Promise<any> {

    this.htmlTags = {};
    this.items = {};
    this.wsItems = {};
    this.alias = [];
    this.variables = [];
    this.lines = [];

    try {
      this.elementRef.nativeElement.remove();
    } catch (error) {

    }

    this.unsuscribe();
    if (this.player) {
      window.location.reload();
    }
  }

  subscribe(subs: any) {
    this.subscriptions.push(subs);
  }

  unsuscribe() {
    this.subscriptions.forEach(subs => {
      try {
        subs.unsubscribe();
      } catch (error) {
        console.log('Error', error);
      }
    });

  }

  async render() {
    this.valueSocket = {};
    this.wsConnecteds = {};
    this.items = {};
    this.dynEvents = {};

    setInterval(function () {
      if (!this.lp_comm_ok) {
        try {

        } catch (error) {

        }
      }
      this.lp_comm_ok = false;
    }, 7000);

    // this.control_intervalChanges = setInterval(() => { this.controlFrees(); }, 100);

    this.subscribe(this.systemServ.changeItemSelected$.subscribe(async data => {
        this.control_intervalChanges = setInterval(() => { this.controlFrees(); }, 100);
        this.itemSelected = data;
      }));

    /**
     * lp003
     * subscribe para cambio de info en un item
     */
    this.subscribe(this.systemServ.changeItemChanged$.subscribe(async items => {

        clearInterval(this.control_intervalChanges);

        let newFree = false;
        for (const item of items) {

        item.item['credentials'] = { user: this.systemServ['user'], role: this.systemServ['role'] };

          newFree = false;
          if (item.item.free) //es un free, miro a ver si ya no lo es
{
            if (item.item.parameters.stylegeneral === 'none') {
              const ff = item.item.freetype;
              item.item.free = false;

              this.removeItem('it' + item.item._id);

              this.items['Synoptic'][0][ff].push(item.item);
              item.item.freetype = '';
              item.item.copy = '';
              this.items['Synoptic'][0]['Free'] = this.items['Synoptic'][0]['Free'].filter(it => it._id !== item.item._id);
            } else {
              newFree = false;
            }
          } else { //no es un free, miro a ver si lo va a ser
            if (item.item.parameters.stylegeneral !== 'none' && item.item.parameters.stylegeneral !== undefined) {

              item.item.free = true;

              if (item.type === 'updateitem') {
                const keys = Object.keys(this.items['Synoptic'][0]);
                for (const key of keys) {
                  if (this.items['Synoptic'][0][key].find(it => it._id === item.item._id)) {
                    item.item.freetype = key;
                    this.items['Synoptic'][0][key] = this.items['Synoptic'][0][key].filter(it => it._id !== item.item._id);
                    item.item.copy = '';
                    this.items['Synoptic'][0]['Free'].push(item.item);
                    break;
                  }
                }
              } else {
                item.item.copy = '';
                this.items['Synoptic'][0]['Free'].push(item.item);
              }
              newFree = true;
            }
          }
          if (item.item.free) {
            try {
              if (this.code_build.hasOwnProperty(item.item.freetype) === false) {
                const build = this.globals['functionalities'][item.item.freetype + ".js"];
                const evv = "const app=this;" + build;
                // eval(evv);
              }

              if (newFree) {
                this.code_build[item.item.freetype](this, item.item);
              } else {
                this.code_change[item.item.freetype](this, item.item);
              }
            } catch (error) {
              console.log(error);
            }
          }
        }

        // this.control_intervalChanges = setInterval(() => { this.controlFrees(); }, 100);

    }));

    /**
     * lp002
     * subscribe para carga del synoptico desde el editor o el player
     * carga de sinóptico
     */
    this.subscribe(this.systemServ.changeEmittedSyn$.subscribe(async data => {

        if (data.reset) {

          try {
            for (const item of this.items["Synoptic"][0]["Free"]) {
              let tag: any = document.getElementById("it" + item.id);
              while (tag.parentNode.id !== 'main') {
                tag = tag.parentNode;
              }
              tag.parentNode.removeChild(tag);
            }
          } catch (error) {
        }

          this.items = {};

          // this.items['Synoptic'][0] = {};
          // document.getElementById('main').innerHTML = '';
          return;
        }

        const scriptsElements = data.scripting ?? { htmlEntities: [] };
        this.htmlTags = {};
        for (const html of scriptsElements.htmlEntities) {
          this.htmlTags[html.name] = html.content.replaceAll("\n", "");
        }

        this.globals['things'] = data.things;
        this.globals['protocols'] = data.protocols;
        this.globals['tags'] = data.tags;

        this.globals['functionalities'] = {};
        this.globals['tags_func'] = {};

        this.report = data.report;
        this.player = data.player;

        this.synoptic = data?.synoptic;

        this.synopticName = data.synoptic?.namegeneral;

        //hay frees cargados del sinóptico anterior, los eliminamos
        try {
          for (const item of this.items["Synoptic"][0]["Free"]) {
            let tag: any = document.getElementById("it" + item.id);
            while (tag.parentNode.id !== 'main') {
              tag = tag.parentNode;
            }
            tag.parentNode.removeChild(tag);
          }
        } catch (error) {
      }

        this.items = {};
        const frees = [];

        data.items['Synoptic'].forEach(synitem => {

          const glob = this.globals['functionalities']['__global.js'];

          for (const [type, _items] of Object.entries(synitem)) {

            const items: any = _items;
            for (const item of items) {

            if (type === "Field") {
                lp_items[item.parameters.namegeneral] = { item: item };
                // const code = "const app=this;" + this.globals['functionalities'][type + ".js"] + ";setItem(this,'" + item.parameters.namegeneral + "');";
                // eval(code);
                // lp_items[item.parameters.namegeneral] = { id: "it" + item.id, get value() { return document.getElementById(this.id)['value'] }, set value(vall) { this._value_ = vall; document.getElementById(this.id)['value'] = vall } };
              }

              try {
                let sFree = false;

                if (item.parameters.stylegeneral !== undefined && item.parameters.stylegeneral !== 'none') {
                  sFree = true;
                }

                // const evv = glob + ";sFree = setFree(item);";
                // eval(evv);

                if (sFree) {
                  item['freetype'] = type;
                  item['free'] = true;
                  item.copy = "";
                  frees.push(JSON.parse(JSON.stringify(item)));
                  data.items['Synoptic'][0][type] = data.items['Synoptic'][0][type].filter(itt => itt.id !== item.id);
                }
              } catch (error) {
            }
            }
          }
        });

        this.items = data.items;

        this.items["Synoptic"][0]["Free"] = frees;

        if (this.player) {
          this.util.systemSynoptic = data.systemSynoptic;
        }
        try {
          this.keybInfo = data.keybInfo;
        } catch (error) {

      }

        if (this.report) {
          this.tOut_report =
          setTimeout(() => {
            this.exportToPdf(false);
          }, 2000);
        }

        if (this.player) {
          //this.keyb = new Keyboard([null], {});
          // this.keyb.keyboardOn(350, 400, 1850, false);

          //setTimeout(() => {
          // this.keyb.keyboardOn(350, 400, 1850, false);
          //this.keyb.events();
          //}, 500);
        }

        this.maxW = 0;
        this.maxH = 0;

        this.minX = 10000000000;
        this.minY = 10000000000;

        this.items['Synoptic'].forEach(synitem => {

          //load keyboards
          this.globals['keyboards'] = {};
          if (synitem['Keyboard'] !== undefined) {
            for (const keyb of synitem['Keyboard']) {
              const kitem = { ...keyb };
              kitem['isLoaded'] = false;
              kitem['obj'] = false;
              this.globals['keyboards'][keyb.id] = kitem;
            }
          }


          this.globals['scr_loads'] = {};

          for (const [type, _items] of Object.entries(synitem)) {
            const items: any = _items;

            items.forEach(item => {

              if (this.player) {
                item['credentials'] = { user: this.util.systemSynoptic['user'], role: this.util.systemSynoptic['role'] };
              }

              if (type === 'Free') {
                if (this.globals['scr_loads'].hasOwnProperty(item.freetype) === false) {
                  this.globals['scr_loads'][item.freetype] = [];
                }
                this.globals['scr_loads'][item.freetype].push(item);
              }


              try {
                if (this.minX > parseInt(item.parameters.xgeneral)) {
                  this.minX = parseInt(item.parameters.xgeneral);
                }
              } catch (error) {
            }

              try {
                if (this.minY > parseInt(item.parameters.ygeneral)) {
                  this.minY = parseInt(item.parameters.ygeneral);
                }
              } catch (error) {
            }


              try {
                let _maxW = parseInt(item.parameters.xgeneral) + parseInt(item.parameters.widthgeneral);
                if (item.padding !== undefined) {
                  _maxW = _maxW + item.padding;
                }
                if (_maxW > this.maxW) {
                  this.maxW = _maxW;
                }
                let _maxH = parseInt(item.parameters.ygeneral) + parseInt(item.parameters.heightgeneral);
                if (item.padding !== undefined) {
                  _maxH = _maxH + item.padding;
                }

                if (_maxH > this.maxH) {
                  this.maxH = _maxH;
                }
              } catch (error) {
                console.log(error);
              }
            });


            switch (type) {
              case 'Free':
              case 'Field':
                items.forEach(item => {
                  try {
                    if (this.player) {
                      try {
                        if (item.parameters.keyboardgeneral !== 'none') {
                          if (this.globals['keyboards'].hasOwnProperty(item.parameters.keyboardgeneral)) {
                            //el teclado existe

                            if (this.globals.keyboards[item.parameters.keyboardgeneral].isLoaded === false) {
                              const keyb = this.globals.keyboards[item.parameters.keyboardgeneral];
                              //teclado no está cargado
                              this.globals.keyboards[item.parameters.keyboardgeneral].obj = new Keyboard([null],
                              {
                                kbId: keyb.id, kbType: this.globals.keyboards[item.parameters.keyboardgeneral].parameters.kbtypegeneral,
                                capsLock: true,
                                w: parseInt(keyb.parameters.widthgeneral),
                                h: parseInt(keyb.parameters.heightgeneral),
                                x: parseInt(keyb.parameters.xgeneral),
                                y: parseInt(keyb.parameters.ygeneral),
                                touchControl: keyb.parameters.touchControlgeneral,
                                modal: (keyb.parameters.popupgeneral === true),
                                modalDescription: item.parameters.descriptiongeneral,
                                keybInfo: this.keybInfo
                              });
                              this.globals.keyboards[item.parameters.keyboardgeneral].isLoaded = true;
                              setTimeout((idKeyb) => {
                                  this.globals.keyboards[idKeyb].obj.keyboardShow();
                                  this.globals.keyboards[idKeyb].obj.events();
                                }, 400, item.parameters.keyboardgeneral);
                            }
                            setTimeout((idKeyb, idField) => {
                                this.globals.keyboards[idKeyb].obj.fieldEevents(idField);
                              }, 500, item.parameters.keyboardgeneral, 'it' + item.id);
                          }
                        }


                      } catch (error) {
                        console.log(error);
                      }

                      setTimeout((idF) => {
                          // this.keyb.fieldEevents(idF);
                        }, 500, 'it' + item.id);
                    }
                  } catch (error) {
                }
                });
                break;
            }
          }
        });

        /*
      const scriptG = document.createElement("script");
      scriptG.setAttribute('type', 'text/javascript');
      scriptG.id = 'fnct';
      scriptG['app'] = this;
      document.head.appendChild(scriptG);
      let scrF = [];
      */

        // console.log(this.countForPlayer);

        const scriptG = document.createElement("script");  // create a script DOM node
        scriptG.setAttribute('type', 'text/javascript');
        scriptG.id = 'fnct';
        scriptG.onload = function () {
          const ths = document.getElementById('fnct')['app'];
          ths.countForPlayer = ths.globals['opts']['flist'].length;
          for (const fname of ths.globals['opts']['flist']) {
            var script = document.createElement("script");  // create a script DOM node
            script.setAttribute('type', 'text/javascript');
            script.id = 'fnct_' + fname;
            switch (fname) {
              case 'Synoptic.js':
                script.onload = function () {
                  const ths = document.getElementById('fnct')['app'];

                  ths.code_build['Synoptic'](ths, data.synoptic);
                  document.getElementById("fnct_" + fname).parentElement.removeChild(document.getElementById("fnct_" + fname));
                  if (ths.player) {
                    ths.countForPlayer = ths.countForPlayer - 1;
                    if (ths.countForPlayer === 0) {
                      // document.getElementById("fnct").parentElement.removeChild(document.getElementById("fnct"));
                      ths.goPlayer();
                    }
                  }
                }
                break;
              default:
                script.onload = function () {
                  const ths = document.getElementById('fnct')['app'];

                  const type = fname.replace('.js', '');
                  if (ths.globals['scr_loads'].hasOwnProperty(type)) {
                    for (const it of ths.globals['scr_loads'][type]) {
                      ths.code_build[type](ths, it);
                    }
                  }
                  document.getElementById("fnct_" + fname).parentElement.removeChild(document.getElementById("fnct_" + fname));
                  if (ths.player) {
                    ths.countForPlayer = ths.countForPlayer - 1;
                    if (ths.countForPlayer === 0) {
                      // document.getElementById("fnct").parentElement.removeChild(document.getElementById("fnct"));
                      ths.goPlayer();
                    }
                  }
                }
                break;
            }
            script['app'] = this;
            script['src'] = '/assets/js/functionalities/' + fname;
            document.head.appendChild(script);
          }
        }
        scriptG['app'] = this;
        scriptG['src'] = '/assets/js/functionalities/__global.js';
        document.head.appendChild(scriptG);

        const divMain = document.getElementById('main');
        divMain.style.backgroundColor = this.synoptic?.colorgeneral;
        document.title = this.synoptic?.descriptiongeneral || this.synoptic?.namegeneral;
        if (this.player) {
          const divMain = document.getElementById('main');
          document.body.style.backgroundColor = this.synoptic?.colorgeneral;
          divMain.style.backgroundColor = this.synoptic?.colorgeneral;

          // maxW = maxW; // + 5;
          // maxH = maxH; // + 5;

          if (this.report) {
            const dmn = document.getElementById('main');
            dmn.style.overflow = 'auto';
            dmn.style.width = this.maxW + 'px';

            this.maxH = this.maxW / 0.7070;

            //maxH = maxH * 5;

            dmn.style.height = this.maxH + 'px';
            // dmn.style.padding = '50px';
            // dmn.style.border = "1px solid gray";

          } else {

            this.onWindowLoadOrResize();
          }
          // divMain.style.width = "100%";
          // divMain.style.height = "100%";
        }

        this.wsItems = {};
        this.itemKeys = {};



      this.items['Synoptic'].forEach(synitem => {
          Object.keys(synitem).forEach(itemType => {
            synitem[itemType].forEach(item => {
              this.itemKeys[`it${item.iditem}`] = item;
              // this.itemKeys[item.iditem] = item;
            });
          });
        });


    }));
  }

  goPlayer() {

    let wsConnect = false;

    this.items.Synoptic.forEach(synitem => {
      for (const [type, _items] of Object.entries(synitem)) {

        const items: any = [];
        const its: any = _items;

        its.forEach(async item => {

          //aplico estilos
          this.util.StyleRules(item);

          const connect: any = {};
          /**
           * lp001
           * cuando entran los mensajes de lp_comm (mqtt,control,iots)
           *
           * control de conexión
           * connect['alias'] = alias de variable
           * connect['tag'] = tag (variable de conexión)
           * connect['protocol'] = protocolo
           * connect['thingconnect'] = thing o externalId
           * connect['document'] = document
           */

          //thingconnect
          if (item.parameters.hasOwnProperty('variable_thingconnect')) {
            connect['thingconnect'] = item.parameters.variable_thingconnect;
          }

          //variables en avanzado (es un alias)
          if (item.parameters.hasOwnProperty('variable_variableconnect')) {
            connect['alias'] = item.parameters.variable_variableconnect;
          }

          connect['historic'] = false;
          //variables en avanzado (es un alias)
          if (item.parameters.hasOwnProperty('historicconnect')) {
            if (item.parameters.historicconnect === true) {
              connect['historic'] = true;
            }
          }

          //buscoAlias
          if (connect.hasOwnProperty('alias')) {
            const tag = this.globals['tags'].find(tag => tag._id === connect.alias.toString());
            if (tag) {
              if (parseInt(tag.parameters.comm_millisread) === -1) {
                const protocol = this.globals['protocols'].find(protocol => protocol._id === tag.protocol);
                const keyplc = `${protocol.parameters.general.logi7_plc_ip}_${protocol.parameters.general.logi7_plc_rack}_${protocol.parameters.general.logi7_plc_slot}`;
                const address = tag.parameters.plc_address;
                const idthing = tag.thing._id;
                const name = tag.name;
                const readvar = { readvar: { keyplc, address, idthing, name } };

                this.readsControl.push(readvar);
              }

              connect['tag'] = tag;
              connect['protocol'] = this.globals['protocols'].find(protocol => protocol._id.toString() === tag.protocol.toString());
              connect['thingconnect'] = connect['tag'].thing._id;
            }
          }

          //variableconnect (select de variables según thing)
          if (item.parameters.hasOwnProperty('variableconnect')) {
            const tag = this.globals['tags'].find(tag => tag._id === item.parameters.variableconnect.toString());
            connect['tag'] = tag;
            connect['protocol'] = this.globals['protocols'].find(protocol => protocol._id === tag.protocol.toString());
            connect['thingconnect'] = connect['tag'].thing._id;
          }

          //controlo protocolo para document
          if (connect.hasOwnProperty('protocol')) {
            if (connect['protocol'].parameters.general.document) {
              connect['document'] = connect['protocol'].parameters.general.document;
            }
          }

          //thingconnect (controlo externalId para thing)
          if (connect.hasOwnProperty('thingconnect')) {
            const thingsel = this.globals['things'].find(thing => thing._id === connect['thingconnect'].toString());
            if (thingsel.parameters.general.externalid) {
              connect['thingconnect'] = thingsel.parameters.general.externalid;
            }
          }

          if (connect.hasOwnProperty('thingconnect')) {
            item.thingconnect = connect.thingconnect;
            try {
              item.variableconnect = connect.tag.name;
            } catch (error) {
            }

            this.subscribeToTopic(connect);

            switch (type) {
              case 'Chart':
                item.type = type;

                if (!this.wsItems.hasOwnProperty(item.thingconnect + '<>' + item.variableconnect)) {
                  this.wsItems[item.thingconnect + '<>' + item.variableconnect] = [];
                }

                item['query'] = false;
                item['reads'] = [];
                this.wsItems[item.thingconnect + '<>' + item.variableconnect].push({ type: type, item: item, group: item.parameters?.group_millischart, interval: parseInt(item.parameters.intervalchart), seconds: parseInt(item.parameters.secondschart), current: 0 });

                const newD = Date.now();
                const dateFrom = new Date(newD - (parseInt(item.parameters.secondschart) * 1000));

                const start_date = this.formatDate(dateFrom)['date'];
                const end_date = this.formatDate(new Date(newD))['date'];
                const start_time = this.formatDate(dateFrom)['time'];
                const end_time = this.formatDate(new Date(newD))['time'];

                const timestampStart = Date.parse(start_date + ' ' + start_time);
                const timestampEnd = Date.parse(end_date + ' ' + end_time);

                if (connect['historic']) {
                  const paramsNew = {
                    id: item.id, item: item, parameters: {
                      xgeneral: item.parameters.xgeneral, ygeneral: item.parameters.ygeneral + 5, widthgeneral: item.parameters.widthgeneral
                      , start_date: start_date, start_time: start_time, end_date: end_date, end_time: end_time
                    }
                  };
                  // item.parameters.ygeneral = parseInt(item.parameters.ygeneral) + 40;
                  // item.parameters.heightgeneral = parseInt(item.parameters.heightgeneral) - 40;
                  item.parameters.paddingTop = "40px";
                  synitem['historicFilter'] = [];
                  synitem['historicFilter'].push(paramsNew);

                  const itemFilter = { item: item, id: item.id };

                  if (item.parameters.hasOwnProperty('histsecsbeforeconnect')) {

                    $(function () {
                      const dd = document.getElementById('it' + item.id);
                      dd.style.borderColor = "gray";
                      dd.style.borderStyle = "groove";
                      dd.style.borderWidth = "2px";
                      // dd.style.boxShadow = "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset";
                      // dd.style.boxShadow = "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px - 1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset";

                      // document.getElementById("btfiltrar").className = "btn badge-primary btn-lg";
                    });

                    setTimeout(() => {

                      this.doFilterNew(itemFilter);
                    }, 500);
                  }
                } else {
                  if (isNaN(timestampStart) === false && isNaN(timestampEnd) === false) {

                    let document = '';

                    await this.executeQueryV2(item, { parameters: { document: document, thing: item.thingconnect, start_date: start_date, start_time: start_time, end_date: end_date, end_time: end_time } });
                    item['query'] = true;
                    this.chartsTO[item.thingconnect + '<>' + item.variableconnect] = setTimeout((t) => {
                        this.newData(item.thingconnect + '<>' + item.variableconnect, 'interval', t);
                      }, parseInt(item.parameters.intervalchart), newD + parseInt(item.parameters.intervalchart));
                  }
                }
                break;
            }

          }

        });

        items.forEach(async item => {

          //control de thingconnect para externalid
          let thingconnect = item.parameters.thingconnect;

          let thingsel = this.globals['things'].find(thing => thing._id === thingconnect);
          try {
            if (thingsel.parameters.general.externalid) {
              thingconnect = thingsel.parameters.general.externalid;
            }
          } catch (error) {
          }

          //aplico estilos
          this.util.StyleRules(item);

          item.type = type;

          if (item.parameters.hasOwnProperty('historicconnect') && this.player) {
            if (item.parameters.historicconnect === true) {

              if (synitem.hasOwnProperty('historicFilter') === false) {
                synitem['historicFilter'] = [];
              }

              let histsecsbeforeconnect = 0;
              if (item.parameters.hasOwnProperty('histsecsbeforeconnect')) {
                histsecsbeforeconnect = parseInt(item.parameters.histsecsbeforeconnect);
              }

              const newD = Date.now();
              const dateFrom = new Date(newD - (histsecsbeforeconnect * 1000));

              const start_date = this.formatDate(dateFrom)['date'];
              const end_date = this.formatDate(new Date(newD))['date'];
              const start_time = this.formatDate(dateFrom)['time'];
              const end_time = this.formatDate(new Date(newD))['time'];

              const paramsNew = {
                id: item.id, item: item, parameters: {
                  xgeneral: item.parameters.xgeneral, ygeneral: item.parameters.ygeneral, widthgeneral: item.parameters.widthgeneral
                  , start_date: start_date, start_time: start_time, end_date: end_date, end_time: end_time
                }
              };
              // item.parameters.ygeneral = parseInt(item.parameters.ygeneral) + 40;
              // item.parameters.heightgeneral = parseInt(item.parameters.heightgeneral) - 40;
              item.parameters.paddingTop = "40px";
              synitem['historicFilter'].push(paramsNew);
            } else {

              const thingsel = this.globals['things'].find(thing => thing._id === item.parameters.thingconnect);

              if (thingsel !== undefined && this.player) {
                const newD = Date.now();
                const dateFrom = new Date(newD - (parseInt(item.parameters.secondschart) * 1000));

                const start_date = this.formatDate(dateFrom)['date'];
                const end_date = this.formatDate(new Date(newD))['date'];
                const start_time = this.formatDate(dateFrom)['time'];
                const end_time = this.formatDate(new Date(newD))['time'];

                const timestampStart = Date.parse(start_date + ' ' + start_time);
                const timestampEnd = Date.parse(end_date + ' ' + end_time);

                if (isNaN(timestampStart) === false && isNaN(timestampEnd) === false) {

                  let document = '';
                  try {
                    const tag = this.globals['tags'].find(tg => tg._id.toString() === item.parameters.variableconnect.toString());
                    const protocol = this.globals['protocols'].find(prot => prot._id.toString() === tag.protocol.toString());
                    if (protocol.parameters.general.document) {
                      document = protocol.parameters.general.document;
                    }
                  } catch (error) {
                  }

                  this.executeQueryV2(item, { parameters: { document: document, thing: thingconnect, start_date: start_date, start_time: start_time, end_date: end_date, end_time: end_time } });

                  // this.executeQuery(item, { parameters: { line_ip: ipconn, start_date: start_date, start_time: start_time, end_date: end_date, end_time: end_time, document: item.parameters.documentconnect, thing: item.parameters.thingconnect } });
                  // this.wait = true;
                }
              }
            }
          }

          if (item.parameters.hasOwnProperty('variable_variableconnect')) {
            const parAlias = item.parameters.variable_variableconnect;
            if (parAlias !== '') {

              //const alias = this.variables.find(variable => variable._id === parAlias);

              const tag = this.globals['tags'].find(tag => tag._id === parAlias);

              if (tag) {
                if (parseInt(tag.parameters.comm_millisread) === -1) {
                  const protocol = this.globals['protocols'].find(protocol => protocol._id === tag.protocol);
                  const keyplc = `${protocol.parameters.general.logi7_plc_ip}_${protocol.parameters.general.logi7_plc_rack}_${protocol.parameters.general.logi7_plc_slot}`;
                  const address = tag.parameters.plc_address;
                  const idthing = tag.thing._id;
                  const name = tag.name;
                  const readvar = { readvar: { keyplc, address, idthing, name } };

                  this.readsControl.push(readvar);
                }

                if (tag.thing.parameters.general.externalid) {
                  item.parameters.thingconnect = tag.thing.parameters.general.externalid;
                } else {
                  item.parameters.thingconnect = tag.thing._id;
                }

                item.parameters.documentconnect = tag.thing.parameters.general.document;
                item.parameters.variableconnect = tag.name;

                // const parWS: any = {
                //  line: linea.parameters.general.ip,
                //  thing: alias.thing.externalId,
                //  document: alias.connection.parameters.general.document
                // };

                // item.parameters.thingconnect = 'Garraf_1';
                // item.parameters.documentconnect = '0';
                if (!this.wsConnecteds.hasOwnProperty(item.parameters.thingconnect + '__' + item.parameters.documentconnect)) {
                  wsConnect = true;
                  this.wsConnecteds[item.parameters.thingconnect + '__' + item.parameters.documentconnect] = [];
                  await this.connectWebSocket({ parameters: { document: item.parameters.documentconnect, thing: item.parameters.thingconnect } });
                }



                item.thingconnect = item.parameters.thingconnect;
                item.variableconnect = item.parameters.variableconnect;
                if (!this.wsItems.hasOwnProperty(item.parameters.thingconnect + '<>' + item.parameters.variableconnect)) {
                  this.wsItems[item.parameters.thingconnect + '<>' + item.parameters.variableconnect] = [];
                }

                if (type === 'Chart') {
                  this.wsItems[item.parameters.thingconnect + '<>' + item.parameters.variableconnect].push({ type: type, item: item, group: item.parameters?.group_millischart, interval: parseInt(item.parameters.intervalchart), seconds: parseInt(item.parameters.secondschart), current: 0 });
                }

              }
            }
          }


          if (thingsel !== undefined && this.player) {



            let tagname = '';
            try {
              tagname = this.globals['tags'].find(tag => tag._id === item.parameters.variableconnect)['name'];
            } catch (error) {
            }

            if (!this.wsConnecteds.hasOwnProperty(item.parameters.thingconnect + '__' + item.parameters.documentconnect)) {

              var historic: boolean = false;
              if (item.parameters.hasOwnProperty('historicconnect')) {
                if (item.parameters.historicconnect === true) {
                  historic = true;

                  const itemFilter = { item: item, id: item.id };

                  if (item.parameters.hasOwnProperty('histsecsbeforeconnect')) {
                    setTimeout(() => {
                      // this.doFilterNew(itemFilter);
                    }, 500);
                  }
                }

              }

              if (historic === false) {
                wsConnect = true;
                this.wsConnecteds[item.parameters.thingconnect + '__' + item.parameters.documentconnect] = [];
                await this.connectWebSocket({ parameters: { thing: item.parameters.thingconnect, document: item.parameters.documentconnect } });
              }
            }

            item.thingconnect = item.parameters.thingconnect;
            item.variableconnect = item.parameters.variableconnect;
            if (!this.wsItems.hasOwnProperty(thingconnect + '<>' + tagname)) {
              this.wsItems[thingconnect + '<>' + tagname] = [];
            }

            if (type === 'Chart' && item.parameters.hasOwnProperty('start_date')) {

            } else {
              if (type === 'Chart') {
                let externalid = item.parameters.thingconnect;
                try {
                  const exter = this.globals['things'].find(thing => thing._id === externalid).parameters.general.externalid;
                  if (exter) {
                    externalid = exter;
                  }
                } catch (error) {
                }

                this.wsItems[externalid + '<>' + tagname].push({ type: type, item: item, interval: parseInt(item.parameters.intervalchart), group: item.parameters?.group_millischart, seconds: parseInt(item.parameters.secondschart), current: 0 });

                await this.connectWebSocket({ parameters: { document: item.parameters.documentconnect, thing: externalid } });

                const newD = Date.now();


                if (item.parameters?.historicconnect !== true) {

                  this.chartsTO[externalid + '<>' + tagname] = setTimeout((t) => {
                      this.newData(externalid + '<>' + tagname, 'interval', t);
                    }, parseInt(item.parameters.intervalchart), newD + parseInt(item.parameters.intervalchart));
                }
              }
            }
          }


        });
      }
    });

    try {
      const glob = document.createElement("script");
      glob.setAttribute('type', 'text/javascript');
      let inner = "var thisApp;"
      glob.innerHTML = inner + this.synoptic.runscript_scriptGlobal;
      document.getElementById('main').appendChild(glob);
    } catch (error) {
    }

    if (this.readsControl.length > 0) {
      try {
        setTimeout(() => {
          this.socketService.emitEvent('reads', this.readsControl);
        }, 20);
      } catch (error) {
        console.log(error);
      }

      this.readsControl_interval = 2000;
      if (this.synoptic?.readIntervalgeneral) {
        this.readsControl_interval = parseInt(this.synoptic?.readIntervalgeneral);
      }

      if (parseInt(this.synoptic?.readIntervalgeneral) !== 0) {
        try {
          setInterval(() => {
            this.socketService.emitEvent('reads', this.readsControl);
          }, this.readsControl_interval);
        } catch (error) {
        }
      }
    }

    this.syn[this.synopticName] = {};

    for (const key of Object.keys(this.items.Synoptic[0])) {
      for (const item of this.items.Synoptic[0][key]) {
        if (this.syn[this.synopticName].hasOwnProperty(item.parameters.namegeneral) === false) {
          this.syn[this.synopticName][item.parameters.namegeneral] = {};
        }

        this.syn[this.synopticName][item.parameters.namegeneral] = { id: item.id, get item() { return document.getElementById("it" + this.id) } };
      }
    }

    if (this.synoptic.runscript_scriptLoad) {
      try {
        setTimeout((scr) => {
            eval(scr);
          }, 20, "thisApp = this;" + this.synoptic.runscript_scriptLoad);
      } catch (error) {
        console.log('load', error);
      }
    }

    if (this.synoptic.runscript_scriptCycle) {
      try {
        setInterval((scr) => {
            eval(scr);
          }, parseInt(this.synoptic.runscript_interval), this.synoptic.runscript_scriptCycle);
      } catch (error) {
        console.log('cycle', error);
      }
    }
  }

  async query(query) {
    try {

      /*
      let tk = item.parameters.thing;
      const query = { tagkey: tk, datetime: { '$gte': item.parameters.start_date + ' ' + item.parameters.start_time, '$lte': item.parameters.end_date + ' ' + item.parameters.end_time } };

      if (item.parameters.document) {
        query['document'] = item.parameters.document;
      }
      */

      const result = await this.tagServ.getValues(query);
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Execute query to get historic
   * @param item report item
   */
  async executeQueryV2(_itemtag: any, item) {
    let nofound = true;
    const parQuery = JSON.stringify(item.parameters);

    const idTotal = _itemtag.parameters?.totalItemchart;
    let acumulate: number = 0;

    if (idTotal) {
      this.items.Synoptic[0]['Text'].find(it => it.id === idTotal).parameters.textgeneral = 0;
    }

    this.controlQuerys.forEach(query => {
      if (nofound) {
        if (query['parameters'] === parQuery) {
          nofound = false;
          query['itemtags'].push(_itemtag);
        }
      }
    });
    if (nofound) {
      const newQuery = { "parameters": parQuery, "itemtags": [_itemtag] };
      this.controlQuerys.push(newQuery);
    }
    if (nofound) {
      let result: any = [];
      let itemTags = [];

      try {
        let tk = item.parameters.thing;

        const query = { tagkey: tk, datetime: { '$gte': item.parameters.start_date + ' ' + item.parameters.start_time, '$lte': item.parameters.end_date + ' ' + item.parameters.end_time } };

        if (item.parameters.document) {
          query['document'] = item.parameters.document;
        }

        if (_itemtag.parameters.hasOwnProperty('groupconnect')) {
          if (_itemtag.parameters.groupconnect.trim().length > 0) {
            query['group'] = _itemtag.parameters.groupconnect.trim();
          }
        }

        result = await this.tagServ.getValues(query);

        try {
          const tt = "lp_getValues(result);";
          eval(tt);
        } catch (error) {
        }
      } catch (error) {
        console.log(error);
      }

      nofound = true;
      this.controlQuerys.forEach(query => {
        if (nofound) {
          if (query['parameters'] === parQuery) {
            nofound = false;
            itemTags = query['itemtags'];
          }
        }
      });

      itemTags.forEach(itemtag => {
        let tagKey = itemtag.parameters.variableconnect;
        try {
          const tagc = this.globals['tags'].find(tag => tag._id === tagKey);
          if (tagc) {
            tagKey = tagc.name;
          }
        } catch (error) {
        }

        const datachart = { ...itemtag.data };
        if (itemtag.type === 'Chart') {
          const dset = itemtag.data.datasets.length - 1;
          let dsets = [{ nds: dset, key: tagKey }];
          if (itemtag.parameters.hasOwnProperty('datasets')) {
            dsets = itemtag.parameters.datasets;
          }
          for (const ds of dsets) {
            itemtag.data.datasets[ds.nds].data = [];
            itemtag.data.datasets[ds.nds].pointRadius = 0;
          }
          let first = true;
          let vall;
          let x_;
          let y_;
          datachart.datasets[dset].data.push({ y: undefined, x: Date.parse(item.parameters.start_date + ' ' + item.parameters.start_time) });
          result.map(elem => {
            try {
              const rt = Date.parse(elem.datetime);
              if (first) {
                for (const ds of dsets) {
                  datachart.datasets[ds.nds].data.push({ x: rt, y: elem.values[ds.key] });
                }
                // datachart.datasets[dset].data.push({ x: rt, y: elem.values[tagKey] });
              } else {
                // compruebo si el valor que voy a introducir es igual que los dos anteriores
                // si es así, elimino el anterior
                if (datachart.datasets[dset].data.length > 1) {
                  const d1 = parseFloat(datachart.datasets[dset].data[datachart.datasets[dset].data.length - 1].y);
                  const d2 = parseFloat(datachart.datasets[dset].data[datachart.datasets[dset].data.length - 2].y);
                  if (d1 === d2 && d1 === parseFloat(elem.values[tagKey])) {
                    datachart.datasets[dset].data.pop();
                  }
                }
                datachart.datasets[dset].data.push({ x: rt, y: elem.values[tagKey] });

              }
              if (this.control_rep) {
                first = false;
              }
              x_ = rt; //elem.datetime;
              y_ = elem.values[tagKey];
              vall = y_;

              if (idTotal) {
                acumulate = acumulate + y_;
              }
            } catch (error) {
            }
          });
          if (!first) {
            datachart.datasets[dset].data.push({ x: x_, y: y_ });
          }
          for (const ds of dsets) {
            datachart.datasets[ds.nds].data.push({ y: undefined, x: Date.parse(item.parameters.end_date + ' ' + item.parameters.end_time) });
          }
          if (idTotal) {
            this.items.Synoptic[0]['Text'].find(it => it.id === idTotal).parameters.textgeneral = acumulate.toFixed();
            _itemtag['acumulate'] = acumulate;
          }

          if (result.length > 0) {
            const dini = datachart.datasets[dset].data[0]['x'];
            const dfin = datachart.datasets[dset].data[datachart.datasets[dset].data.length - 1]['x'];
            itemtag.options.scales.xAxes[0].time.displayFormats['second'] = 'HH:mm:ss';
            let factor: number = 12;
            if (dfin - dini > 3600000) {
              factor = 14;
              itemtag.options.scales.xAxes[0].time.displayFormats['second'] = 'HH:mm:ss';
            }
            if (dfin - dini > 86400000) {
              itemtag.options.scales.xAxes[0].time.displayFormats['second'] = 'DD-MM HH';
            }
            if (dfin - dini > 2592000000) {
              factor = 13;
              itemtag.options.scales.xAxes[0].time.displayFormats['second'] = 'DD-MM-YYYY';
            }
            const ddif = Math.floor((dfin - dini) / itemtag.parameters.widthgeneral / factor);
            itemtag.options.scales.xAxes[0].time.stepSize = ddif;
          }
          itemtag.data = datachart;
        }
      });
    }
  }

  formatDateHora(sdate) {
    let ret = '';
    let date = new Date(sdate)
    let day = `${(date.getDate())}`.padStart(2, '0');
    let month = `${(date.getMonth() + 1)}`.padStart(2, '0');
    let year = date.getFullYear();
    let h = `${(date.getHours())}`.padStart(2, '0');
    let m = `${(date.getMinutes())}`.padStart(2, '0');
    let s = `${(date.getSeconds())}`.padStart(2, '0');

    ret = `${year}-${month}-${day} ${h}:${m}:${s}`;


    return ret;
  }

  showModal() {
    // hide modal
    (<any>$('#modalForm')).modal('show');
    // Insert html code to toast body
    $('.toast-body').html(`<p style="font-size:18px;">DSItemForm</p>`);
    // Show toast
    (<any>$('.toast')).toast('show');
  }

  hide_Modal() {
    // hide modal
    (<any>$('#modalForm')).modal('hide');
  }

  change_slider(event, tag) {
    tag['slider_value'] = event;
    try {
      const ev = "lp_event(tag);";
      eval(ev);
    } catch (error) {
    }
  }

  newData(wsId: string, value, t) {
    let valueConv = value;
    if (Object.keys(this.wsItems).length > 0) {
      this.wsItems[wsId].forEach(wsItem => {
        if (wsItem.type === 'Chart') {
          try {
            clearTimeout(this.chartsTO[wsId]);
          } catch (error) {
            console.log(error);
          }
          if (wsItem.item.parameters?.historicconnect !== true) {
            this.chartsTO[wsId] = setTimeout((t) => {
                this.newData(wsId, 'interval', t);
              }, wsItem.interval, t + wsItem.interval);
          }
        }

        if (wsItem.type === 'Chart' && wsItem.item.parameters?.historicconnect !== true) {

          const group = wsItem.group;
          let go = true;
          if (group !== undefined) {
            //estamos agrupando
            if (wsItem.hasOwnProperty('groupcontrol') === false) {

              wsItem['groupcontrol'] = { now: Date.now(), value: 0, cnt: 0 };
            }

            if (Date.now() - wsItem['groupcontrol'].now >= parseFloat(group)) {
              try {
                valueConv = parseFloat(wsItem['groupcontrol'].value) / parseFloat(wsItem['groupcontrol'].cnt);
              } catch (error) {

              }
              wsItem['groupcontrol'].value = 0;
              wsItem['groupcontrol'].cnt = 0;
              wsItem['groupcontrol'].now = Date.now();
            } else {
              go = false;
            }
            wsItem['groupcontrol'].value = wsItem['groupcontrol'].value + (parseFloat(value));
            wsItem['groupcontrol'].cnt = wsItem['groupcontrol'].cnt + 1;
          }

          if (go) {
            const datachart = { ...wsItem.item.data };

            if (this.chartLimits[wsItem.item.id]) {
              while (datachart.datasets.length > 1) {
                datachart.datasets.shift();
              }
            }

            const dta: any = [{ x: Date.now(), y: 15 }, { x: Date.now(), y: 15 }];
            const dta2: any = [{ x: Date.now(), y: 17 }, { x: Date.now(), y: 17 }];

            // if (datachart.datasets.length === 2) {
            //  datachart.datasets.shift();
            //}

            // if (datachart.datasets.length === 3) {
            //  datachart.datasets.shift();
            //  datachart.datasets.shift();
            // }

            const newD = Date.now();

            // const newD2 = new Date(newD - (wsItem.seconds * 1000));
            const newD2 = new Date(t - (wsItem.seconds * 1000));

            const dIni = newD2;
            const dEnd = Date.now();

            // datachart.datasets[0].borderColor = 'green';

            dta[0]['x'] = newD2;
            dta[1]['x'] = Date.now();

            dta2[0]['x'] = newD2;
            dta2[1]['x'] = Date.now();

            /*
            datachart.datasets[0].data[0]['x'] = newD2; // this.formatNow(newD2);
            delete (datachart.datasets[0].data[0]['y']); // = ; // this.formatNow(newD2);

            while (true) {
              if (datachart.datasets[0].data[1]['x'] <= newD2) {
                if (wsItem.item?.acumulate) {
                  wsItem.item.acumulate = wsItem.item.acumulate - datachart.datasets[0].data[1]['y'];
                }
                datachart.datasets[0].data.shift();
              } else {
                break;
              }
            }
            */

            let valr = 0;
            let deleteStart = false;
            while (true) {
              if (datachart.datasets[0].data[0]['x'] <= newD2) {
                if (wsItem.item?.acumulate) {
                  wsItem.item.acumulate = wsItem.item.acumulate - datachart.datasets[0].data[1]['y'];
                }
                valr = datachart.datasets[0].data[0]['y'];
                datachart.datasets[0].data.shift();
                deleteStart = true;
              } else {
                break;
              }
            }

            if (deleteStart) {
              datachart.datasets[0].data.unshift({ x: new Date(newD2), y: valr });
            } else {
              datachart.datasets[0].data.unshift({ x: new Date(newD2), y: undefined });
            }

            // datachart.datasets[0].data.unshift({ x: newD2 });

            // datachart.datasets[0].data.push({ x: dateF0, y: value });

            if (valueConv === 'interval') {
              datachart.datasets[0].data.push({ x: t, y: undefined });
            } else {
              let add = true;
              try {
                if (datachart.datasets[0].data.length > 1 && this.control_rep) {
                  if (parseFloat(valueConv) === parseFloat(datachart.datasets[0].data[datachart.datasets[0].data.length - 1].y)) {
                    if (datachart.datasets[0].data.length === 2) {
                      add = false;
                      datachart.datasets[0].data[datachart.datasets[0].data.length - 1].x = new Date(t);
                    } else {
                      if (parseFloat(valueConv) === parseFloat(datachart.datasets[0].data[datachart.datasets[0].data.length - 2].y)) {
                        add = false;
                        datachart.datasets[0].data[datachart.datasets[0].data.length - 1].x = new Date(t);
                      }
                    }
                  }
                }
              } catch (error) {

              }
              if (add) {
                datachart.datasets[0].data.push({ x: new Date(t), y: parseFloat(valueConv) });
              }

            }

            if (wsItem.item?.acumulate) {
              if (valueConv !== 'interval') {
                wsItem.item.acumulate = wsItem.item.acumulate + valueConv;
              }
            }

            if (wsItem.item?.acumulate) {
              const idTotal = wsItem.item.parameters.totalItemchart;
              this.items.Synoptic[0]['Text'].find(it => it.id === idTotal).parameters.textgeneral = wsItem.item.acumulate.toFixed();
            }

            if (datachart.datasets[0].data.length > 1) {
              const dini = datachart.datasets[0].data[0]['x'];
              const dfin = datachart.datasets[0].data[datachart.datasets[0].data.length - 1]['x'];

              let factor: number = 12;
              if (dfin - dini > 3600000) {
                factor = 14;
                wsItem.item.options.scales.xAxes[0].time.displayFormats['second'] = 'HH:mm:ss';
              }
              if (dfin - dini > 86400000) {
                wsItem.item.options.scales.xAxes[0].time.displayFormats['second'] = 'DD-MM HH';
              }
              if (dfin - dini > 2592000000) {
                factor = 13;
                wsItem.item.options.scales.xAxes[0].time.displayFormats['second'] = 'DD-MM-YYYY';
              }
              const ddif = Math.floor((dfin - dini) / wsItem.item.parameters.widthgeneral / factor);

              wsItem.item.options.scales.xAxes[0].time.stepSize = ddif;
            }

            const tot = datachart.datasets[0].data.length;

            if (datachart.datasets[0].data[tot - 1].y === undefined) {
              datachart.datasets[0].data[tot - 1].empty = true;

              if (tot > 1) {
                if (datachart.datasets[0].data[tot - 2].hasOwnProperty("empty") === false) {
                  datachart.datasets[0].data[tot - 1].y = datachart.datasets[0].data[tot - 2].y;
                } else {
                  datachart.datasets[0].data[tot - 2].x = datachart.datasets[0].data[tot - 1].x;
                  datachart.datasets[0].data.pop();
                }
              }
            }

            wsItem.item.data = datachart;

            if (this.chartLimits[wsItem.item.id]) {
              // let limdx = 0;
              for (let limdx = 0; limdx < this.chartLimits[wsItem.item.id].length; limdx++) {
                const color0 = this.chartLimits[wsItem.item.id][limdx].color;
                const limit0 = this.chartLimits[wsItem.item.id][limdx].value;
                wsItem.item.data.datasets.unshift({
                  steppedLine: false,
                  barThickness: 10,
                  label: 'limit' + limdx,
                  data: [{ x: dIni, y: limit0 }, { x: dEnd, y: limit0 }],
                  fill: false,
                  borderColor: color0,
                  lineTension: 0,
                });
                // limdx = limdx + 1;
              }
            }

          }
        }
      });
    }
  }

  doFilterNew(itemInfo) {

    let thing = this.globals['things'].find(thing => thing._id === itemInfo.item.parameters.thingconnect);
    const tag = this.globals['tags'].find(tag => tag._id === itemInfo.item.parameters.variableconnect);
    const protocol = this.globals['protocols'].find(prot => prot._id.toString() === tag.protocol.toString());

    let docm = itemInfo.item.parameters.documentconnect;

    if (thing !== undefined && this.player) {

      const start_date = document.getElementById('it' + itemInfo.id + 'start_date')['value'];
      const start_time = document.getElementById('it' + itemInfo.id + 'start_time')['value'];
      const end_date = document.getElementById('it' + itemInfo.id + 'end_date')['value'];
      const end_time = document.getElementById('it' + itemInfo.id + 'end_time')['value'];

      const timestampStart = Date.parse(start_date + ' ' + start_time);
      const timestampEnd = Date.parse(end_date + ' ' + end_time);

      let thingId = thing._id;

      try {
        if (thing.parameters.general.externalid) {
          thingId = thing.parameters.general.externalid;
        }
      } catch (error) { }

      try {
        if (protocol.parameters.general.document) {
          docm = protocol.parameters.general.document;
        }
      } catch (error) {
      }

      if (isNaN(timestampStart) === false && isNaN(timestampEnd) === false) {
        eval(itemInfo.item.parameters.runscriptObject_script);
        this.executeQueryV2(itemInfo.item, { parameters: { thing: thingId, start_date: start_date, start_time: start_time, end_date: end_date, end_time: end_time, document: docm } });
      }

    }
  }

  /**
   * Connect to websocket
   * @param item synoptic item
   */
  async connectWebSocket(item) {
    this.lp_comm_topics.push(item.parameters.thing);

    this.socketService.emitEvent('subscribe',
      {
      topic: 'iot/input/' + item.parameters.thing
    });

  }

  subscribeToTopic(connect) {
    if (this.lp_comm_topics.indexOf(connect.thingconnect) === -1) {
      this.lp_comm_topics.push(connect.thingconnect);
      this.socketService.emitEvent('subscribe',
        {
        topic: 'iot/input/' + connect.thingconnect
      });
    }
  }

  /**
   * Get all lines created in modelizer
   */
  async getLineas(): Promise<any> {
    const items = await this.itemsServ.getModelizerItems();
    this.lineas = await this.itemsServ.getItemsTypes('linea', items);

  }

  formatNow(date): string {
    return date.getFullYear()
      + '-' + this.leftpad(date.getMonth() + 1, 2)
      + '-' + this.leftpad(date.getDate(), 2)
      + ' ' + this.leftpad(date.getHours(), 2)
      + ':' + this.leftpad(date.getMinutes(), 2)
      + ':' + this.leftpad(date.getSeconds(), 2);
  }

  leftpad(val, resultLength = 2, leftpadChar = '0'): string {
    return (String(leftpadChar).repeat(resultLength)
      + String(val)).slice(String(val).length);
  }

  padLeft(n): string {
    return ("00" + n).slice(-2);
  }

  formatDate(dte: Date): {} {
    const dformat = {
      date: dte.getFullYear() + '-' + this.padLeft(dte.getMonth() + 1) + "-" + this.padLeft(dte.getDate())
      , time: this.padLeft(dte.getHours()) + ':' + this.padLeft(dte.getMinutes()) + ':' + this.padLeft(dte.getSeconds())
    };
    return dformat;
  }

  lp_getItem(name) {
    const ret = this.items['Synoptic'][0][Object.keys(this.items['Synoptic'][0]).find(kt => this.items['Synoptic'][0][kt].find(t => t.parameters.namegeneral === name))].find(t => t.parameters.namegeneral === name);
    if (ret) {
      try {
        ret['item'] = document.getElementById("it" + ret.id);
      } catch (error) {

      }
    }
    return ret;
  }

  async lp_get(info) {
    let ret: any = false;
    switch (info.type) {
      case 'DD':
        ret = { DD: null, values: [] };

        try {
          const [dataDesign] = await this.dataDesignService.findAll({ name: info.name });

          if (dataDesign != null) {
            ret['DD'] = dataDesign;

            const fields: any = {};
            for (let i = 0; i < dataDesign.fields.length; i++) {
              fields[dataDesign.fields[i]._id] = dataDesign.fields[i].name;
            }

            const values = await this.dataDesignService.findAllValues(dataDesign._id);
            ret['values'] = [];

            if (values != null) {
              for (const row of values) {
                const mappedValues: any = {};
                for (const field of row.values) {
                  mappedValues[fields[field.field_id]] = field.value;
                }
                ret['values'].push(mappedValues);
            }
          }
        }
        return ret;
        } catch (err) {
          console.error(err)
          return null;
        }
      case 'user':
        ret = { user: this.util.systemSynoptic['user'] };
        return ret;

      case 'item':

        ret = false;

        if (info?.name) {
          ret = this.items['Synoptic'][0][Object.keys(this.items['Synoptic'][0]).find(kt => this.items['Synoptic'][0][kt].find(t => t.parameters.namegeneral === info.name))].find(t => t.parameters.namegeneral === info.name);
          if (info?.get) {
            switch (info.get) {
              case 'value':
                ret = document.getElementById('it' + ret.id)['value'];
                break;
            }
          }
        }

        if (info?._id) {
          ret = await this.systemServ.getItemBy({ _id: info._id });
        }

        if (info?._ids) {
          ret = await this.systemServ.getItemsBy({ _id: info._ids });
        }
        return ret;

      case 'read_plc':
        try {
          let vars = [];
          if (info['var'] !== undefined) {
            vars.push(info.var);
          }

          if (info['vars'] !== undefined) {
            vars = info.vars;
          }

          const reads = [];
          for (const vvar of vars) {
            const tags = vvar.split('.');
            const tag = this.globals['tags'].find(tg => tg.thing.name === tags[0] && tg.name === tags[1]);
            const protocol = this.globals['protocols'].find(protocol => protocol._id === tag.protocol);

            const keyplc = `${protocol.parameters.general.logi7_plc_ip}_${protocol.parameters.general.logi7_plc_rack}_${protocol.parameters.general.logi7_plc_slot}`;
            const address = tag.parameters.plc_address;
            const idthing = tag.thing._id;
            const name = tags[1];
            const read = { keyplc, address, idthing, name };

            reads.push({ readvar: read });
          }

          this.socketService.emitEvent('reads', reads);
        } catch (error) {
          console.log(error);
        }

        return true;
      case 'users':
        let users = await this.systemServ.getUsers();

        /* Comentat si es treuen els 'superusers'
        users = users.filter(user => user.role.name !== 'superuser');
        users = users.filter(user => user.role.name !== 'login');
        return users;
*/
        break;
    }
  }

  async loadTags() {

    const tags: any = await this.tagServ.findAll();

    this.globals['tags'] = tags;
    this.globals['things'] = await this.systemServ.getItemBy({ type: '5f86d624821fd6593dd8806e' });
    this.globals['protocols'] = await this.systemServ.getItemBy({ type: '5f871334821fd6593dd88071' });


  }
}
