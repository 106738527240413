import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/service/auth/auth.service';
import { RolesService } from 'src/app/service/auth/roles.service';
import { UsersService } from 'src/app/service/auth/users.service';
import { LanguageService } from 'src/app/service/system/language.service';
import { MessagesService } from 'src/app/service/system/messages.service';


@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  @ViewChild('mailBoxContent') mailBox: ElementRef;
  @ViewChild('usersList') userList: ElementRef;
  @ViewChild('listContainer') listContainer: ElementRef;
  mail: any = 0;
  readMessage: any;
  newMessage: FormGroup;
  to: any = [];
  boxList: any = [];
  users: any;
  roles: any = [];
  languages: any;
  return: string;
  constructor(
    private messagesServ: MessagesService,
    private userServ: UsersService,
    private rolesServ: RolesService,
    private langServ: LanguageService,
    private authServ: AuthService
  ) {
    this.newMessage = new FormGroup({
      title: new FormControl('', Validators.required),
      body: new FormControl('', Validators.required),
      to: new FormControl(''),
      priority: new FormControl(null, Validators.required)
    })
  }

  ngOnInit(): void {
    if (this.authServ.isLogged()) {
      this.getLanguage();
      this.getAllroles();
      this.messagesServ.getMailBox();
      this.getUsers();
      this.messagesServ.changeEmitted$.subscribe((data) => {
        this.mail = data;
      });
    }

  }

  async getLanguage(): Promise<any> {
    const languages: any = await this.langServ.getVal();
    this.languages = languages.mailBox;
  }

  // Method for close mail window
  closeMailBox(e): void {
    if (e.target.id === 'mailBoxContainer' || e.target.id === 'close') {
      this.mailBox.nativeElement.style.display = 'none';
    }
  }

  // remove class active
  removeActive(e): void {
    e.target.parentNode.classList.remove('active');
    e.target.classList.remove('active');
  }

  returnMethod(site: string) {
    this.return = site;
  }

  // Create message
  createMessage(): any {
    const usersId = [];
    this.to.map(elem => usersId.push(elem.id));

    this.newMessage.value.to = usersId;
    this.messagesServ.createMessage(this.newMessage.value);
    this.to = [];
    this.boxList = [];
    this.newMessage.reset();
  }


  // Get all roles
  async getAllroles(): Promise<any> {
    this.roles = await this.rolesServ.getRoles();
  }

  // Get all user for sent message
  async getUsers(): Promise<any> {
    this.users = await this.userServ.getUsers();
  }

  openList(): void {
    this.userList.nativeElement.style.display = 'block';
    this.listContainer.nativeElement.style.display = 'block';
  }

  closeList(): void {
    this.userList.nativeElement.style.display = 'none';
    this.listContainer.nativeElement.style.display = 'none';
  }

  addUserTo(e): void {
    // Add boxes to list
    const box = {
      id: e.target.dataset.id,
      username: e.target.dataset.name
    };

    if (!(this.boxList.some(element => element.id === e.target.dataset.id)) &&
      !(this.to.some(element => element.id === e.target.dataset.id))) {
      this.boxList.push(box);
    } else if (this.boxList.some(element => element.id === e.target.dataset.id) ||
      this.to.some(element => element.id === e.target.dataset.id)) {

      return;
    }

    // add array to for send message
    let userToPush: any = {};
    if (e.target.dataset.type === 'rol') {
      this.users.forEach(user => {
        userToPush = {};
        if (user.role._id === e.target.dataset.id) {
          userToPush.id = user._id;
          userToPush.username = user.username;
          userToPush.type = 'role';
          if ((this.to.some(element => element.id === user._id))) {
            const i = this.to.map(element => element.id).indexOf(user._id);
            const iListBox = this.boxList.map(element => element.id).indexOf(user._id);
            this.boxList.splice(iListBox, 1);
            this.to.splice(i, 1);


          }
          this.to.push(userToPush);
        }
      }

      );
    } else {
      userToPush.id = e.target.dataset.id;
      userToPush.username = e.target.dataset.name;
      if (!(this.to.some(element => element._id === e.target.dataset.id))) {
        this.to.push(userToPush);
      }
    }

  }

  // Remove user from TO
  removeUserTo(e): void {


    // search if the box is from a group
    if (this.roles.some(elem => elem._id === e.target.dataset.id)) {
      const indexBoxList = this.boxList.map(element => element.id).indexOf(e.target.dataset.id);
      this.boxList.splice(indexBoxList, 1);

      this.users.forEach(user => {

        if (user.role._id === e.target.dataset.id) {
          const id = user._id;
          const indexTo = this.to.map(element => element.id).indexOf(id);
          this.to.splice(indexTo, 1);

        }
      });

    } else {
      const id = e.target.dataset.id;
      const indexBoxList = this.boxList.map(element => element.id).indexOf(id);
      const indexTo = this.to.map(element => element.id).indexOf(id);
      this.boxList.splice(indexBoxList, 1);
      this.to.splice(indexTo, 1);
    }

  }

  moveToBin(e) {
    const messageId = e.target.dataset.id;
    const from = e.target.dataset.from;
    this.messagesServ.moveToBin(from, messageId);
  }

  recoveryMessage(e) {
    const messageId = e.target.dataset.id;
    this.messagesServ.recoveryMessage(messageId);
  }

  readNewMessage(id: string): void {
    this.messagesServ.readMessage(id);
  }
}
