<div class="config-title">{{ language?.remote_assistance }}</div>
<div class="config-container">
  <dl>
    <dd style="display: flex; flex-direction: column; gap: 10px;">
      <mat-slide-toggle #toggleElement [checked]="supportActive" [disabled]="supportActive == null"
        (change)="supportToggle($event)">
        {{ language?.permit_remote_assistance }}
      </mat-slide-toggle>
      <span style="font-size: 75%; color: rgba(0, 0, 0, .6);">
        {{ language?.permit_remote_assistance_hint }}
      </span>
    </dd>
  </dl>
</div>
