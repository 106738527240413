import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/service/system/language.service';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss'],
})
export class ConfigComponent implements OnInit {
  language: any;

  constructor(private router: Router, private langServ: LanguageService) {}

  async ngOnInit(): Promise<void> {
    this.language = (await this.langServ.getVal()).option;
  }

  isActive(route: string): boolean {
    return this.router.url.includes(route);
  }
}
