<div class="container dashboard-container mt-lg-3">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/administration">{{ adminText?.user_management }}</a></li>
        </ol>
    </nav>
    <!-- Grid menu -->
    <div class="container grid-menu-container">
        <div class="row grid-menu-row">
            <div class="col-12 col-lg-3 col-md-6 grid-menu-elem mt-5" routerLink="/administration/permits">
                <img src="assets/img/administration/permits.svg" />
                <div class="grid-menu-title">{{ adminText?.permissions }}</div>
            </div>
            <div class="col-12 col-lg-3 col-md-5 grid-menu-elem mt-5" routerLink="/administration/roles">
                <img src="assets/img/administration/roles.svg" />
                <div class="grid-menu-title">{{ adminText?.roles }}</div>
            </div>
            <div class="col-12 col-lg-3 col-md-6 grid-menu-elem mt-5" routerLink="/administration/users">
                <img src="assets/img/administration/users.svg" />
                <div class="grid-menu-title">{{ adminText?.users }}</div>
            </div>
            <div class="col-12 col-lg-2 col-md-5 grid-menu-elem mt-5" routerLink="/administration/others">
                <img src="assets/img/administration/other.svg" />
                <div class="grid-menu-title">{{ adminText?.others }}</div>
            </div>
        </div>
    </div>
</div>
