<div class="container mt-lg-3">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/administration">{{ roleText?.user_management }}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{ roleText?.roles }}</li>
        </ol>
    </nav>
    <div class="row mb-3 mt-3">
        <div class="col-12">
            <button type="button" class="btn btn-primary float-right" data-toggle="modal"
                data-target=".bd-modal-addRole">{{ roleText?.add_role }}</button>
        </div>
    </div>

    <!-- Table of Roles -->
    <div class="row">
        <div class="col-12" id="divRoles" style="visibility: hidden;">
            <table id="rolesTable" class="table row-border order-column hover">
                <thead>
                    <tr>
                        <th>{{ roleText?.role }}</th>
                        <th>{{ roleText?.permissions }}</th>
                        <th>{{ roleText?.actions }}</th>
                    </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let role of roles">
                    <ng-container *ngIf="role.parameters?.visible !== false">
                      <tr>
                        <td>{{ role.name }}</td>
                        <td class=" permissions-table">
                          <ng-container *ngFor="let permissions of role.permissions; let last = last">
                            <p class="m-0 p-0 d-inline">{{ permissions.name }}{{ !last ? ' / ' : '' }}</p>
                          </ng-container>
                        </td>
                        <td>
                          <a class="action-icon" *ngIf="role.parameters?.editable !== false"><img src="assets/img/action/pencil.svg"
                              class="img-thumbnail" data-target=".bd-modal-editRole" data-toggle="modal" alt=""
                              [attr.data-id]="role._id" (click)="editRole($event)"></a>
                          <a class="action-icon" *ngIf="role.parameters?.removable !== false"><img src="assets/img/action/trash.svg"
                              class="img-thumbnail" alt="" data-target=".bd-modal-deleteRoleModal" [attr.data-id]="role._id"
                              data-toggle="modal" (click)="confirmDeleteRole($event)"></a>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>
<!-- Toast -->
<div role="alert" aria-live="assertive" aria-atomic="true" class="toast">
    <div class="toast-header">
        <img src="assets/img/logo.png" class="logo-toast" alt="Logo">
    </div>
    <div class="toast-body"></div>
</div>

<!-- Modals  -->

<!-- add Role Modal -->
<div class="modal fade bd-modal-addRole" tabindex="-1" role="dialog" aria-labelledby="Añadir Rol" aria-hidden="true"
    id="addRoleModal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalAddRoleTitle">{{ roleText?.add_role }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="m-4" [formGroup]="newRole" (ngSubmit)="addRole()" method="POST">
                    <div class="form-group">
                        <label for="username">{{ roleText?.new_role }}</label>
                        <input type="text" class="form-control" id="username" formControlName="name" placeholder="Rol">
                    </div>

                    <!--  Permissions drop down-->

                    <div class="">
                        <button class="btn btn-primary" type="button" data-toggle="collapse"
                            data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="">{{ roleText?.add_permissions }}</span>
                        </button>
                        <div class="collapse" id="navbarToggleExternalContent">
                            <div *ngFor="let permission of permissions" class="form-check">
                                <input class="form-check-input" type="checkbox" [value]="permission.name"
                                    [attr.data-id]="permission._id" [id]="permission._id"
                                    (change)="onCheckchange($event)">
                                <label class="form-check-label" [for]="permission._id">
                                    {{permission.name}}
                                </label>
                            </div>
                            <div *ngIf="permissions == ''">
                                <label for="">{{ roleText?.no_permissions_to_select }}</label>
                            </div>
                        </div>
                    </div>
                    <label for="">{{ roleText?.selected_permissions }}</label>
                    <div class="content-permissions">
                        <div *ngFor="let permission of newPermissions" class="form-check">
                            <input checked="checked" class="form-check-input" type="checkbox" [id]="permission._id"
                                [value]="permission.name" [attr.data-id]="permission._id"
                                (change)="onCheckchange($event)">
                            <label class="form-check-label" [for]="permission._id">
                                {{permission.name}}
                            </label>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ modalText?.close
                            }}</button>
                        <button type="submit" class="btn btn-primary"
                            [disabled]="!newRole.valid || newPermissions == ''">{{ modalText?.add }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


<!-- Edit Role Modal -->



<div class="modal fade bd-modal-editRole" tabindex="-1" role="dialog" aria-labelledby="Edit Rol" aria-hidden="true"
    id="editRoleModal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalEditRoleTitle">{{ roleText?.edit_role }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="m-4" [formGroup]="editRoleForm" (ngSubmit)="editeRoleRequest()" method="POST">
                    <div class="form-group">
                        <label for="username">{{ roleText?.role }}</label>
                        <input type="text" class="form-control" id="username" formControlName="name" placeholder="Rol">
                    </div>

                    <!--  Permissions drop down-->

                    <div class="">
                        <button class="btn btn-primary" type="button" data-toggle="collapse"
                            data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="">{{ roleText?.permissions }}</span>
                        </button>
                        <div class="collapse" id="navbarToggleExternalContent">
                            <div *ngFor="let permission of permissions" class="form-check">
                                <input class="form-check-input" type="checkbox" [value]="permission.name"
                                    [attr.data-id]="permission._id" [id]="permission._id"
                                    (change)="onCheckchange($event)">
                                <label class="form-check-label" [for]="permission._id">
                                    {{permission.name}}
                                </label>
                            </div>
                            <div *ngIf="permissions == ''">
                                <label for="">{{ roleText?.no_permissions_to_select }}</label>
                            </div>
                        </div>
                    </div>
                    <label for="">{{ roleText?.selected_permissions }}</label>
                    <div class="content-permissions">
                        <div *ngFor="let permission of newPermissions" class="form-check">
                            <input checked="checked" class="form-check-input" type="checkbox" [id]="permission._id"
                                [value]="permission.name" [attr.data-id]="permission._id"
                                (change)="onCheckchange($event)">
                            <label class="form-check-label" [for]="permission._id">
                                {{permission.name}}
                            </label>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" (click)="resetModal()" data-dismiss="modal">{{
                            modalText?.close }}</button>
                        <button type="submit" class="btn btn-primary"
                            [disabled]="!editRoleForm.valid || newPermissions == ''">{{ modalText?.modify }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


<!-- Delete Role -->

<div class="modal fade bd-modal-deleteRoleModal" tabindex="-1" role="dialog" aria-labelledby="Eliminar rol"
    aria-hidden="true" id="delteRoleModal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalDeleteUserTitle">{{ roleText?.confirmation }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <h3>{{ roleText?.confirmation_message }} <span id="usernameToDelete"></span></h3>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ modalText?.close }}</button>
                <button type="button" class="btn btn-danger" (click)="deleteRole()">{{ modalText?.delete }}</button>
            </div>
        </div>
    </div>
</div>
