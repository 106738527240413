import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/service/auth/auth.service';
import { DatatableService } from 'src/app/service/datatable.service';
import { RouterService } from 'src/app/service/router.service';
import { LanguageService } from 'src/app/service/system/language.service';
import { LoginService } from 'src/app/service/system/login.service';
import { MessagesService } from 'src/app/service/system/messages.service';
import { AlarmsSocketService } from 'src/app/service/tags/alarms-socket.service';
import { AlarmsService } from 'src/app/service/tags/alarms.service';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
})
export class LeftSidebarComponent implements OnInit, OnDestroy {
  step = '';
  token: string | null = null;
  language: any;
  sidebarText: any;
  userLanguage: any;
  mail: any;
  alarms!: boolean;
  subscriptions: Subscription[] = [];

  /**
   * Constructor
   * @param authServ auth service
   */
  constructor(
    private authServ: AuthService,
    private langServ: LanguageService,
    private refresh: RouterService,
    private dataServ: DatatableService,
    private messageServ: MessagesService,
    private loginServ: LoginService,
    private alarmSock: AlarmsSocketService,
    private alarmServ: AlarmsService,
    private router: Router
  ) {}

  async ngOnInit() {
    // get mail for alerts
    this.gelMail();

    // get token from auth service
    this.token = this.authServ.getToken();
    // get language
    this.language = await this.langServ.getVal();
    // get language to print this component
    this.sidebarText = this.language['left-sidebar'];
    // get user language
    this.userLanguage = this.langServ.getUserLanguage();

    this.alarmServ.getLogs().then((data) => {
      const currentAlarms = data.filter(
        (alarm: any) => alarm.status !== 'disappeared'
      );
      this.alarms = currentAlarms.length > 0;
    });

    const appeared = this.alarmSock.listenAppeared().subscribe(() => {
      this.alarms = true;
    });

    const disappeared = this.alarmSock.listenDisappeared().subscribe(() => {
      this.alarmServ.getLogs().then((data) => {
        const currentAlarms = data.filter(
          (alarm: any) => alarm.status !== 'disappeared'
        );
        this.alarms = currentAlarms.length > 0;
      });
    });

    this.subscriptions.push(appeared, disappeared);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  async logOut(): Promise<any> {
    if (this.authServ.isLogged()) {
      await this.loginServ.logOut();
    }
    this.authServ.logOut();
  }

  selectLanguage(val: any): void {
    // change language in LocalStorage
    this.langServ.changeLanguage(val);
    // change datatable options
    this.dataServ.setOptions();
    // refresh window
    this.refresh.refresh();
  }

  gelMail(): void {
    this.messageServ.changeEmitted$.subscribe((data) => {
      this.mail = data;
    });
  }

  openMailBox() {
    const mailBox = document.getElementById('mailBoxContainer') ?? null;
    if (mailBox != null) {
      mailBox.style.display = 'block';
    }
  }

  isActive(route: string): boolean {
    return this.router.url.includes(route);
  }
}
