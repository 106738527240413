import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ModelizerTypeService {
  private BASE_URL; // = environment.base_url;
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.base_url;
  }

  getAllTypes(): Promise<any> {
    return this.http.get(this.BASE_URL + '/v1/api/modelizer_types').toPromise();
  }

  async getModelizerTypes(): Promise<any> {
    let modelizerTypes = [];
    const allTypes: any = await this.getAllTypes();

    // tslint:disable-next-line: forin
    for (const item of allTypes) {
      if (
        item.name === 'Instalacion' ||
        item.name === 'Zona' ||
        item.name === 'Seccion' ||
        item.name === 'Linea' ||
        item.name === 'Thing' ||
        item.name === 'Protocolo' ||
        item.name === 'Conexion'
      ) {
        modelizerTypes.push(item);
      }
    }

    modelizerTypes.sort(function (a, b) {
      if (a.parameters.order > b.parameters.order) {
        return 1;
      }

      if (a.parameters.order < b.parameters.order) {
        return -1;
      }

      return 0;
    });

    return modelizerTypes;
  }

  async getModelizerCommTypes(): Promise<any> {
    const modelizerTypes: any = await this.http
      .put(this.BASE_URL + '/v1/api/modelizer_types/info/info', {
        parent: '5f858469093c3d3f06c69a4f',
      })
      .toPromise();

    modelizerTypes.sort(function (a, b) {
      if (a.parameters.order > b.parameters.order) {
        return 1;
      }

      if (a.parameters.order < b.parameters.order) {
        return -1;
      }

      return 0;
    });

    return modelizerTypes;
  }

  getProtocols(): Promise<any> {
    return this.http
      .get(this.BASE_URL + '/v1/api/modelizer/protocols')
      .toPromise();
  }

  getProtocol(id): Promise<any> {
    return this.http
      .get(`${this.BASE_URL}/v1/api/modelizer/protocols/${id}`)
      .toPromise();
  }
}
