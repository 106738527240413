import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
// Import services
import { Subscription } from 'rxjs';
import { SystemService } from 'src/app/service/system/system.service';
import { LanguageService } from 'src/app/service/system/language.service';

var pars = {};
function setPar(par, value) {
  pars[par] = value;
}

@Component({
  selector: 'app-optionsdynmodal',
  templateUrl: './optionsdynmodal.component.html',
  styleUrls: ['./optionsdynmodal.component.scss']
})
export class OptionsDynModalComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[];
  parameters: any;
  tabs: any = [];

  tools: any = {};

  scriptContent: string = 'fddd';

  general: any = [];
  expert: any = [];
  codeMirrorOptions: any;

  options: any = {};

  language: any;
  optionItem: any;
  paramsItem: any;
  paramsDone: boolean;
  color: any;
  optionsDynModalForm: FormGroup;
  thingTypeForm: FormGroup;
  isReport = false;
  isSynoptics = false;
  DDrelationInfo: any;

  itemName = '';
  itemDescription = '';
  reportItem: boolean;
  documentDefinitions: any = [];
  fields: any = [];
  fieldsDD: any = [];
  things: any = [];
  fieldItems: any = [];
  values: any = {};
  script: string = '';

  constructor(
    private elementRef: ElementRef,
    private systemServ: SystemService,
    private langServ: LanguageService,
  ) {
    this.subscriptions = [];
    this.optionsDynModalForm = new FormGroup({});
    this.thingTypeForm = new FormGroup({});

    this.codeMirrorOptions = {
      lineNumbers: true,
      mode: "htmlmixed",
      theme: 'elegant',
      extraKeys: {
        F11: (cm) => {
          if (!cm.getOption('fullScreen')) {
            cm.setOption('fullScreen', true);
            $('ngx-codemirror').addClass('CodeMirror-fullscreen');
            $('.CodeMirror').css('height', '100%');
          } else {
            cm.setOption('fullScreen', false);
            $('ngx-codemirror').removeClass('CodeMirror-fullscreen');
            $('.CodeMirror').css('height', '500px');
          }
        },
        Esc: (cm) => {
          if (cm.getOption('fullScreen')) {
            cm.setOption('fullScreen', false);
            $('ngx-codemirror').removeClass('CodeMirror-fullscreen');
            $('.CodeMirror').css('height', '500px');
          }
        }
      }
    };
  }

  async ngOnInit() {

    this.renderItemComponent();
    this.language = await this.langServ.getVal();
    this.language = this.language.option;
    /* this.webServ.connect('lectura_agua_luz', 'AGUA_TOTAL', ['segunda_pilona', 'cuarta_pilona']); */

    //cargo los options

    /*
    try {
      const scriptGglob = document.createElement("script");  // create a script DOM node
      scriptGglob.setAttribute('type', 'text/javascript');
      scriptGglob.id = 'code';
      scriptGglob.onload = function () {
        document.getElementById("code").parentElement.removeChild(document.getElementById("code"));
      }
      // scriptGglob['app'] = this;
      scriptGglob['src'] = '/assets/js/properties/properties_global.js';
      document.head.appendChild(scriptGglob);

    } catch (error) {

    }
    */

    document.getElementById('optionsdynmodal')['app'] = this;

    try {
      const scriptG = document.createElement("script");  // create a script DOM node
      scriptG.setAttribute('type', 'text/javascript');
      scriptG.id = 'fnctScr';
      scriptG.onload = function () {
        document.getElementById("fnctScr").parentElement.removeChild(document.getElementById("fnctScr"));
      }
      // scriptG['app'] = this;
      scriptG['src'] = '/assets/js/functionalities/options.js';
      document.head.appendChild(scriptG);
    } catch (error) {

    }

    try {
      const scriptG2 = document.createElement("script");  // create a script DOM node
      scriptG2.setAttribute('type', 'text/javascript');
      scriptG2.id = 'propScr';
      scriptG2.onload = function () {
        document.getElementById("propScr").parentElement.removeChild(document.getElementById("propScr"));
      }
      // scriptG2['app'] = this;
      scriptG2['src'] = '/assets/js/properties/options.js';
      document.head.appendChild(scriptG2);
    } catch (error) {

    }
  }

  lp_query(info, fnc) {
    this.systemServ.query(info).then((data) => { fnc(data); });
  }

  async lp_queryAsync(info) {
    const data = await this.systemServ.query(info);
    return data;
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
    this.elementRef.nativeElement.remove();
    this.unsuscribe();
  }

  subscribe(subs: any) {
    this.subscriptions.push(subs);
  }

  unsuscribe() {
    this.subscriptions.forEach(subs => {
      try {
        subs.unsubscribe();
      } catch (error) {
      }
    });

  }

  selectChange(event, opt) {

    if (this.options.prop.hasOwnProperty(event.target.value)) {
      // this.options.prop[event.target.value].prev();
    }

    if (opt !== undefined && false) {

      // this.webServ  parameters[contentTab.key][key]parameters[contentTab.key][key]
      const newOptions = [{ key: "disabled", value: "select field" }];

      const fields = this.fieldsDD.filter(fld => fld.parent === event.target.value);
      fields.forEach(field => {
        newOptions.push({ key: field._id, value: field.name });
      });

      this.parameters['generalmod']['relationfield'].options = newOptions;
    }

  }

  controlselect(event): void {
    const params = this.parameters;
    Object.keys(params).forEach(tab => {
      let tabKey = tab;
      Object.keys(params[tab]).forEach(field => {
        if (params[tab][field].hasOwnProperty('condition')) {
          if (this.optionsDynModalForm.value['type'] === params[tab][field].condition.option) {
            params[tab][field].display = '';
          } else {
            params[tab][field].display = 'hidden';
          }
        }
      });
    });
  }

  renderItemComponent() {
    // Subscribe to shared service that emit items with his options
    this.subscribe(this.systemServ.changeEmitted$.subscribe(async data => {
      if (data.hasOwnProperty('component')) {
        $('#optionsdynmodal').show();

        this.DDrelationInfo = data.item?.DDrelationInfo;
        if (this.DDrelationInfo === undefined) {
          this.DDrelationInfo = { isRelation: false };
        }

        // this.values = { ...data.item.values };

        this.parameters = data.item.type.parameters;
        this.optionItem = data.item;
        this.fieldsDD = data.item?.fieldsDD;
        this.tabs = [];
        this.general = [];
        this.expert = [];
        this.documentDefinitions = [];
        this.fields = [];
        this.things = [];
        this.fieldItems = [];
        this.optionsDynModalForm = new FormGroup({});
        this.thingTypeForm = new FormGroup({});

        this.reportItem = true;
        this.isReport = true;
        // tslint:disable-next-line: forin

        const keys = Object.keys(data.item.type.parameters);
        keys.forEach(key => {
          if (key.substring(key.length - 3) !== 'mod') {
            data.item.type.parameters[key + 'mod'] = data.item.type.parameters[key];
            delete data.item.type.parameters[key];
          }
        });

        this.reportItem = false;
        this.paramsItem = data.item.parameters;
        this.itemName = data.item.name;
        this.itemDescription = data.item.description;

        if (data.item.hasOwnProperty('values')) {
          this.paramsDone = true;

          // if exists expert.color save value in color variable
          //if (data.parameters.expert.hasOwnProperty('color')) {
          //  this.color = data.parameters.expert.color;
          //}
        } else {
          this.paramsDone = false;
        }
        // tslint:disable-next-line: forin
        // for (const key in data.item.type.parameters) {
        for (const key in this.parameters) {

          const tab: any = {};
          tab.key = key;
          tab.controls = [];

          const params: any = Object.keys(data.item.type.parameters[key]);
          /*
          params.forEach(param => {
            if (this.language.hasOwnProperty(data.item.type.parameters[key][param].label)) {
              data.item.type.parameters[key][param].label = this.language[data.item.type.parameters[key][param].label];
            } else {
              data.item.type.parameters[key][param].label = data.item.type.parameters[key][param].label;
            }
          });
          */

          tab.controls.push(params);

          for (const itemKey in data.item.type.parameters[key]) {
            if (this.options.prop.hasOwnProperty('_render')) {

              this.options.prop['_render'](data.item.type.parameters, (this.paramsDone ? data.item.values : false), itemKey);
            }

            let whiteValue = true;

            if (this.paramsDone) {
              whiteValue = false;
            }

            let nfield = itemKey + key;
            if (key.substring(key.length - 3) === 'mod') {
              const ntab = key.substring(0, key.length - 3);
              nfield = itemKey + ntab;
            }
            if (this.DDrelationInfo.isRelation) {
              if (nfield === this.DDrelationInfo.relationFieldName) {
                whiteValue = false;
                this.optionsDynModalForm.addControl(itemKey, new FormControl(this.DDrelationInfo.relationFieldValue, Validators.required));
                setTimeout(() => {
                  document.getElementById(itemKey + key).setAttribute('readonly', 'true');
                }, 400);
              } else {
                if (this.paramsDone) {
                  this.optionsDynModalForm.addControl(itemKey, new FormControl(data.item.values[nfield], Validators.required));
                }
              }
            } else {
              if (this.paramsDone) {

                this.optionsDynModalForm.addControl(itemKey, new FormControl(data.item.values[nfield], Validators.required));
              }
            }

            if (whiteValue) {
              this.optionsDynModalForm.addControl(itemKey, new FormControl('', Validators.required));
            }
          }

          this.tabs.push(tab);
        }

        try {
          setTimeout(() => {
            this.controlselect(null);
          }, 100);

        } catch (error) {

        }
      }
    }));
  }

  /**
   * Updates range value input
   * @param val Range value
   */
  updateRange(val): void {
    const newValue = ($(`#${val}`) as any)[0].value;
    ($(`#range_${val}`) as any)[0].value = newValue;
  }

  checkBoxValue(event, controlName): void {
    this.optionsDynModalForm.controls[controlName].setValue(event.target.checked);
  }

  optionmodalSubmit(): void {

    this.optionItem.parameters = {};
    this.tabs.forEach(tab => {
      tab.controls[0].forEach(control => {
        this.optionItem.parameters[control + tab.key.substring(0, tab.key.length - 3)] = this.optionsDynModalForm.value[control];
        this.options.prop['_submit'](this.parameters, this.optionItem.parameters, control + tab.key.substring(0, tab.key.length - 3));
      });
    });

    this.systemServ.emitItem('action', this.optionItem);
    this.closeOptionmodal();
  }

  confirmItemToDelete($event) {
    $('#itemToDelete').html(this.optionItem.name);
  }

  deleteItem($event) {
    this.systemServ.emitDeleteItem('delete', this.optionItem);
    this.closeOptionmodal();
    (<any>$('#deleteItem')).modal('hide');
  }

  deleteItem2($event) {
    this.systemServ.emitDeleteItem('delete2', this.optionItem);
    this.closeOptionmodal();
    (<any>$('#deleteItem2')).modal('hide');
  }

  closeOptionmodal() {
    $('#DSitemForm')['modal']('hide');
    $('#optionsdynmodal').hide();
  }

  addFieldItem() {
    const field = {
      name: this.thingTypeForm.value.f_name,
      tag: this.thingTypeForm.value.f_tag,
      type: this.thingTypeForm.value.f_type,
      list: '',
      containsThingTime: false,
      containsExternalThingId: false,
      containsDocumentDefinitionId: false,
      nullable: true,
      fields: null,
      fieldAccessor: this.thingTypeForm.value.f_accessor
    };
    if ($('#arrayPos').val() !== 'undefined') {
      const pos: any = $('#arrayPos').val();
      this.fieldItems[pos] = field;
    } else {
      this.fieldItems.push(field);
    }
    this.changeFieldForm('');
    this.cancelAddField();
  }

  addField() {
    $('#fields').show();
    $('#add_field_btn').hide();
    $('#cancel_add_field_btn').show();
    $('#addFieldButton').show();
    $('#modifyFieldButton').hide();
    $('#arrayPos').val('undefined');
    this.changeFieldForm('');
  }

  cancelAddField() {
    $('#fields').hide();
    $('#add_field_btn').show();
    $('#addFieldButton').hide();
    $('#cancel_add_field_btn').hide();
    this.changeFieldForm('');
  }

  changeFieldForm(val) {
    const idFieldForm = ['f_name', 'f_tag', 'f_type', 'f_accessor'];
    idFieldForm.forEach(element => {
      if (val === '') {
        $(`#${element}`).val(val);
        this.thingTypeForm.value[element] = val;
      } else {
        const key: any = element.split('_')[1];
        if (key === 'accessor') {
          $(`#${element}`).val(val.fieldAccessor);
          this.thingTypeForm.value[element] = val.fieldAccessor;
        } else {
          $(`#${element}`).val(val[key]);
          this.thingTypeForm.value[element] = val[key];
        }
      }
    });
  }

  editField(event) {
    this.addField();
    const fieldName = event.target.dataset.fieldname;
    const fieldItem = this.fieldItems.filter((element, index) => {
      if (element.name === fieldName) {
        $('#arrayPos').val(index);
        return element;
      }
    });

    $('#addFieldButton').hide();
    $('#modifyFieldButton').show();
    this.changeFieldForm(fieldItem[0]);
  }

  removeField(event) {
    const fieldName = event.target.dataset.fieldname;
    const indexField = this.fieldItems.map(element => element.name).indexOf(fieldName);
    this.fieldItems.splice(indexField, 1);
  }

  getTagF(info) {

    const doc = document.createElement("lp");
    if (info.hasOwnProperty("html")) {

      fetch('/assets/html/' + info.html)
        .then(function (response) {
          // When the page is loaded convert it to text
          return response.text()
        })
        .then(function (html) {
          // Initialize the DOM parser
          const parserH = new DOMParser();
          info.fnc(parserH.parseFromString(html, "text/html"));
        })
        .catch(function (err) {
          console.log('Failed to fetch page: ', err);
        });
    }
  }

  getTagH(info) {

    try {
      // setPar('typeLineas', '');
      // setPar('typeBarras', '');
      for (const each of Object.entries(info.pars)) {
        setPar(each[0], each[1]);
      }
    } catch (error) {

    }

    const doc = document.createElement("lp");

    if (info.hasOwnProperty("html")) {

      fetch('/assets/html/' + info.html)
        .then(function (response) {
          // When the page is loaded convert it to text
          return response.text()
        })
        .then(function (html) {

          const doc = document.createElement("lp");
          const evcode = "doc.innerHTML = `" + html + "`;";
          eval(evcode);

          if (info.hasOwnProperty('extra')) {
            setTimeout((doc, fnc, extra) => {
              fnc(doc, extra);
            }, 1, doc, info.fnc, info.extra);
          } else {
            setTimeout((doc, fnc) => {
              fnc(doc);
            }, 1, doc, info.fnc);
          }

        })
        .catch(function (err) {
          console.log('Failed to fetch page: ', err);
        });
    }
  }

}
