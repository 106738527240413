import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from 'src/app/service/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SecurityGuard implements CanActivate {
  /**
   * Constructor
   * @param authServ auth service
   * @param router router
   */

  constructor(private authServ: AuthService, private router: Router) {}

  /**
   * Validate the user's path permissions
   */
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    try {
      const currentURL = window.location.pathname.toLowerCase();
      const targetURL = state.url.toLowerCase();
      const canAccess: boolean = await this.authServ.canAccessRoute(targetURL);

      if (canAccess) return true;

      if (
        (targetURL.includes(currentURL) && currentURL !== targetURL) ||
        currentURL === targetURL
      ) {
        this.router.navigate(['']);
      }
      return false;
    } catch (e) {
      console.error(
        'Error when trying to validate user path permissions.\n\n',
        e
      );
      return false;
    }
  }
}
