import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TableComponent } from 'src/app/components/table/table.component';
import { DataDesignService } from 'src/app/service/data-design.service';
import { ModelizerItemsService } from 'src/app/service/modelizer/modelizer-items.service';
import { LanguageService } from 'src/app/service/system/language.service';
import { environment } from 'src/environments/environment';

const TYPES = {
  DATA_DESIGN: 'DataDesign',
  TAG_VALUES: 'TagValues',
} as const;

type Types = (typeof TYPES)[keyof typeof TYPES];

interface Field {
  _id: string;
  name: string;
  type: 'text' | 'number' | 'date' | 'datetime-local' | 'boolean';
}
interface DataDesign {
  _id: string;
  name: string;
  fields: Field[];
}

interface Value {
  field_id: string;
  value: string | number | boolean | Date | null;
}

interface DataDesignValue {
  _id: string;
  datadesign_id: string;
  values: Value[];
}

@Component({
  selector: 'app-item-table',
  templateUrl: './item-table.item.html',
  styleUrls: ['./item-table.item.scss'],
  host: {
    '[id]': '"it" + tag.id',
    class: 'item noselect',
    '[attr.iditem]': 'tag.id',
    '[attr.name]': 'tag.parameters.namegeneral',
    '[style.backgroundColor]': 'tag.parameters.backgroundColorstyle',
    '[style.left.px]': 'tag.parameters.xgeneral',
    '[style.zIndex]': 'tag.parameters.zIndexgeneral',
    '[style.width.px]': 'tag.parameters.widthgeneral',
    '[style.min-width]': '"min-content"',
    '[style.height]': '"max-content"',
    '[style.border-style]': 'tag.parameters.borderStylestyle',
    '[style.border-color]': 'tag.parameters.borderColorstyle',
    '[style.border-width.px]': 'tag.parameters.borderWidthstyle',
    '[style.border-radius.px]': 'tag.parameters.borderRadiusstyle',
    '[style.top.px]': 'tag.parameters.ygeneral',
    '[style.box-shadow]': 'tag.parameters.shadowstyle',
  },
})
export class ItemTableItem implements OnInit, OnDestroy {
  @ViewChild('tableElement') tableElement!: TableComponent;

  @Input() tag: any;
  @Input() isPlayer!: boolean;

  BASE_URL = environment.base_url;
  language: any;
  subscriptions: Subscription[] = [];

  tableHeaders: any[] = [];
  tableData: any[] = [];

  filter = new FormControl();
  filterValue!: any;

  filterForm = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
  });

  get searchText() {
    return this.filter.value !== '' && this.filter.value != null
      ? this.filter.value
      : null;
  }

  get fromDate() {
    return this.filterForm.controls.fromDate.value !== '' &&
      this.filterForm.controls.fromDate.value != null
      ? new Date(this.filterForm.controls.fromDate.value).toISOString()
      : null;
  }

  get toDate() {
    return this.filterForm.controls.toDate.value !== '' &&
      this.filterForm.controls.toDate.value != null
      ? new Date(this.filterForm.controls.toDate.value).toISOString()
      : null;
  }

  type!: Types;
  tagkey: string = '';
  dataDesign!: DataDesign;
  dataDesignValues: DataDesignValue[] = [];

  isLoading: boolean = false;
  isRefreshing: boolean = false;
  isRefreshed: boolean = false;

  datetime_min: number = 0;
  datetime_max: number = 0;

  addValueForm: FormGroup = new FormGroup({});
  valueDialogOpen: boolean = false;
  isEditing: boolean = false;

  deleteDialogOpen: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private itemsServ: ModelizerItemsService,
    private datadesignServ: DataDesignService,
    private langServ: LanguageService
  ) {}

  async ngOnInit(): Promise<void> {
    this.language = (await this.langServ.getVal()).option;
    this.tableData = this.tableData ?? [];

    this.type = this.tag.parameters.typeconnect as Types;

    switch (this.type) {
      case TYPES.DATA_DESIGN:
        this.dataDesign = await this.datadesignServ.findOne(
          this.tag.parameters.datadesignconnect
        );

        for (const field of this.dataDesign.fields) {
          this.addValueForm.addControl(
            field._id,
            new FormControl('', [
              Validators.pattern(/^(?!\s*$)[a-zA-Z0-9\s\-_<>.:()%]+$/),
              Validators.minLength(1),
              Validators.maxLength(150),
            ])
          );
        }

        this.cdr.detectChanges();

        const dataDesignHeaders = this.dataDesign.fields.map((field: any) => {
          return { key: field._id, label: field.name };
        });

        this.tableHeaders = [{ key: 'select' }, ...dataDesignHeaders];
        break;
      case TYPES.TAG_VALUES:
        const thing = await this.itemsServ.findItem(
          this.tag.parameters.thingconnect
        );

        if (thing != null) {
          this.tagkey = thing.parameters?.general?.externalid ?? thing.name;

          const tagHeaders = this.tag.parameters.tagsconnect.map((tag: any) => {
            return { key: `values.${tag}`, label: tag };
          });

          this.tableHeaders = [
            { key: 'datetime', label: 'Fecha' },
            ...tagHeaders,
          ];
        }

        break;
    }

    if (this.isPlayer) {
      try {
        this.isLoading = true;
        const data = await this.getData();
        switch (this.type) {
          case TYPES.DATA_DESIGN:
            this.dataDesignValues = data;
            this.tableData = this.formatDataDesignValues(this.dataDesignValues);
            break;
          case TYPES.TAG_VALUES:
            this.tableData = data;
            break;
        }

        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.tableData = [];
      }
    }

    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  formatDateTime(datetime: Date): string {
    const year = datetime.getFullYear();
    const month = String(datetime.getMonth() + 1).padStart(2, '0');
    const day = String(datetime.getDate()).padStart(2, '0');
    const hours = String(datetime.getHours()).padStart(2, '0');
    const minutes = String(datetime.getMinutes()).padStart(2, '0');
    const seconds = String(datetime.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}, ${hours}:${minutes}:${seconds}`;
  }

  formatDataDesignValues(dataDesignValues: DataDesignValue[]): any {
    return dataDesignValues.map((each: any) => {
      const valuesObject = each.values.reduce((acc: any, value: any) => {
        acc[value.field_id] = value.value;
        return acc;
      }, {});

      valuesObject._id = each._id;
      return valuesObject;
    });
  }

  async refreshDataTable(): Promise<void> {
    this.isRefreshing = true;

    switch (this.type) {
      case TYPES.DATA_DESIGN:
        this.dataDesignValues = await this.getData();
        this.tableData = this.formatDataDesignValues(this.dataDesignValues);
        break;
      case TYPES.TAG_VALUES:
        this.tableData = [...this.tableData, ...(await this.getData())];
        break;
    }

    this.isRefreshing = false;
    this.isRefreshed = true;
    setTimeout(() => {
      this.isRefreshed = false;
    }, 5000);
  }

  async getData(): Promise<any> {
    switch (this.type) {
      case TYPES.DATA_DESIGN:
        return this.datadesignServ.findAllValues(this.dataDesign._id);
      case TYPES.TAG_VALUES:
        /**
         * Get only new data compared to
         * the last time the data was loaded.
         */
        this.datetime_min =
          this.datetime_max > 0
            ? this.datetime_max
            : new Date().getTime() -
              Number(this.tag.parameters.histsecsbeforeconnect) * 1000;
        this.datetime_max = new Date().getTime();

        if (Number(this.tag.parameters.histsecsbeforeconnect) <= 0) {
          this.datetime_min = 0;
          this.tag.parameters.histsecsbeforeconnect = 1;
        }

        const query: any = {
          tagkey: this.tagkey,
          datetime_min: this.datetime_min,
          datetime_sort: 'desc',
        };

        const stringParams = new URLSearchParams(
          query as Record<string, string>
        ).toString();

        const data: any = await this.http
          .get(`${this.BASE_URL}/v1/api/tagValues?${stringParams}`)
          .toPromise();

        return data;
    }
  }

  async formSubmit() {
    if (this.isEditing) {
      const formData = this.addValueForm.value;

      for (const fieldKey of Object.keys(formData)) {
        if (formData[fieldKey] == null || formData[fieldKey] === '') {
          delete formData[fieldKey];
        } else {
          const field = this.dataDesign.fields.find(
            (field) => field._id === fieldKey
          );
          if (field?.type === 'datetime-local') {
            formData[fieldKey] = new Date(formData[fieldKey]).toISOString();
          }
        }
      }

      const editedValues: any = {
        ...this.tableElement.selection.selected[0],
        ...formData,
      };

      const dataDesignValue = this.dataDesignValues.find(
        (each) => each._id === editedValues._id
      );

      if (dataDesignValue != null) {
        const { _id, ...valuesToUpdate } = editedValues;

        dataDesignValue.values = Object.keys(valuesToUpdate).map(
          (fieldKey) => ({
            field_id: fieldKey,
            value: valuesToUpdate[fieldKey],
          })
        );

        await this.editField(dataDesignValue);
      }
    } else {
      const values: any = [];
      const formData = this.addValueForm.value;

      for (const fieldKey of Object.keys(formData)) {
        if (formData[fieldKey] == null || formData[fieldKey] === '') {
          formData[fieldKey] = false;
        }

        const field = this.dataDesign.fields.find(
          (each: Field) => each._id === fieldKey
        );

        if (field?.type === 'datetime-local') {
          formData[fieldKey] = new Date(formData[fieldKey]).toISOString();
        }

        values.push({
          field_id: field?._id,
          value: formData[fieldKey],
        });
      }

      const newDataDesignValue: Omit<DataDesignValue, '_id'> = {
        datadesign_id: this.dataDesign._id,
        values,
      };

      await this.createField(newDataDesignValue);
    }

    this.valueDialogOpen = false;
    this.isEditing = false;
    this.cdr.detectChanges();
  }

  async createField(newValue: Omit<DataDesignValue, '_id'>): Promise<void> {
    const newDataDesignValue = await this.datadesignServ.createValue(newValue);

    this.dataDesignValues.push(newDataDesignValue.data);

    this.tableData = [
      ...this.tableData,
      ...this.formatDataDesignValues([newDataDesignValue.data]),
    ];
  }

  async editField(dataDesignValue: DataDesignValue): Promise<void> {
    const { _id } = dataDesignValue;
    const valueIndex = this.dataDesignValues.findIndex(
      (each: any) => each._id === _id
    );

    if (valueIndex === -1) {
      console.error('DataDesign Value not found');
      return;
    }

    const { data: updatedValue } = await this.datadesignServ.updateValue(
      dataDesignValue
    );

    this.dataDesignValues[valueIndex] = updatedValue;

    const tableDataAux = [...this.tableData];
    tableDataAux[valueIndex] = this.formatDataDesignValues([updatedValue])[0];

    this.tableData = [...tableDataAux];
  }

  async deleteValues(values: any[]) {
    const promises: Promise<any>[] = [];
    const indexToDelete: number[] = [];

    for (const { _id } of values) {
      const valueIndex = this.dataDesignValues.findIndex(
        (each: any) => each._id === _id
      );

      if (valueIndex === -1) {
        console.error('DataDesign Value not found');
        return;
      }

      indexToDelete.push(valueIndex);

      promises.push(this.datadesignServ.deleteValue(_id));
    }

    await Promise.all(promises);

    for (let i = 0; i < indexToDelete.length; i++) {
      this.dataDesignValues.splice(indexToDelete[i] - i, 1);
    }

    this.tableData = this.formatDataDesignValues(this.dataDesignValues);
  }

  applyFilter() {
    let value;
    switch (this.type) {
      case TYPES.DATA_DESIGN:
        value = this.searchText;
        break;
      case TYPES.TAG_VALUES:
        const valueObj = {
          text: this.searchText,
          fromDate: this.fromDate,
          toDate: this.toDate,
        };

        value = JSON.stringify(valueObj);
        break;
    }

    this.filterValue = value;
  }

  filterDate = (data: any, filter: string): boolean => {
    const filterObj = JSON.parse(filter);

    let isValidDate = true;
    let isValidText;

    if (filterObj?.fromDate && filterObj?.toDate) {
      isValidDate =
        data.datetime >= filterObj.fromDate &&
        data.datetime <= filterObj.toDate;
    } else if (filterObj?.fromDate) {
      isValidDate = data.datetime >= filterObj.fromDate;
    } else if (filterObj?.toDate) {
      isValidDate = data.datetime <= filterObj.toDate;
    }

    if (!isValidDate) return false;

    if (filterObj?.text != null) {
      const dataStr = this.tableHeaders
        .reduce((accumulator: any, each: any) => {
          const value = data?.values[each.key.split('.')[1]];

          if (value != null) {
            return accumulator + value + '◬';
          }

          return accumulator;
        })
        .toLowerCase();
      const transformedFilter = filterObj.text.trim().toLowerCase();
      isValidText = dataStr.indexOf(transformedFilter) != -1;
    }

    return isValidText ?? isValidDate;
  };

  clearSearch(): void {
    this.filter.reset();
    this.cdr.detectChanges();
  }

  addDataDesignValue() {
    return null;
  }

  @HostBinding('refresh') refreshFunction = this.refreshDataTable.bind(this);
  @HostBinding('clearSearch') clearSearchFunction = this.clearSearch.bind(this);
}
