<div class="grid-button" routerLink="/modelizer">
  <div class="grid-button-img">
    <img src="assets/img/dashboard/modelizer.svg" />
  </div>
  <div class="grid-button-title">{{ dashboardText?.modeler }}</div>
</div>
<div class="grid-button" routerLink="/synopticsnew">
  <div class="grid-button-img">
    <img src="assets/img/dashboard/synoptic.svg" />
  </div>
  <div class="grid-button-title">{{ dashboardText?.synoptics }}</div>
</div>
<div class="grid-button" routerLink="/reportsV2">
  <div class="grid-button-img">
    <img src="assets/img/dashboard/new-project.svg" />
  </div>
  <div class="grid-button-title">{{ dashboardText?.reports }}</div>
</div>
<div class="grid-button" routerLink="/scripts">
  <div class="grid-button-img" class="grid-button-img">
    <img src="assets/img/dashboard/script.svg" />
  </div>
  <div class="grid-button-title">{{ dashboardText?.scripts }}</div>
</div>
<div class="grid-button" routerLink="/administration">
  <div class="grid-button-img">
    <img src="assets/img/dashboard/users.svg" />
  </div>
  <div class="grid-button-title">{{ dashboardText?.user_management }}</div>
</div>
<div class="grid-button" routerLink="/datadesign">
  <div class="grid-button-img">
    <img src="assets/img/dashboard/datadesign.svg" />
  </div>
  <div class="grid-button-title">{{ dashboardText?.datadesign }}</div>
</div>
<div class="grid-button" routerLink="/alarms">
  <div class="grid-button-img">
    <img src="assets/img/dashboard/alarm.svg" />
  </div>
  <div class="grid-button-title">{{ dashboardText?.alarms }}</div>
</div>
<div class="grid-button" routerLink="/settings/system">
  <div class="grid-button-img">
    <img src=" assets/img/dashboard/settings.svg" />
  </div>
  <div class="grid-button-title">{{ dashboardText?.settings }}</div>
</div>
