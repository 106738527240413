<div class="container-fluid">
  <div class="row">
    <div class="col-12 mb-5">
      <!-- Header -->
      <div class="row header-fixed">
        <div id="headBar" class="col-12 img-container bo1 d-flex">
          <a id="headLogo" class="logo-link" style="user-select: none; cursor: pointer;" (click)="goHome($event)">
            <h2 class="logo px-4">
              <span><strong>LOGi</strong></span>Platform
            </h2>
          </a>
          <button class="ml-5 openbtn" (click)="helpDoc()">?</button>
          <span id="headSpanCenter" class="headSpanCenter">
            <button *ngIf="syn_vars_SynopticId" id="closeItem" class="material-icons bott" style="margin-right: 24px;"
              (click)="syn_func_closeItem()">cancel<span
                style="vertical-align:middle; font-size: small; font-family: Arial, Helvetica, sans-serif;">{{ syn_vars_language?.close }}</span></button>
            <button [style.background]="syn_vars_a4 == 'portrait' ? syn_vars_a4_color : ''" id="a4_portrait"
              [style.display]="syn_vars_isType === 'report' ? '' : 'none'" [disabled]="syn_vars_SynopticId == ''"
              (click)="syn_func_a4_report('portrait')" onfocus="this.blur();"
              class="material-icons bott">crop_portrait</button>
            <button [style.background]="syn_vars_a4 == 'landscape' ? syn_vars_a4_color : ''" id="a4_landscape"
              [style.display]="syn_vars_isType === 'report' ? '' : 'none'" [disabled]="syn_vars_SynopticId == ''"
              (click)="syn_func_a4_report('landscape')" onfocus="this.blur();"
              class="material-icons bott">crop_landscape</button>
            <button [style.display]="syn_vars_isType === 'report' ? '' : 'none'" [disabled]="syn_vars_SynopticId == ''"
              (click)="syn_func_hb_playSynoptic()" onfocus="this.blur();" class="material-icons bott">print</button>
            <button [style.display]="syn_vars_isType === 'synoptic' ? '' : 'none'"
              [disabled]="syn_vars_SynopticId == ''" (click)="syn_func_hb_playSynoptic()" onfocus="this.blur();"
              class="material-icons bott">play_arrow</button>
            <button style="display: none;" onfocus="this.blur();" class="material-icons bott">open_with</button>
            <button onfocus="this.blur();" class="material-icons bott">zoom_out_map</button>
            <button [disabled]="syn_vars_SynopticId == ''" class="material-icons bott" onfocus="this.blur();"
              (click)="syn_func_hb_alignLeft()">align_horizontal_left</button>
            <button [disabled]="syn_vars_SynopticId == ''" class="material-icons bott" onfocus="this.blur();"
              (click)="syn_func_hb_alignRight()">align_horizontal_right</button>
            <button [disabled]="syn_vars_SynopticId == ''" class="material-icons bott" onfocus="this.blur();"
              (click)="syn_func_hb_alignTop()">align_vertical_top</button>
            <button [disabled]="syn_vars_SynopticId == ''" class="material-icons bott" onfocus="this.blur();"
              (click)="syn_func_hb_alignBottom()">align_vertical_bottom</button>
            <button [disabled]="syn_vars_SynopticId == ''" class="material-icons bott" onfocus="this.blur();"
              (click)="syn_func_hb_sameHeight()">height</button>
            <button [disabled]="syn_vars_SynopticId == ''" class="material-icons bott" onfocus="this.blur();"
              (click)="syn_func_hb_sameWidth()"><img [style.opacity]=" syn_vars_SynopticId == '' ? '0.5':'1' "
                style="margin-top: -5px; width: 20px; height: 20px; transform: rotate(90deg);"
                src="assets/img/synoptics/width.png"></button>
            &nbsp;&nbsp;
            <input [disabled]="syn_vars_SynopticId == ''" id="distHor" type="text" size="3" style="width: 60px;"
              [(ngModel)]="syn_vars_distHorInput" />
            <button [disabled]="syn_vars_SynopticId == ''"
              [style.display]="syn_vars_gridPossible && ( !syn_func_isNaN(syn_vars_distHorInput) && !syn_func_isNaN(syn_vars_distVerInput) ) ? '' : 'none'"
              class="material-icons bott" onfocus="this.blur();" (click)="syn_func_hb_distGrid()">grid_view</button>
            <button [disabled]="syn_vars_SynopticId == ''"
              [style.display]="syn_vars_gridPossible && ( !syn_func_isNaN(syn_vars_distHorInput) && !syn_func_isNaN(syn_vars_distVerInput)) ? 'none' : ''"
              class="material-icons bott" onfocus="this.blur();"
              (click)="syn_func_hb_distHorizontal()">horizontal_distribute</button>
            <input [disabled]="syn_vars_SynopticId == ''" id="distVer" type="text" size="3" style="width: 60px;"
              [(ngModel)]="syn_vars_distVerInput" />
            <button [disabled]="syn_vars_SynopticId == ''"
              [style.visibility]="syn_vars_gridPossible && ( !syn_func_isNaN(syn_vars_distHorInput) && !syn_func_isNaN(syn_vars_distVerInput) ) ? 'hidden' : ''"
              class="material-icons bott" onfocus="this.blur();"
              (click)="syn_func_hb_distVertical()">vertical_distribute</button>
            &nbsp;&nbsp;
            <button [disabled]="syn_vars_SynopticId == '' || syn_vars_undo_actual === -1" class="material-icons bott"
              onfocus="this.blur();" (click)="syn_func_hb_undo()">undo</button>
            <button [disabled]="syn_vars_SynopticId == '' || syn_vars_undo_actual === syn_vars_undo_total"
              class="material-icons bott" onfocus="this.blur();" (click)="syn_func_hb_redo()">redo</button>
            &nbsp;&nbsp;
            <input [disabled]="syn_vars_SynopticId == '' || syn_vars_zoom_auto " type="range"
              [(ngModel)]="syn_vars_zoom" class="zoomI" min="1" max="100" id="zoom"
              (change)="syn_func_zoom($event.target)">
            <span class="zoomTx">zoom:{{ syn_vars_zoom }}%<label>&nbsp;&nbsp;Auto:</label><input
                [(ngModel)]="syn_vars_zoom_auto" (click)="syn_func_zoom_auto($event.target)" type="checkbox"></span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="container-fluid">
  <div class="row">
    <div id="sidebarDiv">
      <div style="height: auto; width: 10px;">
        <ng-container *ngFor="let button of syn_vars_buttonsLeft">
          <button [ngStyle]="{ display: button.visible ? '' : 'none' }" id="{{ button.id }}" class="buttLeft"
            (click)="syn_func_leftSection($event.target.id)" [ngClass]="{
                    'pushed': syn_vars_buttonsLeftSelecc === button.id }" onfocus="this.blur();">
            {{ button.text }}
          </button>
        </ng-container>
      </div>
      <div class="divLeftSynoptic col-2 pt-2" id="divLeftSynoptic">
        <ng-container *ngFor="let button of syn_vars_buttonsLeft">
          <div [id]="'tree' + button.id"></div>
        </ng-container>
      </div>
    </div>
    <div style="z-Index: 1; width: 100%; padding: 0px; height: 100%; overflow: auto;" id="main"
      [style.background]="syn_vars_backcolor" [contextMenu]="menuDiv" [contextMenuSubject]="syn_vars_divMask">
      <app-synoptic-core></app-synoptic-core>
      <svg *ngIf="syn_vars_divMask.alignShow !== 0" id="svgLine" style="position: absolute" [attr.width]="
          syn_vars_divMask.alignX1 === syn_vars_divMask.alignX2
            ? 2
            : syn_vars_divMask.alignX1 < syn_vars_divMask.alignX2
            ? syn_vars_divMask.alignX2 - syn_vars_divMask.alignX1
            : syn_vars_divMask.alignX1 - syn_vars_divMask.alignX2
        " [attr.height]="
          syn_vars_divMask.alignY1 === syn_vars_divMask.alignY2
            ? 2
            : syn_vars_divMask.alignY1 < syn_vars_divMask.alignY2
            ? syn_vars_divMask.alignY2 - syn_vars_divMask.alignY1
            : syn_vars_divMask.alignY1 - syn_vars_divMask.alignY2
        " [style.left]="syn_vars_divMask.alignX1 + 'px'" [style.top]="syn_vars_divMask.alignY1 + 'px'"
        style="position: absolute">
        <line x1="0" y1="0" [attr.x2]="
            syn_vars_divMask.alignX1 === syn_vars_divMask.alignX2
              ? 0
              : syn_vars_divMask.alignX2 - syn_vars_divMask.alignX1
          " [attr.y2]="
            syn_vars_divMask.alignY1 === syn_vars_divMask.alignY2
              ? 0
              : syn_vars_divMask.alignY2 - syn_vars_divMask.alignY1
          " style="stroke: rgb(255, 0, 0); stroke-width: 2" />
        Sorry, your browser does not support inline SVG.
      </svg>

      <div *ngIf="syn_vars_divMask.resize1 || syn_vars_divMask.resize" (mousedown)="syn_func_resizeOn(1, $event)"
        class="divresizes" [style.left]="syn_func_parseInt(syn_vars_itemSelecteds[0].left) - 8 + 'px'"
        [style.top]="syn_func_parseInt(syn_vars_itemSelecteds[0].top) - 8 + 'px'">
      </div>

      <div *ngIf="syn_vars_divMask.resize2 || syn_vars_divMask.resize" (mousedown)="syn_func_resizeOn(2, $event)"
        class="divresizes"
        [style.left]="syn_func_parseInt(syn_vars_itemSelecteds[0].left) + syn_func_parseInt(syn_func_parseInt(syn_vars_itemSelecteds[0].width) / 2) - 4 + 'px' "
        [style.top]="syn_func_parseInt(syn_vars_itemSelecteds[0].top) - 8 + 'px'">
      </div>

      <div *ngIf="syn_vars_divMask.resize3 || syn_vars_divMask.resize" (mousedown)="syn_func_resizeOn(3, $event)"
        class="divresizes"
        [style.left]="syn_func_parseInt(syn_vars_itemSelecteds[0].left) + syn_func_parseInt(syn_vars_itemSelecteds[0].width) + 'px'"
        [style.top]="syn_func_parseInt(syn_vars_itemSelecteds[0].top) - 8 + 'px'">
      </div>

      <div *ngIf="syn_vars_divMask.resize4 || syn_vars_divMask.resize" (mousedown)="syn_func_resizeOn(4, $event)"
        class="divresizes"
        [style.top]="syn_func_parseInt(syn_vars_itemSelecteds[0].top) + syn_func_parseInt(syn_func_parseInt(syn_vars_itemSelecteds[0].height) / 2) - 4 +  'px'"
        [style.left]="syn_func_parseInt(syn_vars_itemSelecteds[0].left) - 8 + 'px'">

      </div>

      <div *ngIf="syn_vars_divMask.resize5 || syn_vars_divMask.resize" (mousedown)="syn_func_resizeOn(5, $event)"
        class="divresizes"
        [style.top]="syn_func_parseInt(syn_vars_itemSelecteds[0].top) + syn_func_parseInt(syn_func_parseInt(syn_vars_itemSelecteds[0].height) / 2) - 4 + 'px'"
        [style.left]="syn_func_parseInt(syn_vars_itemSelecteds[0].left) + syn_func_parseInt(syn_vars_itemSelecteds[0].width) + 'px'">
      </div>

      <div *ngIf="syn_vars_divMask.resize6 || syn_vars_divMask.resize" (mousedown)="syn_func_resizeOn(6, $event)"
        class="divresizes" [style.left]="syn_func_parseInt(syn_vars_itemSelecteds[0].left) - 8 + 'px'"
        [style.top]="syn_func_parseInt(syn_vars_itemSelecteds[0].top) + syn_func_parseInt(syn_vars_itemSelecteds[0].height) + 'px'">
      </div>

      <div *ngIf="syn_vars_divMask.resize7 || syn_vars_divMask.resize" (mousedown)="syn_func_resizeOn(7, $event)"
        class="divresizes"
        [style.left]="syn_func_parseInt(syn_vars_itemSelecteds[0].left) + syn_func_parseInt(syn_func_parseInt(syn_vars_itemSelecteds[0].width) / 2) - 4 + 'px'"
        [style.top]="syn_func_parseInt(syn_vars_itemSelecteds[0].top) + syn_func_parseInt(syn_vars_itemSelecteds[0].height) + 'px'">
      </div>

      <div *ngIf="syn_vars_divMask.resize8 || syn_vars_divMask.resize" (mousedown)="syn_func_resizeOn(8, $event)"
        class="divresizes"
        [style.left]="syn_func_parseInt(syn_vars_itemSelecteds[0].left) + syn_func_parseInt(syn_vars_itemSelecteds[0].width) + 'px'"
        [style.top]="syn_func_parseInt(syn_vars_itemSelecteds[0].top) + syn_func_parseInt(syn_vars_itemSelecteds[0].height) + 'px'">
      </div>

      <div class="divMaskTag" (click)="syn_func_clickInControl($event)" (mousedown)="syn_func_downInControl($event)"
        [contextMenu]="basicMenu" [contextMenuSubject]="syn_vars_divMask" [style.cursor]="syn_vars_divMask.moveMode"
        [style.left]="syn_vars_divMask.left" [style.top]="syn_vars_divMask.top" [style.width]="syn_vars_divMask.width"
        [style.height]="syn_vars_divMask.height" [ngStyle]="{ display: syn_vars_divMask.hidden ? 'none' : '' }"></div>
      <ng-container *ngFor="let item of syn_vars_itemSelecteds">
        <div class="divMaskSelect" [style.left]="item.left" [style.top]="item.top" [style.width]="item.width"
          [style.height]="item.height"></div>
      </ng-container>
      <ng-container *ngFor="let item of syn_vars_itemSelectedOthers">
        <div class="divMaskSelect" [style.left]="item.left" [style.top]="item.top" [style.width]="item.width"
          [style.height]="item.height"></div>
      </ng-container>
    </div>
  </div>
</div>
<div class="col-2">
  <app-optionsdyn_v2></app-optionsdyn_v2>
</div>

<!-- DSItem Modal -->
<div class="modal fade bd-modal-role" tabindex="-1" role="dialog" aria-labelledby="DataDesign Item" aria-hidden="true"
  id="DSitemForm">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="dsitemFormTitle">{{ syn_vars_language?.properties }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="modalDSitemForm">
        <app-optionsdynmodal></app-optionsdynmodal>
      </div>
    </div>
  </div>
</div>

<context-menu #basicMenu>
  <ng-template [visible]="syn_vars_itemFocus.context.delete" contextMenuItem (execute)="syn_func_menuItemRemove()">
    &nbsp;{{ syn_vars_language?.delete }}&nbsp;</ng-template>
  <ng-template [visible]="syn_vars_itemFocus.context.copy" contextMenuItem (execute)="syn_func_menuItemCopy()">
    &nbsp;{{ syn_vars_language?.copy }}&nbsp;</ng-template>
  <ng-template [visible]="syn_vars_itemFocus.context.syns[syn_vars_itemFocus.id] " contextMenuItem
    (execute)="syn_func_menuItemCopy()">&nbsp;Importar los items (se eliminará el vínculo)&nbsp;</ng-template>
</context-menu>

<context-menu #menuDiv>
  <ng-template id="mini" contextMenuItem [visible]="syn_vars_SynopticId !== ''" (view)="view($event)"
    (execute)="syn_func_menuFichaSynoptic()">&nbsp;{{ syn_vars_textoFichaxxx }}&nbsp;</ng-template>
  <ng-template contextMenuItem [visible]="syn_vars_SynopticId !== ''" [subMenu]="controls">&nbsp;{{ syn_vars_language?.insert_new_item }}&nbsp;
  </ng-template>
  <ng-template contextMenuItem [visible]="syn_vars_SynopticId !== ''" (execute)="syn_func_menuSelectAll()">
    &nbsp;{{ syn_vars_language?.select_all }}&nbsp;</ng-template>
  <ng-template contextMenuItem [visible]="syn_vars_contextSelects.length>0" (execute)="syn_func_menuItemPaste()">
    &nbsp;{{ syn_vars_language?.paste_items }}&nbsp;</ng-template>
</context-menu>
<context-menu #controls>
  <ng-container *ngFor="let ctrl of syn_vars_system?.controls">
    <ng-template
      [visible]="(ctrl.parameters?.report == true && this.syn_vars_isType == 'report') || (ctrl.parameters?.synoptic == true && this.syn_vars_isType == 'synoptic')"
      contextMenuItem (execute)="syn_func_addControl(ctrl.name )">&nbsp;
      {{ syn_vars_language[ctrl.name | lowercase] || ctrl.name }}&nbsp;
    </ng-template>
    </ng-container>
</context-menu>

<!-- Edit Role Modal -->

<!-- Modal delete item -->
<div class="modal fade bd-modal-deleteSyn mt-5" tabindex="-1" role="dialog" aria-labelledby="Eliminar item"
  aria-hidden="true" id="deleteSyn">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalDeleteItemTitle">
          {{ syn_vars_language?.confirmation }}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3>
          {{ syn_vars_language?.confirmation_message }}<span id="itemToDelete"></span> ?
        </h3>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ syn_vars_language?.close }}
        </button>
        <button type="button" class="btn btn-danger" (click)="syn_func_deleteSyn()">
          {{ syn_vars_language?.delete }}
        </button>
      </div>
    </div>
  </div>
</div>

<div style="position: absolute; display: none; left: 500px; top: 100px;">eo</div>
