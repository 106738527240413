import { FormGroup } from "@angular/forms";
import * as $ from 'jquery';

// ▲ ▼

class kbConfig {
    kbId: string;
    kbType: string;
    x: number;
    y: number;
    w: number;
    h: number;
    capsLock: boolean;
    touchControl: boolean;
    modal: boolean;
    modalDescription: string;
    keybInfo: any;
}

export class Keyboard {

    loginForm: [FormGroup];
    inputForm: HTMLElement;
    inputErrors: any;
    inputOrig: any;
    valueOld: any;

    delayFocusCnts: number = 0;
    delayFocusTime: any = 0;

    cvasKeyboard: HTMLElement;
    configKB: Array<string>;

    modalDiv: any;
    modalDesp: any;
    modalLoaded: boolean = false;

    tipoK: string = ""; //keyb2";
    cfg: string = 'keyb1,10,100,1450';

    p_clickDowns: any = [];
    p_clickUps: any = [];
    keyboard: any;
    capsLock: any;
    keyboardAct: any;
    keyboards: any = {
        "kb_standard": {
            "kb_Width": 1130,
            "kb_Height": 385,
            "font": "30px Arial",
            "xtext": 5,
            "ytext": 5,
            "margin": 10,
            "key_width": 70,
            "key_height": 70,
            "fonts": { "f1": "28px Verdana", "f2": "20px Verdana", "f3": "24px Verdana" },
            "sepx": 5,
            "sepy": 5,
            "zones": [
                {
                    "lines": [
                        [
                            {
                                "k": [{ "k": "", "x": 15, "y": 35 }, { "k": "ª", "x": 15, "y": 5 }, { "k": "\\", "x": 45, "y": 25 }]
                            },
                            {
                                "k": [{ "k": "1", "x": 20, "y": 30 }, { "k": "!", "x": 20, "y": -1 }, { "k": "|", "x": 50, "y": 25 }]
                            },
                            {
                                "k": [{ "k": "2", "x": 20, "y": 30 }, { "k": "\"", "x": 20, "y": -1 }, { "k": "@", "x": 50, "y": 25 }]
                            },
                            {
                                "k": [{ "k": "3", "x": 20, "y": 30 }, { "k": "·", "x": 20, "y": -1 }, { "k": "#", "x": 50, "y": 25 }]
                            },
                            {
                                "k": [{ "k": "4", "x": 20, "y": 30 }, { "k": "$", "x": 20, "y": -1 }, { "k": "~", "x": 50, "y": 25 }]
                            },
                            {
                                "k": [{ "k": "5", "x": 20, "y": 30 }, { "k": "%", "x": 20, "y": -1 }]
                            },
                            {
                                "k": [{ "k": "6", "x": 20, "y": 30 }, { "k": "&", "x": 20, "y": -1 }, { "k": "¬", "x": 50, "y": 25 }]
                            },
                            {
                                "k": [{ "k": "7", "x": 20, "y": 30 }, { "k": "/", "x": 20, "y": -1 }]
                            },
                            {
                                "k": [{ "k": "8", "x": 20, "y": 30 }, { "k": "(", "x": 20, "y": -1 }]
                            },
                            {
                                "k": [{ "k": "9", "x": 20, "y": 30 }, { "k": ")", "x": 20, "y": -1 }]
                            },
                            {
                                "k": [{ "k": "0", "x": 20, "y": 30 }, { "k": "=", "x": 20, "y": -1 }]
                            },
                            {
                                "k": [{ "k": "'", "x": 20, "y": 30 }, { "k": "?", "x": 20, "y": -1 }]
                            },
                            {
                                "k": [{ "k": "¡", "x": 20, "y": 25 }, { "k": "¿", "x": 20, "y": -5 }]
                            },
                            {
                                "k": [{ "k": "Retroceso", "x": 65, "y": 20, "font": "f2" }], "width_factor": 2
                            }
                        ],
                        [
                            {
                                "k": "", "width_factor": 1.5, "x": 40, "y": 15
                            },
                            {
                                "k": [{ "k": "Q", "font": "f1" }]
                            },
                            {
                                "k": "W"
                            },
                            {
                                "k": [{ "k": "E" }, { "k": "" }, { "k": "€", "x": 45, "y": 25 }]
                            },
                            {
                                "k": "R"
                            },
                            {
                                "k": "T"
                            },
                            {
                                "k": "Y"
                            },
                            {
                                "k": "U"
                            },
                            {
                                "k": "I"
                            },
                            {
                                "k": "O"
                            },
                            {
                                "k": "P"
                            }
                            ,
                            {
                                "k": [{ "k": "`", "x": 15, "y": 35 }, { "k": "^", "x": 15, "y": 5 }, { "k": "[", "x": 45, "y": 25 }]
                            },
                            {
                                "k": [{ "k": "+", "x": 15, "y": 35 }, { "k": "*", "x": 15, "y": 5 }, { "k": "]", "x": 45, "y": 25 }]
                            },
                            {
                                "k": "OK", "x": 50, "width_factor": 1.5, "y": 15, "exit": true, "ok": true
                            }
                        ],
                        [
                            {
                                "k": "Bloq.Mays", "width_factor": 1.8, "font": "f2", "x": 62, "y": 20
                            },
                            {
                                "k": "A"
                            },
                            {
                                "k": "S"
                            },
                            {
                                "k": "D"
                            },
                            {
                                "k": "F"
                            },
                            {
                                "k": "G"
                            },
                            {
                                "k": "H"
                            },
                            {
                                "k": "J"
                            },
                            {
                                "k": "K"
                            },
                            {
                                "k": "L"
                            },
                            {
                                "k": "Ñ"
                            }
                            ,
                            {
                                "k": [{ "k": "´", "x": 15, "y": 35 }, { "k": "¨", "x": 15, "y": 5 }, { "k": "{", "x": 45, "y": 25 }]
                            },
                            {
                                "k": [{ "k": "ç", "x": 15, "y": 25 }, { "k": "" }, { "k": "}", "x": 45, "y": 25 }]
                            },
                            {
                                "k": "Esc", "width_factor": 1.2, "x": 40, "y": 15, "exit": true
                            }
                        ],
                        [
                            {
                                "k": "Mays.", "width_factor": 1.2, "font": "f2", "x": 40, "y": 15
                            },
                            {
                                "k": [{ "k": "<", "x": 20, "y": 30 }, { "k": ">", "x": 20, "y": -1 }]
                            },
                            {
                                "k": "Z"
                            },
                            {
                                "k": "X"
                            },
                            {
                                "k": "C"
                            },
                            {
                                "k": "V"
                            },
                            {
                                "k": "B"
                            },
                            {
                                "k": "N"
                            },
                            {
                                "k": "M"
                            },
                            {
                                "k": [{ "k": ",", "x": 20, "y": 25 }, { "k": ";", "x": 20, "y": -5 }]
                            },
                            {
                                "k": [{ "k": ".", "x": 20, "y": 25 }, { "k": ":", "x": 20, "y": -5 }]
                            },
                            {
                                "k": [{ "k": "-", "x": 20, "y": 30 }, { "k": "_", "x": 20, "y": -10 }]
                            }
                            ,
                            {
                                "k": "Mays.", "width_factor": 2.88, "x": 60, "y": 10
                            }
                        ],
                        [
                            {
                                "k": "$"
                            },
                            {
                                "k": "€"
                            },
                            {
                                "k": "&"
                            },
                            {
                                "k": " ", "width_factor": 7.28
                            },
                            {
                                "k": "Alt Gr", "width_factor": 2.15, "x": 50, "y": 15
                            },
                            {
                                "k": "◄", "y": 15, "x": 30
                            },
                            {
                                "k": "►", "y": 15, "x": 30
                            },
                            { "k": "Supr", "font": "f2", "x": 35, "y": 15 },
                        ]
                    ]
                }
            ]
        }, "kb_numeric": {
            "kb_Width": 385,
            "kb_Height": 385,
            "font": "30px Arial",
            "xtext": 5,
            "ytext": 5,
            "margin": 10,
            "key_width": 70,
            "key_height": 70,
            "fonts": { "f1": "28px Verdana", "f2": "20px Verdana", "f3": "24px Verdana" },
            "sepx": 5,
            "sepy": 5,
            "zones": [

                {
                    "lines":
                        [
                            [

                                { "k": "=" },
                                { "k": "/" },
                                { "k": "*" },
                                { "k": "-" },
                                { "k": "+" }
                            ],
                            [
                                { "k": "7" },
                                { "k": "8" },
                                { "k": "9" },
                                { "k": "Supr", "font": "f2", "x": 35, "y": 15 },
                                { "k": "Back", "font": "f2", "x": 35, "y": 15 },
                            ],
                            [
                                { "k": "4" },
                                { "k": "5" },
                                { "k": "6" },
                                { "k": "OK", "font": "f1", "x": 40, "y": 15, "width_factor": 2.08, "exit": true, "ok": true }
                            ],
                            [
                                { "k": "1" },
                                { "k": "2" },
                                { "k": "3" },
                                { "k": "CANCEL", "font": "f3", "x": 70, "y": 15, "width_factor": 2.08, "exit": true }
                            ],
                            [
                                { "k": "0", "width_factor": 2.08 },
                                { "k": "." },
                                { "k": "◄", "y": 15, "x": 30 },
                                { "k": "►", "y": 15, "x": 30 }
                            ]
                        ]
                }]
        }
    };

    config: kbConfig;

    width: any;
    timeOutBlur: any;
    time_getUps: any;

    constructor(form, cfg: kbConfig) {

        this.loginForm = form;

        this.keyboardAct = { on: false };

        this.configKB = [];
        try {
            // this.configKB = cfg.kbType;
        } catch (error) {
        }

        this.config = cfg;
        this.config.keybInfo.keyb = this;

        this.deleteElement(this.config.kbId);
        this.deleteElement('butPress');
        this.deleteElement('butUnPress');

        this.cvasKeyboard = document.createElement("canvas");
        this.cvasKeyboard.id = this.config.kbId;
        this.cvasKeyboard.setAttribute("in", "0");

        this.cvasKeyboard.addEventListener("mouseout", function () { this.setAttribute("in", "0"); });
        this.cvasKeyboard.addEventListener('mousedown', function () { this.setAttribute("in", "1"); });
        this.cvasKeyboard.addEventListener('mouseup', function () { this.setAttribute("in", "0"); });

        this.cvasKeyboard.style.position = 'absolute';
        this.cvasKeyboard.style.borderRadius = '20px';
        // this.cvasKeyboard.style.padding = '10px';
        this.cvasKeyboard.style.borderStyle = 'solid';
        this.cvasKeyboard.style.borderWidth = '2px';
        this.cvasKeyboard.style.borderColor = 'gray';

        this.cvasKeyboard.style.backgroundColor = 'silver';
        this.cvasKeyboard.style.display = 'none';
        this.cvasKeyboard['width'] = this.keyboards[cfg.kbType].kb_Width; //"1130";
        this.cvasKeyboard['height'] = this.keyboards[cfg.kbType].kb_Height; //"585";
        this.width = parseInt(this.keyboards[cfg.kbType].kb_Width);

        const butPress: HTMLElement = document.createElement("img");
        butPress.id = 'butPress';
        butPress.style.display = 'none';
        butPress.style.width = '100px';
        butPress.style.height = '100px';
        butPress['src'] = 'assets/img/keyboard/bot_pres.png';

        const butUnPress: HTMLElement = document.createElement("img");
        butUnPress.id = 'butUnPress';
        butUnPress.style.display = 'none';
        butUnPress.style.width = '100px';
        butUnPress.style.height = '100px';
        butUnPress['src'] = 'assets/img/keyboard/bot_unpres.png';

        if (this.config.modal) {
            var modd = document.getElementById('keyboardModal');
            modd.appendChild(this.cvasKeyboard);
        } else {
            document.body.appendChild(this.cvasKeyboard);
        }
        //
        document.body.appendChild(butPress);
        document.body.appendChild(butUnPress);

        document.getElementById('modalInput').onkeydown = this.eventKey.bind(this);

    }

    deleteElement(idEl) {
        try {
            const elem = document.getElementById(idEl);
            elem.parentNode.removeChild(elem);
        } catch (error) {
        }
    }

    addEventListener(obj, event, func) {
        if (obj.getAttribute('el_' + event) === null) {
            obj.setAttribute('el_' + event, true);
            obj.addEventListener(event, func, true);
        }
    }

    fieldEevents(fieldId) {
        console.log(fieldId);

        if (navigator.maxTouchPoints > 0 || this.config.touchControl !== true) {
            // this.keyboard.addEventListener('mousedown', this.getDowns.bind(this), true);
            // this.keyboard.addEventListener('mouseup', this.getUps.bind(this), true);

            this.addEventListener(document.getElementById(fieldId), 'focus', this.eventFocus.bind(this));
            /// document.getElementById(fieldId).addEventListener('focus', this.eventFocus.bind(this), true);

            this.addEventListener(document.getElementById(fieldId), 'blur', this.eventBlur.bind(this));
            /// document.getElementById(fieldId).addEventListener('blur', this.eventBlur.bind(this), true);
        }

    }

    events() {


        if (navigator.maxTouchPoints > 0 || this.config.touchControl !== true) {

            this.addEventListener(this.keyboard, 'mousedown', this.getDowns.bind(this));
            /// this.keyboard.addEventListener('mousedown', this.getDowns.bind(this), true);

            this.addEventListener(this.keyboard, 'mouseup', this.getUps.bind(this));
            /// this.keyboard.addEventListener('mouseup', this.getUps.bind(this), true);

            // window.addEventListener('focus', this.eventFocus.bind(this), true);
            // window.addEventListener('blur', this.eventBlur.bind(this), true);
        }

    }

    eventKey(event) {
        if (event.keyCode === 27) {
            try {

                this.keyboardAct['on'] = false;

                try {
                    this.keyboards[this.config.kbType].input_active.blur();
                } catch (error) {
                }

                this.cvasKeyboard.style.display = 'none';
                this.modalLoaded = false;
                try {
                    clearTimeout(this.time_getUps);
                } catch (error) {
                }
            } catch (error) {
                console.log(error);
            }

        }
    }

    eventFocus(event) {
        if (this.cvasKeyboard.style.display === 'none') {
            this.valueOld = event.target.value;
            this.inputOrig = event.target;

            this.config.modalDescription = event.target.getAttribute('description');
            try {
                document.getElementById('modalDescription').innerHTML = this.config.modalDescription;
            } catch (error) {
            }

        }

        // this.inputForm = event.target;
        this.inputErrors = {};

        this.loginForm.forEach(lForm => {
            try {
                if (lForm.controls[this.inputForm['id']].errors) {
                    this.inputErrors = lForm.controls[this.inputForm['id']].errors;
                }
            } catch (error) {
            }
        });

        const tags = location.href.split('/');
        const _window = tags[tags.length - 1];

        let toFind = '';
        try {
            if (event.target.id) {
                toFind = '_id_' + event.target.id;
            } else {
                if (event.target['name']) {
                    toFind = '_name_' + event.target['name'];
                } else {
                    if (event.target['type']) {
                        toFind = '_type_' + event.target['type'];
                    }
                }
            }
        } catch (error) {
        }

        this.inputfocus(event.target, this.config.x, this.config.y, this.config.w);
        if (toFind !== '' && false) {
            toFind = _window + toFind;

            const found: any = this.cfg; //'keyb,10,200,1300';

            if (found) {
                const tags = found.split(',');

                if (tags.length === 5) { //es un modal, voy a colgar el keyboad del modal
                    const mdl = document.getElementById(tags[4]);
                    this.modalDiv = mdl;

                    try {
                        mdl.removeChild(this.keyboard);
                    } catch (error) {
                    }

                    try {
                        document.body.removeChild(this.keyboard);
                    } catch (error) {
                    }
                    mdl.appendChild(this.keyboard);

                    // this.inputfocus(event.target, parseInt(tags[1]), parseInt(tags[2]), parseInt(tags[3]));
                    this.inputfocus(event.target, this.config.x, this.config.y, this.config.w);
                } else {
                    try {
                        document.body.removeChild(this.keyboard);
                    } catch (error) {
                    }

                    try {
                        this.modalDiv.removeChild(this.keyboard);
                    } catch (error) {
                    }
                    this.modalDiv = false;

                    document.body.appendChild(this.keyboard);
                    // this.inputfocus(event.target, parseInt(tags[1]), parseInt(tags[2]), parseInt(tags[3]));
                    this.inputfocus(event.target, this.config.x, this.config.y, this.config.w);
                }
            }
        }
    }

    eventBlur(event) {

        if (this.cvasKeyboard.getAttribute("in") === '0') {
            this.inputblur(event.target);
        }
    }

    change() {
        const obj = { keyboard: this.keyboards[this.config.kbType] };
        const evv = "lp_event(obj);";
        try {
            eval(evv);
        } catch (error) {
        }
    }

    getDown(event, delay) {
        if (delay) {
            this.delayFocusCnts = this.delayFocusCnts - 1;
        }
        const factor = this.width / parseInt(this.keyboard.style.width);

        let despX = this.keyboard.offsetParent.offsetLeft + this.keyboard.offsetLeft;
        let despY = this.keyboard.offsetParent.offsetTop + this.keyboard.offsetTop;

        if (this.modalDiv) {
            despX = this.keyboard.offsetLeft + this.modalDesp.x;
            despY = this.keyboard.offsetTop + this.modalDesp.y;
        }

        const gMP: any = { x: (event.clientX - despX) * factor, y: (event.clientY - despY) * factor };
        const cvas: any = document.getElementById(this.config.kbId);

        //vamos a repasar si hemos picado en algún objeto con click mapeado
        for (var np = 0; np < this.p_clickDowns.length; np++) {
            //lert(np);
            if (gMP.x >= this.p_clickDowns[np].x1 && gMP.x <= this.p_clickDowns[np].x2 && gMP.y >= this.p_clickDowns[np].y1 && gMP.y <= this.p_clickDowns[np].y2) {
                const cvas: any = document.getElementById(this.config.kbId);
                const _ctx = cvas.getContext('2d');

                if (this.keyboards[this.config.kbType].active) {
                    this.keyboards[this.config.kbType].input_time_down = new Date().getTime();

                    var gD_x1 = parseInt(this.p_clickDowns[np].x1);
                    var gD_x2 = parseInt(this.p_clickDowns[np].x2);
                    var gD_y1 = parseInt(this.p_clickDowns[np].y1);
                    var gD_y2 = parseInt(this.p_clickDowns[np].y2);
                    var gD_w = gD_x2 - gD_x1;
                    var gD_h = gD_y2 - gD_y1;

                    var ctx = _ctx;

                    if (delay === false) {
                        ctx.beginPath();
                        ctx.rect(gD_x1, gD_y1, gD_w, gD_h);
                        ctx.fillStyle = "#434343";
                        ctx.fill();
                        ctx.drawImage(this.p_clickDowns[np].imgg, gD_x1, gD_y1, gD_w, gD_h);
                    }

                    var texts = this.p_clickDowns[np].texts;

                    gD_x1 += 2;
                    gD_y1 += 2;

                    var ntKey = 0; //0=nada, 1=mays, 2=altgr
                    if (this.keyboards[this.config.kbType].input_mays)
                        ntKey = 1;

                    if (this.keyboards[this.config.kbType].input_altgr)
                        ntKey = 2;

                    for (var nt = 0; nt < texts.length; nt++) {
                        if (nt === ntKey || (ntKey === 1 && texts.length === 1)) {
                            const keyPress = { kbKeyPress: texts[nt].k };
                            const evv = "lp_event(keyPress);";
                            try {
                                eval(evv);
                            } catch (error) {
                            }
                            switch (texts[nt].k) {
                                case "▲":

                                    break;
                                case "▼":

                                    break;
                                case "◄":
                                    if (texts.length == 1) {
                                        if (this.keyboards[this.config.kbType].input_active.selectionStart > 0) {
                                            this.keyboards[this.config.kbType].input_active.selectionStart--;
                                            this.keyboards[this.config.kbType].input_active.selectionEnd = this.keyboards[this.config.kbType].input_active.selectionStart;
                                        }
                                    }
                                    break;
                                case "►":
                                    if (texts.length == 1) {
                                        if (this.keyboards[this.config.kbType].input_active.selectionStart < this.keyboards[this.config.kbType].input_active.value.length) {
                                            this.keyboards[this.config.kbType].input_active.selectionStart++;
                                            this.keyboards[this.config.kbType].input_active.selectionEnd = this.keyboards[this.config.kbType].input_active.selectionStart;
                                        }
                                    }
                                    break;
                                case "Back":
                                case "Retroceso":
                                    if (this.keyboards[this.config.kbType].input_active.selectionStart > 0) {
                                        var selStart = this.keyboards[this.config.kbType].input_active.selectionStart;

                                        var val1 = this.keyboards[this.config.kbType].input_active.value.substring(0, selStart - 1);
                                        var val2 = this.keyboards[this.config.kbType].input_active.value.substring(this.keyboards[this.config.kbType].input_active.selectionStart);
                                        this.keyboards[this.config.kbType].input_active.value = val1 + val2;
                                        this.change();

                                        if (selStart <= this.keyboards[this.config.kbType].input_active.value.length) {
                                            this.keyboards[this.config.kbType].input_active.selectionStart = selStart - 1;
                                            this.keyboards[this.config.kbType].input_active.selectionEnd = this.keyboards[this.config.kbType].input_active.selectionStart;
                                        }
                                    }
                                    break;
                                case "Supr":
                                    if (this.keyboards[this.config.kbType].input_active.selectionStart < this.keyboards[this.config.kbType].input_active.value.length) {
                                        var selStart = this.keyboards[this.config.kbType].input_active.selectionStart;

                                        var val1 = this.keyboards[this.config.kbType].input_active.value.substring(0, selStart);
                                        var val2 = this.keyboards[this.config.kbType].input_active.value.substring(this.keyboards[this.config.kbType].input_active.selectionStart + 1);
                                        this.keyboards[this.config.kbType].input_active.value = val1 + val2;
                                        this.change();

                                        this.keyboards[this.config.kbType].input_active.selectionStart = selStart;
                                        this.keyboards[this.config.kbType].input_active.selectionEnd = this.keyboards[this.config.kbType].input_active.selectionStart;
                                    }
                                    break;
                                case "OK":
                                case "Entrar":

                                    break;
                                case "Mays.":

                                    break;
                                case "Bloq.Mays":
                                    break;
                                case "Alt Gr":

                                    break;
                                case "CANCEL":
                                case "Esc":
                                    // this.keyboards[this.config.kbType].input_active.value = this.keyboards[this.config.kbType].input_value;
                                    break;
                                default:
                                    var myField = this.keyboards[this.config.kbType].input_active;
                                    let maxlength = 1000;
                                    try {
                                        maxlength = parseInt(this.inputOrig.getAttribute("maxlength"));
                                        if (isNaN(maxlength)) {
                                            maxlength = 1000;
                                        }
                                    } catch (error) {

                                    }
                                    if (myField.value.length < maxlength) {
                                        var myValue = texts[nt].k;

                                        if (!this.config.capsLock && texts[nt].k.length === 1) {
                                            myValue = myValue.toLowerCase();
                                        }

                                        if (ntKey === 1 && !this.config.capsLock) {
                                            myValue = myValue.toUpperCase();
                                        }

                                        if (ntKey === 1 && this.config.capsLock) {
                                            myValue = myValue.toLowerCase();
                                        }

                                        if (myField.selectionStart || myField.selectionStart == '0') {
                                            var startPos = myField.selectionStart;
                                            var endPos = myField.selectionEnd;

                                            //if (this.posStart === -1) {
                                            //
                                            //} else {
                                            //
                                            //}
                                            // console.log('val', startPos, endPos);
                                            myField.value = myField.value.substring(0, startPos)
                                                + myValue
                                                + myField.value.substring(endPos, myField.value.length);

                                            this.keyboards[this.config.kbType].input_active.selectionEnd = startPos + 1;
                                        } else {
                                            //synoptic.f_consola("d");
                                            myField.value += myValue;
                                        }
                                        this.change();

                                        var noinst = true;
                                        if (noinst && false) {
                                            try {
                                                this.keyboards[this.config.kbType].input_active.insertAtCursor(texts[nt].k);
                                                //document.execCommand('insertText', false /*no UI*/, texts[nt].k);
                                                noinst = false;
                                            }
                                            catch (error) {
                                            }
                                        }

                                        if (noinst && false) {
                                            try {
                                                this.keyboards[this.config.kbType].input_active.insertAtCursor(texts[nt].k);
                                                noinst = false;
                                            } catch (error) {
                                            }
                                        }

                                        this.keyboards[this.config.kbType].input_active.selectionStart = this.keyboards[this.config.kbType].input_active.selectionEnd;
                                    }
                                    break;
                            }
                        }

                        this.loginForm.forEach(lForm => {
                            try {
                                lForm.value[this.keyboards[this.config.kbType].input_active.getAttribute('formcontrolname')] = this.keyboards[this.config.kbType].input_active.value;

                                if (this.keyboards[this.config.kbType].input_active.value === '') {
                                    lForm.controls[this.inputForm['id']].setErrors(this.inputErrors);
                                } else {
                                    lForm.controls[this.inputForm['id']].setErrors(null);
                                }

                            } catch (error) {
                            }
                        });

                        var ft = this.p_clickDowns[np].fonts[texts[nt].font] || "28px Arial";
                        //var alm = synoptic.f_factor(ft);
                        var alm = parseInt(ft);

                        var incx = texts[nt].x || (alm / 2 + 5);
                        var incy = texts[nt].y || (alm / 2 - 10);

                        var tagsFont = ft.split(" ");
                        ctx.font = alm + "px " + tagsFont[1];
                        ctx.fillStyle = "#000000";
                        ctx.textAlign = "center";

                        if (delay === false) {
                            if (!this.config.capsLock && texts[nt].k.length === 1) {
                                ctx.fillText(texts[nt].k.toLowerCase(), gD_x1 + incx, gD_y1 + alm + incy);
                            } else {
                                ctx.fillText(texts[nt].k, gD_x1 + incx, gD_y1 + alm + incy);
                            }
                        }

                        try {
                            var ev = new Event('keyup');
                            this.inputForm.dispatchEvent(ev);
                        } catch (error) {
                        }

                    }
                }

                break;
            }
        }
    }

    getDowns(event) {
        try {
            clearTimeout(this.timeOutBlur);
        } catch (error) {
        }

        if ((new Date().getTime() - this.delayFocusTime) < 200) {
            this.delayFocusCnts = this.delayFocusCnts + 1;
            setTimeout(() => {
                this.getDown(event, true);
            }, 200);
        } else {
            if (this.delayFocusCnts > 0) {
                this.delayFocusCnts = this.delayFocusCnts + 1;
                setTimeout(() => {
                    this.getDown(event, true);
                }, 200);
            } else {
                this.getDown(event, false);
            }
        }
    }

    inputblur(obj) {
        this.keyboards[this.config.kbType].input_time_blur = new Date().getTime();
        this.timeOutBlur = setTimeout(() => {
            if (Math.abs(this.keyboards[this.config.kbType].input_time_blur - this.keyboards[this.config.kbType].input_time_down) > 200) {
                if (!this.modalDiv) {
                    this.cvasKeyboard.style.display = 'none';
                }
                try {
                    clearTimeout(this.time_getUps);
                } catch (error) {
                }
                if (this.modalDiv) {
                    // (<any>$('#KeyboardModal')).modal('hide');
                    // this.modalLoaded = false;
                } else {
                    setTimeout((id, value) => {
                        document.getElementById(id)['value'] = value;
                    }, 100, this.inputOrig.id, this.valueOld);
                }
            }
        }, 200);
    }

    keybInEditor() {
        this.cvasKeyboard.style.display = '';
    }

    getOffSet(obj, xy) {
        xy.x = xy.x + parseInt(obj.offsetLeft);
        xy.y = xy.y + parseInt(obj.offsetTop);
        if (obj.offsetParent) {
            const retxy = this.getOffSet(obj.offsetParent, xy);
            xy = retxy;
        }
        return xy;
    }

    inputfocus(obj, _x, _y, _w) {

        const dm = document.getElementById('main');

        let x = _x;
        let y = _y;
        let w = _w;

        // si en main hay un transform scale() lo aplico
        const scale = function (sc) {
            x = Math.round(x * sc);
            y = Math.round(y * sc);
            w = Math.round(w * sc);
        };
        try {
            eval(dm.style.transform + ";");
        } catch (error) {

        }


        if (this.config.modal && this.modalLoaded === false) {
            this.modalLoaded = true;
            var mi = document.getElementById("modalInput");

            this.addEventListener(mi, 'focus', this.eventFocus.bind(this));
            /// mi.addEventListener('focus', this.eventFocus.bind(this), true);

            this.addEventListener(mi, 'blur', this.eventBlur.bind(this));
            /// mi.addEventListener('blur', this.eventBlur.bind(this), true);
            setTimeout(() => {
                const inp: any = document.getElementById("modalInput");
                inp.value = this.valueOld;
                inp.focus();

                const obj = { keyboardModalEnter: this.keyboards[this.config.kbType] };
                const evv = "lp_event(obj);";
                try {
                    eval(evv);
                } catch (error) {
                }
                // inp.select();

                // document.getElementById("modalInput")['value'] = this.valueOld;
                // document.getElementById("modalInput").focus();


            }, 100);
            try {
                document.getElementById('modalDescription').innerHTML = this.config.modalDescription;
                // $('#KeyboardModal').show();
                // hide modal



                (<any>$('#KeyboardModal')).modal('show');
                // Insert html code to toast body
                // $('.toast-body').html(`<p style="font-size:18px;">DSItemForm</p>`);
                // Show toast
                // (<any>$('.toast')).toast('show');
            } catch (error) {
                console.log(error);
            }

            this.modalDiv = document.getElementById('KeyboardModal');

            const tt = document.getElementById("keyboardModal");
            const xy = this.getOffSet(tt, { x: 0, y: 0 });
            this.modalDesp = xy;

            const width = parseInt(this.keyboards[this.config.kbType].kb_Width);
            const height = parseInt(this.keyboards[this.config.kbType].kb_Height);

            const factorW = tt.offsetWidth / width;
            const factorH = (tt.offsetHeight - 110) / height;
            let factor = 1;

            this.config.x = 600;
            this.config.w = 1700;
            this.config.y = 50;

            if (factorH < factorW) {
                factor = factorH;
            } else {
                factor = factorW
            }

            this.config.w = width * factor;
            this.config.x = ((tt.offsetWidth - this.config.w) / 2);


        }

        // this.config.obj = obj;

        if (this.modalDiv) {
            // x = x - this.modalDiv.offsetParent.offsetLeft;
            // y = y - this.modalDiv.offsetParent.offsetTop;

            // x = x - this.modalDesp.x;
            // y = y - this.modalDesp.y;
        }

        //const x = this.config.x;
        //const x = this.config.x;
        //const x = this.config.x;

        // this.config.x = x;
        // this.config.y = y;
        // this.config.w = w;

        // this.keyboardOn(x, y, h);



        this.cvasKeyboard.style.left = x + 'px';
        this.cvasKeyboard.style.top = y + 'px';
        this.cvasKeyboard.style.width = w + 'px';

        this.keyboards[this.config.kbType].input_time_down = new Date().getTime();
        if (this.keyboards[this.config.kbType].input_time_down - this.keyboards[this.config.kbType].input_time_blur < 200)
            this.keyboards[this.config.kbType].active = false;

        if (!this.keyboards[this.config.kbType].active) {
            this.keyboards[this.config.kbType].input_value = obj.value;
        } else {
            // console.log('noo');
        }


        this.keyboards[this.config.kbType].active = true;
        this.keyboards[this.config.kbType].input_active = obj;

        this.cvasKeyboard.style.display = '';
        this.keyboardAct['on'] = true;
    }

    upKey(np) {

        var texts = this.p_clickDowns[np].texts;
        const cvas: any = document.getElementById(this.config.kbId);
        var gD_x1 = 0;
        var gD_x2 = 0;
        var gD_y1 = 0;
        var gD_y2 = 0;
        var gD_w = 0;
        var gD_h = 0;

        var ctx = cvas.getContext('2d');

        gD_x1 = parseInt(this.p_clickUps[np].x1);
        gD_x2 = parseInt(this.p_clickUps[np].x2);
        gD_y1 = parseInt(this.p_clickUps[np].y1);
        gD_y2 = parseInt(this.p_clickUps[np].y2);
        gD_w = gD_x2 - gD_x1;
        gD_h = gD_y2 - gD_y1;

        ctx.beginPath();
        ctx.rect(gD_x1, gD_y1, gD_w, gD_h);
        ctx.fillStyle = "#434343";
        ctx.fill();

        ctx.drawImage(this.p_clickUps[np].imgg, gD_x1, gD_y1, gD_w, gD_h);

        for (var nt = 0; nt < texts.length; nt++) {
            var ft = this.p_clickDowns[np].fonts[texts[nt].font] || "28px Arial";
            //var alm = synoptic.f_factor(ft);
            var alm = parseInt(ft);

            var incx = texts[nt].x || (alm / 2 + 5);
            var incy = texts[nt].y || (alm / 2 - 10);

            var tagsFont = ft.split(" ");
            ctx.font = alm + "px " + tagsFont[1];
            ctx.fillStyle = "#000000";
            ctx.textAlign = "center";

            if (!this.config.capsLock && texts[nt].k.length === 1) {
                ctx.fillText(texts[nt].k.toLowerCase(), gD_x1 + incx, gD_y1 + alm + incy);
            } else {
                ctx.fillText(texts[nt].k, gD_x1 + incx, gD_y1 + alm + incy);
            }

            var keyb = this.p_clickUps[np].keyb;

            if (nt == 0) {
                try {
                    if (texts[nt].exit) {
                        this.keyboardAct['on'] = false;
                        this.keyboards[this.config.kbType].input_active.blur();
                        this.cvasKeyboard.style.display = 'none';
                        try {
                            clearTimeout(this.time_getUps);
                        } catch (error) {
                        }
                        if (this.modalDiv) {
                            (<any>$('#KeyboardModal')).modal('hide');
                            this.cvasKeyboard.style.display = 'none';
                            this.modalLoaded = false;
                        }

                        if (!texts[nt].ok) {
                            setTimeout((id, value) => {
                                document.getElementById(id)['value'] = value;
                            }, 200, this.inputOrig.id, this.valueOld);
                            // this.inputOrig.value = this.valueOld;
                        }

                        if (texts[nt].ok) {
                            const obj = { keyboardOK: this.keyboards[this.config.kbType] };
                            const evv = "lp_event(obj);";
                            try {
                                eval(evv);
                            } catch (error) {
                            }
                            if (this.modalDiv) {
                                setTimeout((id, value) => {
                                    document.getElementById(id)['value'] = value;
                                }, 100, this.inputOrig.id, document.getElementById("modalInput")['value']);
                            }
                        }
                    }
                } catch (error) {
                }
            }
        }

    }

    getUps(event) {
        const factor = this.width / parseInt(this.keyboard.style.width);

        let despX = this.keyboard.offsetParent.offsetLeft + this.keyboard.offsetLeft;
        let despY = this.keyboard.offsetParent.offsetTop + this.keyboard.offsetTop;

        if (this.modalDiv) {
            despX = this.keyboard.offsetLeft + this.modalDesp.x;
            despY = this.keyboard.offsetTop + this.modalDesp.y;

        }

        const gMP: any = { x: (event.clientX - despX) * factor, y: (event.clientY - despY) * factor };
        // const cvas: any = document.getElementById(this.config.kbId);

        //vamos a repasar si hemos picado en algún objeto con click mapeado
        for (var np = 0; np < this.p_clickUps.length; np++) {
            //lert(np);
            if (gMP.x >= this.p_clickUps[np].x1 && gMP.x <= this.p_clickUps[np].x2 && gMP.y >= this.p_clickUps[np].y1 && gMP.y <= this.p_clickUps[np].y2) {

                const cvas: any = document.getElementById(this.config.kbId);
                var _ctx = cvas.getContext('2d');
                if (this.keyboards[this.config.kbType].active || true) {
                    var texts_ = this.p_clickDowns[np].texts;
                    var ok = true;

                    if (texts_.length == 1 && texts_[0].k == "Alt Gr") {
                        ok = false;
                        if (this.keyboards[this.config.kbType].input_altgr) {
                            this.keyboards[this.config.kbType].input_altgr = false;
                            this.upKey(this.keyboards[this.config.kbType].input_altgr_key);
                        } else {
                            this.keyboards[this.config.kbType].input_altgr = true;
                            this.keyboards[this.config.kbType].input_altgr_key = np;
                        }
                    } else {
                        if (this.keyboards[this.config.kbType].input_altgr) {
                            this.keyboards[this.config.kbType].input_altgr = false;
                            this.upKey(this.keyboards[this.config.kbType].input_altgr_key);
                        }
                    }

                    if (texts_.length === 1 && texts_[0].k === "Mays.") {
                        ok = false;
                        if (this.keyboards[this.config.kbType].input_mays) {
                            this.keyboards[this.config.kbType].input_mays = false;
                            this.upKey(this.keyboards[this.config.kbType].input_mays_key);
                        } else {
                            this.keyboards[this.config.kbType].input_mays = true;
                            this.keyboards[this.config.kbType].input_mays_key = np;
                        }
                    } else {
                        if (this.keyboards[this.config.kbType].input_mays) {
                            this.keyboards[this.config.kbType].input_mays = false;
                            this.upKey(this.keyboards[this.config.kbType].input_mays_key);
                        }
                    }

                    if (texts_.length === 1 && texts_[0].k === "Bloq.Mays") {
                        this.config.capsLock = !this.config.capsLock;
                        ok = false;

                        if (!this.config.capsLock) {
                            this.upKey(np);
                        }
                        this.keyboardShow();
                    }
                    try {
                        clearTimeout(this.time_getUps);
                    } catch (error) {

                    }

                    this.time_getUps = setTimeout(() => {
                        this.delayFocusTime = new Date().getTime();
                        this.keyboards[this.config.kbType].input_active.focus();
                    }, 400);


                    if (ok)
                        this.upKey(np);

                }
                break;
            }
        }
    }

    controlFocus() {
        if (this.cvasKeyboard.getAttribute("in") === '0') {
            this.keyboards[this.config.kbType].input_active.focus();
        }
    }

    keyboardShow() {

        this.p_clickDowns = [];
        this.p_clickUps = [];

        var w = this.config.w;
        const x = this.config.x;
        const y = this.config.y;
        const capsLock = this.config.capsLock;

        /*
        this.config.x = x;
        this.config.y = y;
        this.config.w = w;
        this.config.capsLock = capsLock;
        */

        this.keyboard = document.getElementById(this.config.kbId);


        //this.keyboard.style.height = '38px';
        this.keyboard.style.left = x + 'px';
        this.keyboard.style.top = y + 'px';
        // this.keyboard.style.display = 'none';
        // this.keyboard.width = '1130';
        // this.keyboard.height = '385';

        // this.keyboards[this.config.kbType].active = true;
        // this.keyboardAct['on'] = true;
        const cvas: any = document.getElementById(this.config.kbId);

        // cvas.style.backgroundColor = 'white';
        cvas.style.zIndex = '150';

        var _ctx = cvas.getContext('2d');

        _ctx.beginPath();

        this.keyboards[this.config.kbType].input_time_down = 0;
        this.keyboards[this.config.kbType].input_altgr = false;
        this.keyboards[this.config.kbType].input_mays = false;

        const data = this.keyboards[this.config.kbType];

        //synoptic.f_consola(synoptic.factorCanvas);
        w = (parseInt(data.margin) * 2);
        var h = (parseInt(data.margin) * 2);
        var key_width = parseInt(data.key_width);

        //synoptic.f_consola(key_width);

        var key_width_2 = 0;

        var hz = 0;
        for (var nz = 0; nz < data.zones.length; nz++) {
            if (nz > 0) {
                w = w + parseInt(data.zones[nz].sepx);
            }


            var zone = data.zones[nz];
            var wz = 0;
            var wz2 = 0;
            var hz2 = 0;

            for (var nl = 0; nl < zone.lines.length; nl++) {
                if (nl > 0) {
                    hz2 = hz2 + parseInt(data.sepy);
                }

                hz2 = hz2 + parseInt(data.key_height);

                var lines = zone.lines[nl];
                wz2 = 0;
                for (var nk = 0; nk < lines.length; nk++) {
                    key_width_2 = key_width;
                    if (lines[nk].width_factor) {
                        key_width_2 = key_width_2 * parseFloat(lines[nk].width_factor);
                    }

                    wz2 = wz2 + key_width_2;
                    if (nk > 0) {
                        wz2 = wz2 + parseInt(data.sepx);
                    }
                }
                if (wz2 > wz) {
                    wz = wz2;
                }
            }

            if (hz2 > hz) {
                hz = hz2;
            }
            w = w + wz;
            //synoptic.f_consola("w:" + parseInt(wz) + " h:" + parseInt(hz));
        }
        h = h + hz;

        const xx = 0;
        const yy = 0;

        _ctx.fillStyle = "#AAAAAA";
        _ctx.fillRect(0, 0, 5000, 5000);
        _ctx.clearRect(0, 0, 5000, 5000);

        const imgg = document.getElementById('butUnPress');
        const imggDown = document.getElementById('butPress');

        var k_x = parseInt(data.margin);
        var k_y = k_x;
        var k_x2 = k_x;

        for (var nz = 0; nz < data.zones.length; nz++) {
            k_y = parseInt(data.margin);
            if (nz > 0) {
                k_x2 = k_x2 + parseInt(data.zones[nz].sepx);
            }
            var k_xIni = k_x2;
            zone = data.zones[nz];

            for (var nl = 0; nl < zone.lines.length; nl++) {
                k_x = k_xIni;
                if (nl > 0) {
                    k_y = k_y + parseInt(data.sepy);
                }

                var lines = zone.lines[nl];
                for (var nk = 0; nk < lines.length; nk++) {
                    if (nk > 0) {
                        k_x = k_x + parseInt(data.sepx);
                    }

                    key_width_2 = key_width;
                    if (lines[nk].width_factor) {
                        key_width_2 = key_width_2 * parseFloat(lines[nk].width_factor);
                    }

                    let imgbot = imgg;
                    let minusc = false;

                    var texts = [];
                    if (typeof (lines[nk].k) == "string") {

                        if (lines[nk].k === 'Bloq.Mays' && capsLock) {
                            imgbot = imggDown;
                        }
                        texts.push(lines[nk]);

                    } else {
                        texts = lines[nk].k;
                    }
                    _ctx.drawImage(imgbot, xx + k_x, yy + k_y, key_width_2, parseInt(data.key_height));

                    for (var nt = 0; nt < texts.length; nt++) {
                        var ft = data.fonts[texts[nt].font] || "28px Arial";
                        //var alm = synoptic.f_factor(ft);
                        var alm = parseInt(ft);

                        var incx = texts[nt].x || (alm / 2 + 5);
                        var incy = texts[nt].y || (alm / 2 - 10);

                        var tagsFont = ft.split(" ");
                        _ctx.font = alm + "px " + tagsFont[1];
                        _ctx.fillStyle = "#000000";
                        _ctx.textAlign = "center";

                        if (nt === 0 && !capsLock && texts[nt].k.length === 1) {
                            _ctx.fillText(texts[nt].k.toLowerCase(), xx + k_x + incx, yy + k_y + alm + incy);
                        } else {
                            _ctx.fillText(texts[nt].k, xx + k_x + incx, yy + k_y + alm + incy);
                        }
                    }

                    var mc = {};

                    mc["x1"] = xx + k_x;
                    mc["x2"] = xx + k_x + key_width_2;
                    mc["y1"] = yy + k_y;
                    mc["y2"] = yy + k_y + parseInt(data.key_height)
                    //mc["idScript"] = nsc;

                    mc["texts"] = texts;
                    mc["imgg"] = imggDown;
                    mc["fonts"] = data.fonts;

                    this.p_clickDowns.push(mc);

                    mc = {};

                    mc["x1"] = xx + k_x;
                    mc["x2"] = xx + k_x + key_width_2;
                    mc["y1"] = yy + k_y;
                    mc["y2"] = yy + k_y + parseInt(data.key_height)
                    //mc["idScript"] = nsc;
                    mc["texts"] = texts;
                    mc["fonts"] = data.fonts;
                    mc["imgg"] = imgg;
                    this.p_clickUps.push(mc);
                    k_x = k_x + key_width_2;

                    if (k_x > k_x2) {
                        k_x2 = k_x;
                    }
                }
                k_y = k_y + parseInt(data.key_height);
            }
            if (hz2 > hz) {
                hz = hz2;
            }
            w = w + wz;
            //synoptic.f_consola("w:" + parseInt(wz) + " h:" + parseInt(hz));
        }
    }
}
