import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConnectionsService {
  private BASE_URL;
  private endpoint = '/v1/api/modelizer/connections';
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.base_url;
  }

  sendVariableList(obj) {
    this.http
      .post(`${this.BASE_URL}${this.endpoint}/variablelist/Logi7`, obj)
      .toPromise();
  }

  sendEthernetVariableList(obj) {
    this.http
      .post(`${this.BASE_URL}${this.endpoint}/variablelist/Ethernet`, obj)
      .toPromise();
  }
}
