import { Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Socket, io } from 'socket.io-client';
import { AuthService } from 'src/app/service/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

/**
 * Extendemos la clase "Socket" a nuestra clase
 */
export class lpSocket {
  /**
   * Declaramos un metodo de emitir el cual llamaremos "outEven"
   */
  @Output() outEven: Observable<any>;

  private socket: Socket;

  constructor(private authServ: AuthService) {
    const base_url = environment.base_url;

    this.socket = io(`${base_url}/comm`, {
      transports: ['websocket'],
      auth: {
        token: this.authServ.getToken()?.replace(/"/g, ''),
      },
    });

    this.outEven = new Observable((subscriber) => {
      this.socket.on('message', (data: any) => {
        subscriber.next(data);
      });
    });
  }

  emitEvent = (event = 'default', payload = {}) => {
    this.socket.emit('default', { event, payload });
  };
}
