import { Component } from '@angular/core';

@Component({
  selector: 'app-header-component',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  step = '';
  title = 'header-component';

  toggleNav(): void {
    const sidebar = document.querySelector('.main-menu') as HTMLElement;
    const sidebarNone = document.querySelector(
      '.main-menu-none'
    ) as HTMLElement;

    if (sidebar != null && sidebarNone != null) {
      if (sidebar.style.width !== '200px') {
        sidebar.style.width = '200px';
        sidebar.style.zIndex = '1002';
        sidebarNone.style.width = '200px';
      } else {
        sidebar.style.width = '60px';
        sidebar.style.zIndex = '1000';
        sidebarNone.style.width = '0px';
      }
    }
  }
}
