import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as $$ from 'jquery';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { lpSocket } from 'src/app/service/lp-websocket.service';
import { ConnectionsService } from 'src/app/service/modelizer/connections.service';
import { ModelizerItemsService } from 'src/app/service/modelizer/modelizer-items.service';
import { ModelizerTypeService } from 'src/app/service/modelizer/modelizer-type.service';
import { ModuleIotService } from 'src/app/service/modelizer/module-iot.service';
import { SharedService } from 'src/app/service/modelizer/shared.service';
import { BackupsService } from 'src/app/service/system/backups.service';
import { LanguageService } from 'src/app/service/system/language.service';
import { TagService } from 'src/app/service/tags/tag.service';

declare var $: any;

@Component({
  selector: 'app-modelizer5',
  templateUrl: './modelizer5.component.html',
  styleUrls: ['./modelizer5.component.scss']
})
export class Modelizer5Component implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(ContextMenuComponent) public basicMenu: ContextMenuComponent;
  @ViewChild(ContextMenuComponent) public basicMenu2: ContextMenuComponent;
  @ViewChild(ContextMenuComponent) public basicMenu3: ContextMenuComponent;

  mod_vars_options: {
    export: boolean;
    clone: boolean;
    cloneBranch: boolean;
    removeLink: boolean;
    insert: boolean;
    itemPaste: boolean;
    itemPasteIn: boolean;
    itemCut: boolean;
  } = { export: true, clone: true, cloneBranch: true, removeLink: false, insert: true, itemPaste: false, itemPasteIn: false, itemCut: true };

  mod_vars_system: {
    items: any;
  } = { items: [] }

  mod_vars_toClone: any = '';
  mod_vars_PasteItemId: string = '';
  mod_vars_PasteTo: string = '';

  mod_vars_timeOut_cursor: any;
  mod_vars_divMain: any;
  mod_vars_mouseDownPos: { x: number, y: number } = { x: 0, y: 0 };
  mod_vars_contextFrom: string = '';
  mod_vars_languageOptions: any;

  mod_vars_typeName: any;
  mod_vars_typeId: any;

  logsMSGS: any = [];
  logsFiltered: any = [];
  logsFilter: string = '';

  master: any = [];

  elements: any = {}
  items: any = [];
  anotherItems: any = [];
  types: any = [];
  updateError: any = undefined;
  language: any;
  modelizerText: any;
  structure: any = [];
  modelizertype: string = '5f858469093c3d3f06c69a4f';
  itemsInScreen: any = [];
  treeItemsInScreen: any = [];
  links: any = [];
  connectors: any = [];
  divItems: HTMLElement;
  elementDrag: any;
  modeEditor = true;
  idTypeDrag: any;
  parentTypeDrag: any;
  parentElementDrag: any;
  typeParentElementDrag: any;
  nameElementDrag: any;
  eventX: any;
  eventY: any;
  position: any;
  noClick = false;
  toNoClick: any = 0;
  contadorItem = 1;
  gridX = 20;
  gridY = 20;

  move: any = { 'id': 'none', 'shift': false };
  keydown: any = { 'shift': false };

  controlTime: number = 0;

  naveg: any = [];

  inDiv: string = 'doParent';
  mod_vars_cloneBranch: boolean;
  mod_vars_idPrev: string;
  mod_vars_PasteItemType: any;


  constructor(
    private typeServ: ModelizerTypeService,
    private itemsServ: ModelizerItemsService,
    private sharServ: SharedService,
    private moduleServ: ModuleIotService,
    private connServ: ConnectionsService,
    private langServ: LanguageService,
    private BackupsSrv: BackupsService,
    private tagServ: TagService,
    private socketService: lpSocket
  ) {
    this.socketService.outEven.subscribe(res => {
      const keys = Object.keys(res);
      if (keys.length === 1) {
        const resms = res.msg.replaceAll(' ', '_');
        this.logsMSGS.unshift(resms);
        this.logsFiltered = this.logsMSGS.filter(log => log.includes(this.logsFilter))
      }
    });
    this.parentElementDrag = 'none';
  }

  async ngOnInit(): Promise<any> {
    await this.getTypes();
    await this.getItems();
    await this.getAnotherItems();

    this.language = await this.langServ.getVal();
    this.modelizerText = this.language.modelizer;
    this.mod_vars_languageOptions = this.language.option;

    /* Se ha reubicado la inicialización de this.naveg para poder asignar el valor en función del idioma. */
    this.naveg = [{ txt: `${this.modelizerText.home}`, _id: 'home' }];

    this.loadJSTree(true);
    document.onkeydown = this.onWindowKeyDown.bind(this);
    document.onkeyup = this.onWindowKeyUp.bind(this);
    document.onresize = this.onWindowLoadOrResize.bind(this);
    this.onWindowLoadOrResize();
  }

  async ngOnDestroy(): Promise<any> {
  }
  ngAfterViewInit() {
    //this.setLinksCatch();
  }

  async go(_id,) {

    if (_id === 'home') {

      /*
      const tree = $('#jstreeNew').jstree(true);
      const items = tree.settings.core.data;
      delete items[0]['children'][2];
      // items[0]['children'][2]['text'] = 'Ams';
      tree.refresh();
      */

      this.naveg = [{ txt: `${this.modelizerText.home}`, _id: 'home' }];
      this.refreshTree(true);

    } else {
      this.emitOption2(_id);
    }

  }

  setLinksCatch() {
    try {
      this.setLinks();
    } catch (error) {
      setTimeout(() => {
        this.setLinksCatch();
      }, 100);
    }
  }

  onWindowKeyUp(event): void {
    this.keydown['shift'] = false;
  }

  onWindowKeyDown(event): void {

    if (event.keyCode === 16) {
      this.keydown['shift'] = true;
    }

    if (event.keyCode === 27) {
      this.mod_func_clearTO_cursor();
      try {
        $('#options').hide();
      } catch (error) {
      }
    }
  }

  filterLogs(target) {
    this.logsFilter = target.value;
    this.logsFiltered = this.logsMSGS.filter(log => log.includes(this.logsFilter))
  }

  checkTrama(target) {
    const checked: boolean = target.checked;

    let elem = document.getElementById('toFilter');
    if (checked) {
      this.logsFilter = 'trama:';
      elem['value'] = 'trama:';
      elem.setAttribute('disabled', 'true');
    } else {
      this.logsFilter = '';
      elem['value'] = '';
      elem.removeAttribute('disabled');
    }
    this.filterLogs(elem);
  }

  mDown(event) {
    if (event.buttons === 1) {  //left button
      this.controlTime = Date.now();
      this.move['id'] = event.target.id;
      if (this.keydown['shift']) {
        this.move['shift'] = true;
        const childs = this.itemsInScreen.filter(item => item['parents'].includes(this.move['id']));
        childs.forEach(child => {
          (<any>$(`#${child['id']}`)).attr('iniLeft', parseFloat($(`#${child['id']}`).css('left')));
          (<any>$(`#${child['id']}`)).attr('iniTop', parseFloat($(`#${child['id']}`).css('top')));

        });
      }
      this.move['left'] = parseFloat(event.target.style.left);
      this.move['top'] = parseFloat(event.target.style.top);
      this.move['xini'] = event.clientX - window.scrollX;
      this.move['yini'] = event.clientY - window.scrollY;
    }

  }

  getMenu(item) {
    return "#basicMenu2";
  }

  mMove(event) {

    if (this.move['id'] !== 'none') {
      const xx = event.clientX - window.scrollX;
      const yy = event.clientY - window.scrollY;

      const width = (<any>$(`#${this.move['id']}`)).width();
      const height = (<any>$(`#${this.move['id']}`)).height();

      let newX = this.move['left'] + (xx - this.move['xini']);
      let newY = this.move['top'] + (yy - this.move['yini']);

      let newXC = newX + (width / 2);
      let newYC = newY + (height / 2);

      const fX = newXC % 20;
      const fY = newYC % 20;

      if (fX < 10) {
        newX = newX - fX;
      } else {
        newX = newX + (20 - fX);
      }

      if (fY < 10) {
        newY = newY - fY;
      } else {
        newY = newY + (20 - fY);
      }

      (<any>$(`#${this.move['id']}`)).css({ 'left': newX + 'px' });
      (<any>$(`#${this.move['id']}`)).css({ 'top': newY + 'px' });

      if (this.move['shift']) {
        const childs = this.itemsInScreen.filter(item => item['parents'].includes(this.move['id']));
        childs.forEach(child => {

          const width = (<any>$(`#${child['id']}`)).width();
          const height = (<any>$(`#${child['id']}`)).height();

          let newXChild = parseFloat((<any>$(`#${child['id']}`)).attr('iniLeft')) + (xx - this.move['xini']);
          let newYChild = parseFloat((<any>$(`#${child['id']}`)).attr('iniTop')) + (yy - this.move['yini']);

          let newXCH = newXChild + (width / 2);
          let newYCH = newYChild + (height / 2);

          const fXC = newXCH % 20;
          const fYC = newYCH % 20;

          if (fXC < 10) {
            newXChild = newXChild - fXC;
          } else {
            newXChild = newXChild + (20 - fXC);
          }

          if (fYC < 10) {
            newYChild = newYChild - fYC;
          } else {
            newYChild = newYChild + (20 - fYC);
          }

          (<any>$(`#${child['id']}`)).css({ 'left': newXChild + 'px' });
          (<any>$(`#${child['id']}`)).css({ 'top': newYChild + 'px' });
        });
      }

      // event.target.style.left = this.move['left'] + (xx - this.move['xini']) + 'px';
      // event.target.style.top = this.move['top'] + (yy - this.move['yini']) + 'px';
      try {
        window.clearTimeout(this.toNoClick);
      } catch (error) {

      }
      this.links = [];
      this.toNoClick = setTimeout(() => {
        this.noClick = false;
      }, 500);
      this.connectors = [];
      this.setLinks();
      // this.setLinks2();
      this.noClick = true;
    }
    // this.setLinks();
  }

  mClick(event) {
    /*
    if (this.noClick === false) {
      this.emitOption2(event.target.id);
    }
    */


  }

  mDblClick(event) {
    this.noClick = true;
  }

  async updatePositionItem(idItem, newX, newY) {
    const updateItem: any = await this.itemsServ.findItem(idItem);
    if (updateItem.hasOwnProperty('parameters')) {
      updateItem.parameters.position = { x: parseInt(newX), y: parseInt(newY) };
    } else {
      updateItem.parameters = { position: { x: parseInt(newX), y: parseInt(newY) } };
    }
    await this.itemsServ.updateItem(updateItem);
    await this.getItems();
  }

  mUpOld(event) {

    this.itemsInScreen.forEach(item => {
      item.doParent = '';
    });

    if (this.move['id'] !== 'none') {

      if (this.move['shift']) {
        const childs = this.itemsInScreen.filter(item => item['parents'].includes(this.move['id']));
        childs.forEach(child => {
          const nx = parseInt((<any>$(`#${child['id']}`)).css('left'));
          const ny = parseInt((<any>$(`#${child['id']}`)).css('top'));

          this.updatePositionItem(child['id'], nx, ny);
        });
      }
      this.updatePositionItem(this.move['id'], event.target.style.left, event.target.style.top);
      //this.emitOption2(this.move['id']);

      // const updateItem = await this.itemsServ.findItem(this.move['id']);
    }

    const xx = event.clientX - window.scrollX;
    const yy = event.clientY - window.scrollY;

    const ox = event.offsetX;
    const oy = event.offsetY;

    this.move['id'] = 'none';
    this.move['shift'] = false;
  }

  contextMenu(item) {

  }

  async systemConfig(item) {

  }

  mod_func_clearTO_cursor() {
    this.mod_vars_divMain.style.cursor = '';
    try {
      clearTimeout(this.mod_vars_timeOut_cursor);
    } catch (error) {
    }
  }

  async mod_func_itemCut(item) {
    this.mod_vars_PasteItemId = item.id;

    this.mod_vars_PasteItemType = item.typeId;
    this.mod_vars_options.itemCut = false;
    this.mod_vars_options.itemPaste = true;
    this.mod_vars_options.itemPasteIn = true;
    this.mod_vars_timeOut_cursor = setTimeout(() => {
      this.mod_vars_options.itemPaste = false;
      this.mod_vars_options.itemPasteIn = false;
      this.mod_vars_options.itemCut = true;
      this.mod_vars_PasteItemType = '';
    }, 10000);
  }

  async mod_func_itemPaste(item) {
    this.mod_vars_PasteTo = item.id;
    this.mod_vars_divMain.style.cursor = 'wait';
    this.mod_vars_timeOut_cursor = setTimeout(() => {
      this.mod_vars_divMain.style.cursor = '';
    }, 3000);
    // console.log(this.itemsInScreen);
  }

  async mod_func_itemClone(item) {

    this.mod_vars_cloneBranch = false;
    this.mod_vars_toClone = item;

    this.mod_func_clearTO_cursor();
    this.mod_vars_divMain.style.cursor = 'copy';
    this.mod_vars_timeOut_cursor = setTimeout(() => {
      this.mod_vars_divMain.style.cursor = '';
    }, 5000);
  }

  /*
  item.name = nombre de tipo de item
  item.id = id de tipo de item
  */
  async mod_func_addItem(item) {
    if (this.mod_vars_contextFrom !== 'none') {
      let parentId = this.mod_vars_contextFrom;
      if (parentId === 'svgMain') {
        parentId = undefined;
        const newItem = {
          parent: parentId,
          name: item.name,
          description: '',
          section: 'modelizer',
          type: item.id,
          parameters: {
            position: {
              x: this.mod_vars_mouseDownPos.x,
              y: this.mod_vars_mouseDownPos.y,
            }
          }
        };
        this.mod_func_createItem(newItem)
      } else {
        this.mod_vars_typeName = item.name;
        this.mod_vars_typeId = item.id;
        this.mod_func_clearTO_cursor();
        this.mod_vars_divMain.style.cursor = 'wait';
        this.mod_vars_timeOut_cursor = setTimeout(() => {
          this.mod_vars_divMain.style.cursor = '';
        }, 3000);
      }
    }
  }

  async mod_func_createItem(newItem) {
    const itemCreated = await this.itemsServ.createItem(newItem);
    await this.getItems();
    this.refreshScreen();
  }

  async mod_func_itemCloneBranch(item) {

    this.mod_vars_toClone = item;
    this.mod_vars_cloneBranch = true;
    this.mod_func_clearTO_cursor();
    this.mod_vars_divMain.style.cursor = 'copy';
    this.mod_vars_timeOut_cursor = setTimeout(() => {
      this.mod_vars_divMain.style.cursor = '';
    }, 5000);
  }

  async mod_func_itemEdit(item) {
    this.emitOption2(item.id);
  }

  async itemRemove(item) {
    (<any>$$('#deleteItem')).modal('show');
  }

  async mod_func_deleteItem() {
    (<any>$$('#deleteItem')).modal('hide');
    const itemToDelete = this.items.find(item => item._id === this.mod_vars_contextFrom);
    await this.deleteItem(itemToDelete);
  }

  async itemExport(item) {

    const bak = await this.BackupsSrv.export(item.id);

    let blob: any = new Blob([bak], { type: 'application/zip' });
    const date = new Date();
    const dateTransform = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.download = `${item.text}-${dateTransform}.zip`;
    anchor.target = '_blank';

    anchor.href = url;
    anchor.click();
  }

  async importZip(e) {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      await this.tagServ.importZip(file, this.mod_vars_mouseDownPos);
      // this.sharedServ.emitReport('upload', this.reportItem);

      await this.getItems();
      this.refreshScreen();
    }
  }

  viewLogs() {
    ($$('#viewLogs') as any).modal('show');
  }

  async mod_func_itemImport(item) {

    const subfile = document.getElementById('filePath');
    await subfile.click();

    /*
    const frm = document.createElement("form");
    frm.action = "POST";

    const inp = document.createElement("input");
    inp.type = "file";
    inp.id = "filePath";
    inp.style.display = "none";

    frm.appendChild(inp);

    // document.appendChild(frm);

    inp.click();
    */

    //Los thing no pueden tener el nombre repetido (se tendrá que renombrar de forma automática)
    //Los things estarán desactivados cuando se dupliquen
  }

  selfPageCheckId(_id) {
    let found = false;
    if (this.naveg.length > 1) {
      if (_id === this.naveg[this.naveg.length - 1]['_id']) {
        found = true;
      }
    }
    return found;
  }

  async mUp(event) {
    if (Date.now() - this.controlTime < 250) {
      this.move['id'] = 'none';
      this.move['shift'] = false;
      if (this.noClick === false) {
        this.emitOption2(event.target.id);
      }
    } else {

      let iitems: any = [];

      try {
        if (this.move['id'] !== 'none') {

          if (this.move['shift']) {
            const childs = this.itemsInScreen.filter(item => item['parents'].includes(this.move['id']));
            childs.forEach(async child => {
              const nx = parseInt((<any>$(`#${child['id']}`)).css('left'));
              const ny = parseInt((<any>$(`#${child['id']}`)).css('top'));

              iitems.push({ id: child['id'], x: nx, y: ny, type: 'normal' });

            });
          }
          // items.push(await this.updatePositionItem(this.move['id'], event.target.style.left, event.target.style.top));

          let self = false;
          if (this.move['id'] === this.naveg[this.naveg.length - 1]['_id']) {
            self = true;
          }

          if (self) {
            iitems.push({ id: this.move['id'], type: 'self', x: parseInt(event.target.style.left), y: parseInt(event.target.style.top) });
          } else {
            iitems.push({ id: this.move['id'], type: 'noself', x: parseInt(event.target.style.left), y: parseInt(event.target.style.top) });
          }
        }
      } catch (error) {

      }

      this.move['id'] = 'none';
      this.move['shift'] = false;

      if (iitems.length > 0) {
        await this.itemsServ.updateItems(iitems);

        iitems.forEach(iit => {
          const it = this.items.find(itm => itm._id === iit.id);

          if (iit.type === 'self') {
            it.parameters.selfPosition.x = iit.x;
            it.parameters.selfPosition.y = iit.y;
          } else {
            it.parameters.position.x = iit.x;
            it.parameters.position.y = iit.y;
          }
        });
      }

    }
  }

  mOver(event) {
    // event.target.style.opacity = "1";
  }

  mOut(event) {
    // event.target.style.opacity = "0";
  }

  linkOver(event) {
    event.target.setAttribute("stroke", "green");
  }

  linkOut(event) {
    event.target.setAttribute("stroke", "gray");
  }

  setLinks(): void {
    this.itemsInScreen.forEach(item => {
      const offsetStart = (<any>$(`#${item.id}`)).offset();
      const offsetDiv = (<any>$('#divItems')).offset();

      const width = (<any>$(`#${item.id}`)).width();
      const height = (<any>$(`#${item.id}`)).height();

      switch (item.type) {
        case 'instalation':
          break;
        case 'lineItem':
          break;
      }

      item.links.forEach(link => {
        const offsetEnd = (<any>$(`#${link.to}`)).offset();
        const widthEnd = (<any>$(`#${link.to}`)).width();
        const heightEnd = (<any>$(`#${link.to}`)).height();

        let xIn0 = -offsetDiv.left + 15 + offsetStart.left + width;
        let yIn0 = -offsetDiv.top + 10 + offsetStart.top + (height / 2);

        let xIn1 = -offsetDiv.left + 10 + offsetStart.left + (width / 2);
        let yIn1 = -offsetDiv.top + 20 + offsetStart.top + height;

        let xFin0 = -offsetDiv.left + offsetEnd.left;
        let yFin0 = -offsetDiv.top + 10 + offsetEnd.top + ((<any>$(`#${link.to}`)).height() / 2);

        let xFin1 = -offsetDiv.left + 10 + offsetEnd.left + ((<any>$(`#${link.to}`)).width() / 2);
        let yFin1 = -offsetDiv.top + offsetEnd.top - 3;

        let startX = 0, startY = 0, endX = 0, endY = 0;

        let xFactor = 0, yFactor = 0;

        link.start = 0;
        link.end = 0;

        if (xFin0 - xIn0 < yFin0 - yIn0) {
          link.start = 1;
          link.end = 1;

          this.connectors.push(this.getConnectBottom(item.id));
          this.connectors.push(this.getConnectTop(link.to));
        } else {
          this.connectors.push(this.getConnectRight(item.id));
          this.connectors.push(this.getConnectLeft(link.to));
        }

        let xIn;
        let xFin;
        let yIn;
        let yFin;

        if (link.start === 0) {
          startX = 100;
          xFactor = 1;

          xIn = xIn0;
          yIn = yIn0;
        } else {
          yFactor = 1;
          startY = 100;

          xIn = xIn1;
          yIn = yIn1;
        }

        if (link.end === 0) {
          xFactor++;
          endX = 100;

          xFin = xFin0;
          yFin = yFin0;
        } else {
          yFactor++;
          endY = 100;

          xFin = xFin1;
          yFin = yFin1;
        }

        if (((xFin0 - xIn0) / xFactor) < 100) {
          if (startX === 100) {
            startX = ((xFin0 - xIn0) / xFactor);
          }

          if (endX === 100) {
            endX = ((xFin0 - xIn0) / xFactor);
          }
        }

        if (((yFin0 - yIn0) / yFactor) < 100) {
          if (startY === 100) {
            startY = ((yFin0 - yIn0) / yFactor);
          }

          if (endY === 100) {
            endY = ((yFin0 - yIn0) / yFactor);
          }
        }

        this.links.push({ from_id: item.id, to_id: link.to, xIn0: xIn, yIn0: yIn, xIn1: xIn + startX, yIn1: yIn + startY, xFin1: xFin - endX, yFin1: yFin - endY, xFin0: xFin, yFin0: yFin });
      });

    });

  }


  getConnectLeft(itemId): any {
    try {
      const offsetDiv = (<any>$('#divItems')).offset();
      const offsetItem = (<any>$(`#${itemId}`)).offset();
      const width = (<any>$(`#${itemId}`)).width();
      const height = (<any>$(`#${itemId}`)).height();
      return { x: -offsetDiv.left - 5 + offsetItem.left, y: -offsetDiv.top + 5 + offsetItem.top + (height / 2) };
    } catch (error) {
      return {};
    }
  }

  getConnectRight(itemId): any {
    try {
      const offsetDiv = (<any>$('#divItems')).offset();
      const offsetItem = (<any>$(`#${itemId}`)).offset();
      const width = (<any>$(`#${itemId}`)).width();
      const height = (<any>$(`#${itemId}`)).height();

      return { x: -offsetDiv.left + 14 + offsetItem.left + width, y: -offsetDiv.top + 5 + offsetItem.top + (height / 2) };
    } catch (error) {
      return {};
    }
  }

  getConnectTop(itemId): any {
    try {
      const offsetDiv = (<any>$('#divItems')).offset();
      const offsetItem = (<any>$(`#${itemId}`)).offset();
      const width = (<any>$(`#${itemId}`)).width();
      const height = (<any>$(`#${itemId}`)).height();
      return { x: -offsetDiv.left + offsetItem.left + (width / 2) + 4, y: -offsetDiv.top - 5 + offsetItem.top };
    } catch (error) {
      return {};
    }
  }

  getConnectBottom(itemId): any {
    try {
      const offsetDiv = (<any>$('#divItems')).offset();
      const offsetItem = (<any>$(`#${itemId}`)).offset();
      const width = (<any>$(`#${itemId}`)).width();
      const height = (<any>$(`#${itemId}`)).height();
      return { x: -offsetDiv.left + offsetItem.left + (width / 2) + 4, y: -offsetDiv.top + 13 + offsetItem.top + height };
    } catch (error) {
      return {};
    }
  }

  async getTypes(): Promise<any> {
    this.itemOptionSubscribe();
    this.types = await this.typeServ.getModelizerCommTypes();

    this.elements.types = this.types;
    this.elements.item = {};

    const primerelemento = this.types.find(type => type.parent === this.modelizertype);

  }

  async getItems(): Promise<any> {
    this.items = await this.itemsServ.getModelizerItems();

  }

  async getAnotherItems(): Promise<any> {
    const info = { $or: [{ type: '5f900543c7c2a908ade7b828' }, { type: '5f994d5908425a1078a1b7b2' }] };
    this.anotherItems = await this.itemsServ.getItemsByInfo(info);
  }

  // Generic Item options Subscribe
  itemOptionSubscribe(): void {
    this.sharServ.changeEmitted$.subscribe(async data => {
      if (data.val === 'action') {
        data.item.parameters.position = this.position;
        if (data.item.type.name === 'Conexion') {
          const protocol: any = await this.itemsServ.findItem(data.item.parent);
          const protocolSelected = await this.typeServ.getProtocol(protocol.parameters.general.type.selected);
          if (protocolSelected.name === 'Logi7') {
            const thing: any = await this.itemsServ.findItem(protocol.parent);
            const variableList = {
              document: `${data.item.parameters.general.document}`,
              id: `${thing.name}`,
              ip: data.item.parameters.general.type.logi.ip,
              rack: data.item.parameters.general.type.logi.rac,
              slot: data.item.parameters.general.type.logi.slot,
              ip_mqtt: data.item.parameters.general.type.logi.mqtt,
              variables: []
            };
            if (data.item.parameters.general.hasOwnProperty('variables')) data.item.parameters.general.variables.forEach(element => {
              if (element.type !== 'INTERNAL') {
                const variable = { name: element.name, address: element.direction };
                variableList.variables.push(variable);
              }
            });
            this.connServ.sendVariableList(variableList);
          } else if (protocolSelected.name === 'Ethernet') {
            const thing: any = await this.itemsServ.findItem(protocol.parent);
            const variableList = {
              document: `${data.item.parameters.general.document}`,
              id: `${thing.name}`,
              ip: data.item.parameters.general.type.ethernet.ip,
              pOrigen: data.item.parameters.general.type.ethernet.pOrigen,
              pDestino: data.item.parameters.general.type.ethernet.pDestino,
              ip_mqtt: data.item.parameters.general.type.ethernet.mqtt,
              request: data.item.parameters.general.type.ethernet.request,
              variables: []
            };

            if (data.item.parameters.general.hasOwnProperty('variables')) data.item.parameters.general.variables.forEach(element => {
              if (element.type !== 'INTERNAL') {
                const variable = { name: element.name, byte_inicio: element.byte_inici, byte_final: element.byte_final };
                variableList.variables.push(variable);
              }
            });
            this.connServ.sendEthernetVariableList(variableList);
          }
          this.updateItem(data.item);
        }

        this.updateItem(data.item);
      } else if (data.val === 'delete') {
        this.deleteItem(data.item);

      } else if (data.val === 'thing') {

        const thing: any = {
          id: data.item.thingId,
          /* configuration: '', */
          /* configurationStatus: 'ACK', */
          description: data.item.description,
          enabled: true,
          externalId: data.item.externalId,
          name: data.item.name,
          timeZone: 'UTC',
          type: { id: data.item.thingtype }
        };
        if (!thing.id) {
          const id = await this.moduleServ.createThing(thing);
          if (id) {
            data.item.thingId = id;
            this.updateItem(data.item);
          }
        } else {
          if (await this.moduleServ.updateThing(thing)) {
            this.updateItem(data.item);
          }
        }
      } else if (data.val === 'thingType') {
        if (data.hasOwnProperty('action')) {
          await this.moduleServ.updateThingType(JSON.stringify(data.item));
        } else {
          await this.moduleServ.createThingType(data.item);
        }
      } else if (data.val === 'documentDefinition') {
        if (data.hasOwnProperty('action')) {

          const thingExternalId = data.item.thing;
          delete data.item.thing;
          const id = await this.moduleServ.getThingByExternalId(thingExternalId);
          if (!isNaN(id)) {
            await this.moduleServ.updateThingRequest(id);
            if (await this.moduleServ.existsDocumentDefinition(data.item)) {
              delete data.item.externalId;
              await this.moduleServ.updateDocumentDefinition(data.item);
            } else {
              await this.moduleServ.createDocumentDefinition(data.item);
            }
          }
        } else {
          const id = await this.moduleServ.searchByName(data.item.thingTypes[0].id);
          data.item.thingTypes[0].id = id;
          await this.moduleServ.createDocumentDefinition(data.item);
        }
      }
      // this.itemOption(item);
    });
  }

  async syn_func_divMainMouseDown(event): Promise<any> {
    this.mod_vars_mouseDownPos.x = event.layerX;
    this.mod_vars_mouseDownPos.y = event.layerY;

    //hemos picado con el botón derecho, guardar posición para menu contextual
    if (event.button === 2) {

      const tid = event.target.id;
      this.mod_vars_contextFrom = tid;
      switch (tid) {
        case 'svgMain':
          //voy a controlar qué items se pueden insertar
          for (const item of this.mod_vars_system.items) {
            item.insert = false;
            item.parents.forEach(typeParent => {
              if (typeParent === 'none') {
                item.insert = true;
              }
            });
          }
          break;
        case 'link':
          this.mod_vars_options.removeLink = false;

          const from = this.itemsInScreen.find(item => item.id === event.target.getAttribute("from"));
          if (from.type === 'Zona') {
            this.mod_vars_options.removeLink = true;
          }
          break;
        default:
          try {
            const itm = this.itemsInScreen.find(item => item.id === tid);
            let doInsert = false;
            this.mod_vars_options.insert = false;
            //voy a controlar qué items se pueden insertar
            this.mod_vars_options.itemPasteIn = false;
            for (const item of this.mod_vars_system.items) {
              item.insert = false;
              item.parents.forEach(typeParent => {

                if (typeParent === itm.typeId) {

                  if (this.mod_vars_options.itemPaste) {
                    if (item.id === this.mod_vars_PasteItemType) {
                      this.mod_vars_options.itemPasteIn = true;
                    }
                  }

                  if (item.only_one === true) {
                    const hav = this.itemsInScreen.find(itt => item.id === itt.typeId && itt.parents.find(par => par === tid) !== undefined);
                    if (hav === undefined) {
                      doInsert = true;
                      item.insert = true;
                    }
                  } else {
                    doInsert = true;
                    item.insert = true;
                  }
                }
              });
            }
            if (doInsert) {
              this.mod_vars_options.insert = true;
            }

            this.mod_vars_options.export = true;
            this.mod_vars_options.clone = true;
            this.mod_vars_options.cloneBranch = false;

            switch (itm.type) {
              case "Protocolo":
                this.mod_vars_options.export = false;
                this.mod_vars_options.clone = false;
                break;
              case "DataDesign":
                this.mod_vars_options.export = false;
                this.mod_vars_options.clone = false;
                break;
              case "Synoptic":
                this.mod_vars_options.export = false;
                this.mod_vars_options.clone = false;
                break;
              case "Script":
                this.mod_vars_options.export = false;
                this.mod_vars_options.clone = false;
                break;
              default:
                if (itm.parents.length > 0) {
                  this.mod_vars_options.cloneBranch = true;
                }
                break;
            }
          } catch (error) {
            console.log(error);
          }
          break;
      }
    }

    if (event.button === 0 && event.target?.id !== '') {
      const clickedItem = event.target.id ?? null;
      this.mod_vars_contextFrom = clickedItem;
    }

    if (event.button === 0 && this.mod_vars_divMain.style.cursor === 'copy') {
      this.mod_func_clearTO_cursor();

      if (this.mod_vars_cloneBranch) {
        await this.tagServ.cloneBranch(this.mod_vars_toClone.id, this.mod_vars_idPrev, this.mod_vars_mouseDownPos);
      } else {
        this.mod_vars_idPrev = prompt("Identificador");
        await this.tagServ.cloneItem(this.mod_vars_toClone.id, this.mod_vars_idPrev, this.mod_vars_mouseDownPos);
      }
      await this.getItems();
      this.refreshScreen();
    }

    if (event.button === 0 && this.mod_vars_divMain.style.cursor === 'wait') {
      this.mod_func_clearTO_cursor();

      if (this.mod_vars_PasteItemId !== '' && this.mod_vars_PasteTo !== '') {
        const itemToUpdate = this.items.find(each => each._id === this.mod_vars_PasteItemId);
        if (itemToUpdate) {
          itemToUpdate.parent = this.mod_vars_PasteTo;
          itemToUpdate.parameters.position.x = this.mod_vars_mouseDownPos.x;
          itemToUpdate.parameters.position.y = this.mod_vars_mouseDownPos.y;

          await this.itemsServ.updateItem(itemToUpdate);
          this.mod_vars_PasteTo = '';
          this.mod_vars_PasteItemId = '';

          await this.getItems();
          this.refreshScreen();
        }
      } else {
        //estamos creando un item

        let parentId = this.mod_vars_contextFrom;
        const newItem = {
          parent: parentId,
          name: this.mod_vars_typeName,
          description: '',
          section: 'modelizer',
          type: this.mod_vars_typeId,
          parameters: {
            position: {
              x: this.mod_vars_mouseDownPos.x,
              y: this.mod_vars_mouseDownPos.y,
            }
          }
        };
        this.mod_func_createItem(newItem)
      }

    }
  }

  onWindowLoadOrResize(): void {

    const leftMain = 60;
    const leftCanvas = 235;
    const top = 0;

    const innerWidth = window.innerWidth;
    const innerHeight = window.innerHeight;

    if (true) {
      this.divItems = document.getElementById('divItems');
      const navoptions = document.getElementById('navoptions');

      navoptions.style.position = 'absolute';

      this.mod_vars_divMain = document.getElementById('main');

      this.mod_vars_divMain.addEventListener('mousedown', this.syn_func_divMainMouseDown.bind(this), true);

      const left2 = (leftMain - this.offsetParent('main').left);
      const top2 = this.offsetParent('main').top;

      navoptions.style.top = '0px';
      navoptions.style.left = leftCanvas + 'px';

      this.mod_vars_divMain.style.left = left2 + 'px';

      this.divItems.style.backgroundColor = '#eeeeff';
      this.divItems.style.borderStyle = 'solid';
      this.divItems.style.borderWidth = '1px';
      this.divItems.style.position = 'absolute';
      this.divItems.style.left = leftCanvas + 'px';
      this.divItems.style.top = '0 px';
      this.divItems.style.width = (window.innerWidth - (leftCanvas + leftMain + 17)) + 'px';
      this.divItems.style.height = (window.innerHeight - (top + top2 + 55)) + 'px';

      //const lleft = document.getElementById('divLeftSynoptic');
      //lleft.style.height = (window.innerHeight - top) + 'px';
    }
  }

  // method for update iem
  async updateItem(item): Promise<any> {
    const oldItem = this.items.find(it => it._id === item._id);
    const result = await this.itemsServ.updateItem(item);


    if (result.err) {
      console.log(result.err);
      const text = result.err.error.message.split('\'');
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      const msg = this.modelizerText.update_error;
      this.updateError = msg.replace('replace_text', text[1]);
      setTimeout(() => { this.updateError = undefined }, 5000);
    }
    await this.getItems();
    // await this.refreshTree(true);

    const modItem = this.items.find(it => it._id === item._id);
    if (this.selfPageCheckId(oldItem._id)) {
      if (modItem.parameters.selfPage === false) {
        await this.naveg.pop();
        const _id = this.naveg[this.naveg.length - 1]['_id'];
        await this.naveg.pop();
        this.go(_id);
      } else {
        try {
          this.go(oldItem._id);
        } catch (error) {
        }
      }
    } else {
      if (modItem.parameters.selfPage) {
        this.go(oldItem._id);
      }
    }
    this.refreshScreen();
  }
  // Method for delete item
  async deleteItem(deleteItem): Promise<any> {

    if (this.items.find(itt => itt.parent === deleteItem._id)) {
      return alert('No puedes eliminar este Item...');
    }

    if (deleteItem.children?.length !== 0) {
      return alert('No puedes eliminar este Item');
    }


    /*
    if (deleteItem.parent) {
      const parent = await this.itemsServ.findItem(deleteItem.parent);

      // Remove children of parent
      const index = parent.children.indexOf(deleteItem._id);
      parent.children.splice(index, 1);
      // Update parent
      await this.itemsServ.updateItem(parent);
    }
    */

    await this.itemsServ.deleteItem(deleteItem._id);
    if (deleteItem.type._id === '5f871334821fd6593dd88071') { //es un protocolo, me cargo los tags
      const info: any = { tags: [], parentsInfo: { protocol: deleteItem._id, thing: deleteItem.parent } };
      await this.tagServ.updateTags(info);
    }

    /*
    const info: any = { tags: [], parentsInfo: { protocol: this.item._id, thing: this.item.parent } };
    await this.tagServ.updateTags(info);
    */

    await this.getItems();

    // await this.refreshTree(true);
    this.refreshScreen();

  }

  async refreshTree(load) {
    if (load) {
      await this.getStructure(false);
    }
    const tree = $('#jstreeNew').jstree(true);

    tree.settings.core.data = this.structure;

    tree.refresh();
    tree.redraw(true);
  }

  async emitOption2(e: any): Promise<any> {

    const item = this.items.find(it => it._id === e);

    let self = false;
    try {
      this.position = item.parameters.position;

      if (!item.parameters.hasOwnProperty('position')) {
        item.parameters['position'] = { "x": 100, "y": 100 };
        await this.itemsServ.updateItem(item);
      }
    } catch (error) {

    }

    try {
      if (item.parameters.hasOwnProperty('selfPosition')) {
        if (item._id !== this.naveg[this.naveg.length - 1]['_id']) {
          self = true;
          this.position = item.parameters.selfPosition;
        }
      }
    } catch (error) {

    }

    if (self) {
      item.parentsArray = [];

      const itt: any = [];
      let nofound = true;
      this.naveg.forEach(nav => {
        if (nav._id === item._id) {
          nofound = false;
        }

        if (nofound) {
          itt.push(nav);
        }
      });
      this.naveg = itt;

      this.naveg.push({ txt: item.name, _id: item._id });

      await this.getStructure(item);
      this.refreshTree(false);
    } else {


      for (var [tKey, tValue] of Object.entries(Object.entries(item.type.parameters['general']))) {

        switch (tValue[0]) {
          case 'synoptic':
            const syns = this.anotherItems.filter(item => item.type?._id === '5f900543c7c2a908ade7b828');
            syns.forEach(syn => {
              item.type.parameters['general']['synoptic']['options'].push({ key: syn._id, value: syn.name });
            });
            break;
          case 'datadesign':
            const dds = this.anotherItems.filter(item => item.type?._id === '5f994d5908425a1078a1b7b2');
            dds.forEach(dds => {
              item.type.parameters['general']['datadesign']['options'].push({ key: dds._id, value: dds.name });
            });
            break;
          default:
            break;
        }

      }
      this.sharServ.emitChange('modelizer', item);
    }
  }


  // Create new item method
  async createItem2(e): Promise<any> {

    const id = e.original.typeId;
    const parentId = e.id;

    // Taking type of the new item
    let type: any = {};
    for (const typeIn of this.types) {

      if (typeIn.parent === id) {
        type = typeIn;
      }
    }
    // New Item
    const newItem = {
      parent: parentId,
      name: type.name,
      description: '',
      section: 'modelizer',
      type: type._id

    };

    const itemCreated = await this.itemsServ.createItem(newItem);

    // Modify parent item for add children ID
    const updateItem = await this.itemsServ.findItem(e.id);

    updateItem.children.push(itemCreated._id);

    await this.itemsServ.updateItem(updateItem);
    await this.refreshTree(true);
  }

  //Return Modelizer Items
  async getDirectories() {
    return await this.getItems();
  }

  getChild(parent: any, first) {
    const element: any = {};

    element.id = parent._id;
    element.text = parent.name;
    element.type = parent.type.name;
    element.typeId = parent.type._id;
    element.icon = parent.type.parameters.icon;
    element.li_attr = { "class": parent.type.name };
    //element.links = [];
    //element.x = parent.parameters.x;
    //element.y = parent.parameters.y;
    element.children = [];

    const childs = this.items.filter(item => item.parent === parent._id);
    childs.forEach(child => {
      const _child: any = {};
      _child._id = child._id;
      _child.name = child.name;
      _child.type = child.type;

      const getChild = this.getChild(_child, false);
      element.children.push(getChild);
    });
    if (first) {
      this.structure.push(element);
      //this.itemsInScreen.push(element);
    } else {
      return element;
    }
  }

  getChildNew(parent: any, first) {
    const element: any = {};

    element.id = parent?._id;
    element.text = parent?.name;
    element.type = parent?.type?.name;
    element.typeId = parent?.type?._id;
    // element.icon = false;

    element.icon = parent?.type?.parameters?.icon;

    element.children = [];
    element.parentsArray = [...parent.parentsArray];

    let x = 300;
    let y = 200;

    const itemF: any = this.items.find(item => item._id === parent._id);

    let goToChilds = true;
    let self = '';
    if (itemF !== undefined) {
      if (itemF.hasOwnProperty('parameters')) {
        if (itemF.parameters.hasOwnProperty('position')) {
          x = parseInt(itemF.parameters.position.x);
          y = parseInt(itemF.parameters.position.y);
        }
        if (itemF.parameters.hasOwnProperty('selfPosition')) {
          if (itemF._id !== this.naveg[this.naveg.length - 1]['_id']) {
            goToChilds = false;
            element.text = element.text + '...';
            self = 'Self';
          } else {
            x = parseInt(itemF.parameters.selfPosition.x);
            y = parseInt(itemF.parameters.selfPosition.y);
          }
        }
      }
    }

    let tipoMenu = "menu2";

    if (parent?.type?.name === 'Protocolo') {
      // tipoMenu = "menuProtocol";
    }

    let txt = parent.name;
    if (element.type === 'Protocolo') {
      if (itemF.parameters.general?.master) {
        this.master.push(itemF);
        txt = '<' + txt + '>';
      }
    }

    this.itemsInScreen.push({ tipoMenu, self: self, doParent: '', type: parent.type?.name, typeId: parent.type?._id, id: parent._id, x, y, w: 120, h: 50, text: txt, links: [], parents: element.parentsArray });

    if (goToChilds) {
      const childs = this.items.filter(item => item.parent === parent._id);
      childs.forEach(child => {
        const _child: any = {};
        _child._id = child._id;
        _child.name = child.name;
        _child.type = child.type;
        _child.parentsArray = [...parent.parentsArray];
        _child.parentsArray.push(parent._id);

        const itemInS = this.itemsInScreen.find(item => item.id === parent._id);
        itemInS.links.push({ to: child._id, from_id: child._id, start: 1, end: 1 });

        const getChild = this.getChildNew(_child, false);
        element.children.push(getChild);

      });
    }
    if (first) {
      this.structure.push(element);
    } else {
      return element;
    }
  }

  async refreshScreen() {
    if (this.naveg[this.naveg.length - 1]['_id'] === 'home') {
      this.naveg = [{ txt: `${this.modelizerText.home}`, _id: 'home' }];
      this.refreshTree(true);
    } else {
      const itemm = await this.items.find(it => it._id === this.naveg[this.naveg.length - 1]['_id']);

      // await this.getStructure(itemm);
      this.refreshTree(true);

      await this.getStructure(itemm);
      this.refreshTree(false);
    }
  }



  //cpc self es para pantalla self
  async getStructure(self) {

    // await this.getDirectories();
    this.structure = [];

    this.itemsInScreen = [];
    this.links = [];
    this.connectors = [];

    if (self) {
      this.getChildNew(self, true);
    } else {
      // this.getChildNew(primerelemento, true);
      this.items.forEach(item => {
        if (item['parent'] === undefined) {
          item.parentsArray = [];
          this.getChildNew(item, true);
        }
      });
    }

    const _items: any = {};
    _items.text = 'Items';
    _items.children = [];

    this.mod_vars_system.items = [];

    this.types.forEach(type => {
      const _type: any = {};
      _type.text = type.name;
      _type.icon = type.parameters.icon;
      _type.li_attr = type.parameters.class;

      this.mod_vars_system.items.push({ name: type.name, id: type._id, icon: type.parameters.icon, insert: true, parents: type.parameters.typeParents, only_one: type.parameters?.only_one });

      if (type.name === 'Protocolo') {
        for (const mast of this.master) {
          if (_type.hasOwnProperty('children') === false) {
            _type.children = [];
          }
          _type.children.push({ text: mast.name, icon: 'assets/img/modelizer2/baseline_cable_black_24dp.png', li_attr: 'protocol' });
        }
      }
      _items.children.push(_type);
    });

    // this.treeItemsInScreen.push(_items);
    // this.structure.push(_items);

    // setTimeout(() => {
    // this.setLinks();
    // }, 3000);
    this.setLinksCatch();

  }

  async loadJSTree(load) {
    if (load) {
      await this.getStructure(false);
    }

    $('#jstreeNew').jstree({
      core: {
        check_callback: true,
        data: this.structure
      },
      contextmenu: {
        items: (node) => {
          const items = $.jstree.defaults.contextmenu.items();

          items.open_node = {
            label: "Open All",
            separator_after: true,
            action: async () => {
              this.expandNode(node);
            }
          };

          delete items.rename;
          delete items.remove;
          delete items.create;
          delete items.ccp;
          delete items.Create;
          return items;
        }
      },
      plugins: ["core", "contextmenu"],
    });

    $('#jstreeNew').on("___ready.jstree", function (e, data) {


      const tree = document.getElementById('jstreeNew');
      const lis = tree.getElementsByTagName('li');
      for (const li of Object.keys(lis)) {
        // const offset = offsetParentNode(lis[li]);
        const anchor = lis[li].children[lis[li].children.length - 1];
        const offset = offsetParentNode(anchor);
        const nw = document.createElement('span');
        nw.style.width = (400 - (offset.left + anchor.offsetWidth)) + 'px';
        nw.style.textAlign = "right";
        nw.style.color = 'green';
        nw.style.display = 'inline-block';
        nw.innerHTML = '&#128065&#128274&#10003';
        lis[li].appendChild(nw);
      }

    });

    $('#jstreeNew').on("open_all.jstree", function (e, data) {

    });

    $('#jstreeNew').on("___after_open.jstree", function (e, data) {

      // const tree = document.getElementById('jstreeNew');

      const anchor = document.getElementById(data.node.id + '_anchor');
      try {
        const node = anchor.parentElement;
        const lis = node.getElementsByTagName('LI');
        for (const li of Object.keys(lis)) {
          // const offset = offsetParentNode(lis[li]);
          const anchor = lis[li].children[lis[li].children.length - 1];
          const offset = offsetParentNode(anchor);
          const nw = document.createElement('span');
          nw.style.width = (400 - (offset.left + anchor.offsetWidth)) + 'px';
          nw.style.textAlign = "right";
          nw.style.color = 'green';
          nw.style.display = 'inline-block';
          nw.innerHTML = '&#128065&#128274&#10003';
          lis[li].appendChild(nw);
        }
      } catch (error) {
        console.log(error);
      }

      /*
      const anchor = document.getElementById(data.node.id + '_anchor');

      const elem = document.getElementById(data.node.a_attr.id);
      const node = elem.parentElement;
      const offset = offsetParentNode(elem);

      const nw = document.createElement('span');
      nw.style.width = (400 - (offset.left + anchor.offsetWidth)) + 'px';
      nw.style.textAlign = "right";
      nw.style.color = 'green';
      nw.style.display = 'inline-block';
      nw.innerHTML = '&#128065&#128274&#10003';

      if (node.children[node.children.length - 1].tagName === 'UL') {
        node.insertBefore(nw, node.children[node.children.length - 1]);
      } else {
        node.appendChild(nw);
      }
      */

    });

    $('#jstreeNew').on("open_node.jstree", function (e, data) {
      // treeControl('li');
    });

    this.jstreeFunctions();
  }

  expandNode(node) {
    $("#jstreeNew").jstree("open_node", node);
    const childrens = node.children_d;
    childrens.forEach(children => {
      const thisnode = $("#jstreeNew").jstree("get_node", children);
      $("#jstreeNew").jstree("open_node", thisnode);
    });
  }

  jstreeFunctions() {

    // Emit Option
    $("#jstreeNew").on('click', 'li > a', (node) => {
      const target = node.target.id;

      const id = target.replace('_anchor', '');
      this.emitOption2(id);
    });

    $("#jstreeNew").on('dblclick', 'li > a', (node) => {

      const target = node.target.id;
      const id = target.replace('_anchor', '');
      var thisNode = $("#jstreeNew").jstree("get_node", id);

      this.expandNode(thisNode);
    });
  }

  onDrop(event) {
    event.preventDefault();
  }

  onDragOver(event) {

    event.stopPropagation();
    event.preventDefault();

    this.eventX = event.layerX;
    this.eventY = event.layerY;

  }

  onDragStart(event) {
    const typeFound: any = this.types.find(type => type['name'] === event.target.text);

    typeFound.parameters.typeParents.forEach(typeParent => {
      if (typeParent === 'none') {
        document.getElementById('divItems').style.backgroundColor = 'cyan';
      } else {
        this.itemsInScreen.forEach(item => {
          if (item.typeId === typeParent) {
            if (typeFound.parameters.hasOwnProperty("only_one")) {

            }
            item.doParent = 'doParent';
            try {
              if (item.self === 'Self') {
                item.doParent = '';
              }
            } catch (error) {
            }
          }
        });
      }
    });
  }

  onDragLeave(event) {
    this.eventX = 0;
    this.eventY = 0;
  }

  onDragEnd(event) {

    if (this.eventX === undefined) {
      this.eventX = 0;
    }
    if (this.eventY === undefined) {
      this.eventY = 0;
    }

    if (this.eventX !== 0 && this.eventY !== 0 && (this.naveg.length === 1 || this.parentElementDrag !== 'none')) {

      const typeFound: any = this.types.find(type => type['name'] === event.target.text);

      if (this.typeParentElementDrag === undefined) {
        this.typeParentElementDrag = 'none';
      }

      if (typeFound.parameters.typeParents.includes(this.typeParentElementDrag)) {
        if (typeFound !== undefined) {
          this.parentTypeDrag = typeFound.parent;
          this.idTypeDrag = typeFound._id;

          // const cadena: any = event.target.text.substring(0, event.target.text.length);
          const cadena: any = event.target.text;
          this.nameElementDrag = cadena; // + (parseInt(event.target.text.indexOf(event.target.text.length - 1)) + this.contadorItem);

          this.createItem(event);
        }
      }
      this.contadorItem++;
    }

    this.parentElementDrag = 'none';
    this.itemsInScreen.forEach(item => {
      item.doParent = '';
    });
    document.getElementById('divItems').style.backgroundColor = '';

  }

  onDragEnter(event) {

    if (event.target.id !== '' && event.target.id !== 'svgMain') {

      const itemOnDrag: any = this.itemsInScreen.find(it => it.id === event.target.id);
      if (itemOnDrag.doParent !== '') {
        this.parentElementDrag = event.target.id;
        const objectID = this.items.find(type => type._id === this.parentElementDrag);
        this.typeParentElementDrag = undefined;
        try {
          this.typeParentElementDrag = objectID.type._id;
        } catch (error) {
        }
      }
    }
    // this.setLinks2();
    // this.setLinks();
  }

  async createItem(e): Promise<any> {
    if (this.idTypeDrag === '5f86d624821fd6593dd8806e') {
      //es un thing, el nombre no se puede repetir en toda la base de datos
    }

    const name = this.nameElementDrag;
    const typeItem = this.idTypeDrag;
    const eventX = this.eventX;
    const eventY = this.eventY;
    const id = this.idTypeDrag;

    let parentId = this.parentElementDrag;
    if (parentId === 'none' || parentId === 'svgMain' || parentId === 'divItems') {
      parentId = undefined;
    }

    // Taking type of the new item
    let type: any = {};
    for (const typeIn of this.types) {
      if (typeIn.parent === id) {
        type = typeIn;
      }
    }
    // New Item
    const newItem = {
      parent: parentId,
      name: name,
      description: '',
      section: 'modelizer',
      type: typeItem,
      parameters: {
        position: {
          x: eventX,
          y: eventY,
        }
      }
    };

    const itemCreated = await this.itemsServ.createItem(newItem);
    await this.getItems();
    this.refreshScreen();

    // this.getItems();
    /*
    await this.refreshTree(true);
    setTimeout(() => {
      this.setLinks();
    }, 100);
    */

    // Modify parent item for add children ID
    // const updateItem = await this.itemsServ.findItem(e.id);
    // updateItem.children.push(itemCreated._id);
    // await this.itemsServ.updateItem(updateItem);

    /*     this.getItems();
        await this.refreshTree();
        setTimeout(() => {
          this.setLinks();
        }, 2000); */
  }

  setLinks2(): void {
    this.itemsInScreen.forEach(item => {
      const offsetStart = (<any>$(`#${item.id}`)).offset();
      const offsetDiv = (<any>$('#divItems')).offset();

      const width = (<any>$(`#${item.id}`)).width();
      const height = (<any>$(`#${item.id}`)).height();

      switch (item.type) {
        case 'instalation':
          break;
        case 'lineItem':
          break;
      }

      item.links.forEach(link => {
        const offsetEnd = (<any>$(`#${link.to}`)).offset();
        const widthEnd = (<any>$(`#${link.to}`)).width();
        const heightEnd = (<any>$(`#${link.to}`)).height();

        let xIn0 = -offsetDiv.left + 15 + offsetStart.left + width;
        let yIn0 = -offsetDiv.top + 10 + offsetStart.top + (height / 2);

        let xIn1 = -offsetDiv.left + 10 + offsetStart.left + (width / 2);
        let yIn1 = -offsetDiv.top + 20 + offsetStart.top + height;

        let xFin0 = -offsetDiv.left + offsetEnd.left;
        let yFin0 = -offsetDiv.top + 10 + offsetEnd.top + ((<any>$(`#${link.to}`)).height() / 2);

        let xFin1 = -offsetDiv.left + 10 + offsetEnd.left + ((<any>$(`#${link.to}`)).width() / 2);
        let yFin1 = -offsetDiv.top + offsetEnd.top - 3;

        let startX = 0, startY = 0, endX = 0, endY = 0;

        let xFactor = 0, yFactor = 0;

        link.start = 0;
        link.end = 0;

        if (xFin0 - xIn0 < yFin0 - yIn0) {
          link.start = 1;
          link.end = 1;

          this.connectors.push(this.getConnectBottom(this.parentElementDrag));
          this.connectors.push(this.getConnectTop(link.to));
        } else {
          this.connectors.push(this.getConnectRight(this.parentElementDrag));
          this.connectors.push(this.getConnectLeft(link.to));
        }

        let xIn;
        let xFin;
        let yIn;
        let yFin;

        if (link.start === 0) {
          startX = 100;
          xFactor = 1;

          xIn = xIn0;
          yIn = yIn0;
        } else {
          yFactor = 1;
          startY = 100;

          xIn = xIn1;
          yIn = yIn1;
        }

        if (link.end === 0) {
          xFactor++;
          endX = 100;

          xFin = xFin0;
          yFin = yFin0;
        } else {
          yFactor++;
          endY = 100;

          xFin = xFin1;
          yFin = yFin1;
        }

        if (((xFin0 - xIn0) / xFactor) < 100) {
          if (startX === 100) {
            startX = ((xFin0 - xIn0) / xFactor);
          }

          if (endX === 100) {
            endX = ((xFin0 - xIn0) / xFactor);
          }
        }

        if (((yFin0 - yIn0) / yFactor) < 100) {
          if (startY === 100) {
            startY = ((yFin0 - yIn0) / yFactor);
          }

          if (endY === 100) {
            endY = ((yFin0 - yIn0) / yFactor);
          }
        }

        this.links.push({ from_id: this.parentElementDrag, to_id: link.to, xIn0: xIn, yIn0: yIn, xIn1: xIn + startX, yIn1: yIn + startY, xFin1: xFin - endX, yFin1: yFin - endY, xFin0: xFin, yFin0: yFin });
      });

    });
  }

  offsetParent(_id) {
    let rr: any = document.getElementById(_id);
    const tt = { 'left': 0, 'top': 0 };
    try {
      if (rr.offsetLeft) {
        tt['left'] = rr.offsetLeft;
        tt['top'] = rr.offsetTop;
        while (rr.offsetParent) {
          rr = rr.offsetParent;
          tt['left'] = tt['left'] + rr.offsetLeft;
          tt['top'] = tt['top'] + rr.offsetTop;
        }
      }
    } catch (error) {
    }
    return tt;
  }
}

function offsetParentNode(node) {

  let tt = { 'left': 0, 'top': 0, 'width': 0 };
  try {
    if (node.offsetLeft) {
      tt['left'] = node.offsetLeft;
      tt['top'] = node.offsetTop;
      tt['width'] = node.offsetWidth;
      while (node.offsetParent) {
        node = node.offsetParent;
        tt['left'] = tt['left'] + node.offsetLeft;
        tt['top'] = tt['top'] + node.offsetTop;
        tt['width'] = tt['width'] + node.offsetTop;
      }
    }
  } catch (error) {
  }
  return tt;
}

function getOffsetParent(node, obj) {
  obj['left'] = obj['left'] + node.offsetLeft;
  obj['top'] = obj['top'] + node.offsetTop;
  obj['width'] = obj['width'] + node.offsetWidth;
  obj['height'] = obj['height'] + node.offsetHeight;
  if (node.offsetParent) {
    getOffsetParent(node.offsetParent, obj);
  } else {
    return obj;
  }
}

function treeButtons() {
  const tree = document.getElementById('jstreeNew');
  const anchors: HTMLCollection = tree.getElementsByTagName('a');
  for (const anchor of Object.keys(anchors)) {
    const offset = offsetParentNode(anchors[anchor].parentElement);
    if (anchors[anchor].childElementCount > 1) {
      anchors[anchor].parentNode.removeChild(anchors[anchor].nextElementSibling);
    }
    const nw = document.createElement('span');
    nw.style.width = (235 - (offset.left + anchors[anchor].offsetWidth)) + 'px';
    nw.style.textAlign = "right";
    nw.style.color = 'green';
    nw.style.display = 'inline-block';
    nw.innerHTML = '&#128065&#128274&#10003';
    anchors[anchor].parentNode.appendChild(nw);
  }
}

function treeControl(type) {
  const tree = document.getElementById('jstreeNew');
  const nodes: HTMLCollection = tree.getElementsByTagName(type);
  for (const node of Object.keys(nodes)) {
    if (nodes[node].children[nodes[node].children.length - 1].tagName === 'SPAN') {
      nodes[node].removeChild(nodes[node].children[nodes[node].children.length - 1]);
    }
    const offset = offsetParentNode(nodes[node]);
    const nw = document.createElement('span');
    nw.style.width = (235 - (offset.left + nodes[node].children[nodes[node].children.length - 1].offsetWidth)) + 'px';
    nw.style.textAlign = "right";
    nw.style.color = 'green';
    nw.style.display = 'inline-block';
    nw.innerHTML = '&#128065&#128274&#10003';
    nodes[node].appendChild(nw);
  }
}
