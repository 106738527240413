import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GaugesModule } from '@biacsics/ng-canvas-gauges';
import { ChartjsModule } from '@ctrl/ngx-chartjs';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { DataTablesModule } from 'angular-datatables';
import { ContextMenuModule } from 'ngx-contextmenu';
import { NgxGaugeModule } from 'ngx-gauge';
import { QuillModule } from 'ngx-quill';

import { AdministrationMenuComponent } from 'src/app/components/administration/administrarion-menu/administrarion-menu.component';
import { AuditTrailComponent } from 'src/app/components/administration/others/audit-trail/audit-trail.component';
import { OthersComponent } from 'src/app/components/administration/others/others.component';
import { PermitsComponent } from 'src/app/components/administration/permits/permits.component';
import { RolesComponent } from 'src/app/components/administration/roles/roles.component';
import { UsersComponent } from 'src/app/components/administration/users/users.component';
import { AlarmsComponent } from 'src/app/components/alarms/alarms.component';
import { BackupsSystemComponent } from 'src/app/components/backups/backups-system/backups-system.component';
import { BackupsTagsComponent } from 'src/app/components/backups/backups-tags/backups-tags.component';
import { ConfigComponent } from 'src/app/components/config/config.component';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
import { DataDesignComponent } from 'src/app/components/datadesign/datadesign.component';
import { DialogComponent } from 'src/app/components/dialog/dialog/dialog.component';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { ItemTableItem } from 'src/app/components/items/item-table/item-table.item';
import { LeftSidebarComponent } from 'src/app/components/left-sidebar/left-sidebar.component';
import { LoginComponent } from 'src/app/components/login/login.component';
import { MainComponent } from 'src/app/components/main/main.component';
import { MessagesComponent } from 'src/app/components/messages/messages.component';
import { Modelizer5Component } from 'src/app/components/modelizer5/modelizer5.component';
import { AlarmFormComponent } from 'src/app/components/optionscomm/alarm-form/alarm-form.component';
import { OptionscommComponent } from 'src/app/components/optionscomm/optionscomm.component';
import { OptionsDynComponentV2 } from 'src/app/components/optionsdyn_v2/optionsdyn_v2.component';
import { OptionsDynModalComponent } from 'src/app/components/optionsdynmodal/optionsdynmodal.component';
import { SchedulerComponent } from 'src/app/components/scheduler/scheduler.component';
import { ScriptsComponent } from 'src/app/components/scripts/scripts.component';
import { SynopticCoreComponent } from 'src/app/components/synoptic-core/synoptic-core.component';
import { SynopticnewComponent } from 'src/app/components/synopticnew/synopticnew.component';
import { Synopticplayer_v2Component } from 'src/app/components/synopticplayer_v2/synopticplayer_v2.component';
import { TableComponent } from 'src/app/components/table/table.component';

import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';

import { AuthInterceptor } from 'src/app/interceptors/auth.interceptor';
import { HeaderInterceptor } from 'src/app/interceptors/header.interceptor';

import { AuditTrailLanguagesPipe } from 'src/app/pipes/audit-trail-languages.pipe';
import { PermissionsPipe } from 'src/app/pipes/permissions.pipe';
import { ReversePipe } from 'src/app/pipes/reverse.pipe';

import { MaterialModule } from 'src/app/material.module';
import { SupportComponent } from './components/config/support/support.component';
import { SystemComponent } from './components/config/system/system.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    PermitsComponent,
    RolesComponent,
    UsersComponent,
    OthersComponent,
    AdministrationMenuComponent,
    LeftSidebarComponent,
    MainComponent,
    PermissionsPipe,
    OptionscommComponent,
    Modelizer5Component,
    OptionsDynComponentV2,
    OptionsDynModalComponent,
    SynopticnewComponent,
    Synopticplayer_v2Component,
    DataDesignComponent,
    MessagesComponent,
    ReversePipe,
    ScriptsComponent,
    AuditTrailComponent,
    AuditTrailLanguagesPipe,
    SynopticCoreComponent,
    AlarmsComponent,
    SchedulerComponent,
    ConfigComponent,
    BackupsSystemComponent,
    BackupsTagsComponent,
    TableComponent,
    AlarmFormComponent,
    ItemTableItem,
    DialogComponent,
    SystemComponent,
    SupportComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    DataTablesModule,
    DragDropModule,
    QuillModule.forRoot(),
    CodemirrorModule,
    NgxGaugeModule,
    GaugesModule,
    ChartjsModule,
    NgxSliderModule,
    ContextMenuModule.forRoot(),
    DlDateTimeDateModule,
    DlDateTimePickerModule,
    BrowserAnimationsModule,
    MaterialModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
