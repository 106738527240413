import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  private BASE_URL; // = environment.base_url;

  /**
   * Constructor
   * @param http httpClient
   * @param createHeaders headers service
   */
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.base_url;
  }

  /**
   * Get roles
   */
  getRoles(): Promise<any> {
    return this.http.get(this.BASE_URL + '/v1/api/roles').toPromise();
  }

  /**
   * Add role
   * @param newRole role to add
   */
  newRole(newRole): Promise<any> {
    return this.http.post(this.BASE_URL + '/v1/api/roles', newRole).toPromise();
  }

  /**
   * Update role
   * @param editedRole role to edit
   */
  editeRole(editedRole): Promise<any> {
    return this.http
      .put(this.BASE_URL + '/v1/api/roles/' + editedRole._id, editedRole)
      .toPromise();
  }

  /**
   * Delete role
   * @param deleteId id of role to delete
   */
  delteRole(deleteId): Promise<any> {
    return this.http
      .delete(this.BASE_URL + '/v1/api/roles/' + deleteId)
      .toPromise();
  }
}
