<nav id="menu_lateral" class="main-menu d-lg-block d-none">
  <ul>
    <li [ngClass]="{ active: isActive('modelizer') }" routerLink="/modelizer">
      <a>
        <i class="fa fa-2x">
          <mat-icon aria-hidden="true" fontIcon="material-icons-outlined">cable</mat-icon>
        </i>
        <span class="nav-text">
          {{ sidebarText?.modeler }}
        </span>
      </a>
    </li>
    <li [ngClass]="{ active: isActive('synopticsnew') }" routerLink="/synopticsnew">
      <a>
        <i class="fa fa-2x">
          <mat-icon aria-hidden="true">dvr</mat-icon>
        </i>
        <span class="nav-text">
          {{ sidebarText?.synoptics }}
        </span>
      </a>
    </li>
    <li [ngClass]="{ active: isActive('reportsV2') }" routerLink="/reportsV2">
      <a>
        <i class="fa fa-file fa-2x"></i>
        <span class="nav-text">
          {{ sidebarText?.reports }}
        </span>
      </a>
    </li>
    <li [ngClass]="{ active: isActive('scripts') }" routerLink="/scripts">
      <a>
        <i class="fa fa-2x">
          <mat-icon aria-hidden="true">code</mat-icon>
        </i>
        <span class="nav-text">
          {{ sidebarText?.scripts }}
        </span>
      </a>
    </li>
    <li [ngClass]="{ active: isActive('datadesign') }" routerLink="/datadesign">
      <a>
        <i class="fa fa-database"></i>
        <span class="nav-text">
          {{ sidebarText?.datadesign }}
        </span>
      </a>
    </li>
    <li [ngClass]="{ active: isActive('administration') }" class="has-subnav" routerLink="/administration">
      <a>
        <i class="fa fa-users fa-2x"></i>
        <span class="nav-text">
          {{ sidebarText?.user_management }}
        </span>
      </a>
    </li>
    <li (click)="openMailBox()" class="has-subnav">
      <a>
        <div>
          <i class="fa fa-2x">
            <mat-icon aria-hidden="true">mail</mat-icon>
            <i *ngIf="mail?.unread.length > 0" class="fa fa-exclamation-circle exclamation"></i>
          </i>
        </div>
        <span class="nav-text">
          {{ sidebarText?.mailBox }}
        </span>
      </a>
    </li>
    <li class="has-subnav" [ngClass]="{ active: isActive('alarms') }" routerLink="/alarms">
      <a>
        <div>
          <i class="fa fa-bell fa-2x"><i *ngIf="alarms" class="fa fa-exclamation-circle exclamation"></i></i>
        </div>
        <span class="nav-text">
          {{ sidebarText?.alarms }}
        </span>
      </a>
    </li>
    <li [ngClass]="{ active: isActive('settings') }" routerLink="/settings/system">
      <a>
        <i class="fa fa-2x">
          <mat-icon aria-hidden="true" fontIcon="material-icons-filled">settings</mat-icon>
        </i>
        <span class="nav-text">
          {{ sidebarText?.settings }}
        </span>
      </a>
    </li>
  </ul>

  <ul class="logout">
    <li>
      <a>
        <i class="fa fa-2x">
          <mat-icon aria-hidden="true">translate</mat-icon>
        </i>
        <select class="form-control nav-text" id="languageSelect" (change)="selectLanguage($event.target?.value)">
          <option value="es" [selected]="userLanguage === 'es'">
            {{ sidebarText?.spanish }}
          </option>
          <option value="ca" [selected]="userLanguage === 'ca'">
            {{ sidebarText?.catalan }}
          </option>
          <option value="en" [selected]="userLanguage === 'en'">
            {{ sidebarText?.english }}
          </option>
        </select>
      </a>
    </li>
    <li (click)="logOut()">
      <a>
        <i class="fa fa-power-off fa-2x"></i>
        <span class="nav-text">
          {{ sidebarText?.logout }}
        </span>
      </a>
    </li>
  </ul>
</nav>

<nav class="main-menu-none main-menu d-md-block d-lg-none">
  <ul>
    <li [ngClass]="{ active: isActive('modelizer') }" routerLink="/modelizer">
      <a>
        <i class="fa fa-2x">
          <mat-icon aria-hidden="true" fontIcon="material-icons-outlined">cable</mat-icon>
        </i>
        <span class="nav-text">
          {{ sidebarText?.modeler }}
        </span>
      </a>
    </li>
    <li [ngClass]="{ active: isActive('synopticsnew') }" routerLink="/synopticsnew">
      <a>
        <i class="fa fa-2x">
          <mat-icon aria-hidden="true">dvr</mat-icon>
        </i>
        <span class="nav-text">
          {{ sidebarText?.synoptics }}
        </span>
      </a>
    </li>
    <li [ngClass]="{ active: isActive('reportsV2') }" routerLink="/reportsV2">
      <a>
        <i class="fa fa-file fa-2x"></i>
        <span class="nav-text">
          {{ sidebarText?.reports }}
        </span>
      </a>
    </li>
    <li [ngClass]="{ active: isActive('scripts') }" routerLink="/scripts">
      <a>
        <i class="fa fa-2x">
          <mat-icon aria-hidden="true">code</mat-icon>
        </i>
        <span class="nav-text">
          {{ sidebarText?.scripts }}
        </span>
      </a>
    </li>
    <li [ngClass]="{ active: isActive('datadesign') }" routerLink="/datadesign">
      <a>
        <i class="fa fa-database"></i>
        <span class="nav-text">
          {{ sidebarText?.datadesign }}
        </span>
      </a>
    </li>
    <li [ngClass]="{ active: isActive('administration') }" class="has-subnav" routerLink="/administration">
      <a>
        <i class="fa fa-users fa-2x"></i>
        <span class="nav-text">
          {{ sidebarText?.user_management }}
        </span>
      </a>
    </li>
    <li (click)="openMailBox()" class="has-subnav">
      <a>
        <div>
          <i class="fa fa-2x">
            <mat-icon aria-hidden="true">mail</mat-icon>
            <i *ngIf="mail?.unread.length > 0" class="fa fa-exclamation-circle exclamation"></i>
          </i>
        </div>
        <span class="nav-text">
          {{ sidebarText?.mailBox }}
        </span>
      </a>
    </li>
    <li class="has-subnav" [ngClass]="{ active: isActive('alarms') }" routerLink="/alarms">
      <a>
        <div>
          <i class="fa fa-bell fa-2x"><i *ngIf="alarms" class="fa fa-exclamation-circle exclamation"></i></i>
        </div>
        <span class="nav-text">
          {{ sidebarText?.alarms }}
        </span>
      </a>
    </li>
  </ul>
  <li [ngClass]="{ active: isActive('settings') }" routerLink="/settings/system">
    <a>
      <i class="fa fa-2x">
        <mat-icon aria-hidden="true" fontIcon="material-icons-filled">settings</mat-icon>
      </i>
      <span class="nav-text">
        {{ sidebarText?.settings }}
      </span>
    </a>
  </li>

  <ul class="logout">
    <li>
      <a>
        <i class="fa fa-2x">
          <mat-icon aria-hidden="true">translate</mat-icon>
        </i>
        <select class="form-control nav-text" id="languageSelect" (change)="selectLanguage($event.target?.value)">
          <option value="es" [selected]="userLanguage === 'es'">
            {{ sidebarText?.spanish }}
          </option>
          <option value="ca" [selected]="userLanguage === 'ca'">
            {{ sidebarText?.catalan }}
          </option>
          <option value="en" [selected]="userLanguage === 'en'">
            {{ sidebarText?.english }}
          </option>
        </select>
      </a>
    </li>
    <li (click)="logOut()">
      <a>
        <i class="fa fa-power-off fa-2x"></i>
        <span class="nav-text">
          {{ sidebarText?.logout }}
        </span>
      </a>
    </li>
  </ul>
</nav>
