import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  @Input() isOpened: boolean = false;
  @Output() isOpenedChange = new EventEmitter<boolean>();

  @HostListener('document:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    if (this.isOpened && event.key === 'Escape') {
      this.closeDialog();
    }
  }

  closeDialog() {
    this.isOpened = false;
    this.isOpenedChange.emit(this.isOpened);
  }
}
