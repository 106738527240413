<div class="login-container">
  <div class="logo-container">
    <img src="assets/img/logo.png" />
  </div>
  <form
    name="login-form"
    [formGroup]="loginForm"
    (ngSubmit)="onSubmit()"
    method="POST"  >
    <div *ngIf="showError" class="alert alert-danger" id="alert" role="alert">
      Usuario o contraseña incorrecto
    </div>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1"
          ><i class="fa fa-user"></i
        ></span>
      </div>
      <input
        type="text"
        class="form-control corp-color"
        formControlName="username"
        placeholder="{{ languageLogin?.user }}"
        aria-label="Usuari"
        aria-describedby="basic-addon1"
      />
    </div>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1"
          ><i class="fa fa-unlock-alt"></i
        ></span>
      </div>
      <input
        type="password"
        class="form-control corp-color"
        formControlName="password"
        placeholder="{{ languageLogin?.password }}"
        aria-label="Contrasenya"
        aria-describedby="basic-addon1"
      />
    </div>
    <input
      type="submit"
      class="btn btn-primary mb-3"
      [disabled]="!loginForm.valid"
      value="{{ languageLogin?.login }}"
    /><br />
    <input
      style="display: none"
      type="button"
      class="btn btn-primary"
      (click)="loginAsGuest()"
      value="{{ languageLogin?.loginAsGuest }}"
    />
  </form>
  <button class="btn" (click)="toggleDisplayKeyboard(); focusFirstInput();" [hidden]="this.visibleKeyboard"
    style="font-size: 35px; position: absolute; right: 35px; bottom: 35px; padding: 0; display: flex; outline: none;">
    <i class="fa fa-keyboard-o" aria-hidden="true"></i>
  </button>
</div>
<div [hidden]="!this.visibleKeyboard" style="text-align:center; position: absolute; width: 99%; min-width: 375px; max-width: 775px; bottom: 1px;">
  <div class="simple-keyboard"></div>
</div>
