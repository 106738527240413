import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private BASE_URL;
  userMail: any;
  /**
   * Constructor
   * @param http httpClient
   */
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.base_url;
  }

  /**
   * Login Method
   * @param user user
   */
  login(user: any): Promise<any> {
    return this.http
      .post(`${this.BASE_URL}/v1/api/auth/login`, user)
      .toPromise();
  }

  logOut(): Promise<any> {
    return this.http.get(this.BASE_URL + '/v1/api/auth/logout').toPromise();
  }

  timeOut(): Promise<any> {
    return this.http.get(this.BASE_URL + '/v1/api/auth/timeout').toPromise();
  }
  reload(): any {
    return this.http.get(this.BASE_URL + '/v1/api/auth/reload').toPromise();
  }
}
