import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ModelizerItemsService {
  private BASE_URL;
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.base_url;
  }

  async getModelizerItems(): Promise<any> {
    return await this.http
      .put(this.BASE_URL + '/v1/api/modelizer_items/info/info', {
        section: 'modelizer',
      })
      .toPromise();
  }

  async getItemsByInfo(info): Promise<any> {
    return await this.http
      .put(this.BASE_URL + '/v1/api/modelizer_items/info/info', info)
      .toPromise();
  }

  async agetModelizerItems(): Promise<any> {
    const modelizerItems: any = [];
    const items: any = await this.http
      .get(this.BASE_URL + '/v1/api/modelizer/items')
      .toPromise();

    for (const item of items) {
      // pushing only modelizer section
      if (item.section === 'modelizer') {
        modelizerItems.push(item);
      }
    }
    return modelizerItems;
  }

  getItemsTypes(type, items): any {
    const types: any = [];
    for (const item of items) {
      if (item.type.name.toLowerCase() === type.toLowerCase()) {
        types.push(item);
      }
    }
    return types;
  }

  getItems(): Promise<any> {
    return this.http.get(this.BASE_URL + '/v1/api/modelizer/items').toPromise();
  }

  createItem(newItem: any): Promise<any> {
    return this.http
      .post(this.BASE_URL + '/v1/api/modelizer/items', newItem)
      .toPromise();
  }

  findItem(id: any): Promise<any> {
    return this.http
      .get(this.BASE_URL + '/v1/api/modelizer/items/' + id)
      .toPromise();
  }

  async updateItem(item: any): Promise<any> {
    return await this.http
      .put(this.BASE_URL + '/v1/api/modelizer/items/' + item._id, item)
      .toPromise();
  }

  async updateItems(items: any): Promise<any> {
    return await this.http
      .put(
        this.BASE_URL + '/v1/api/modelizer/items/updateitems/positions/',
        items
      )
      .toPromise();
  }

  deleteItem(id: string): Promise<any> {
    return this.http
      .delete(this.BASE_URL + '/v1/api/modelizer/items/' + id)
      .toPromise();
  }

  updateFile(id, file) {
    const fd = new FormData();
    fd.append('image', file);
    return this.http
      .put(`${this.BASE_URL}/v1/api/modelizer_items/file/${id}`, fd)
      .toPromise();
  }
}
