import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverse'
})
export class ReversePipe implements PipeTransform {

    transform(value: any[], order = '', column: string = ''): any[] {
      if (!value || order === '' || !order) { return value; } // no array
      if (value.length <= 1) { return value; } // array with only one item
      if (!column || column === '') {
        if(order==='reverse'){
          return value.sort()}
        else{return value.sort().reverse();}
      } // sort 1d array
    }
  }


