import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/service/system/language.service';

@Component({
  selector: 'app-administrarion-menu',
  templateUrl: './administrarion-menu.component.html',
  styleUrls: ['./administrarion-menu.component.scss']
})
export class AdministrationMenuComponent implements OnInit {
  language: any;
  adminText: any;

  constructor(
    private langServ: LanguageService
  ) { }

  async ngOnInit() {
    // get language
    this.language = await this.langServ.getVal();
    // get the users text
    this.adminText = this.language.administration;
  }

}
