import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io } from 'socket.io-client';
import { AuthService } from 'src/app/service/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AlarmsSocketService {
  private socket: any;

  constructor(private authServ: AuthService) {
    const base_url = environment.base_url;

    this.socket = io(`${base_url}/alarms`, {
      transports: ['websocket'],
      auth: {
        token: this.authServ.getToken()?.replace(/"/g, ''),
      },
    });

    this.socket.on('connect_error', (err: Error): void => {
      console.error(`Error on connect Socket.IO server: ${err.message}`);
      if (this.socket.active) {
        console.error('Socket.IO Reconecting...');
      }
    });
  }

  listenAppeared(): Observable<any> {
    return new Observable((subscriber) => {
      this.socket.on('appeared', (data) => {
        subscriber.next(data);
      });
    });
  }

  listenAck(): Observable<any> {
    return new Observable((subscriber) => {
      this.socket.on('ack', (data) => {
        subscriber.next(data);
      });
    });
  }

  emitAck(alarmId: string, alarmLog: any): void {
    this.socket.emit('ack', { alarmId, alarmLog });
  }

  listenDisappeared(): Observable<any> {
    return new Observable((subscriber) => {
      this.socket.on('disappeared', (data) => {
        subscriber.next(data);
      });
    });
  }
}
