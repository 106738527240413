import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  private BASE_URL; // = environment.base_url;
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.base_url;
  }

  // Observable string sources
  private emitChangeSource = new Subject<any>();

  private emitItemSelectedObs = new Subject<any>();

  private emitItemChangedObs = new Subject<any>();

  private emitChangeSyn = new Subject<any>();
  // Observable string streams
  changeEmitted$ = this.emitChangeSource.asObservable();

  changeEmittedSyn$ = this.emitChangeSyn.asObservable();

  changeItemSelected$ = this.emitItemSelectedObs.asObservable();

  changeItemChanged$ = this.emitItemChangedObs.asObservable();

  getModelizer(): Promise<any> {
    return this.http
      .get(this.BASE_URL + '/v1/api/modelizer_types/name/Modelizer')
      .toPromise();
  }

  async getScripting(types): Promise<any> {
    return this.http
      .put(this.BASE_URL + '/v1/api/scripting/types/info/', types)
      .toPromise();
  }

  getSynoptics(): Promise<any> {
    return this.http
      .get(this.BASE_URL + '/v1/api/modelizer_types/name/Synoptics')
      .toPromise();
  }

  getSynopticEditor(): Promise<any> {
    return this.http
      .get(this.BASE_URL + '/v1/api/modelizer_types/name/SynopticEditor')
      .toPromise();
  }

  getReportsV2Editor(): Promise<any> {
    return this.http
      .get(this.BASE_URL + '/v1/api/modelizer_types/name/ReportsV2Editor')
      .toPromise();
  }

  async getSynoptic(): Promise<any> {
    return this.http
      .get(this.BASE_URL + '/v1/api/modelizer_types/name/SynopticsComp')
      .toPromise();
  }

  async getReportsV2(): Promise<any> {
    return this.http
      .get(this.BASE_URL + '/v1/api/modelizer_types/name/ReportsV2Comp')
      .toPromise();
  }

  async getSynopticSystem(): Promise<any> {
    return this.http
      .get(this.BASE_URL + '/v1/api/modelizer_types/typename/SynopticsComp')
      .toPromise();
  }

  async getSystem(type): Promise<any> {
    return this.http
      .get(this.BASE_URL + '/v1/api/system/type/' + type)
      .toPromise();
  }

  async getSystemBy(info): Promise<any> {
    return this.http
      .put(this.BASE_URL + '/v1/api/system/type/info/', info)
      .toPromise();
  }

  async getItemsBy(tofind): Promise<any> {
    return this.http
      .put(this.BASE_URL + '/v1/api/system/items/info/', tofind)
      .toPromise();
  }

  async updateByInfo(info): Promise<any> {
    return this.http
      .put(this.BASE_URL + '/v1/api/system/update/info/', info)
      .toPromise();
  }

  /**
   * Get users
   */
  getUsers(): Promise<any> {
    return this.http.get(`${this.BASE_URL}/v1/api/users`).toPromise();
  }

  /**
   *
   * add User
   */
  addUser(user: Promise<any>) {
    return this.http
      .post<any>(`${this.BASE_URL}/v1/api/auth/signup`, user)
      .toPromise();
  }

  /**
   * Update user
   * @param user user
   * @param id user id
   */
  editUser(user: Promise<any>, id) {
    // console.log(user, id);
    return this.http
      .put<any>(`${this.BASE_URL}/v1/api/users/${id}`, user)
      .toPromise();
  }

  /**
   * Delete user
   * @param id id of user
   */
  deleteUser(id) {
    return this.http.delete(`${this.BASE_URL}/v1/api/users/${id}`).toPromise();
  }

  async lp_db(info): Promise<any> {
    return this.http
      .put(this.BASE_URL + '/v1/api/system/queryDD/info/', info)
      .toPromise();
  }

  getReports(): Promise<any> {
    return this.http
      .get(this.BASE_URL + '/v1/api/modelizer_types/name/Reports')
      .toPromise();
  }

  newItem(newItem): Promise<any> {
    return this.http
      .post(this.BASE_URL + '/v1/api/modelizer_items/', newItem)
      .toPromise();
  }

  query(info): Promise<any> {
    return this.http.post(this.BASE_URL + '/v1/api/query/', info).toPromise();
  }

  updItem(editedItem): Promise<any> {
    return this.http
      .put(
        this.BASE_URL + '/v1/api/modelizer_items/' + editedItem._id,
        editedItem
      )
      .toPromise();
  }

  delItem(deleteId): Promise<any> {
    return this.http
      .delete(this.BASE_URL + '/v1/api/modelizer_items/' + deleteId)
      .toPromise();
  }

  getDataDesign(): Promise<any> {
    return this.http
      .get(this.BASE_URL + '/v1/api/modelizer_types/name/DataDesignComp')
      .toPromise();
  }

  getName(info): Promise<any> {
    const tofind = info;
    return this.http
      .put(this.BASE_URL + '/v1/api/modelizer_items/getname/info', tofind)
      .toPromise();
  }

  async getItemBy(info): Promise<any> {
    const tofind = info; // { name: 'GaugeNew'};
    return this.http
      .put(this.BASE_URL + '/v1/api/modelizer_items/info/info', tofind)
      .toPromise();
  }

  // Observable report item
  // Service message commands
  emitChange(fromComponent: any, change: any) {
    this.emitChangeSource.next({ component: fromComponent, item: change });
  }

  emitItem(action, obj) {
    this.emitChangeSource.next({ val: action, item: obj });
  }

  emitComponentItem(item: any) {
    this.emitChangeSource.next(item);
  }

  emitDeleteItem(action, obj) {
    this.emitChangeSource.next({ val: action, item: obj });
  }

  emitUpdateSyn(data) {
    this.emitChangeSyn.next(data);
  }

  emitItemSelected(data) {
    this.emitItemSelectedObs.next(data);
  }

  emitItemChanged(data) {
    this.emitItemChangedObs.next(data);
  }
}
