import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import 'datatables.net';
import * as $ from 'jquery';
import { Permit } from 'src/app/interfaces/permit';
import { PermissionsService } from 'src/app/service/auth/permissions.service';
import { DatatableService } from 'src/app/service/datatable.service';
import { RouterService } from 'src/app/service/router.service';
import { LanguageService } from 'src/app/service/system/language.service';

@Component({
  selector: 'app-permits',
  templateUrl: './permits.component.html',
  styleUrls: ['./permits.component.scss']
})
export class PermitsComponent implements OnInit {
  permits!: Permit[];
  addPermitForm: FormGroup;
  editPermitForm: FormGroup;
  $!: JQuery;
  deletePermitId!: string;
  dtOptions: DataTables.Settings = {};
  language: any;
  permText: any;
  modalText: any;

  /**
   * Constructor
   * @param PermServ permission service
   * @param DataServ datatable service
   * @param langServ language service
   */
  constructor(
    private PermServ: PermissionsService,
    private DataServ: DatatableService,
    private langServ: LanguageService,
    private refresh: RouterService
  ) {
    // Add FormControls to addPermitForm
    this.addPermitForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required)
    });
    // Add FormControls to editPermitForm
    this.editPermitForm = new FormGroup({
      id: new FormControl(),
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required)
    });
  }

  async ngOnInit() {
    // Get permissions from permission service
    await this.getPermits();
    // Timeout function to load datatable
    setTimeout(() => {
      // Get options from datatable service
      const options = this.DataServ.optionsDatatable();
      // Execute datatable function to load it
      (<any>$('#permTable')).dataTable(options);
      let divPerms = document.getElementById("divPerms");
      let dP: any = document.getElementById("divPerms");
      let top = 20;
      while (dP.offsetParent !== null) {
        top = top + dP.offsetTop;
        dP = dP.offsetParent;
      }
      if (divPerms != null) {
        divPerms.style.visibility = "";
        divPerms.style.height = (window.innerHeight - top) + 'px';
      }
    }, 140);
    // get language
    this.language = await this.langServ.getVal();
    // get the users text
    this.permText = this.language.administration.permissions_component;
    // get the modal text
    this.modalText = this.language.administration.modals;
  }

  /**
   * Get permits from permission service
   */
  async getPermits(): Promise<any> {
    this.permits = await this.PermServ.getPermissions();
  }

  /**
   * Add permits submit action
   */
  async addPermitSubmit() {
    // get toas add message
    const text = this.permText.toast_add_message;
    // replace _PERMISSION_ for permission name
    const showText = text.replace('_PERMISSION_', this.addPermitForm.value.name);
    // Send permission info to addPermit method in permission service
    await this.PermServ.addPermit(this.addPermitForm.value);
    // Insert html code to toast body
    $('.toast-body').html(`<p style="font-size:18px;">${showText}</p>`);
    // Show toast
    (<any>$('.toast')).toast('show');
    // Reset addPermitForm
    this.addPermitForm.reset();
    // Hide modal
    (<any>$('#addPermitModal')).modal('hide');
    // Reload array of permissions
    await this.getPermits();
    this.refresh.refresh();
  }

  /**
   * editPermission button pressed
   * @param $event event to know the id of permission
   */
  editPermit($event: any) {
    // get id of permission with event.target with @param
    const id = $event?.target?.dataset?.permitid;
    // foreach array of permissions
    this.permits.forEach(elem => {
      // if permission._id equals id
      if (elem._id === id) {
        // set values to form edit with permission information
        this.editPermitForm.patchValue({
          id: elem._id,
          name: elem.name,
          description: elem.description
        });
      }
    });
  }

  /**
   * edit permission submit action
   */
  async editPermitSubmit() {
    // get toast message
    const text = this.permText.toast_edit_message;
    // Replace _PERMISSION_ for permission name
    const showText = text.replace('_PERMISSION_', this.editPermitForm.value.name);
    // send permission and id to editPermit method from permission service
    await this.PermServ.editPermit(this.editPermitForm.value, this.editPermitForm.value.id);
    (<any>$('#editPermitModal')).modal('hide');
    // Insert html code to toast body
    $('.toast-body').html(`<p style="font-size:18px;">${showText}</p>`);
    // Show toast
    (<any>$('.toast')).toast('show');
    // Reload array of permissions
    this.getPermits();
  }

  /**
   * delete permission button pressed
   * @param $event event to know the id of permission
   */
  confirmDeletePermit($event: any): void {
    // set the permission id to variable deletePermitId
    this.deletePermitId = $event?.target?.dataset?.permitid;
    // foreach array of permits
    this.permits.forEach(elem => {
      // if permission._id is equals deletePermitId
      if (elem._id === this.deletePermitId) {
        // Set in modal confirm the name of permission to delete
        document.getElementById('permitToDelete').innerHTML = elem.name;
      }
    });
  }

  /**
   * Delete permission function
   */
  async deletePermit() {
    // get toast delete message
    const text = this.permText.toast_delete_message;
    // send the permission id to the method deletePermit in permission service
    await this.PermServ.deletePermit(this.deletePermitId);
    // Hide modal
    (<any>$('#deletePermitConfirm')).modal('hide');
    // Insert html code to toast body
    $('.toast-body').html(`<p style="font-size:18px;">${text}</p>`);
    // Show toast
    (<any>$('.toast')).toast('show');
    // Reload array of permissions
    this.getPermits();
  }

}
