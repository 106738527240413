import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  private BASE_URL;

  constructor(private http: HttpClient) {
    this.BASE_URL = environment.base_url;
  }

  async createTag(tag) {
    return this.http
      .post(`${this.BASE_URL}/v1/api/tags`, tag)
      .toPromise()
      .catch((error) => {
        return { error };
      });
  }

  async updateTag(tag) {
    return this.http
      .put(`${this.BASE_URL}/v1/api/tags/${tag.tag}`, tag)
      .toPromise()
      .catch((error) => {
        return { error };
      });
  }

  async updateTags(tags) {
    return this.http
      .put(`${this.BASE_URL}/v1/api/tags/update/info`, tags)
      .toPromise()
      .catch((error) => {
        return { error };
      });
  }

  async findAll() {
    return this.http.get(`${this.BASE_URL}/v1/api/tags`).toPromise();
  }

  async getTagTypes() {
    return this.http.get(`${this.BASE_URL}/v1/api/tagTypes`).toPromise();
  }

  findOne(id) {
    return this.http.get(`${this.BASE_URL}/v1/api/tags/${id}`).toPromise();
  }

  async getTagsByInfo(toFind) {
    return this.http
      .put(this.BASE_URL + '/v1/api/tags/info/info', toFind)
      .toPromise();
  }

  async getValues(toFind) {
    return this.http
      .put(this.BASE_URL + '/v1/api/tagValues/info/info', toFind)
      .toPromise();
  }

  /**
   * Exports TagValues to CSV based on the given dates and thing key.
   *
   * @param thing Tag key of the thing where the TagValues belong.
   * @param start Start date of the export interval.
   * @param end End date of the export interval.
   *
   * @returns Promise resolving the request to the API.
   */
  async exportValuesToCSV(thing: string, start: Date, end: Date): Promise<any> {
    const startDate = start.toISOString().split('.')[0];
    const endDate = end.toISOString().split('.')[0];
    return this.http
      .get(
        `${this.BASE_URL}/v1/api/tagValues/csv/${thing}/${startDate}Z/${endDate}Z`
      )
      .toPromise();
  }

  /**
   * Updates TagValues based on the query and data provided.
   *
   * @param query Query to find the documents to update.
   * @param data Key-value pairs to update in found documents.
   *
   * @returns Promise resolving the request to the API.
   */
  async updateValues(query: object, data: object): Promise<any> {
    return this.http
      .put(`${this.BASE_URL}/v1/api/tagValues`, { query, values: data })
      .toPromise();
  }

  /**
   * Removes TagValues based on the provided query parameters.
   *
   * @param thing Tag key of the thing where the values belongs.
   * @param start Start date of the date range.
   * @param end End date of the date range
   *
   * @returns Promise resolving the request to the API.
   */
  async deleteValuesByInfo(thing?: string, start?: Date, end?: Date) {
    const thingParam = thing ? `thing=${thing}` : '';
    const startParam = start ? `start=${start.toISOString()}` : '';
    const endParam = end ? `end=${end.toISOString()}` : '';

    return this.http
      .delete(
        `${this.BASE_URL}/v1/api/tagValues?${thingParam}&${startParam}&${endParam}`
      )
      .toPromise();
  }

  async importZip(file, pos) {
    const fd = new FormData();
    fd.append('zip', file);
    fd.append('pos', JSON.stringify(pos));
    return this.http
      .put(`${this.BASE_URL}/v1/api/tags/zip/zip`, fd)
      .toPromise();
  }

  async cloneItem(itemId, prev, pos) {
    return this.http
      .put(`${this.BASE_URL}/v1/api/tags/clone/clone`, { itemId, prev, pos })
      .toPromise();
  }

  async cloneBranch(itemId, prev, pos) {
    return this.http
      .put(`${this.BASE_URL}/v1/api/tags/cloneBranch/cloneBranch`, {
        itemId,
        prev,
        pos,
      })
      .toPromise();
  }

  async getTags(thing) {
    const tags: any = await this.findAll();
    const result = [];
    tags.map((elem) => {
      if (elem.thing.externalId === thing) result.push(elem);
    });
    return result;
  }

  removeTag(id) {
    return this.http.delete(`${this.BASE_URL}/v1/api/tags/${id}`).toPromise();
  }
}
