import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModelizerItemsService } from 'src/app/service/modelizer/modelizer-items.service';
import { LanguageService } from 'src/app/service/system/language.service';
import { TagService } from 'src/app/service/tags/tag.service';

@Component({
  selector: 'app-backups-tags',
  templateUrl: './backups-tags.component.html',
  styleUrls: ['./backups-tags.component.scss'],
})
export class BackupsTagsComponent implements OnInit {
  language: any;
  things: any;
  showExportModal: boolean = false;
  showDeleteModal: boolean = false;
  exportForm: FormGroup;
  deleteForm: FormGroup;
  maxDate: string = '';
  exporting: boolean = false;
  deleting: boolean = false;
  deleted: boolean = false;
  deletedCount: number = 0;

  constructor(
    private langServ: LanguageService,
    private tagServ: TagService,
    private modelizerItemsServ: ModelizerItemsService,
    private cdr: ChangeDetectorRef
  ) {
    this.exportForm = new FormGroup({
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      thingKey: new FormControl('', Validators.required),
      deleteData: new FormControl(''),
    });

    this.deleteForm = new FormGroup({
      deleteType: new FormControl('', Validators.required),
    });
  }

  async ngOnInit(): Promise<void> {
    this.language = (await this.langServ.getVal()).option;
    this.maxDate = `${new Date().toISOString().split('.')[0]}`;
    this.things = await this.modelizerItemsServ.getItemsByInfo({
      type: '5f86d624821fd6593dd8806e',
    });
  }

  async exportSubmit(): Promise<void> {
    this.deleted = false;
    const { thingKey, deleteData, startDate, endDate } = this.exportForm.value;
    const start = new Date(startDate);
    const end = new Date(endDate);

    this.exportForm.updateValueAndValidity();

    try {
      if (!this.exportForm.valid || start >= end)
        throw {
          internalMessage: 'Datos inválidos, revisa el rango de fechas.',
        };

      this.exporting = true;

      const data = await this.tagServ.exportValuesToCSV(thingKey, start, end);
      if (data.downloadLink) {
        window.location.href = data.downloadLink;
        this.exporting = false;
        this.cdr.detectChanges();
        if (deleteData) {
          this.showDeleteModal = true;
        }
      } else {
        throw { internalMessage: 'No se han encontrado datos para exportar.' };
      }
    } catch (error) {
      this.exporting = false;
      setTimeout(() => {
        const errorMessage =
          error?.internalMessage ??
          'Error al exportar, inténtalo de nuevo más tarde.';
        this.exportForm.setErrors({ error: errorMessage });
      });
    }
  }

  async deleteSubmit(): Promise<void> {
    try {
      this.deleting = true;

      const { deleteType } = this.deleteForm.value;
      const { thingKey, startDate, endDate } = this.exportForm.value;

      let start: Date = null;
      let end: Date;
      switch (deleteType) {
        case 'range':
          start = new Date(startDate);
          end = new Date(endDate);
          break;
        case 'beforeEnd':
          end = new Date(endDate);
          break;
        default:
          throw { internalMessage: 'Selecciona una opción válida.' };
      }

      const response: any = await this.tagServ.deleteValuesByInfo(
        thingKey,
        start,
        end
      );

      this.deletedCount = Number(response.deletedCount);
      this.deleting = false;
      this.deleted = true;
    } catch (error) {
      this.deleting = false;
      setTimeout(() => {
        const errorMessage =
          error?.internalMessage ??
          'Error al exportar, inténtalo de nuevo más tarde.';
        this.deleteForm.setErrors({ error: errorMessage });
      });
    }
  }
}
