<div class="container dashboard-container mt-lg-3">
    <div class="container mt-lg-3">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/administration/others">{{otherText}}</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Audit Trail</li>
            </ol>
        </nav>
    </div>
    <div class="container">

        <div class="row">
            <!-- <div class="col-12">
                <i *ngIf="searchPlus" (click)="toggleFilter()" class="fa fa-search-plus fa-2x"></i>
                <i *ngIf="!searchPlus" (click)="toggleFilter()" class="fa fa-search-minus fa-2x"></i>
            </div>
            <div #filterContainer class="col-12 toggleFilter"> -->
            <div class="container">
                <form [formGroup]="filter" (ngSubmit)="filterLogs()" class="mb-2" action="POST">
                    <div class="form-row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 ">
                                    <div class="form-row d-flex justify-content-between">
                                        <div class="col-2">
                                            <label class="" for="userSelect">{{language?.label_user}}:</label>
                                            <select formControlName="username" class="form-control" name="" id="">
                                                <option value="null">{{language?.select}}</option>
                                                <option [value]="user.username" *ngFor="let user of users">
                                                    {{user.username}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-2">
                                            <label class="" for="actionSelect">{{language?.label_action}}:</label>
                                            <select formControlName="action" class="form-control" name="" id="">
                                                <option value="null">{{language?.select}}</option>
                                                <option (click)="changeSelect('User')" value="User">
                                                    {{language?.option_user}}
                                                </option>
                                                <option (click)="changeSelect('Login')" value="Login">
                                                    {{language?.option_login}}
                                                </option>
                                                <option (click)="changeSelect('Logout')" value="Logout">
                                                    {{language?.option_logout}}
                                                </option>

                                            </select>
                                        </div>
                                        <div class="col-2">
                                            <label class="" for="statusSelect">{{language?.label_status}}:</label>
                                            <select formControlName="status" class="form-control" name="" id="">
                                                <option value="null">{{language?.select}}</option>
                                                <option *ngIf="statusSelect == 'User'" value="Create">
                                                    {{language?.status_create}}
                                                </option>
                                                <option *ngIf="statusSelect == 'User'" value="Update">
                                                    {{language?.status_update}}
                                                </option>
                                                <option *ngIf="statusSelect == 'User'" value="Delete">
                                                    {{language?.status_delete}}
                                                </option>
                                                <option *ngIf="statusSelect == 'Login'" value="Successfully">
                                                    {{language?.login_successfully}}</option>
                                                <option *ngIf="statusSelect == 'Login'" value="Invalid password">
                                                    {{language?.login_invalid_password}}
                                                </option>
                                                <option *ngIf="statusSelect == 'Login'" value="Invalid user">
                                                    {{language?.login_invalid_user}}</option>
                                                <option *ngIf="statusSelect == 'Logout'" value="Successfully">
                                                    {{language?.logout_successfully}}</option>
                                                <option *ngIf="statusSelect == 'Logout'" value="Timeout">
                                                    {{language?.logout_timeout}}</option>

                                            </select>
                                        </div>
                                        <div class="col-2">
                                            <label class="" for="datefrom">{{language?.time_from}}:</label>

                                            <input formControlName="date" class="form-control" name="" id="date"
                                                type="date">
                                        </div>
                                        <div class="col-2">
                                            <label class="" for="dateTo">{{language?.time_to}}:</label>
                                            <input formControlName="dateTo" class="form-control" name="" id="dateTo"
                                                type="date">
                                        </div>
                                    </div>

                                </div>
                                <div class="col-12 mt-2">
                                    <div class="p-0 col-2 d-flex justify-content-between">
                                        <button class="btn btn-primary">Reset</button>
                                        <button class="btn btn-primary">{{language?.button_filter}}</button>

                                    </div>
                                </div>
                            </div>
                        </div>







                    </div>

                </form>
            </div>

            <!-- </div> -->
        </div>
    </div>

    <!-- Grid menu -->
    <div class="container">
        <table class="table" id="auditTable">
            <thead>
                <tr>
                    <th>{{language?.table_user}}</th>
                    <th>{{language?.table_action}}</th>
                    <th>{{language?.table_status}}</th>
                    <th>{{language?.table_date}}</th>
                </tr>
            </thead>

            <tbody #tableBody>

                <tr *ngFor="let log of logs">
                    <td>{{log.username}}</td>
                    <td>{{log.action | language}}</td>
                    <td>{{log.status | language}}</td>
                    <td>{{log.date | date : 'dd/MM/yyy HH:mm' }}</td>
                </tr>

            </tbody>

        </table>
    </div>
</div>
