import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Keyboard from 'simple-keyboard';
import { MessagesService } from 'src/app/service/system/messages.service';
import { AuthService } from 'src/app/service/auth/auth.service';
import { lpSocket } from 'src/app/service/lp-websocket.service';
import { LanguageService } from 'src/app/service/system/language.service';
import { LoginService } from 'src/app/service/system/login.service';

@Component({
  selector: 'app-login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  // synoptics: any = [];
  // roles: Role[];
  /**
   * Constructor
   * @param loginServ login service
   * @param authServ auth service
   * @param router router
   * @param langServ language service
   */

  showError = false;
  view: string = '';
  keyboard!: Keyboard;
  visibleKeyboard: boolean = false;

  constructor(
    private loginServ: LoginService,
    private authServ: AuthService,
    private router: Router,
    private langServ: LanguageService,
    private messageServ: MessagesService,
    private socketService: lpSocket
  ) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    try {
      const view = urlParams.get('view');
      if (view != null) {
        this.view = view;
      }
    } catch (error) {}

    this.socketService.outEven.subscribe((res: any) => {
      if (res.msg !== 'iot/internal/lp_comm') {
        const topic = JSON.parse(res.msg);
        if (topic.view === this.view) {
          if (topic.synoptic) {
            const separator = topic.synoptic.includes('?') ? '&' : '?';
            window.location.href = `${window.location.origin}/play/${topic.synoptic}${separator}view=${this.view}`;
          }
        }
      }
    });
  }

  language: any;
  languageLogin: any;
  title = 'login-component';

  // Add FormControls to loginForm
  loginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });

  async ngOnInit(): Promise<any> {
    this.showError = false;

    // Load default language
    if (localStorage.language === undefined) {
      this.langServ.setDefaultLanguage();
    }

    this.language = await this.langServ.getVal();
    this.languageLogin = this.language.login;

    this.keyboard = new Keyboard({
      onKeyPress: (button) => this.onKeyPress(button),
      onChange: (input) => {
        const activeElement = document.activeElement as HTMLInputElement;
        const controlName = activeElement.getAttribute('formControlName');
        const formControl = this.loginForm.get(controlName!) as FormControl;

        formControl.setValue(input);
        activeElement.setSelectionRange(
          this.keyboard.getCaretPosition(),
          this.keyboard.getCaretPositionEnd()
        );
      },
      preventMouseDownDefault: true,
      layout: {
        default: [
          '{esc} 1 2 3 4 5 6 7 8 9 0',
          'q w e r t y u i o p',
          'a s d f g h j k l \u00f1 \u00e7',
          '{shift} z x c v b n m {bksp}',
          '{space}',
        ],
        shift: [
          '{esc} 1 2 3 4 5 6 7 8 9 0',
          'Q W E R T Y U I O P',
          'A S D F G H J K L \u00d1 \u00c7',
          '{shift} Z X C V B N M {bksp}',
          '{space}',
        ],
      },
      display: {
        '{esc}': 'esc',
        '{bksp}': '⌫',
        '{shift}': '⇧',
        '{space}': 'space',
      },
    });

    document.addEventListener('focusin', (e: any) => {
      if (e.target.tagName === 'INPUT') {
        this.keyboard.setInput(e.target.value);
        this.keyboard.setCaretPosition(e.target.selectionStart);
      }
    });

    document.addEventListener('focusout', () => {
      this.keyboard.setInput('');
    });

    document.addEventListener('input', (e: any) => {
      if (e.target.tagName === 'INPUT') {
        this.keyboard.setInput(e.target.value);
      }
    });
  }

  /**
   * Change the keyboard layout to set it to uppercase letters.
   */
  setUppercase = () => {
    let currentLayout = this.keyboard.options.layoutName;
    let shiftToggle = currentLayout === 'default' ? 'shift' : 'default';

    this.keyboard.setOptions({
      layoutName: shiftToggle,
    });
  };

  /**
   * Shows or hides the keyboard
   */
  toggleDisplayKeyboard = () => {
    this.visibleKeyboard = !this.visibleKeyboard;
  };

  /**
   * Sets the focus to the first input on the page.
   */
  focusFirstInput = () => {
    document.querySelector('input')?.focus();
  };

  /**
   * Handle keypress event on keyboard.
   * @param button - Value of the key pressed.
   */
  onKeyPress = (button: string) => {
    switch (button) {
      case '{shift}':
        this.setUppercase();
        break;
      case '{esc}':
        this.toggleDisplayKeyboard();
        break;
    }
  };

  async onSubmit() {
    try {
      const data = await this.loginServ.login(this.loginForm.value);

      this.showError = false;

      this.authServ.setToken(data.accessToken);
      this.messageServ.getMailBox();

      if (data.parameters?.synoptic) {
        window.location.href = `${window.location.origin}/synopticplayer/${data.parameters.synoptic}`;
      } else {
        this.router.navigate(['']);
      }
    } catch (err) {
      this.showError = true;
    }
  }

  /**
   * Login as a guest button pressed
   */
  loginAsGuest() {
    this.authServ.logOut();
    this.authServ.logInAsAGuest();
    this.router.navigate(['']);
  }
}
