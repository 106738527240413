import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ConfigService } from 'src/app/service/system/config.service';
import { LanguageService } from 'src/app/service/system/language.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit {
  @ViewChild('toggleElement') toggle!: MatSlideToggle;

  language: any;
  supportActive: boolean | null = null;

  constructor(
    private configServ: ConfigService,
    private langServ: LanguageService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.language = (await this.langServ.getVal()).option;
      const response = await this.configServ.getSupportStatus();
      this.supportActive = response.status;
    } catch (err) {
      this.supportActive = null;
    }
  }

  async supportToggle(event: any) {
    try {
      if (event.checked) await this.configServ.supportEnable();
      else if (!event.checked) await this.configServ.supportDisable();
    } catch (err) {
      this.toggle.checked = !event.checked;
    }
  }
}
