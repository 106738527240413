import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Alarm } from 'src/app/interfaces/alarm';

@Injectable({
  providedIn: 'root',
})
export class AlarmsService {
  private BASE_URL: string;

  constructor(private http: HttpClient) {
    this.BASE_URL = environment.base_url;
  }

  create(alarm: Omit<Alarm, '_id'>) {
    return this.http.post(`${this.BASE_URL}/v1/api/alarms`, alarm).toPromise();
  }

  update(alarm: Alarm): Promise<Alarm> {
    return this.http
      .put<Alarm>(`${this.BASE_URL}/v1/api/alarms/${alarm._id}`, alarm)
      .toPromise();
  }

  delete(id: string) {
    return this.http.delete(`${this.BASE_URL}/v1/api/alarms/${id}`).toPromise();
  }

  get() {
    return this.http.get(`${this.BASE_URL}/v1/api/alarms`).toPromise();
  }

  getLogs(): Promise<any> {
    return this.http.get(`${this.BASE_URL}/v1/api/alarmslogs`).toPromise();
  }

  ackLog(id: string): Promise<any> {
    return this.http
      .post(`${this.BASE_URL}/v1/api/alarms/ack`, { id })
      .toPromise();
  }
}
