<div style="width: 100%; display: flex; justify-content: space-between;">
  <h3>Alarmas</h3>
  <button mat-raised-button color="logi" (click)="ackSelected()">Reconocer</button>
</div>
<mat-tab-group>
  <mat-tab label="Activas">
    <app-table #active [headers]="actvieTableHeaders" [data]="activeTableData" [sortable]="true"
      [sortDefault]="{ header: 'appeared_datetime', direction: 'desc' }" [paginable]="true" [cellStyle]="cellStyle">
    </app-table>
  </mat-tab>
  <mat-tab label="Histórico">
    <app-table #historic [headers]="historicTableHeaders" [data]="historicTableData" [sortable]="true"
      [sortDefault]="{ header: 'date', direction: 'desc' }" [paginable]="true" [cellStyle]="cellStyle">
    </app-table>
  </mat-tab>
</mat-tab-group>
