<div class="optionsdynmodal" id="optionsdynmodal">
  <div class="container" id="optionmodal-container">
    <ul class="nav nav-tabs" id="myTabModal" role="tablist">
      <li class="nav-item" *ngFor="let tab of tabs">
        <a class="nav-link" id="{{ tab.key }}-tab" [ngClass]="{ active: tab.key === 'generalmod' }" data-toggle="tab"
          href="#{{ tab.key }}" role="tab" aria-controls="home" aria-selected="true">{{ language[tab.key] }}</a>
      </li>
      <li class="nav-item" *ngIf="reportItem">
        <a class="nav-link" id="variable-modaltab" [ngClass]="{ active: tab === 'variable' }" data-toggle="tab"
          href="#variable" role="tab" aria-controls="home" aria-selected="true">{{ language.variable }}</a>
      </li>
    </ul>
    <div class="tab-content" id="myTabModalContent">
      <div class="tab-pane fade mt-3" *ngFor="let contentTab of tabs"
        [ngClass]="{ 'show active': contentTab.key === 'generalmod' }" [id]="contentTab.key" role="tabpanel"
        [aria-labelledby]="contentTab - tab">
        <form [formGroup]="optionsDynModalForm" (ngSubmit)="optionmodalSubmit()" method="POST">
          <ng-container *ngFor="let generalKey of contentTab.controls">
            <ng-container *ngFor="let key of generalKey">
              <div class="form-group" [ngStyle]="{
                  display:
                    parameters[contentTab.key][key]?.display === 'hidden'
                      ? 'none'
                      : ''
                }">
                <!-- codemirror -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'codemirror'">
                  <label [for]="key + contentTab.key">
                    {{ language[parameters[contentTab.key][key]?.label] }}
                  </label>
                  <ngx-codemirror [(ngModel)]="parameters[contentTab.key][key].value" [id]="key + contentTab.key"
                    [options]="codeMirrorOptions" [formControlName]="key"
                    value="{{ parameters[contentTab.key][key]?.value }}"></ngx-codemirror>
                </ng-container>
                <!-- Input -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'input'">
                  <label [for]="key + contentTab.key">
                    {{ language[parameters[contentTab.key][key]?.label] }}
                  </label>
                  <input [type]="parameters[contentTab.key][key]?.input" [id]="key + contentTab.key"
                    [formControlName]="key" class="form-control" value="{{ parameters[contentTab.key][key]?.value }}" />
                  <small class="form-text text-muted">
                    {{ language[parameters[contentTab.key][key]?.small] }}
                  </small>
                </ng-container>
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'div'">
                  <div [id]="key + contentTab.key"
                    style="background-color: rgb(255, 255, 255); width:100px; height: 100px;">
                  </div>
                </ng-container>
                <!-- List -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'list'">
                  <label [for]="key + contentTab.key">
                    {{ language[parameters[contentTab.key][key]?.label] }}
                  </label>
                  <input autocomplete="off" [id]="key + contentTab.key" [formControlName]="key" class="form-control"
                    value="{{ parameters[contentTab.key][key]?.value }}" />
                  <small class="form-text text-muted">
                    {{ language[parameters[contentTab.key][key]?.small] }}
                  </small>
                </ng-container>
                <!-- Texarea -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'textarea'">
                  <label [for]="key + contentTab">
                    {{ language[parameters[contentTab.key][key]?.label] }}
                  </label>
                  <!-- If user has value in item -->
                  <textarea [id]="key + contentTab.key" [formControlName]="key" rows="4"
                    class="form-control"></textarea>
                  <small class="form-text text-muted">
                    {{ language[parameters[contentTab.key][key]?.small] }}
                  </small>
                </ng-container>
                <!-- Select -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'select'">
                  <label [for]="key + contentTab.key">
                    {{ language[parameters[contentTab.key][key]?.label] }}
                  </label>
                  <select class="form-control" [id]="key + contentTab.key" [formControlName]="key"
                    value="{{ parameters[contentTab.key][key]?.value }}" (change)="
                      selectChange(
                        $event,
                        parameters[contentTab.key][key]?.select
                      );
                      controlselect($event)
                    ">
                    <ng-container *ngFor="
                        let option of parameters[contentTab.key][key]?.options
                      ">
                      <option *ngIf="option.key === 'default'" [value]="option.key" data-toggle="modal"
                        data-target=".bd-modal-addThingtype" (click)="addThingType($event)">
                        {{ language[option.value] }}
                      </option>
                      <option *ngIf="option.key !== 'disabled'" [value]="option.key">
                        {{ option.value }}
                      </option>
                      <option *ngIf="option.key === 'disabled'" disabled selected>
                        {{ language[option.value] }}
                      </option>
                    </ng-container>
                  </select>
                </ng-container>
                <!-- Checkbox -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'checkbox'">
                  <div class="col-12">
                    <input [type]="parameters[contentTab.key][key]?.type" class="form-check-input"
                      [id]="key + contentTab.key" [formControlName]="key" (change)="checkBoxValue($event, key)" />
                    <label class="form-check-label" [for]="key + contentTab.key">{{
                      parameters[contentTab.key][key]?.label }}</label>
                  </div>
                </ng-container>
                <!-- Date -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'date'">
                  <label [for]="key + contentTab.key">{{
                    parameters[contentTab.key][key]?.label
                    }}</label>
                  <input [type]="parameters[contentTab.key][key]?.type" [id]="key + contentTab.key"
                    [formControlName]="key" />
                  <small class="form-text text-muted">{{
                    language[parameters[contentTab.key][key]?.small]
                    }}</small>
                </ng-container>
                <!-- Color picker -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'color'">
                  <label [for]="key + contentTab.key">{{
                    parameters[contentTab.key][key]?.label
                    }}</label><br />
                  <input [type]="parameters[contentTab.key][key]?.type" [id]="key + contentTab.key"
                    [formControlName]="key" />
                </ng-container>
                <!-- Range -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'range'">
                  <label [for]="key + contentTab.key">{{
                    parameters[contentTab.key][key]?.label
                    }}</label>
                  <input [type]="parameters[contentTab.key][key]?.type" class="custom-range"
                    (change)="updateRange(key + contentTab.key)" [min]="parameters[contentTab.key][key]?.min"
                    [max]="parameters[contentTab.key][key]?.max" [step]="parameters[contentTab.key][key]?.step"
                    [formControlName]="key" [id]="key + contentTab.key" />
                  <input type="text" id="range_{{ key + contentTab.key }}" [value]="
                      paramsDone ? paramsItem[contentTab.key][key] : null
                    " />
                </ng-container>
                <!-- Free -->
                <ng-container *ngIf="parameters[contentTab.key][key]?.type === 'free'">
                  <div [id]="key + contentTab.key"></div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
          <button type="submit" class="btn btn-primary float-right">
            {{ language.save_button }}
          </button>
          <button type="button" id="deleteButtonModal" class="btn btn-danger" *ngIf="paramsDone || !reportItem"
            data-toggle="modal" data-target=".bd-modal-deleteItemMod" (click)="confirmItemToDelete($event)">
            {{ language.delete }}
          </button>
        </form>
      </div>
      <div class="tab-pane fade mt-3" id="variable" role="tabpanel" aria-labelledby="variable-tab">
        <p>HOLA</p>
      </div>
    </div>
  </div>
</div>
<!-- Modal delete item -->
<div class="modal fade bd-modal-deleteItem mt-5" tabindex="-1" role="dialog" aria-labelledby="Eliminar item"
  aria-hidden="true" id="deleteItemMod">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalDeleteItemTitle">
          {{ language?.confirmation }}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3>
          {{ language?.confirmation_message }}<span id="itemToDelete"></span> ?
        </h3>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ language?.close }}
        </button>
        <button type="button" class="btn btn-danger" (click)="deleteItem($event)">
          {{ language?.delete }}
        </button>
      </div>
    </div>
  </div>
</div>
