import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Token } from 'src/app/interfaces/token';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private BASE_URL;

  constructor(private http: HttpClient, private router: Router) {
    this.BASE_URL = environment.base_url;
  }

  /**
   * Set token
   * @param token token
   */
  setToken(token: Token): void {
    // create token sessionStorage
    sessionStorage.setItem('token', JSON.stringify(token));
    // create variable isLogged sessionStorage
    sessionStorage.isLogged = true;
  }

  /**
   * Get token from sessionStorage
   */
  getToken(): string | null {
    return sessionStorage.getItem('token');
  }

  /**
   * Clear sessionStorage
   */
  logOut(): void {
    sessionStorage.clear();
    this.router.navigate(['login']);
  }

  logOutSpecial(): void {
    // sessionStorage.clear();
    if (location.href.indexOf('synopticPlayer') == -1) {
      this.router.navigate(['synopticPlayer/_login']);
    } else {
      this.router.navigate(['synopticplayer/_login']);
    }
  }

  /**
   * Login as a Guest
   */
  logInAsAGuest(): void {
    // Different token than normal user
    sessionStorage.setItem('token', 'invitado');
    // isLogged false
    sessionStorage.isLogged = false;
  }

  /**
   * Return value of isLogged sessionStorage variable
   */
  isLogged(): boolean {
    const logged = sessionStorage.getItem('isLogged');
    if (logged === 'true') return true;
    return false;
  }

  async canAccessRoute(targetUrl: string): Promise<boolean> {
    try {
      await this.http
        .get<any>(`${this.BASE_URL}/v1/api/auth/route?targetUrl=${targetUrl}`)
        .toPromise();
      return true;
    } catch (err) {
      return false;
    }
  }
}
