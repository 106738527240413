import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'language'
})
export class AuditTrailLanguagesPipe implements PipeTransform {

  transform(value): unknown {
    switch (value) {
      case 'User':
        return 'Usuario';
      case 'Login':
        return 'Inicio de sesión';
      case 'Logout':
        return 'Cierre de sesión';
      case 'Successfully':
        return 'Éxito';
      case 'Invalid password':
        return 'Contraseña incorrecta';
      case 'Invalid user':
        return 'Usuario invalido';
      case 'Timeout':
        return 'Automático';
      case 'Create':
        return 'Crear';
      case 'Update':
        return 'Modificar';
      case 'Delete':
        return 'Eliminar';
      default:
        return value;
    }

    // return value;
  }

}
