import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Permit } from 'src/app/interfaces/permit';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private BASE_URL; // = environment.base_url;

  /**
   * Constructor
   * @param http httpClient
   * @param createHeaders headers service
   */
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.base_url;
  }

  /**
   * Get permissions
   */
  getPermissions(): Promise<any> {
    return this.http.get(this.BASE_URL + '/v1/api/permissions').toPromise();
  }

  /**
   * Add permissions
   * @param permit permission to add
   */
  addPermit(permit: Promise<Permit>) {
    return this.http
      .post<Permit>(`${this.BASE_URL}/v1/api/permissions`, permit)
      .toPromise();
  }

  /**
   * Update permission
   * @param permit permission to update
   * @param id id of permission
   */
  editPermit(permit: Promise<Permit>, id) {
    return this.http
      .put<Permit>(`${this.BASE_URL}/v1/api/permissions/${id}`, permit)
      .toPromise();
  }

  /**
   * Delete permission
   * @param id id of permission
   */
  deletePermit(id) {
    return this.http
      .delete(`${this.BASE_URL}/v1/api/permissions/${id}`)
      .toPromise();
  }
}
