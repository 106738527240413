<div class="container mt-lg-3">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/administration">{{ userText?.user_management }}</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        {{ userText?.users }}
      </li>
    </ol>
  </nav>
  <div class="row mb-lg-3 mt-lg-3 mb-2 mt-2">
    <div class="col-12">
      <button type="button" class="btn btn-primary float-right" data-toggle="modal" data-target=".bd-modal-addUser">
        {{ userText?.add_user }}
      </button>
    </div>
  </div>
  <div class="row" id="divus">
    <div class="col-12" id="divUsers" style="visibility: hidden;">
      <table id="userTable" class="table row-border order-column hover">
        <thead>
          <tr>
            <th>{{ userText?.name }}</th>
            <th>{{ userText?.role }}</th>
            <th>{{ userText?.email }}</th>
            <th>{{ userText?.telephone }}</th>
            <th>{{ userText?.actions }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let user of users">
            <ng-container *ngIf="user.parameters?.visible !== false">
              <tr>
                <td>{{ user.username }}</td>
                <td>{{ user.role.name || user.role }}</td>
                <td>{{ user.email }}</td>
                <td>{{ user.telephone }}</td>
                <td>
                  <button *ngIf="user.parameters?.editable !== false" data-toggle="modal"
                    data-target=".bd-modal-editUser" [attr.data-userid]="user._id" (click)="editUser($event)">
                    <img src="assets/img/action/pencil.svg" class="img-thumbnail" [attr.data-userid]="user._id"
                      alt="" />
                  </button>
                  <button *ngIf="user.parameters?.removable !== false" data-toggle="modal"
                    data-target=".bd-modal-deleteUser" [attr.data-userid]="user._id"
                    (click)="confirmDeleteUser($event)">
                    <img src="assets/img/action/trash.svg" class="img-thumbnail" [attr.data-userid]="user._id" alt="" />
                  </button>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- Toast -->
<div role="alert" aria-live="assertive" aria-atomic="true" class="toast">
  <div class="toast-header">
    <img src="assets/img/logo.png" class="logo-toast" alt="Logo" />
  </div>
  <div class="toast-body"></div>
</div>
<!-- Modal add user -->
<div class="modal fade bd-modal-addUser" tabindex="-1" role="dialog" aria-labelledby="Añadir un usuario"
  aria-hidden="true" id="addUserModal">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalAddUserTitle">
          {{ userText?.add_user }}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="m-4" [formGroup]="addUserForm" (ngSubmit)="addUserSubmit()" method="POST">
          <div class="form-group">
            <label for="username">{{ userText?.name }}</label>
            <input type="text" class="form-control" id="username" formControlName="username"
              placeholder="{{ userText?.user_placeholder }}" />
          </div>
          <div class="form-group">
            <label for="telephone">{{ userText?.telephone }}</label>
            <input type="text" class="form-control" id="telephone" formControlName="telephone"
              placeholder="{{ userText?.telephone_placeholder }}" />
            <div *ngIf="addUserForm.controls.telephone.errors?.pattern">
              <span class="error">{{ userText?.telephone_error }}</span>
            </div>
          </div>
          <div class="form-group">
            <label for="email">{{ userText?.email }}</label>
            <input type="email" class="form-control" id="email" formControlName="email"
              placeholder="{{ userText?.email_placeholder }}" />
            <div *ngIf="addUserForm.controls.email.errors?.pattern">
              <span class="error">{{ userText?.email_error }}</span>
            </div>
          </div>
          <div class="form-group">
            <label for="roles">{{ userText?.role }}</label>
            <select class="custom-select" id="roles" formControlName="role">
              <option [ngValue]="null" disabled>
                {{ userText?.select_a_role }}
              </option>
              <ng-container *ngFor="let role of roles">
                <ng-container *ngIf="role.parameters?.visible !== false">
                  <option value="{{ role.name }}">
                    {{ role.name }}
                  </option>
                </ng-container>
              </ng-container>
            </select>
          </div>
          <div class="form-group">
            <label for="synoptic">{{ userText?.synoptic }}</label>
            <input id="synoptic" autocomplete="off" list="synopticList" class="form-control"
              formControlName="synoptic" />
          </div>
          <div class="form-group">
            <label for="password">{{ userText?.password }}</label>
            <input type="password" class="form-control" id="password" formControlName="password"
              placeholder="{{ userText?.password_placeholder }}" />
          </div>
          <div class="form-group">
            <label for="repeatPassword">{{ userText?.repeatPassword }}</label>
            <input type="password" class="form-control" id="repeatPassword" formControlName="repeatPassword"
              placeholder="{{ userText?.repeatPassword_placeholder }}" />
          </div>
          <mat-error *ngIf="checkPasswords('create')" class="error">{{
            userText?.password_error
            }}</mat-error>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ modalText?.close }}
            </button>
            <button type="submit" class="btn btn-primary" [disabled]="!addUserForm.valid">
              {{ modalText?.add }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Modal edit user-->
<div class="modal fade bd-modal-editUser" tabindex="-1" role="dialog" aria-labelledby="Editar un usuario"
  aria-hidden="true" id="editUserModal">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modaelEditUserTitle">
          {{ userText?.edit_user }}
        </h5>
        <div class="col text-right">
          <button class="btn btn-primary" (click)="changePassword()">
            {{ userText?.change_password }}
          </button>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="m-4" [formGroup]="editUserForm" (ngSubmit)="editUserSubmit()" method="POST">
          <div class="form-group oculto">
            <label for="username">Id</label>
            <input type="text" class="form-control" id="id" formControlName="id" placeholder="Usuario" />
          </div>
          <div class="form-group">
            <label for="username">{{ userText?.name }}</label>
            <input type="text" class="form-control" id="usernameEdit" formControlName="username"
              placeholder="{{ userText?.user_placeholder }}" />
          </div>
          <div class="form-group">
            <label for="telephone">{{ userText?.telephone }}</label>
            <input type="text" class="form-control" id="telephone" formControlName="telephone"
              placeholder="{{ userText?.telephone_placeholder }}" />
            <div *ngIf="editUserForm.controls.telephone.errors?.pattern">
              <span class="error">{{ userText?.telephone_error }}</span>
            </div>
          </div>
          <div class="form-group">
            <label for="email">{{ userText?.email }}</label>
            <input type="email" class="form-control" id="email" formControlName="email"
              placeholder="{{ userText?.email_placeholder }}" />
            <div *ngIf="editUserForm.controls.email.errors?.pattern">
              <span class="error">{{ userText?.email_error }}</span>
            </div>
          </div>
          <div class="form-group">
            <label for="rolesEdit">{{ userText?.role }}</label>
            <select class="custom-select" id="rolesEdit" formControlName="role">
              <option [ngValue]="null" disabled>
                {{ userText?.select_a_role }}
              </option>
              <ng-container *ngFor="let role of roles">
                <ng-container *ngIf="role.parameters?.visible !== false">
                  <option value="{{ role._id }}">
                    {{ role.name }}
                  </option>
                </ng-container>
              </ng-container>
            </select>
          </div>
          <div class="form-group">
            <label for="synoptic">{{ userText?.synoptic }}</label>
            <input id="synoptic" autocomplete="off" list="synopticList" class="form-control"
              formControlName="synoptic" />
          </div>
          <div class="oculto" id="changePassword">
            <div class="form-group">
              <label for="password">{{ userText?.new_password }}</label>
              <input type="password" class="form-control" id="password" formControlName="password"
                placeholder="{{ userText?.password_placeholder }}" />
            </div>
            <div class="form-group">
              <label for="repeatPassword">{{ userText?.repeatPassword }}</label>
              <input type="password" class="form-control" id="repeatPassword" formControlName="repeatPassword"
                placeholder="{{ userText?.password_placeholder }}" />
            </div>
          </div>
          <mat-error *ngIf="checkPasswords('edit')" class="error">{{
            userText?.password_error
            }}</mat-error>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ modalText?.close }}
            </button>
            <button type="submit" class="btn btn-primary" [disabled]="!editUserForm.valid">
              {{ modalText?.modify }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<datalist id="synopticList">
  <option *ngFor="let synoptic of synoptics" [value]="synoptic.name"></option>
</datalist>

<!-- Modal delete user-->
<div class="modal fade bd-modal-deleteUser" tabindex="-1" role="dialog" aria-labelledby="Eliminar usuario"
  aria-hidden="true" id="deleteUserConfirm">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalDeleteUserTitle">
          {{ userText?.confirmation }}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3>
          {{ userText?.confirmation_message
          }}<span id="usernameToDelete"></span> ?
        </h3>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ modalText?.close }}
        </button>
        <button type="button" class="btn btn-danger" (click)="deleteUser()">
          {{ modalText?.delete }}
        </button>
      </div>
    </div>
  </div>
</div>
