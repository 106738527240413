import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/service/system/language.service';

@Component({
  selector: 'app-others',
  templateUrl: './others.component.html',
  styleUrls: ['./others.component.scss']
})

export class OthersComponent implements OnInit {
  language: any;
  otherText: any;

  constructor(
    private langServ: LanguageService
  ) { }

  async ngOnInit() {
    // get language
    this.language = await this.langServ.getVal();
    // get the users text
    this.otherText = this.language.administration;
  }

}
