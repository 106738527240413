<div class="config-title">{{ language?.networks }}</div>
<div style="width: 100%;">
  <form [formGroup]="configData" (ngSubmit)="configSubmit()" method="POST">
    <dl>
      <ng-container *ngFor="let control of controls">
        <dt>{{ language[control.key] || control.key }}</dt>
        <dd>
          <mat-form-field appearance="outline" style="width: 100%;">
            <input matInput [id]="control.key" [formControlName]="control.key" placeholder="">
          </mat-form-field>
          <span style="font-size: 75%; color: rgba(0, 0, 0, .6);">
            {{ language[control.key + '_hint'] || '' }}
          </span>
        </dd>
      </ng-container>

      <dt style="margin-top: 1rem;">
        <button mat-flat-button color="logi" type="submit">
          {{ language?.save_button }}
        </button>
      </dt>
    </dl>
  </form>
</div>
