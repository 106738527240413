import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { Role } from 'src/app/interfaces/role';
import { User } from 'src/app/interfaces/user';
import { RolesService } from 'src/app/service/auth/roles.service';
import { UsersService } from 'src/app/service/auth/users.service';
import { DatatableService } from 'src/app/service/datatable.service';
import { RouterService } from 'src/app/service/router.service';
import { LanguageService } from 'src/app/service/system/language.service';
import { SystemService } from 'src/app/service/system/system.service';
import { validatePasswords } from './users.validator';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  users: User[];
  roles: Role[];
  addUserForm: FormGroup;
  $: any;
  editUserForm: FormGroup;
  deleteUserId: string;
  dtOptions: DataTables.Settings = {};
  language: any;
  userText: any;
  currentUser: User | null;
  modalText: any;
  parameters: any;
  synoptics: any = [];
  syn: boolean;

  /**
   * Constructor
   * @param userServ user service
   * @param rolesServ role service
   * @param dataServ datatable service
   * @param langServ language service
   */
  constructor(
    private userServ: UsersService,
    private rolesServ: RolesService,
    private dataServ: DatatableService,
    private langServ: LanguageService,
    private refresh: RouterService,
    private systemServ: SystemService
  ) {
    this.syn = false;
    this.parameters = { synoptic: 'cpc01' };
    // Add FormControls to addUserForm
    this.addUserForm = new FormGroup(
      {
        username: new FormControl('', Validators.required),
        telephone: new FormControl('', [
          Validators.pattern(/^[0-9][A-Za-z0-9 -]*$/),
          Validators.required,
        ]),
        email: new FormControl('', [
          Validators.pattern(/\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/),
          Validators.required,
        ]),
        role: new FormControl('', Validators.required),
        synoptic: new FormControl(''),
        password: new FormControl('', Validators.required),
        repeatPassword: new FormControl('', Validators.required),
      },
      {
        // Use a validatePasswords imported from user.validator to validate password and repeatPassword
        validators: validatePasswords,
      }
    );
    // Add FormControls to editUserForm
    this.editUserForm = new FormGroup(
      {
        id: new FormControl(),
        username: new FormControl('', Validators.required),
        telephone: new FormControl('', [
          Validators.pattern(/^[0-9][A-Za-z0-9 -]*$/),
          Validators.required,
        ]),
        email: new FormControl('', [
          Validators.pattern(/\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/),
          Validators.required,
        ]),
        role: new FormControl('', Validators.required),
        synoptic: new FormControl(''),
        password: new FormControl(''),
        repeatPassword: new FormControl(''),
      },
      {
        // Use a validatePasswords imported from user.validator to validate password and repeatPassword
        validators: validatePasswords,
      }
    );
  }

  async ngOnInit(): Promise<any> {
    // Get users from user service
    await this.getUsers();
    // Get roles from role service
    this.roles = await this.rolesServ.getRoles();

    this.synoptics = await this.systemServ.getItemBy({
      type: '5f900543c7c2a908ade7b828',
    });

    // Timeout function to load datatable
    setTimeout(() => {
      // Get options from datatable service
      const options = this.dataServ.optionsDatatable();
      // Execute datatable function to load it
      (<any>$('#userTable')).dataTable(options);
      let divUsers = document.getElementById('divUsers');
      let dU: any = document.getElementById('divUsers');
      let top = 20;
      while (dU.offsetParent !== null) {
        top = top + dU.offsetTop;
        dU = dU.offsetParent;
      }
      divUsers.style.visibility = '';
      divUsers.style.height = window.innerHeight - top + 'px';
    }, 250);
    // get language
    this.language = await this.langServ.getVal();
    // get the users text
    this.userText = this.language.administration.users_component;
    // get the modal text
    this.modalText = this.language.administration.modals;
  }

  /**
   * Check if password and repeatPassword are equals
   *
   * @param form variable form indicate if user is using create or edit user form
   */
  checkPasswords(form: string): boolean {
    // if is create form
    if (form === 'create') {
      // return a true boolean if users.validator return a true value
      return (
        this.addUserForm.hasError('noSonIguales') &&
        this.addUserForm.get('password').dirty &&
        this.addUserForm.get('repeatPassword').dirty
      );
      // if is edit form
    } else if (form === 'edit') {
      // return a true boolean if users.validator return a true value
      return (
        this.editUserForm.hasError('noSonIguales') &&
        this.editUserForm.get('password').dirty &&
        this.editUserForm.get('repeatPassword').dirty
      );
    }
  }

  /**
   * addUserSubmit action
   */
  async addUserSubmit() {
    // get toast add message
    const text = this.userText.toast_add_message;
    // replace _USER_ for username
    const showText = text.replace('_USER_', this.addUserForm.value.username);
    // Send user info to user service

    const infoUser = this.addUserForm.value;
    infoUser['parameters'] = { synoptic: infoUser.synoptic };

    await this.userServ.addUser(infoUser);
    // Insert html code to toast body
    $('.toast-body').html(`<p style="font-size:18px;">${showText}</p>`);
    // Show toast
    (<any>$('.toast')).toast('show');
    // Reset addUserForm
    this.addUserForm.reset();
    // Hide modal
    (<any>$('#addUserModal')).modal('hide');
    // Reload array of users to show in datatable
    await this.getUsers();
    this.refresh.refresh();
  }

  /**
   * Get users from user service
   */
  async getUsers() {
    this.users = await this.userServ.getUsers();
  }

  /**
   * editUser button pressed
   * @param $event event to know the id of user
   */
  editUser($event): void {
    const id = $event.target.dataset.userid;
    this.currentUser = this.users.find((each) => each._id === id);

    if (this.currentUser) {
      this.editUserForm.patchValue({
        id: this.currentUser._id,
        username: this.currentUser.username,
        telephone: this.currentUser.telephone,
        email: this.currentUser.email,
        role: this.currentUser.role._id,
        synoptic: this.currentUser.parameters.synoptic,
      });

      if (this.currentUser.parameters?.editable === 'limited') {
        this.editUserForm.get('telephone')['disable']();
        this.editUserForm.get('email')['disable']();
        this.editUserForm.get('role')['disable']();
        this.editUserForm.get('synoptic')['disable']();
      }
    }
  }

  /**
   * changePassword button is pressed
   */
  changePassword(): void {
    // get div with passwords in form
    const changePassword = document.getElementById('changePassword');
    // if div has oculto class
    if (changePassword.className === 'oculto') {
      // remove it
      changePassword.classList.remove('oculto');
    } else {
      // add it
      changePassword.classList.add('oculto');
    }
  }

  /**
   * Submits the data of edit user form.
   */
  async editUserSubmit() {
    const text = this.userText.toast_edit_message;
    const showText = text.replace('_USER_', this.editUserForm.value.username);

    const formData = this.editUserForm.value;
    formData['parameters'] = {
      ...this.currentUser.parameters,
      ...{ synoptic: formData.synoptic ?? null },
    };

    if (formData.password === '') {
      delete formData.password;
      delete formData.repeatPassword;
    }

    const userData: User = formData;
    await this.userServ.editUser(userData, this.editUserForm.value.id);

    this.getUsers();

    // Shows a notification about the update result.
    (<any>$('#editUserModal')).modal('hide');
    $('.toast-body').html(`<p style="font-size:18px;">${showText}</p>`);
    (<any>$('.toast')).toast('show');
  }

  /**
   * delete user button pressed
   * @param $event event to know the id of user
   */
  confirmDeleteUser($event): void {
    // set the user id to variable deleteUserId
    this.deleteUserId = $event.target.dataset.userid;
    // foreach array of users
    this.users.forEach((elem) => {
      // if user._id is equals deleteUserId
      if (elem._id === this.deleteUserId) {
        // Set in modal confirm the name of user to delete
        document.getElementById('usernameToDelete').innerHTML = elem.username;
      }
    });
  }

  /**
   * Delete user function
   */
  async deleteUser() {
    // get delete message
    const text = this.userText.toast_delete_message;
    // send the user id to the method deleteUser in user service
    await this.userServ.deleteUser(this.deleteUserId);
    (<any>$('#deleteUserConfirm')).modal('hide');
    // Insert html code to toast body
    $('.toast-body').html(`<p style="font-size:18px;">${text}</p>`);
    // Show toast
    (<any>$('.toast')).toast('show');
    // Reload array of users
    await this.getUsers();

    setTimeout(() => {
      let divUsers = document.getElementById('divUsers');
      let dU: any = document.getElementById('divUsers');
      let top = 20;
      while (dU.offsetParent !== null) {
        top = top + dU.offsetTop;
        dU = dU.offsetParent;
      }
      divUsers.style.visibility = '';
      divUsers.style.height = window.innerHeight - top + 'px';
    }, 100);
  }
}
