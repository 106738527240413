<div #mailBoxContent (click)="closeMailBox($event)" id="mailBoxContainer" class="mailBoxContent">
  <!-- Modal Content -->

  <!--  -->
  <div id="mailBox" class="mailBox">

    <!-- div for close list -->
    <div class="list-container" (click)="closeList()" #listContainer></div>

    <!-- Modal header -->
    <div class="modal-header">
      <h5 class="modal-title" id="staticBackdropLabel">{{languages?.header}}</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span id="close" aria-hidden="true">&times;</span>
      </button>
    </div>
    <!-- Modal body -->
    <div class="container-fluid ">
      <div class="row body-container left-side">
        <div class="col-2 border-right left-side-mailBox alto">
          <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <!-- write -->
            <a class="nav-link" id="v-pills-write-tab" data-toggle="pill" href="#v-pills-write" role="tab"
              aria-controls="v-pills-write" aria-selected="true"><i
                class="mr-3 fa fa-edit fa-2x"></i><span>{{languages?.write}}</span></a>

            <!-- Inbox -->
            <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab"
              aria-controls="v-pills-home" aria-selected="true"><i
                class="fa fa-inbox mr-3 fa-2x"></i>{{languages?.inbox}}</a>
            <!-- Sent -->
            <a class="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab"
              aria-controls="v-pills-profile" aria-selected="false"><i
                class="mr-3 fa fa-paper-plane fa-2x"></i>{{languages?.sent}}</a>
            <!-- Bin -->
            <a class="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab"
              aria-controls="v-pills-messages" aria-selected="false"><i
                class="mr-3 fa fa-trash fa-2x"></i>{{languages?.bin}}</a>

          </div>
        </div>
        <div class="col-10">
          <div class="tab-content" id="v-pills-tabContent">
            <!-- write message -->
            <div class="tab-pane fade" id="v-pills-write" role="tabpanel" aria-labelledby="v-pills-write-tab">
              <form [formGroup]="newMessage" (ngSubmit)="createMessage()" method="POST">
                <div class="row mt-3">
                  <!-- Head destinatary and priorituy -->
                  <div class="col-9 form-group">
                    <!-- <label for="to"></label> -->

                    <!-- ADD users  -->
                    <div name="to" class="to" placeholder="Destinatario">
                      <div class="row">
                        <div class="col-1 border-right d-flex justify-content-center">
                          <div class="addUser">
                            <i (click)="openList()" class="fa fa-user-plus fa-2x"></i>
                            <div class="usersList list" #usersList>

                              <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                  <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab"
                                    aria-controls="home" aria-selected="true">{{languages?.sent_to_user}}</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                  <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                                    aria-controls="profile" aria-selected="false">{{languages?.sent_to_group}}</a>
                                </li>

                              </ul>
                              <!-- Tab Users -->
                              <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel"
                                  aria-labelledby="home-tab">
                                  <div class="box" (click)="addUserTo($event)" [attr.data-id]="user?._id"
                                    data-type="user" [attr.data-name]="user?.username" *ngFor="let user of users">
                                    {{user.username}}</div>
                                </div>
                                <!-- Tab Roles -->
                                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                  <div class="box" (click)="addUserTo($event)" [attr.data-id]="role?._id"
                                    data-type="rol" [attr.data-name]="role?.name" *ngFor="let role of roles">
                                    {{role.name}} </div>
                                </div>

                              </div>

                            </div>
                          </div>
                        </div>
                        <!-- Writing div with user names -->
                        <div class="col-11">
                          <div *ngFor="let user of boxList" class="contact" [attr.data-type]="user.type">
                            {{user?.username}}<span class="border-left float-right px-2 remove"
                              (click)="removeUserTo($event)" [attr.data-id]="user?.id"> X </span> </div>
                        </div>
                      </div>
                    </div>
                  </div>






                  <div class="col-3 form-froup">
                    <select formControlName="priority" class="form-control">
                      <option [ngValue]="null">{{languages?.select_priority}}</option>
                      <option value="important">{{languages?.priority_important}}</option>
                      <option value="info">{{languages?.priority_info}}</option>
                      <option value="urgent">{{languages?.priority_urgent}}</option>
                    </select>
                  </div>
                  <!-- Title message -->
                  <div class="col-12 foem-group">
                    <input class="form-control" type="text" formControlName="title" placeholder="Asunto">
                  </div>
                  <div class="col-12 form-group">
                    <textarea clasS="mt-2 form-control" formControlName="body" cols="30" rows="10"></textarea>

                  </div>
                  <div class="col-12 form-group">
                    <button [disabled]="to == '' || !newMessage.valid" type="submit"
                      class="btn btn-primary">{{languages?.sent_message}}</button>
                  </div>

                </div>
              </form>
            </div>

            <!-- Inbox div -->
            <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">

              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">{{languages?.table_priority}}</th>
                    <th scope="col">{{languages?.table_from}}</th>
                    <th scope="col">{{languages?.table_subject}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let message of mail?.inbox | reverse: 'reverse'" (click)="readMessage = message"
                    [ngClass]="{'unread': !message?.read }" (click)="removeActive($event)" data-parent="home"
                    (click)="returnMethod('home')">

                    <td *ngIf="message?.priority === 'info'" (click)="readNewMessage(message._id)"
                      id="v-pills-message-tab" data-toggle="pill" href="#v-pills-read" role="tab"
                      aria-controls="v-pills-read" aria-selected="true"><span class="badge"
                        [ngClass]="{'badge-danger': message?.priority === 'urgent', 'badge-warning': message?.priority === 'important', 'badge-info': message?.priority === 'info'  }">{{languages?.priority_info}}</span>
                    </td>

                    <td *ngIf="message?.priority === 'urgent'" (click)="readNewMessage(message._id)"
                      id="v-pills-message-tab" data-toggle="pill" href="#v-pills-read" role="tab"
                      aria-controls="v-pills-read" aria-selected="true"><span class="badge"
                        [ngClass]="{'badge-danger': message?.priority === 'urgent', 'badge-warning': message?.priority === 'important', 'badge-info': message?.priority === 'info'  }">{{languages?.priority_urgent}}</span>
                    </td>

                    <td *ngIf="message?.priority === 'important'" (click)="readNewMessage(message._id)"
                      id="v-pills-message-tab" data-toggle="pill" href="#v-pills-read" role="tab"
                      aria-controls="v-pills-read" aria-selected="true"><span class="badge"
                        [ngClass]="{'badge-danger': message?.priority === 'urgent', 'badge-warning': message?.priority === 'important', 'badge-info': message?.priority === 'info'  }">{{languages?.priority_important}}</span>
                    </td>





                    <td (click)="readNewMessage(message._id)" id="v-pills-message-tab" data-toggle="pill"
                      href="#v-pills-read" role="tab" aria-controls="v-pills-read" aria-selected="true">
                      {{message.from.username}}</td>
                    <td (click)="readNewMessage(message._id)" id="v-pills-message-tab" data-toggle="pill"
                      href="#v-pills-read" role="tab" aria-controls="v-pills-read" aria-selected="true">
                      {{message?.title}}</td>
                    <td><i [attr.data-id]="message._id" data-from="inbox" class="fa fa-trash trash"
                        (click)="moveToBin($event)"></i></td>
                  </tr>
                </tbody>
              </table>


            </div>
            <!-- Sent div -->
            <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">{{languages?.table_priority}}</th>
                    <th scope="col">{{languages?.table_from}}</th>
                    <th scope="col">{{languages?.table_subject}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let message of mail?.sent | reverse: 'reverse'" (click)="readMessage = message"
                    (click)="removeActive($event)" data-parent="profile" (click)="returnMethod('profile') ">
                    <td *ngIf="message?.priority === 'info'" (click)="readNewMessage(message._id)"
                      id="v-pills-message-tab" data-toggle="pill" href="#v-pills-read" role="tab"
                      aria-controls="v-pills-read" aria-selected="true"><span class="badge"
                        [ngClass]="{'badge-danger': message?.priority === 'urgent', 'badge-warning': message?.priority === 'important', 'badge-info': message?.priority === 'info'  }">{{languages?.priority_info}}</span>
                    </td>

                    <td *ngIf="message?.priority === 'urgent'" (click)="readNewMessage(message._id)"
                      id="v-pills-message-tab" data-toggle="pill" href="#v-pills-read" role="tab"
                      aria-controls="v-pills-read" aria-selected="true"><span class="badge"
                        [ngClass]="{'badge-danger': message?.priority === 'urgent', 'badge-warning': message?.priority === 'important', 'badge-info': message?.priority === 'info'  }">{{languages?.priority_urgent}}</span>
                    </td>

                    <td *ngIf="message?.priority === 'important'" (click)="readNewMessage(message._id)"
                      id="v-pills-message-tab" data-toggle="pill" href="#v-pills-read" role="tab"
                      aria-controls="v-pills-read" aria-selected="true"><span class="badge"
                        [ngClass]="{'badge-danger': message?.priority === 'urgent', 'badge-warning': message?.priority === 'important', 'badge-info': message?.priority === 'info'  }">{{languages?.priority_important}}</span>
                    </td>




                    <td id="v-pills-message-tab" data-toggle="pill" href="#v-pills-read" role="tab"
                      aria-controls="v-pills-read" aria-selected="true">{{message?.from.username}}</td>
                    <td id="v-pills-message-tab" data-toggle="pill" href="#v-pills-read" role="tab"
                      aria-controls="v-pills-read" aria-selected="true">{{message?.title}}</td>
                    <td><i [attr.data-id]="message?._id" data-from="sent" class="fa fa-trash trash"
                        (click)="moveToBin($event)"></i></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Bin div -->
            <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">{{languages?.table_priority}}</th>
                    <th scope="col">{{languages?.table_from}}</th>
                    <th scope="col">{{languages?.table_subject}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let message of mail?.bin | reverse: 'reverse'" (click)="readMessage = message"
                    (click)="removeActive($event)" (click)="returnMethod('messages')">
                    <td *ngIf="message?.priority === 'info'" (click)="readNewMessage(message._id)"
                      id="v-pills-message-tab" data-toggle="pill" href="#v-pills-read" role="tab"
                      aria-controls="v-pills-read" aria-selected="true"><span class="badge"
                        [ngClass]="{'badge-danger': message?.priority === 'urgent', 'badge-warning': message?.priority === 'important', 'badge-info': message?.priority === 'info'  }">{{languages?.priority_info}}</span>
                    </td>

                    <td *ngIf="message?.priority === 'urgent'" (click)="readNewMessage(message._id)"
                      id="v-pills-message-tab" data-toggle="pill" href="#v-pills-read" role="tab"
                      aria-controls="v-pills-read" aria-selected="true"><span class="badge"
                        [ngClass]="{'badge-danger': message?.priority === 'urgent', 'badge-warning': message?.priority === 'important', 'badge-info': message?.priority === 'info'  }">{{languages?.priority_urgent}}</span>
                    </td>

                    <td *ngIf="message?.priority === 'important'" (click)="readNewMessage(message._id)"
                      id="v-pills-message-tab" data-toggle="pill" href="#v-pills-read" role="tab"
                      aria-controls="v-pills-read" aria-selected="true"><span class="badge"
                        [ngClass]="{'badge-danger': message?.priority === 'urgent', 'badge-warning': message?.priority === 'important', 'badge-info': message?.priority === 'info'  }">{{languages?.priority_important}}</span>
                    </td>






                    <td id="v-pills-message-tab" data-toggle="pill" href="#v-pills-read" role="tab"
                      aria-controls="v-pills-read" aria-selected="true">{{message?.from.username}}</td>
                    <td id="v-pills-message-tab" data-toggle="pill" href="#v-pills-read" role="tab"
                      aria-controls="v-pills-read" aria-selected="true">{{message?.title}}</td>
                    <td>
                      <i [attr.data-id]="message?._id" data-from="bin" class="fa fa-undo trash"
                        (click)="recoveryMessage($event)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!--  BODY MESSAGE FOR READ -->
            <div class="tab-pane fade" id="v-pills-read" role="tabpanel" aria-labelledby="v-pills-read">
              <div class="row">
                <div class="col-12 mt-2">
                  <div class="row">
                    <div class="col-1">
                      <a class="return" id="v-pills-home-return" (click)="removeActive($event)" data-toggle="pill"
                        [attr.href]="'#v-pills-' + return" role="tab" aria-controls="v-pills-return"
                        aria-selected="true">
                        <i class="fa fa-arrow-circle-left fa-2x"></i>
                      </a>
                    </div>
                    <div class="col-10">
                      <h5>
                        <span class="from">{{languages?.message_from}}:</span> {{readMessage?.from.username}}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-4 m-3">
                  <h2 cass="mt-5">{{readMessage?.title}}</h2>
                  <p class="mt-4">{{readMessage?.body}}</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>




  </div>

</div>