import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth/auth.service';
import { LoginService } from 'src/app/service/system/login.service';

@Injectable({
  providedIn: 'root',
})
export class AuthAutoLogin implements CanActivate {
  /**
   * Constructor
   * @param authServ auth service
   * @param router router
   */
  constructor(
    private loginServ: LoginService,
    private authServ: AuthService,
  ) {}

  /**
   * can Activate function
   */
  async canActivate(): Promise<boolean> {
    try {
      const data = await this.loginServ.login({ username: 'autologin', password: 'autologin' });
      this.authServ.setToken(data.accessToken);
    } catch (error) {}

    if (this.authServ.getToken()) {
      return true;
    } else {
      setTimeout(() => {
        location.reload();
      }, 2000);
      return true;
    }
  }
}
