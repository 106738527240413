<div *ngIf="isOpened" class="dialog">
  <div (click)="closeDialog()" class="dialog-overlay">
  </div>
  <div class="dialog-container" dir="ltr">
    <div class="dialog-container-content">
      <div tabindex="-1" aria-modal="true" class="mat-dialog-container">
        <div>
          <h2 mat-dialog-title="" class="mat-dialog-title">
            <ng-content select="[title]"></ng-content>
          </h2>
          <div class="mat-dialog-content mat-typography">
            <ng-content select="[body]"></ng-content>
          </div>
          <div class="mat-dialog-actions">
            <ng-content select="[footer]"></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
