<div class="page-title">{{ language?.config || 'Configuration' }}</div>
<div class="config-container">
  <mat-list>
    <mat-list-item [ngClass]="{ 'active-setting': isActive('system') }" routerLink="/settings/system">
      <mat-icon mat-list-icon>settings</mat-icon>
      <div mat-line>{{ language?.system }}</div>
    </mat-list-item>
    <mat-list-item [ngClass]="{ 'active-setting': isActive('exports') }" routerLink="/settings/exports">
      <mat-icon mat-list-icon>import_export</mat-icon>
      <div mat-line>{{ language?.export }}</div>
    </mat-list-item>
    <mat-list-item [ngClass]="{ 'active-setting': isActive('backups') }" routerLink="/settings/backups">
      <mat-icon mat-list-icon>backup</mat-icon>
      <div mat-line>{{ language?.backups }}</div>
    </mat-list-item>
    <mat-list-item [ngClass]="{ 'active-setting': isActive('support') }" routerLink="/settings/support">
      <mat-icon mat-list-icon>help_outline</mat-icon>
      <div mat-line>{{ language?.support }}</div>
    </mat-list-item>
  </mat-list>

  <div class="config-component">
    <router-outlet></router-outlet>
  </div>
</div>
