// Using `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**
 * This configuration assumes that the server is on the same
 * machine that is provided in the frontend.
 */

const { origin } = window.location;

export const environment = {
  production: true,

  // URL to connect to the LOGiPlatform API.
  base_url: `${origin}`,
};
