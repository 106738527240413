import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdministrationMenuComponent } from 'src/app/components/administration/administrarion-menu/administrarion-menu.component';
import { AuditTrailComponent } from 'src/app/components/administration/others/audit-trail/audit-trail.component';
import { OthersComponent } from 'src/app/components/administration/others/others.component';
import { PermitsComponent } from 'src/app/components/administration/permits/permits.component';
import { RolesComponent } from 'src/app/components/administration/roles/roles.component';
import { UsersComponent } from 'src/app/components/administration/users/users.component';
import { AlarmsComponent } from 'src/app/components/alarms/alarms.component';
import { BackupsSystemComponent } from 'src/app/components/backups/backups-system/backups-system.component';
import { BackupsTagsComponent } from 'src/app/components/backups/backups-tags/backups-tags.component';
import { ConfigComponent } from 'src/app/components/config/config.component';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
import { DataDesignComponent } from 'src/app/components/datadesign/datadesign.component';
import { LoginComponent } from 'src/app/components/login/login.component';
import { MainComponent } from 'src/app/components/main/main.component';
import { Modelizer5Component } from 'src/app/components/modelizer5/modelizer5.component';
import { SchedulerComponent } from "src/app/components/scheduler/scheduler.component";
import { ScriptsComponent } from 'src/app/components/scripts/scripts.component';
import { SynopticnewComponent } from 'src/app/components/synopticnew/synopticnew.component';
import { Synopticplayer_v2Component } from 'src/app/components/synopticplayer_v2/synopticplayer_v2.component';
import { SupportComponent } from './components/config/support/support.component';
import { SystemComponent } from './components/config/system/system.component';

import { AuthGuard } from 'src/app/guard/auth.guard';
import { AuthAutoLogin } from 'src/app/guard/autologin.guard';
import { SecurityGuard } from 'src/app/guard/security.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'synopticsnew', component: SynopticnewComponent, canActivate: [AuthGuard, SecurityGuard] },
  { path: 'synopticplayer', component: Synopticplayer_v2Component, canActivate: [AuthGuard, SecurityGuard] },
  { path: 'synopticplayer/:syn', component: Synopticplayer_v2Component, canActivate: [AuthGuard, SecurityGuard] },
  { path: 'play/:syn', component: Synopticplayer_v2Component, canActivate: [AuthAutoLogin] },
  { path: 'synopticPlayer/:syn', component: Synopticplayer_v2Component, canActivate: [AuthGuard, SecurityGuard] },
  { path: 'synopticPlayer', component: Synopticplayer_v2Component, canActivate: [AuthGuard, SecurityGuard] },
  { path: 'datadesign', component: DataDesignComponent, canActivate: [SecurityGuard, AuthGuard] },
  { path: 'scheduler', component: SchedulerComponent },
  {
    path: '', component: MainComponent,
    children:
      [
        { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
        { path: 'modelizer', component: Modelizer5Component, canActivate: [AuthGuard, SecurityGuard] },
        { path: 'settings', component: ConfigComponent, canActivate: [AuthGuard],
          children: [
            { path: '', component: SystemComponent, canActivate: [AuthGuard, SecurityGuard] },
            { path: 'system', component: SystemComponent, canActivate: [AuthGuard, SecurityGuard] },
            { path: 'exports', component: BackupsTagsComponent, canActivate: [AuthGuard, SecurityGuard] },
            { path: 'backups', component: BackupsSystemComponent, canActivate: [AuthGuard, SecurityGuard] },
            { path: 'support', component: SupportComponent, canActivate: [AuthGuard, SecurityGuard] },
          ]
        },
        { path: 'scripts', component: ScriptsComponent, canActivate: [AuthGuard, SecurityGuard] },
        { path: 'alarms', component: AlarmsComponent, canActivate: [AuthGuard] },
        {
          path: 'administration',
          children:
            [
              { path: '', component: AdministrationMenuComponent, canActivate: [AuthGuard, SecurityGuard] },
              { path: 'others', component: OthersComponent, canActivate: [AuthGuard, SecurityGuard] },
              { path: 'permits', component: PermitsComponent, canActivate: [AuthGuard] },
              { path: 'roles', component: RolesComponent, canActivate: [AuthGuard, SecurityGuard] },
              { path: 'users', component: UsersComponent, canActivate: [AuthGuard, SecurityGuard] },
              { path: 'audittrail', component: AuditTrailComponent, canActivate: [AuthGuard, SecurityGuard] },
            ]
        }
      ]
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
