import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/service/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  mail: any = {};
  interval: any;
  private BASE_URL; // = environment.base_url;
  private emitChangeSource = new Subject<any>();
  private emitUsers = new Subject<any>();
  changeEmitted$ = this.emitChangeSource.asObservable();
  emitUsers$ = this.emitUsers.asObservable();
  constructor(private http: HttpClient, private authServ: AuthService) {
    this.BASE_URL = environment.base_url;
  }

  async getMailBox() {
    if (!this.authServ.isLogged()) return;

    this.mail = await this.http
      .get(this.BASE_URL + '/v1/api/mail/')
      .toPromise();
    this.emitMailBox(this.mail);
    clearInterval(this.interval);
    this.refreshMailBox();
  }
  // Service message commands
  emitMailBox(mail: any) {
    this.emitChangeSource.next(mail);
  }

  refreshMailBox(): void {
    this.interval = setInterval(() => {
      if (this.authServ.isLogged()) {
        this.getMailBox();
      }
    }, 120000);
  }

  async createMessage(newMessage) {
    await this.http
      .post(this.BASE_URL + '/v1/api/messages', newMessage)
      .toPromise();
    this.getMailBox();
  }

  // Move message to Bin
  async moveToBin(from, id) {
    await this.http
      .get(this.BASE_URL + `/v1/api/mail/${from}/${id}`)
      .toPromise();
    this.getMailBox();
  }

  // Restore message from bin
  async recoveryMessage(id) {
    await this.http
      .get(this.BASE_URL + '/v1/api/mail/restore/' + id)
      .toPromise();
    this.getMailBox();
  }

  async readMessage(id): Promise<any> {
    await this.http.get(this.BASE_URL + '/v1/api/mail/read/' + id).toPromise();
    this.getMailBox();
  }
}
