<div [style.height.px]="tableHeight">
  <table mat-table [dataSource]="dataSource" matSort [matSortActive]="sortDefault?.header || ''"
    [matSortDirection]="sortDefault?.direction || ''">
    <ng-container *ngFor="let header of headers" [matColumnDef]="header.key">
      <ng-container *ngIf="header.key === 'select'; else elseBlock">
        <th class="select-header" mat-header-cell *matHeaderCellDef>
          <input type="checkbox" (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [ngStyle]="{ visibility: this.selection.selected.length > 0 ? 'visible' : 'hidden' }">
        </th>
        <td class="select-row" mat-cell *matCellDef="let row">
          <input type="checkbox" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [ngStyle]="{ visibility: this.selection.selected.length > 0 ? 'visible' : 'hidden' }">
        </td>
      </ng-container>
      <ng-template #elseBlock>
        <ng-container *ngIf="sortable; else elseSort">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [style]="headerRowStyleStatic">
            {{ header?.label || header.key }}
          </th>
        </ng-container>
        <ng-template #elseSort>
          <th mat-header-cell *matHeaderCellDef [style]="headerRowStyleStatic">
            {{ header?.label || header.key }}
          </th>
        </ng-template>
        <td [style]="rowStyleStatic + cellStyle(header.key, element)" mat-cell *matCellDef="let element">
          <!-- Check only if it is float because the pipe adds a comma to the thousands -->
          <ng-container *ngIf="isFloat(getValue(header.key, element)); else dateTemplate">
            {{ getValue(header.key, element) | number:'1.0-3' }}
          </ng-container>
          <ng-template #dateTemplate>
            <ng-container *ngIf="isISODate(getValue(header.key, element)); else textTemplate">
              {{ getValue(header.key, element) | date:'dd-MM-yyyy, HH:mm:ss' }}
            </ng-container>
            <ng-template #textTemplate>
              {{ getValue(header.key, element) }}
            </ng-template>
          </ng-template>
        </td>
      </ng-template>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="dataHeaders; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: dataHeaders;" [style.height.px]="52" (click)="selection.toggle(row)"
      [style]="rowStyle(row)">
    </tr>
  </table>
</div>
<mat-paginator *ngIf="paginable" [pageSizeOptions]="pageSizeOptions"
  [showFirstLastButtons]="showFirstLastButtons"></mat-paginator>
