import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuditTrailService {
  private BASE_URL; //cpc redirect = environment.base_url;
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.base_url;
  }

  getAllLogs(): any {
    return this.http.get(this.BASE_URL + '/v1/api/audittrail').toPromise();
  }

  filterLogs(query) {
    return this.http
      .post(this.BASE_URL + '/v1/api/audittrail/filter', query)
      .toPromise();
  }
}
