<div class="header">
  <app-messages></app-messages>
  <app-header-component></app-header-component>
</div>
<div class="content">
  <app-left-sidebar></app-left-sidebar>
  <div id="main" class="main">
    <router-outlet></router-outlet>
  </div>
</div>
