import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ScriptsService {
  private BASE_URL;

  constructor(private http: HttpClient) {
    this.BASE_URL = environment.base_url;
  }

  /**
   * Get directory files
   */
  getFiles(): Promise<any> {
    return this.http.get(`${this.BASE_URL}/v1/api/scripts`).toPromise();
  }

  /**
   * Get script
   */
  getScript(id): Promise<any> {
    return this.http.get(`${this.BASE_URL}/v1/api/scripts/${id}`).toPromise();
  }

  /**
   * Create folder
   */
  createFolder(folder): Promise<any> {
    return this.http
      .post(`${this.BASE_URL}/v1/api/scripts/folder`, folder)
      .toPromise();
  }

  /**
   * Create a script
   */
  createFile(file): Promise<any> {
    return this.http.post(`${this.BASE_URL}/v1/api/scripts`, file).toPromise();
  }

  /**
   * Update folder
   */
  updateFolder(folder): Promise<any> {
    return this.http
      .put(`${this.BASE_URL}/v1/api/scripts/folder/${folder._id}`, folder)
      .toPromise();
  }

  /**
   * Update a script
   */
  updateFile(file): Promise<any> {
    return this.http
      .put(`${this.BASE_URL}/v1/api/scripts/${file._id}`, file)
      .toPromise();
  }

  /**
   * Delete folder
   */
  deleteFolder(id): Promise<any> {
    return this.http
      .delete(`${this.BASE_URL}/v1/api/scripts/folder/${id}`)
      .toPromise();
  }

  /**
   * Delete a script
   */
  deleteScript(id): Promise<any> {
    return this.http
      .delete(`${this.BASE_URL}/v1/api/scripts/${id}`)
      .toPromise();
  }
}
