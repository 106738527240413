import { importType } from '@angular/compiler/src/output/output_ast';
import { SystemService } from 'src/app/service/system/system.service';

export class utils {

    public systemSynoptic: any;
    public systemServ: SystemService;
    public synoptics: [any];
    public synoptic: any;

    constructor(systemServ: SystemService) {
        // this.systemServ = systemServ;
        // this.getSystem();
    }

    async getSystem(): Promise<any> {
        this.systemSynoptic = await this.systemServ.getSynoptic();
    }

    findItemById(_id: string) {
        const itt = this.systemSynoptic.items.find(item => item['_id'] === _id);
        return itt;
    }

    StyleRules(item: any) {
        let type: any = {};
        try {
            type = this.systemSynoptic.controls.find(type => type._id === item.type);
        } catch (error) {
            type = { name: item.typeName };
        }

        const styleSheet: any = document.getElementsByTagName('style')[document.getElementsByTagName('style').length - 1];
        switch (type.name) {
            case 'aCheck':
                const ww: string = item.parameters.widthgeneral;
                const hh: string = item.parameters.heightgeneral;

                const p60 = parseInt(ww);
                const p34 = parseInt(hh);
                const p4 = Math.round(p34 / 7);
                const p26 = p34 - (p4 * 2);
                const pdesp = p60 - p26 - (p34 - p26);

                console.log('p4:', p4, 'p26:', p26, 'p34:', p34, 'p60:', p60, 'pdesp:', pdesp);

                let idd = '';
                if (item.hasOwnProperty('_id')) {
                    idd = '#it' + item._id;
                } else {
                    idd = '#it' + item.id;
                }

                styleSheet.sheet.insertRule(
                    idd + ' {	position: relative;	display: inline-block;	width: ' +
                    p60 +
                    'px; height: ' +
                    p34 +
                    'px;  }',
                    styleSheet.sheet.cssRules.length
                );
                styleSheet.sheet.insertRule(
                    idd + ' input { opacity: 0;	width: 0; height: 0;  }',
                    styleSheet.sheet.cssRules.length
                );
                styleSheet.sheet.insertRule(
                    idd + ' span {	position: absolute;	cursor: pointer; top: 0; left: 0; right: 0;	bottom: 0;	background-color: #ccc;	-webkit-transition: .4s; transition: .4s;  }',
                    styleSheet.sheet.cssRules.length
                );
                styleSheet.sheet.insertRule(
                    idd + ' span:before {	position: absolute;	content: ""; height: ' +
                    p26 +
                    'px;	width: ' +
                    p26 +
                    'px; left: ' +
                    p4 +
                    'px;	bottom: ' +
                    p4 +
                    'px; background-color: white; -webkit-transition: .4s; transition: .4s; }',
                    styleSheet.sheet.cssRules.length
                );
                styleSheet.sheet.insertRule(
                    idd + ' input:checked + span { background-color: #2196F3; }',
                    styleSheet.sheet.cssRules.length
                );
                styleSheet.sheet.insertRule(
                    idd + ' input:focus + span { box-shadow: 0 0 1px #2196F3; }',
                    styleSheet.sheet.cssRules.length
                );
                styleSheet.sheet.insertRule(
                    idd + ' input:checked + span:before {	-webkit-transform: translateX(' +
                    pdesp +
                    'px); -ms-transform: translateX(' +
                    pdesp +
                    'px); transform: translateX(' +
                    pdesp +
                    'px); }',
                    styleSheet.sheet.cssRules.length
                );
                styleSheet.sheet.insertRule(
                    idd + ' span { border-radius: ' + p34 + 'px; }',
                    styleSheet.sheet.cssRules.length
                );
                styleSheet.sheet.insertRule(
                    idd + ' span:before { border-radius: 50%; }',
                    styleSheet.sheet.cssRules.length
                );

                break;
        }
    }

    itemRebuild(items, item2) {
        switch (item2.type) {
            case '5fd1e820e166cb0cbd2bba97': //'radialGauge':
                const item = { ...item2 };
                let highlights: any[];
                highlights = [];
                let majorticks: string[];
                majorticks = [];

                let interval = parseFloat(item.parameters?.intervalgauge);
                if (interval === undefined) {
                    interval = 10;
                }
                const nMarcas = (item.parameters.maxvaluegauge - item.parameters.minvaluegauge) / interval;
                let minimo: number = parseInt(item.parameters.minvaluegauge);
                for (let index = 0; index < nMarcas; index++) {
                    majorticks.push(minimo.toString());
                    minimo = minimo + interval;
                }
                majorticks.push(minimo.toString());

                if (item.parameters.highlightsgauge.length === 0) {
                    highlights.push({ from: 0, to: 0, color: '#888888' });
                } else {
                    highlights = item.parameters.highlightsgauge;
                }

                item.options =
                {
                    minValue: item.parameters.minvaluegauge,
                    maxValue: item.parameters.maxvaluegauge,
                    highlights: highlights,
                    majorTicks: majorticks
                }

                const ndx = items['radialGauge'].findIndex(it => it._id === item._id);
                if (ndx > -1) {
                    items['radialGauge'].splice(ndx, 1);
                    items['radialGauge'].push(item);
                }

                break;
        }
    }
}
