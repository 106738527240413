import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { DatatableService } from 'src/app/service/datatable.service';
import { lpSocket } from 'src/app/service/lp-websocket.service';
import { ModelizerTypeService } from 'src/app/service/modelizer/modelizer-type.service';
import { SharedService } from 'src/app/service/modelizer/shared.service';
import { LanguageService } from 'src/app/service/system/language.service';
import { SystemService } from 'src/app/service/system/system.service';
import { AlarmsService } from 'src/app/service/tags/alarms.service';
import { TagService } from 'src/app/service/tags/tag.service';

@Component({
  selector: 'app-optionscomm',
  templateUrl: './optionscomm.component.html',
  styleUrls: ['./optionscomm.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class OptionscommComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  codeMirrorOptions: any;
  docSel: any;
  TO_showValues: any;

  getValues: string = '';

  item: any;
  parameters: any;
  tabs: any = [];
  general: any = [];
  language: any;
  optionItem: any;
  paramsItem: any;
  paramsDone: boolean;
  optionsForm: FormGroup;
  tagForm: FormGroup;
  formfield = false;
  isReport = false;
  itemName = '';
  itemDescription = '';
  reportItem: boolean;
  documentDefinitions: any = [];
  fields: any = [];
  things: any = [];
  fieldItems: any = [];
  documentsItems: any = [];
  updateId: any;
  protocolType: any;
  selectedProtocol: any;
  documents: any = [];
  DocProt: any = [];
  connectionThing: any;

  allTags: any;
  allAlarms: any;
  alarms: any;
  currentThing: string;

  thing: boolean;
  thingId: string = '';
  externalid: string = '';

  messageForm: FormGroup;
  messageList: any = [];
  aliasAlredyInUse: boolean;
  connectionError: string;
  logi7: boolean;
  protocol: boolean;

  tags: any = [];
  oldTags: any = [];

  tagTypes: any = [];
  tagTypesKeys: any = [];
  tagAlredyInUse: boolean;
  tagInUse: any;

  constructor(
    private shaServ: SharedService,
    private langServ: LanguageService,
    private modelizerServ: ModelizerTypeService,
    private alarmServ: AlarmsService,
    private tagServ: TagService,
    private socketService: lpSocket,
    private dataServ: DatatableService,
    private systemServ: SystemService
  ) {
    this.codeMirrorOptions = {
      mode: 'javascript',
      lineNumbers: true,
      theme: 'elegant',

      extraKeys: {
        'Ctrl-Space': 'autocomplete',
        F11: (cm) => {
          if (!cm.getOption('fullScreen')) {
            cm.setOption('fullScreen', true);
            $('ngx-codemirror').addClass('CodeMirror-fullscreen');
            $('.CodeMirror').css('height', '100%');
            $('.CodeMirror').css('zindex', 150000);
            $('.CodeMirror').css('z-index', 150000);
          } else {
            cm.setOption('fullScreen', false);
            $('ngx-codemirror').removeClass('CodeMirror-fullscreen');
            $('.CodeMirror').css('height', '500px');
          }
        },
        Esc: (cm) => {
          if (cm.getOption('fullScreen')) {
            cm.setOption('fullScreen', false);
            $('ngx-codemirror').removeClass('CodeMirror-fullscreen');
            $('.CodeMirror').css('height', '500px');
          }
        },
      },
    };

    socketService.outEven.subscribe(res => {
      res.msg = res.msg.replace(':+', ':');
      try {
        const data = JSON.parse(res.msg);
        const keysPayload = Object.keys(data);
        const id = data.id;

        //keysPayload.forEach(keyPayload => {

        this.tags.forEach((element) => {
          element.value = data[element.name];
        });
      } catch (error) {}

      /*
      if (keyPayload !== 'id' && keyPayload !== 'dd' && keyPayload !== 't') {
        this.valueSocket[id + keyPayload] = topic[keyPayload];
        if (this.player) {
          if (this.wsItems[id + "<>" + keyPayload] !== undefined) {
            this.newData(id + "<>" + keyPayload, topic[keyPayload], parseInt(topic['t']));
          }
        }
      }
      */
      //});

      /*
      this.valueSocket['THtemperatura'] = '23';
      */

      // const topic = JSON.parse(res.msg);
    });

    this.optionsForm = new FormGroup({});
    this.tagForm = new FormGroup({
      name: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required)
    });
  }

  async ngOnInit() {
    this.aliasAlredyInUse = false;
    this.tagInUse = undefined;
    this.renderItemComponent();

    this.language = await this.langServ.getVal();
    this.language = this.language.option;
    this.allTags = await this.tagServ.getTagsByInfo({});
    this.allAlarms = await this.alarmServ.get();
  }

  async viewAlarms() {
    const thingId = this.item?._id;
    this.currentThing = thingId;

    this.alarms = this.allAlarms.filter(
      (alarm) => alarm.tag?.thing === this.thingId
    );
    this.tags = this.allTags;

    ($('#alarms') as any).modal('show');
  }

  renderItemComponent() {

    // Subscribe to shared service that emit items with his options
    this.subscription = this.shaServ.changeEmitted$.subscribe(async data => {
      // this.webServ.disconnect();
      this.socketDisconnect();

      this.item = data.item;

      if (data.hasOwnProperty('component')) {
        $('#options').show();
        if (data.item.hasOwnProperty('parameters')) {
          this.parameters = { ...data.item.type.parameters };
        }

        this.optionItem = data.item;
        this.tabs = [];
        this.general = [];
        this.documentDefinitions = [];
        this.fields = [];
        this.things = [];
        this.fieldItems = [];

        this.optionsForm = new FormGroup({});
        this.tagForm = new FormGroup({});
        this.protocolType = undefined;

        this.documents = [];
        this.messageList = [];
        this.connectionThing = undefined;
        this.connectionError = undefined;

        this.thing = false;
        this.protocol = false;
        this.reportItem = false;
        this.paramsItem = data.item.parameters;
        this.itemName = data.item.name;
        this.itemDescription = data.item.description;
        // tslint:disable-next-line: forin

        this.item.tagColumns = {};

        // thing
        if (data.item.type._id === '5f86d624821fd6593dd8806e') {
          this.thing = true;
          this.thingId = data.item._id;
          this.docSel = 'none';

          //buscar documentos del thing
          let protocolos = await this.systemServ.getItemBy({
            type: '5f871334821fd6593dd88071',
          });
          protocolos = protocolos.filter(
            (prot) => prot.parent.toString() === this.thingId.toString()
          );
          this.DocProt = [];
          for (const prot of protocolos) {
            let doc = '';
            try {
              if (prot.parameters.general.document) {
                doc = prot.parameters.general.document;
              }
            } catch (error) {
            }

            if (doc) {
              if (
                this.DocProt.find(
                  (docum) => docum.document === doc && docum.type === 'doc'
                ) === undefined
              ) {
                this.DocProt.push({
                  type: 'doc',
                  document: doc,
                  name: doc,
                  id: doc,
                });
              }
            } else {
              if (
                this.DocProt.find(
                  (docum) => docum.document === doc && docum.type === 'prot'
                ) === undefined
              ) {
                this.DocProt.push({
                  type: 'prot',
                  prot_id: prot._id,
                  prot_name: prot.name,
                  name: prot.name,
                  id: prot._id,
                });
              }
            }
          }

          this.externalid = this.thingId;
          try {
            if (data.item.parameters.general.externalid) {
              this.externalid = data.item.parameters.general.externalid;
            }
          } catch (error) {}
        }

        // protocol
        if (data.item.type._id === '5f871334821fd6593dd88071') {
          this.protocol = true;

          if (!this.optionItem.parameters.hasOwnProperty('general')) {
            this.optionItem.parameters.general = { protocoltype: '' };
          }

          this.item.tagColumns = {};
          this.tagForm = new FormGroup({});
          this.tagForm.addControl(
            'name',
            new FormControl('', Validators.required)
          );
          this.tagForm.addControl(
            'type',
            new FormControl('', Validators.required)
          );

          this.item.protocolTypes = await this.modelizerServ.getProtocols();
          this.parameters.general.protocoltype.options = [];

          this.item.protocolTypes.forEach((element) => {
            this.parameters.general.protocoltype.options.push({
              key: element._id,
              value: element.name,
              type: 'protocol',
            });

            //voy a añadir los fields según parametros
            try {
              const kys = Object.keys(element.parameters.general);

              kys.forEach((ky) => {
                this.parameters['general'][ky] = element.parameters.general[ky];
                this.parameters['general'][ky]['display'] = 'hidden';
                this.parameters['general'][ky]['protocolId'] = element._id;

                if (this.optionItem.hasOwnProperty('parameters')) {
                  if (
                    element._id ===
                    this.optionItem.parameters.general.protocoltype
                  ) {
                    this.parameters['general'][ky]['display'] = '';
                  }
                }

                /*
                if (this.optionItem.hasOwnProperty('parameters')) {
                  this.optionsForm.addControl(ky, new FormControl('', Validators.required));
                  // this.optionsForm.addControl(ky, new FormControl(this.optionItem.parameters.general[ky], Validators.required));
                } else {
                  this.optionsForm.addControl(ky, new FormControl('', Validators.required));
                }
                */
              });
            } catch (error) {}
          });

          /*
          if (this.optionItem.hasOwnProperty('parameters')) {
            try {
              const selectedProtocol = this.optionItem.parameters.general.type.selected;
              setTimeout(() => {
                this.optionsForm.patchValue({
                  type: selectedProtocol
                });
              }, 0);
            } catch (error) {
            }
          }
          */
        }

        for (const key in this.parameters) {
          switch (key) {
            case 'general':
              this.general.push(Object.keys(data.item.type.parameters[key]));

              if (data.item.hasOwnProperty('parameters')) {
                if (data.item.parameters.hasOwnProperty('selfPage')) {
                  if (data.item.parameters.selfPage) {
                    setTimeout(() => {
                      this.optionsForm.patchValue({
                        selfPage: 'true',
                      });
                    }, 0);
                  }
                }
              }

              // Thing item selected
              // tslint:disable-next-line: forin
              for (const itemKey in data.item.type.parameters[key]) {
                if (itemKey === 'name' || itemKey === 'description') {
                  if (itemKey === 'name') {
                    this.optionsForm.addControl(
                      itemKey,
                      new FormControl(data.item.name, Validators.required)
                    );
                  } else {
                    this.optionsForm.addControl(
                      itemKey,
                      new FormControl(
                        data.item.description,
                        Validators.required
                      )
                    );
                  }
                } else {
                  if (this.optionItem.parameters.hasOwnProperty('general')) {
                    // this.optionsForm.addControl(itemKey, new FormControl('', Validators.required));
                    this.optionsForm.addControl(
                      itemKey,
                      new FormControl(
                        this.optionItem.parameters.general[itemKey],
                        Validators.required
                      )
                    );
                  } else {
                    this.optionsForm.addControl(
                      itemKey,
                      new FormControl('', Validators.required)
                    );
                  }
                }
              }

              this.tabs.push(key);
              break;
          }
        }
      }
    });
  }

  /**
   * When user submit options
   */
  optionSubmit(): void {
    if (this.optionsForm.value.hasOwnProperty('selfPage')) {
      if (this.optionsForm.value.selfPage) {
        if (!this.item.parameters.selfPage) {
          this.optionItem.parameters['selfPosition'] =
            this.optionItem.parameters['position'];
        }
      } else {
        if (this.item.parameters.selfPage) {
          delete this.optionItem.parameters.selfPosition;
        }
      }
      this.optionItem.parameters.selfPage = this.optionsForm.value.selfPage;
    }

    for (const key in this.optionItem.type.parameters) {
      switch (key) {
        case 'general':
          for (const itemKey in this.optionItem.type.parameters[key]) {
            {
              if (
                itemKey !== 'name' &&
                itemKey !== 'description' &&
                itemKey !== 'selfPage'
              ) {
                if (!this.optionItem.parameters.hasOwnProperty('general')) {
                  this.optionItem.parameters['general'] = {};
                }
                this.optionItem.parameters.general[itemKey] =
                  this.optionsForm.value[itemKey];
              }
            }
          }
          break;
      }
    }

    this.optionItem.name = this.optionsForm.value.name;
    this.optionItem.description = this.optionsForm.value.description;

    if (this.protocolType !== undefined) {
      // this.connectionParameters();
      // this.optionItem.parameters.general.document = this.optionsForm.value.documentDefinition;
    }

    this.shaServ.emitItem('action', this.optionItem);

    this.closeOption();
  }

  /**
   * Show modal to confirm before delete
   * @param $event event
   */
  confirmItemToDelete($event) {
    $('#itemToDelete').html(this.optionItem.name);
  }

  /**
   * Delete item
   * @param $event event
   */
  deleteItem($event) {
    this.shaServ.emitDeleteItem('delete', this.optionItem);
    this.closeOption();
    ($('#deleteItem') as any).modal('hide');
  }

  /**
   * Close options and disconnect from websocket
   */
  closeOption() {
    $('#options').hide();
    // this.webServ.disconnect();
    this.socketDisconnect();
  }

  /**
   * Change select from thingtypes or protocols
   * @param event event
   */
  changeSelect(event) {
    if (event.target.id === 'protocoltypegeneral') {
      const kys = Object.keys(this.parameters['general']);
      kys.forEach((ky) => {
        if (this.parameters['general'][ky].hasOwnProperty('protocolId')) {
          if (
            event.target.value === this.parameters['general'][ky].protocolId
          ) {
            this.parameters['general'][ky].display = '';
          } else {
            this.parameters['general'][ky].display = 'hidden';
          }
        }
      });

      this.tagForm = new FormGroup({});
      this.tagForm.addControl('name', new FormControl('', Validators.required));
      this.tagForm.addControl('type', new FormControl('', Validators.required));

      this.item.protocolTypes.forEach((element) => {
        if (element._id === event.target.value) {
          this.item.tagColumns[element._id] = element.parameters.fields;
        }
      });

      const fieldsKey = Object.keys(this.item.tagColumns[event.target.value]);
      fieldsKey.forEach((key) => {
        this.tagForm.addControl(key, new FormControl('', Validators.required));
      });
    }
  }

  async selectDoc(event) {}

  async showValues() {
    try {
      clearTimeout(this.TO_showValues);
    } catch (error) {}

    let values: any;
    if (this.docSel !== 'none') {
      values = await this.tagServ.getValues([
        { $match: { tagkey: this.externalid, document: this.docSel } },
        { $sort: { datetime: -1 } },
        { $limit: 20 },
      ]);
    } else {
      values = await this.tagServ.getValues([
        { $match: { tagkey: this.externalid } },
        { $sort: { datetime: -1 } },
        { $limit: 20 },
      ]);
    }

    if (this.getValues !== JSON.stringify(values)) {
      this.getValues = JSON.stringify(values);
      try {
        (<any>$('#tblRegs')).dataTable().fnDestroy();
        const tblRegs = document.getElementById('tblRegs');
        tblRegs.parentElement.removeChild(tblRegs);
      } catch (error) {}

      const optionsDataTable = this.dataServ.optionsDatatable();

      const divRegs = document.getElementById('divRegs');
      divRegs.style.visibility = 'hidden';

      const tbl = document.createElement('table');
      const tagthead: HTMLElement = document.createElement('thead');
      tbl.id = 'tblRegs';

      const tbody = document.createElement('tbody');
      const tr = document.createElement('tr');
      let keys = Object.keys(values[0].values);

      let th = document.createElement('th');
      th.innerHTML = 'datetime';
      tr.appendChild(th);
      tagthead.appendChild(tr);

      for (const kk of keys) {
        const th = document.createElement('th');
        th.innerHTML = kk;
        tr.appendChild(th);
      }
      tagthead.appendChild(tr);

      values.forEach((value) => {
        const tr = document.createElement('tr');
        let td = document.createElement('td');
        td.innerHTML = this.formatDateHora(value['datetime']);
        tr.appendChild(td);

        for (const kk of keys) {
          const td = document.createElement('td');
          td.innerHTML = value.values[kk];
          tr.appendChild(td);
        }
        tbody.appendChild(tr);
      });

      tbl.appendChild(tagthead);
      tbl.appendChild(tbody);
      divRegs.appendChild(tbl);

      setTimeout(
        (div) => {
          (<any>$('#tblRegs')).dataTable(optionsDataTable);
          div.style.visibility = '';
        },
        50,
        divRegs
      );
    }

    this.TO_showValues = setTimeout(() => {
      this.showValues();
    }, 2000);
  }

  async viewValues() {
    ($('#viewValues') as any).modal('show');

    await this.showValues();
  }

  /* Tag list */
  /**
   * Show tag list and create formcontrols
   */
  async tagsControl() {
    this.tagTypes = await this.tagServ.getTagTypes();

    this.tagTypesKeys = {};
    this.tagTypes.forEach((tagType) => {
      this.tagTypesKeys[tagType['_id']] = tagType['name'];
    });

    this.tagForm = new FormGroup({});
    this.tagForm.addControl('name', new FormControl('', Validators.required));
    this.tagForm.addControl('type', new FormControl('', Validators.required));

    this.item.protocolTypes.forEach((element) => {
      try {
        this.item.tagColumns[element._id] = element.parameters.fields;
      } catch (error) {
        this.item.tagColumns[element._id] = {};
      }

      //voy a añadir los fields según parametros
      try {
      } catch (error) {}
    });

    const fieldsKey = Object.keys(
      this.item.tagColumns[this.optionsForm.value['protocoltype']]
    );
    fieldsKey.forEach((key) => {
      this.tagForm.addControl(key, new FormControl('', Validators.required));
    });

    this.tags = await this.tagServ.getTagsByInfo({
      protocol: this.item._id,
      thing: this.item.parent,
    });

    this.oldTags = JSON.parse(JSON.stringify(this.tags));

    // this.tags = [{ "name": "PH", "type": "number", "address": "323" }];
    // this.tagColumns = { "address": "string" };

    ($('#tagsList') as any).modal('show');
  }

  /* Tag list */
  /**
   * Show tag list and create formcontrols
   */
  async tagList() {
    if (this.tags === undefined) {
      this.tags = [];
    }

    ($('#tagList') as any).modal('show');
    this.tagForm.addControl('name', new FormControl('', Validators.required));
    this.tagForm.addControl('type', new FormControl('', Validators.required));
  }

  /**
   * Show modal and specifics buttons
   */
  showTagForm() {
    $('#addTag_btn').hide();
    $('#cancelTag_btn').show();

    $('#tagsForm').show();
    // $('#valuesView').show();

    //$('#addressLabel').show();
    //$('#expressionLabel').hide();
  }

  /**
   * Add tag to this.tags array
   */
  async addTag() {
    let tag: any = {};
    tag = {
      name: this.tagForm.value.name.trim(),
      type: this.tagForm.value.type,
    };

    tag.parameters = {};

    const fieldsKey = Object.keys(
      this.item.tagColumns[this.optionsForm.value['protocoltype']]
    );
    fieldsKey.forEach((key) => {
      tag.parameters[key] = this.tagForm.value[key];
    });

    this.tags.push(tag);
    this.tagForm.reset();
    this.cancelAddTag();

  }

  /**
   * Cancel add tag
   */
  cancelAddTag() {
    $('#cancelTag_btn').hide();
    $('#addTag_btn').show();
    $('#tagsForm').hide();
    $('#addTag_submit_btn').show();
    $('#editTag_submit_btn').hide();
    this.tagForm.reset();
  }

  /**
   * Remove tag from this.tags array
   * @param event event
   */
  async removeTag(event) {
    const tagName = event.target.dataset.tagname;
    const indexTag = this.tags.map((element) => element.name).indexOf(tagName);
    const tag = this.tags[indexTag];

    this.tags.splice(indexTag, 1);
  }

  /**
   * Edit tag, load info into inputs
   * @param event event
   */
  editTag(event) {
    this.showTagForm();

    const tagName = event.target.dataset.tagname;
    const tagItem = this.tags.filter((element, index) => {
      if (element.name === tagName) {
        $('#tagPos').val(index);
        return element;
      }
    });

    const vls = {};
    vls['name'] = tagItem[0].name.trim();
    vls['type'] = tagItem[0].type;

    const keysF = Object.keys(
      this.item.tagColumns[this.optionsForm.value['protocoltype']]
    );

    keysF.forEach((keyF) => {
      vls[keyF] = tagItem[0]['parameters'][keyF];
    });

    /*
    this.tagForm.patchValue({
      name: tagItem[0].name.trim(),
      type: tagItem[0].type
    });
    */

    this.tagForm.patchValue(vls);

    this.tagForm.value['name'] = 'DB23,RE200';

    $('#addTag_submit_btn').hide();
    $('#editTag_submit_btn').show();
  }

  /**
   * Edit tag submit
   */
  editTagSubmit() {
    const index: any = $('#tagPos').val();

    this.tags[index].name = this.tagForm.value.name.trim();
    this.tags[index].type = this.tagForm.value.type;

    const fieldsKey = Object.keys(
      this.item.tagColumns[this.optionsForm.value['protocoltype']]
    );
    fieldsKey.forEach((key) => {
      this.tags[index].parameters[key] = this.tagForm.value[key];
    });

    this.cancelAddTag();
  }

  /**
   * Change check value
   * @param event event
   */
  checkBoxValue(event, controlName) {
    this.optionsForm.controls[controlName].setValue(event.target.checked);
  }

  socketDisconnect() {
    this.socketService.emitEvent('unsubscribe', {
      topic: 'all',
    });
  }

  /**
   * Create parameters into Connection item and set his tags
   */
  async addTagListToItem() {
    const info: any = {
      tags: this.tags,
      parentsInfo: { protocol: this.item._id, thing: this.item.parent },
    };
    await this.tagServ.updateTags(info);
    this.tagServ.getTagsByInfo({}).then((data) => this.allTags = data);
    this.alarmServ.get().then((data) => this.allAlarms = data);
    ($(`#tagsList`) as any).modal('hide');
  }

  asIs() {
    return 0;
  }

  /**
   * Change label to expression if is a internal tag
   * @param event event
   */
  checkTagType(event) {
    if (event.target.value === 'INTERNAL') {
    } else {
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  formatDateHora(sdate) {
    let ret = '';
    let date = new Date(sdate);
    let day = `${date.getDate()}`.padStart(2, '0');
    let month = `${date.getMonth() + 1}`.padStart(2, '0');
    let year = date.getFullYear();
    let h = `${date.getHours()}`.padStart(2, '0');
    let m = `${date.getMinutes()}`.padStart(2, '0');
    let s = `${date.getSeconds()}`.padStart(2, '0');

    ret = `${year}-${month}-${day} ${h}:${m}:${s}`;

    return ret;
  }
}
