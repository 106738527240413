import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { PermissionsService } from 'src/app/service/auth/permissions.service';
import { RolesService } from 'src/app/service/auth/roles.service';
import { DatatableService } from 'src/app/service/datatable.service';
import { RouterService } from 'src/app/service/router.service';
import { LanguageService } from 'src/app/service/system/language.service';


@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  roles: any;
  newRole: FormGroup;
  permissions: any;
  newPermissions: any;
  $: any;
  editRoleForm: FormGroup;
  deleteRoleId!: string;
  dtOptions: DataTables.Settings = {};
  language: any;
  roleText: any;
  modalText: any;

  /**
   * Constructor
   * @param rolesServ role service
   * @param permissionsServ permission service
   * @param dataServ datatable service
   * @param langServ language service
   */
  constructor(
    private rolesServ: RolesService,
    private permissionsServ: PermissionsService,
    private dataServ: DatatableService,
    private langServ: LanguageService,
    private refresh: RouterService
  ) {
    // Add FormControls to newRole
    this.newRole = new FormGroup({
      name: new FormControl('', Validators.required),
      permissions: new FormControl('')
    });
    // Add FormControls to editRoleForm
    this.editRoleForm = new FormGroup({
      name: new FormControl('', Validators.required),
      permissions: new FormControl(''),
      _id: new FormControl('')
    });
    // declare newPermissions as a empty array
    this.newPermissions = [];
    // declare permissions as a empty array
    this.permissions = [];
  }

  async ngOnInit(): Promise<any> {
    // get roles
    await this.getRoles();
    // get permissions
    await this.getPermissions();
    // Timeout function to load datatable
    setTimeout(() => {
      // Get options from datatable service
      const options = this.dataServ.optionsDatatable();
      // Execute datatable function to load it
      (<any>$('#rolesTable')).dataTable(options);
      let divRoles = document.getElementById("divRoles");
      let dR: any = document.getElementById("divRoles");
      let top = 20;
      while (dR.offsetParent !== null) {
        top = top + dR.offsetTop;
        dR = dR.offsetParent;
      }
      divRoles.style.visibility = "";
      divRoles.style.height = (window.innerHeight - top) + 'px';
    }, 100);
    // get language
    this.language = await this.langServ.getVal();
    // get the users text
    this.roleText = this.language.administration.roles_component;
    // get the modal text
    this.modalText = this.language.administration.modals;
  }

  /**
   * Get roles from roles service
   */
  async getRoles(): Promise<any> {
    this.roles = await this.rolesServ.getRoles();
  }

  /**
   * addRole submit action
   */
  async addRole(): Promise<any> {
    // get toast add message
    const text = this.roleText.toast_add_message;
    // replace _ROLE_ for role name
    const showText = text.replace('_ROLE_', this.newRole.value.name);
    // set role permission
    this.newRole.value['permissions'] = (this.newPermissions);
    // send role info to role service
    const newRole = await this.rolesServ.newRole(this.newRole.value)
    // call resetModal function
    this.resetModal();
    // hide modal
    (<any>$('#addRoleModal')).modal('hide');
    // Insert html code to toast body
    $('.toast-body').html(`<p style="font-size:18px;">${showText}</p>`);
    // Show toast
    (<any>$('.toast')).toast('show');
    await this.getRoles();
    this.refresh.refresh();
  }

  /**
   * Reset modal function
   */
  resetModal(): void {
    // call init method
    this.getPermissions();
    this.getRoles();
    // reset newRole form
    this.newRole.reset();
    // declare newPermissions array empty
    this.newPermissions = [];


  }

  /**
   * Get permissions from permission service
   */
  async getPermissions(): Promise<any> {
    this.permissions = await this.permissionsServ.getPermissions();
  }

  /**
   * Move permissions if user check it
   * @param $event know the target values
   */
  onCheckchange($event): void {
    // declare object with id and name permission
    const permissionToMove = {
      _id: $event.target.dataset.id,
      name: $event.target.value
    };

    // if user check it
    if ($event.target.checked) {
      // add permissionToMove to newPermissions array
      this.newPermissions.push(permissionToMove);
      // get index permissions of item
      const i = this.permissions.map(index => index._id).indexOf($event.target.dataset.id);
      // remove from permissions array
      this.permissions.splice(i, 1);
    } else {
      // get index newPermission of item
      const i = this.newPermissions.map(index => index._id).indexOf($event.target.dataset.id);
      // remove from newPermissions array
      this.newPermissions.splice(i, 1);
      // add to permissions array
      this.permissions.push(permissionToMove);

    }
  }

  /**
   * edit role button
   * @param $event know target values
   */
  editRole($event): void {
    // get the id of role to edit
    const id = $event.target.dataset.id;
    // get the role from this.roles array
    const editedRole = this.roles.filter(role => role._id == id)
    // set roles info to editRoleForm
    this.editRoleForm.patchValue({
      _id: editedRole[0]._id,
      name: editedRole[0].name

    });
    // get permissions of roles
    const permissions = editedRole[0].permissions;
    // foreach permissions
    for (let permission of permissions) {
      for (let findPermission of this.permissions) {
        // if permission._id equals findPermission._id
        if (permission._id === findPermission._id) {
          // add findPermission to newPermissions array
          this.newPermissions.push(findPermission);
          // get indexOf findPermission in permissions array
          const i = this.permissions.map(index => index._id).indexOf(findPermission._id);
          // remove findPermissions from permissions array
          this.permissions.splice(i, 1);

        }
      }
    }
  }

  /**
   * Edite rol request
   */
  async editeRoleRequest(): Promise<any> {
    // get toast edit message
    const text = this.roleText.toast_edit_message;
    // replace _ROLE_ for role name
    const showText = text.replace('_ROLE_', this.editRoleForm.value.name);
    // get permissions value in edit form
    this.editRoleForm.value['permissions'] = (this.newPermissions);
    // send role to method editeRole in role service
    await this.rolesServ.editeRole(this.editRoleForm.value);
    // call resetModal function
    this.resetModal();
    // Hide modal
    (<any>$('#editRoleModal')).modal('hide');
    // Insert html code to toast body
    $('.toast-body').html(`<p style="font-size:18px;">${showText}</p>`);
    // Show toast
    (<any>$('.toast')).toast('show');
  }

  /**
   * Confirm delete role
   * @param $event know the role id
   */
  confirmDeleteRole($event) {
    // set deleteRoleId the id we get from $event
    this.deleteRoleId = $event.target.dataset.id;
  }

  /**
   * deleteRole function
   */
  async deleteRole(): Promise<any> {
    // get toast delete message
    const text = this.roleText.toast_delete_message;
    // send the id to method deleteRole in role service
    const status = await this.rolesServ.delteRole(this.deleteRoleId);
    // call on init method
    this.resetModal();
    // hide modal
    (<any>$('#delteRoleModal')).modal('hide');
    // Insert html code to toast body
    $('.toast-body').html(`<p style="font-size:18px;">${text}</p>`);
    // Show toast
    (<any>$('.toast')).toast('show');
  }
}
