import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/service/system/language.service';
import { AlarmsSocketService } from 'src/app/service/tags/alarms-socket.service';
import { AlarmsService } from 'src/app/service/tags/alarms.service';
import { TableComponent } from 'src/app/components/table/table.component';

@Component({
  selector: 'app-alarms',
  templateUrl: './alarms.component.html',
  styleUrls: ['./alarms.component.scss'],
})
export class AlarmsComponent implements OnInit, OnDestroy {
  language: any;
  alarmsLogs: any = [];
  subscriptions: Subscription[] = [];

  actvieTableHeaders: any = [];
  activeTableData = [];
  historicTableHeaders: any = [];
  historicTableData: any = [];
  cellStyle = (column: any, row: any) => {
    if (row?.status === 'appeared' || row?.status === 'IN')
      return 'color: #E90000;';
    if (row?.status === 'disappeared' || row?.status === 'OUT')
      return 'color: #008700;';
  };

  @ViewChild('active') activeTable!: TableComponent;

  constructor(
    private langServ: LanguageService,
    private alarmServ: AlarmsService,
    private alarmSock: AlarmsSocketService
  ) {}

  /**
   * Formats the AlarmLog object to display data in the table
   * @param alarmLog AlarmLog object
   * @returns Formatted AlarmLog object
   */
  formatAlarmLog(alarmLog: any): any {
    const log = { ...alarmLog };

    log.username = log.user?.username ?? null;
    log.message = log.alarm?.message ?? null;

    return log;
  }

  /**
   * Formats the AlarmLog to display the historic.
   *
   * @param num AlarmLog Id.
   * @param alarmLog AlarmLog object.
   *
   * @returns Array with the historic of the AlarmLog.
   */
  formatHistoricLog(num: number, alarmLog: any): any[] {
    const historic = [];

    historic.push({
      id: num,
      date: alarmLog.appeared_datetime,
      message: alarmLog.alarm?.message ?? null,
      status: 'IN',
    });

    if (alarmLog?.ack_datetime && alarmLog?.status !== 'appeared') {
      historic.push({
        id: num,
        date: alarmLog.ack_datetime,
        message: alarmLog.alarm?.message ?? null,
        username: alarmLog.user?.username ?? null,
        status: 'ACK',
      });
    }

    if (alarmLog?.status === 'disappeared') {
      historic.push({
        id: num,
        date: alarmLog.disappeared_datetime,
        message: alarmLog.alarm?.message ?? null,
        status: 'OUT',
      });
    }

    return historic;
  }

  /**
   * A AlarmLog and updates the displayed data
   *
   * @param alarmLogId AlarmLog Id
   */
  async ackAlarmLog(alarmId: string, alarmLogId: string): Promise<void> {
    try {
      const ackAlarm = await this.alarmServ.ackLog(alarmLogId);
      this.alarmSock.emitAck(alarmId, ackAlarm);
      const index = this.alarmsLogs.findIndex(
        (log: any) => log._id === ackAlarm._id
      );
      this.alarmsLogs[index] = ackAlarm;
    } catch (error) {}
  }

  async loadActiveTable(): Promise<void> {
    this.activeTableData = this.alarmsLogs
      .map((log: any) => this.formatAlarmLog(log))
      .filter((log: any) => log.status !== 'disappeared');
  }

  async loadHistoricTable(): Promise<void> {
    const historicData = [];

    let i = 0;
    for (const log of this.alarmsLogs) {
      i += 1;
      // if (log.status === 'disappeared') {
      historicData.push(this.formatHistoricLog(i, log));
      // }
    }
    this.historicTableData = historicData.flat();
  }

  /**
   * Acknowledges all AlarmLogs selected in table.
   */
  ackSelected(): void {
    const selected = this.activeTable.selection.selected;
    const promises: Promise<any>[] = [];
    for (const log of selected) {
      if (log.status === 'appeared') {
        const promise = this.ackAlarmLog(log.alarm._id, log._id);
        promises.push(promise);
      }
    }

    Promise.all(promises).then(() => {
      this.activeTable.selection.clear();
      this.loadActiveTable();
      this.loadHistoricTable();
    });
  }

  async ngOnInit(): Promise<void> {
    const language: any = await this.langServ.getVal();
    this.language = language.alarms;

    this.alarmsLogs = await this.alarmServ.getLogs();

    this.actvieTableHeaders = [
      { key: 'select' },
      { key: 'appeared_datetime', label: 'Fecha aparecida' },
      { key: 'message', label: 'Mensaje' },
      { key: 'ack_datetime', label: 'Fecha acusada' },
      { key: 'username', label: 'Acusada por' },
    ];

    this.historicTableHeaders = [
      { key: 'id', label: 'Num.' },
      { key: 'status', label: 'Estado' },
      { key: 'date', label: 'Fecha' },
      { key: 'message', label: 'Mensaje' },
      { key: 'username', label: 'Acusada por' },
    ];

    this.loadActiveTable();
    this.loadHistoricTable();

    const appeared = this.alarmSock.listenAppeared().subscribe((data) => {
      this.alarmsLogs.push(data);
      const newActiveTableData = [...this.activeTableData];
      newActiveTableData.push(this.formatAlarmLog(data));
      this.activeTableData = newActiveTableData;

      this.loadHistoricTable();
    });

    const ack = this.alarmSock.listenAck().subscribe((data) => {
      const index = this.alarmsLogs.findIndex((log) => log._id === data.alarmLog._id);
      this.alarmsLogs[index] = data.alarmLog;

      this.loadActiveTable();
      this.loadHistoricTable();
    });

    const disappeared = this.alarmSock.listenDisappeared().subscribe((data) => {
      const index = this.alarmsLogs.findIndex((log) => log._id === data._id);
      this.alarmsLogs[index] = data;

      this.loadActiveTable();
      this.loadHistoricTable();
    });

    this.subscriptions.push(appeared, ack, disappeared);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
