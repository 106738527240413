import { AfterContentInit, AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { Item } from 'src/app/interfaces/item';
import { AuthService } from 'src/app/service/auth/auth.service';
import { DatatableService } from 'src/app/service/datatable.service';
import { SharedService } from 'src/app/service/modelizer/shared.service';
import { SystemService } from 'src/app/service/system/system.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-synopticplayer_v2',
  templateUrl: './synopticplayer_v2.component.html',
  styleUrls: ['./synopticplayer_v2.component.scss'],
})
export class Synopticplayer_v2Component implements OnInit, OnDestroy, AfterContentInit, AfterViewInit {
  private subscriptions: Subscription[];

  ply_vars_system: {
    synoptics: [any],
    reports: [any],
    synopticType: any,
    controls: any,
    items: any,
    itemsInSyn: any,
    tags: any,
    things: any,
    protocols: any,
    synoptic: any,
    folders: any,
    datadesign: any,
    DDFields: any,
    DDContent: any,
    DataDesignFieldsType: any,
    cssEntitiesGlobal: any,
    user: any,
    role: any,
    scriptsElements: any
  };

  private BASE_URL;

  controlInt: any;
  report: string;
  isReport: boolean = false;

  options: any = { tpo: 3 };

  keybInfo: any = { keyb: false };

  synItems: any = {};

  intervalStart: any;

  backcolor: string;

  systemSynoptic: any;
  // systemDataDesign: any;
  tree: HTMLElement;
  elemMove: HTMLElement;
  movIni = false;
  despX = 0;
  despY = 0;
  tblCnt = 0;
  inDiv = false;
  ps: object[];
  divMain: HTMLElement;
  tagParams: {};
  headboard: any;
  footer: any;
  detail: any;
  parameters: {};
  type: any;
  parentAdd: any;
  synoptic: HTMLElement;
  itemsInSynoptic: HTMLElement[];
  divMask: HTMLElement;
  intervalOption: any;

  selectItem: string;
  selectTag: HTMLElement;
  modePlay = true;

  deleteItemButtonEvent = false;
  param_synopticId = '';
  param_synopticName = '';

  param_reportId = '';
  param_reportName = '';

  //DataDesign
  DSSelected: any;
  modalText: any;
  TypeDataDesignFields: any;
  TypeDataDesignContent: any;
  TypeDataDesign: any;
  DDFields: any = {};
  stateParameters: any;

  DDisRelation: boolean = false;
  DDrelationFieldName: any;
  DDrelationFieldValue: any;
  DDrelationDDName: any;

  selectedItemId = '';
  selectedSynopticId = '';

  deleteFieldId = '';

  deleteInfo: any;
  updateInfo: any;
  addnewInfo: any;

  constructor(
    private elementRef: ElementRef,
    private dataServ: DatatableService,
    private ply_serv_System: SystemService,
    private sharServ: SharedService,
    private router: Router,
    private route: ActivatedRoute,
    private authServ: AuthService
  ) {
    this.report = '';

    this.BASE_URL = environment.base_url;

    this.subscriptions = [];
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {
      mode: string,
      synopticId: string
    };

    this.addnewInfo = { mode: 'none' };
    this.updateInfo = { mode: 'none' };
    this.deleteInfo = { mode: 'none' };

    this.stateParameters = state;
    this.modePlay = true;

    try {
      if (this.route.snapshot.routeConfig.path === 'synopticReport/:syn') {
        this.isReport = true;
      }
    } catch (error) {
    }

    if (this.stateParameters !== undefined) {
      this.param_synopticName = '';
      this.param_synopticId = this.stateParameters.synopticId;
    } else {
      this.param_synopticId = '';
      if (this.route.snapshot.paramMap.get("syn")) {
        this.param_synopticName = this.route.snapshot.paramMap.get("syn");
      } else {
        if (this.route.snapshot.paramMap.get("rep")) {
          this.param_reportName = this.route.snapshot.paramMap.get("rep");
        } else {
          this.param_synopticName = '';
          this.router.navigate(['/']);
        }
      }
    }
  }
  async ngAfterContentInit(): Promise<void> {
    // throw new Error('Method not implemented.');
  }

  async ngAfterViewInit() {

  }

  async ngOnDestroy(): Promise<any> {


    this.synItems = {};
    this.itemsInSynoptic = [];
    this.elementRef.nativeElement.remove();

    try {
      document.getElementById('botstop').parentElement.removeChild(document.getElementById('botstop'));
    } catch (error) {
    }

    this.unsuscribe();
  }

  subscribe(subs: any) {
    this.subscriptions.push(subs);
  }

  unsuscribe() {
    this.subscriptions.forEach(subs => {
      try {
        subs.unsubscribe();
      } catch (error) {
        // console.log('Error', error);
      }
    });

  }

  async ply_func_start(token: any) { // }, scriptsElements: any) {

    this.isReport = false;
    if (this.param_reportName !== '') {
      this.isReport = true;
      this.ply_vars_system = await this.ply_serv_System.getSystemBy({ type: 'reports', token: token, byName: this.param_reportName });
    }

    if (this.param_synopticName !== '') {

      this.ply_vars_system = await this.ply_serv_System.getSystemBy({ type: 'synoptics', token: token, byName: this.param_synopticName });

    }

    if (this.param_synopticId !== '') {
      this.ply_vars_system = await this.ply_serv_System.getSystemBy({ type: 'synoptics', token: token, byId: this.param_synopticId });
    }
    const scriptsElements: any = this.ply_vars_system.scriptsElements;

    const thgs = {};
    for (const thing of this.ply_vars_system['things']) {
      thgs[thing._id.toString()] = thing;
    }
    for (const tag of this.ply_vars_system['tags']) {
      tag['thing'] = thgs[tag['thing'].toString()];
    }

    // this.getSystemSynoptic();

    this.divMain = document.getElementById('main');
    this.backcolor = '#FFFFFF';

    this.tagParams = {};

    this.modalText = { add: 'Texto' };


    this.ps = [];

    this.ps.push({ val: 'p21' });
    this.ps.push({ val: 'p22' });

    this.tree = document.createElement('UL');
    this.tree.className = 'ulil';

    this.subscribe(this.ply_serv_System.changeEmittedSyn$.subscribe(async data => {
    }));

    document.onkeydown = null;
    document.onkeyup = null;
    document.onresize = null;
    document.onmousemove = null;
    document.onmouseup = null;

    this.subscribe(this.sharServ.changeEmitted$.subscribe(async data => {
      if (data.val === 'action') {
        if (data.item.id === 'undefined' || data.item.id === 'new') {
          // add
          this.saveNewItem();
        } else {
          // edit
          this.saveEdtItem(data.item.id, data.item.parameters, data.item.parent);
        }
      }
    }));

    this.divMask = document.createElement('div');
    this.divMask.style.display = 'none';
    this.divMask.style.position = 'absolute';
    this.divMask.style.backgroundColor = 'orange';
    this.divMask.style.zIndex = '500';
    this.divMask.style.opacity = '0.5';
    this.divMask.style.cursor = 'pointer';
    this.divMask.addEventListener('click', this.clickInControl.bind(this));
    document.body.appendChild(this.divMask);

    this.itemsInSynoptic = [];

    let itemClick = this.ply_vars_system.synoptic;

    this.param_synopticId = itemClick.parameters.namegeneral;
    this.param_synopticId = itemClick._id;
    this.backcolor = itemClick.parameters.colorgeneral;

    this.ply_vars_system.items.forEach(item => {
      this.controlBuild(item);

      if (item.type === '61555a6fb6fe1cee610b0253') {
        const synn = this.ply_vars_system.synoptics.find(each => each._id === item.parameters.synopticgeneral);

        if (synn) {
          itemClick.parameters.colorgeneral = synn.parameters.colorgeneral;
          if (synn.parameters.runscript_scriptLoad) {
            try {
              setTimeout((scr) => {
                eval(scr);
              }, 1, synn.parameters.runscript_scriptLoad);
            } catch (error) {
              console.log('load', error);
            }
          }
        }

        // console.log('synoptic', item);
      }
    });

    const syn = itemClick.parameters;
    syn['_id'] = itemClick._id;

    this.ply_serv_System.emitUpdateSyn({ scripting: scriptsElements, synoptic: syn, items: { "Synoptic": [this.synItems] }, player: true, report: this.isReport, systemSynoptic: this.ply_vars_system, keybInfo: this.keybInfo, tags: this.ply_vars_system.tags, protocols: this.ply_vars_system.protocols, things: this.ply_vars_system.things });
  }

  async ngOnInit(): Promise<any> {
    await this.ply_func_start(this.authServ.getToken());
    document.getElementById('main')['app'] = this;
  }

  loadSynoptic(synopticItem) {
    const _id = synopticItem._id;
    const _x = synopticItem.parameters.xgeneral;
    const _y = synopticItem.parameters.ygeneral;
    const _idSyn = synopticItem.parameters.synopticgeneral;

    const itemsFiltered = this.ply_vars_system.itemsInSyn.filter(item => item['parent'] === _idSyn);

    let itemsIn = {};
    itemsIn = synopticItem.parameters.items;

    let ret = false;
    itemsFiltered.forEach(item => {
      ret = true;
      const _it = JSON.stringify(item);
      const it = JSON.parse(_it);

      it._id = it._id + '_' + _id;

      if (itemsIn) {
        if (itemsIn.hasOwnProperty(item._id)) {
          const keys = Object.keys(itemsIn[item._id]);
          keys.forEach(key => {
            it.parameters[key] = itemsIn[item._id][key];
          });
        }
      }

      it.parameters.xgeneral = parseInt(it.parameters.xgeneral) + parseInt(_x);
      it.parameters.ygeneral = parseInt(it.parameters.ygeneral) + parseInt(_y);

      this.controlBuild(it);
    });

    return ret;
  }

  loadSynopticOld(_id, _idSyn, _x, _y) {
    const itemsFiltered = this.systemSynoptic.items.filter(item => item['parent'] === _idSyn);
    itemsFiltered.forEach(item => {
      const _it = JSON.stringify(item);
      const it = JSON.parse(_it);

      it.parameters.xgeneral = parseInt(it.parameters.xgeneral) + parseInt(_x);
      it.parameters.ygeneral = parseInt(it.parameters.ygeneral) + parseInt(_y);
      it._id = it._id + _id;

      this.controlBuild(it);
    });
  }

  getItems(type: string, obj: HTMLElement) {
    const itemsFiltered = this.systemSynoptic.items.filter(item => item['type'] === type);

    itemsFiltered.forEach(item => {
      const li: HTMLElement = document.createElement('LI');
      li.id = item._id;
      li.className = 'ulil';
      const sp: HTMLElement = document.createElement('SPAN');
      sp.style.cursor = 'pointer';
      sp.style.userSelect = 'none';
      sp.className = 'spn';
      sp.innerHTML = item.name;
      sp.addEventListener('click', this.clickInOption.bind(this));
      sp.setAttribute('idSynoptic', item._id);
      li.appendChild(sp);
      obj.appendChild(li);
    });
  }

  clickInOption(event) {
    this.clickInOptionTarget(event.target);
  }

  clickInOptionTarget(target): void {
    const text = target.innerHTML;

    const uls = target.parentNode.getElementsByTagName('ul');
    if (uls.length > 0) {
      if (uls[0].style.display === 'none') {
        target.parentNode.getElementsByTagName('span')[0].innerHTML = '\u25BC ';
        uls[0].style.display = '';
      } else {
        target.parentNode.getElementsByTagName('span')[0].innerHTML = '\u25B6 ';
        uls[0].style.display = 'none';
      }
    } else {
      if (target.style.backgroundColor !== 'blue') {
        target.style.backgroundColor = 'blue';
        target.style.color = 'white';
        if (this.synoptic !== undefined) {
          this.synoptic.style.backgroundColor = '';
          this.synoptic.style.color = '';
        }
        this.synoptic = target;

        this.divMain.innerHTML = '';
        this.itemsInSynoptic = [];

        this.divMain.appendChild(this.divMask);

        const itemClick = this.systemSynoptic.items.find(item => item['_id'] === target.getAttribute('idsynoptic'));

        const itemsFiltered = this.systemSynoptic.items.filter(item => item['parent'] === this.synoptic.getAttribute('idsynoptic'));
        itemsFiltered.forEach(item => {
          const typeText = this.systemSynoptic.types.filter(type => type['_id'] === item['type']);
          this.showControl(typeText[0]['name'], item['type'], item['parameters'], item['_id']); //itemClick[0]['_id']);

        });

        this.param_synopticId = itemClick._id;
        this.divMask.style.display = 'none';

      }
    }
  }

  clickInSpan(event): void {
    const parameters = JSON.parse(event.target.parentElement.getAttribute('parameters'));

    if (parameters.hasOwnProperty('add')) {
      if (parameters['add']) {
        if (this.synoptic !== undefined) {
          this.synoptic.style.backgroundColor = '';
          this.synoptic.style.color = '';
          this.synoptic = undefined;
        }
        this.newItem(event.target);
      }
    }

  }

  setParametersValue(parValues: object): void {
    const keys = Object.keys(parValues);
    keys.forEach(key => {
      document.getElementById(key)['value'] = parValues[key];
    });

  }

  getParametersValue(): object {
    const ret = {};

    const tabs = Object.keys(this.parameters);
    tabs.forEach(tab => {
      const keys = Object.keys(this.parameters[tab]);
      keys.forEach(key => {
        ret[key + tab] = document.getElementById(key + tab)['value'];
      });
    });

    return ret;
  }

  async saveEdtItem(id: string, parameters: object, parent: string): Promise<any> {

    const newIt: Item = { _id: id, description: '', name: '', order: 10, parent: parent, type: this.type, parameters: parameters };

    const itemUpdated = await this.ply_serv_System.updItem(newIt);
  }

  async saveNewItem(): Promise<any> {

    const parameters = this.getParametersValue();
    const newIt: Item = { _id: '', description: '', name: '', order: 10, parent: '', type: this.type, parameters: parameters };
    const itemUpdated = await this.ply_serv_System.newItem(newIt);

    const obj = this.parentAdd;

    const li: HTMLElement = document.createElement('LI');
    li.id = itemUpdated.data._id;
    li.className = 'ulil';
    const sp: HTMLElement = document.createElement('SPAN');
    sp.style.cursor = 'pointer';
    sp.className = 'spn';
    sp.innerHTML = itemUpdated.data.name;
    sp.style.userSelect = 'none';
    sp.addEventListener('click', this.clickInOption.bind(this));
    li.appendChild(sp);
    obj.appendChild(li);

    this.systemSynoptic.items.push(itemUpdated);
  }

  spanDown(event): void {
    const elemMove: HTMLElement = event.target.cloneNode(true);
    this.movIni = true;
    elemMove.style.position = 'inherit';
    elemMove.style.display = 'none';

    elemMove.setAttribute('tagId', event.target.id);
    elemMove.setAttribute('tagType', event.target.getAttribute('tagType'));
    elemMove.setAttribute('idType', event.target.getAttribute('idType'));
    this.despX = (event.layerX - event.target.offsetLeft) + 200;
    this.despY = (event.layerY - event.target.offsetTop) + 50;

    elemMove.id = 'moving';
    elemMove.style.zIndex = '200';

    this.divMain.appendChild(elemMove);
  }

  buildTag(elemParent: HTMLElement, tags: [object], x: any, y: any, ini: boolean, idItem: string): void {
    tags.forEach(tag => {
      const newElem: HTMLElement = document.createElement(tag['tagName']);
      // newElem.style.position = 'inherit';
      newElem.style.left = x;
      newElem.style.top = y;

      for (const [key, value] of Object.entries(tag)) {
        switch (key) {
          case 'innerText':
            newElem.innerText = value;
            break;
          case 'innerHTML':
            newElem.innerHTML = value;
            break;
          case 'style':
            for (const [keyStyle, valueStyle] of Object.entries(value)) {
              newElem.style[keyStyle] = valueStyle;
            }
            break;
          case 'value':
            newElem['value'] = value;
            break;
          case 'tags':
            this.buildTag(newElem, value, x, y, false, idItem);
            break;
        }
      }

      if (ini) {
        newElem.id = idItem;
        this.itemsInSynoptic.push(newElem);
      }
      elemParent.appendChild(newElem);
    });

  }

  controlBuild(item: any) {
    let elemNew: HTMLElement;

    const type = this.ply_vars_system.controls.find(type => type._id === item.type);
    switch (type.name) {
      case 'Synoptic':
        const ok = this.loadSynoptic(item);
        break;
      default:
        let data: any = {};
        let options: any = {};

        if (type.name === 'Chart') {
          options = {
            title: {
              text: 'Chart.js Time Scale'
            },
            legend: { display: true },
            plugins: { legend: { display: true }, title: { display: true }, subtitle: { display: true } },
            tooltips: {
              callbacks: {
                label: function (tooltipItem) {
                  return tooltipItem.yLabel;
                }
              }
            },
            scales: {
              xAxes: [{

                ticks: {
                  fontColor: item.parameters.colorTickschart
                },
                gridLines:
                {
                  color: item.parameters.colorGridchart,
                },
                type: 'time',
                time: {
                  unit: 'second',
                  maxTicksLimit: 100,
                  stepSize: 6,
                  displayFormats:
                  {
                    second: 'HH:mm:00',
                    hour: 'HH:mm',
                    day: 'DD-MM HH',
                    month: 'DD-MM-YYYY'
                  }
                },
                scaleLabel: {
                  display: false,
                  labelString: 'Date'
                }
              }],
              yAxes: [{

                ticks: {
                  fontColor: item.parameters.colorTickschart
                },
                gridLines:
                {
                  color: item.parameters.colorGridchart
                },
                scaleLabel: {
                  display: false,
                  labelString: item.parameters.measurechart
                }
              }]
            },
          };

          if (item.parameters.minchart !== '0' || item.parameters.maxchart !== '0') {
            options.scales.yAxes[0].ticks = { fontColor: item.parameters.colorTickschart, min: parseFloat(item.parameters.minchart), max: parseFloat(item.parameters.maxchart) };
          }

          data = {
            datasets: [
              {
                steppedLine: false,
                barThickness: 10,

                label: item.parameters.titlechart,
                data: [{
                  x: '2020-12-23 11:00:00'
                }],
                fill: false,
                backgroundColor: 'rgba(0,0,255,0.8)',
                borderColor: item.parameters.colorchart,
                lineTension: 0,
              }
            ],
          };

          if (item.parameters.titlechart === 'none') {
            options['plugins']['legend']['display'] = false;
            options['plugins']['title']['display'] = false;
            options['plugins']['subtitle']['display'] = false;
            options['legend']['display'] = false;
          }


          if (item.parameters.typechart === 'stepped') {
            data.datasets[0].steppedLine = true;
          }


        }

        let typeChart = item.parameters['typechart'];

        if (typeChart === 'stepped') {
          data.datasets[0].steppedLine = true;
          typeChart = 'line';
        }

        if (!this.synItems.hasOwnProperty(type.name)) {
          this.synItems[type.name] = [];
        }
        this.synItems[type.name].push({ typeChart, id: item._id, type: item.type, iditem: item._id, parameters: item.parameters, data: data, options: options, padding: type.parameters?.padding });
        this.itemsInSynoptic.push(item._id);

        break;
    }
  }

  showControl(tagType: string, typeId: string, parameters: any, idItem: string): void {

    let elemNew: HTMLElement;

    switch (tagType) {
      case 'Field':
      case 'Button':
      case 'Text':
        if (!this.tagParams.hasOwnProperty(tagType)) {
          const params = this.systemSynoptic.types.find(type => type.name === tagType).parameters;
          this.tagParams[tagType] = params;
        }
        this.buildTag(this.divMain, this.tagParams[tagType]['tags'], parameters['xgeneral'], parameters['ygeneral'], true, idItem);
        break;
    }
  }

  clickInControl(event): void {
    // var idItem = event.target.id;
    this.selectedItemId = this.selectItem;
  }

  async mouseup(event) {
    if (this.movIni) {
      this.movIni = false;
      const spanClone = document.getElementById('moving');
      if (spanClone.style.display !== 'none' && parseInt(spanClone.style.left) >= 0) {
        // const divMain: HTMLElement = document.getElementById('main');
        const tagType = spanClone.getAttribute('tagType');
        const tagId = spanClone.getAttribute('tagId');

        const parameters = { xgeneral: spanClone.style.left, ygeneral: spanClone.style.top, namegeneral: tagType, descriptiongeneral: tagType };

        const newIt: Item = { _id: '', description: tagType, name: tagType, order: 10, parent: this.synoptic.getAttribute('idsynoptic'), type: tagId, parameters: parameters };
        const itemUpdated = await this.ply_serv_System.newItem(newIt);

        this.showControl(tagType, tagId, parameters, itemUpdated.data._id);

        this.systemSynoptic.items.push(itemUpdated.data);


      }
      spanClone.parentNode.removeChild(spanClone);
    }
  }

  async deleteItem(idItem: string) {

    this.ply_serv_System.delItem(this.param_synopticId);
    this.itemsInSynoptic = this.itemsInSynoptic.filter(item => item !== this.selectTag);
    this.systemSynoptic.items = this.systemSynoptic.items.filter(item => item._id !== this.selectItem);

    this.selectTag.parentNode.removeChild(this.selectTag);
  }

  async newItem(target: HTMLElement) {
    const parameters = JSON.parse(target.parentElement.parentElement.getAttribute('parameters'));

    this.divMain.innerHTML = '';

    this.type = target.parentElement.parentElement.getAttribute('type');
    this.parentAdd = target.parentElement.parentElement;
    this.parameters = parameters;

    $('#headboard-editor').css('display', 'block');
    $('#detail-editor').css('display', 'block');
    $('#footer-editor').css('display', 'block');
    $('#detail-editor > .ck-editor > .ck-editor__main > .ck-content').css('height', '400px');
    this.headboard = '';
    this.detail = '';
    this.footer = '';

  }

  closeKBModal() {
    this.keybInfo.keyb.modalLoaded = false;
  }
}

