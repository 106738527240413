import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import * as $$ from 'jquery';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { Subscription } from 'rxjs';
import { DatatableService } from 'src/app/service/datatable.service';
import { ModelizerItemsService } from 'src/app/service/modelizer/modelizer-items.service';
import { SharedService } from 'src/app/service/modelizer/shared.service';
import { LanguageService } from 'src/app/service/system/language.service';
import { SystemService } from 'src/app/service/system/system.service';
import { utils } from 'src/app/components/synoptic-core/synoptic';



/*
cpc000
cpc001 - Click en sinóptico
cpc013 - Click en report
cpc002 - Click en un item de pantalla
cpc003 - Editar item/synoptic (ok en formulario)
cpc004 - Eliminar item/synoptic (ok en formulario)
cpc005 - Seleccionar items (mouse move)
cpc006 - Copy items (contextual en item/selección)
cpc007 - Resize de un item (mouseup-final)
cpc008 - Desplazar item/items (inicio)
cpc009 - Desplazar item/items (movimiento)
cpc010 - Desplazar item/items (final-mouseclick)
cpc011 - Detectar elementos en un sync_func_mousemove
cpc012 - Add item desde contextual
*/

declare var $: any;

@Component({
  selector: 'app-synoptic',
  templateUrl: './synopticnew.component.html',
  styleUrls: ['./synopticnew.component.scss'],
  // encapsulation: ViewEncapsulation.None
})

export class SynopticnewComponent implements OnInit, OnDestroy {

  @ViewChild(ContextMenuComponent) public basicMenu: ContextMenuComponent;
  @ViewChild(ContextMenuComponent) public basicMenu2: ContextMenuComponent;
  @ViewChild(ContextMenuComponent) public basicMenu3: ContextMenuComponent;

  @ViewChild(ContextMenuComponent) public menuDiv: ContextMenuComponent;

  syn_vars_itemTypes: { synoptic: string, chart: string, keyboard: string } = {
    synoptic: '61555a6fb6fe1cee610b0253',
    chart: '5f858469093c3d3f06c69a59',
    keyboard: '6241daaf354943e8fccc2c2f'
  };

  syn_vars_free_types: any = [
    "63bd4a5dbc2535b4fa26ae9d" //ChartNew
  ];

  syn_vars_zoom: any = 100;
  syn_vars_zoom_auto: boolean = false;

  syn_vars_textoFichaxxx: string = '';

  syn_vars_distHorInput: string = '';
  syn_vars_distVerInput: string = '';

  syn_vars_language: any;

  syn_vars_isType: string = '';

  // syn_vars_isReport: boolean = false;

  syn_vars_a4: string = 'portrait';
  syn_vars_a4_color: string = 'orange';

  syn_vars_nodeSelected: any = false;
  syn_vars_nodeItemSelected: any = false;

  syn_vars_gridPossible: boolean = false;

  syn_vars_backcolor: string = '';

  syn_vars_SynopticId: string = '';

  syn_vars_contextX: number = 0;
  syn_vars_contextY: number = 0;
  syn_vars_contextType: any = {};
  syn_vars_contextFocus: any = null;
  syn_vars_contextSelects: any = [];

  syn_vars_buttonsLeft: any = [
    { id: "blSynoptics", text: "synoptics_text", visible: true, open: ['blItems', 'blLayers', 'blControls', 'blComm'] },
    { id: "blReports", text: "reports_text", visible: true, open: ['blItems', 'blControls', 'blComm'] },
    { id: "blItems", text: "items_text" },
    { id: "blLayers", text: "layers_text" },
    { id: "blControls", text: "controls_text" },
    { id: "blComm", text: "connections_text" }
  ];
  syn_vars_buttonsLeftSelecc: string = 'blSynoptics';

  globals: any = {};

  syn_vars_system: {
    folders: [any],
    synoptics: [any],
    synoptics_templates: [any],
    controls: [any],
    reports: [any],
    reports_templates: [any],
    synopticType: any,
    reportType: any,
    scriptProperties: any,
    scriptFunctionalities: any,
    cssEntitiesGlobal: any,
    items: any,
    tags: any,
    things: any,
    protocols: any,
    scriptsElements: any
  };

  syn_vars_util: any;
  syn_vars_itemsInSynoptic: any = [];

  syn_vars_subscriptions: Subscription[];

  syn_vars_pasteItems: boolean = false;

  /**
   * boolean, string, float, date, integer
   */
  syn_vars_typeTag: {} =
    {
      "61f8657bd76affa6748bd36a": "boolean",
      "61f8657bd76affa6748bd364": "string",
      "61f8657bd76affa6748bd35e": "float",
      "61f8657bd76affa6748bd367": "date",
      "61f8657bd76affa6748bd361": "integer"
    }

  syn_vars_itemSelected: {
    id: string;
    tag: HTMLElement;
    left: any;
    top: any;
    width: any;
    height: any;
  }
  syn_vars_itemSelecteds: any = [];
  syn_vars_itemSelectedOthers: any = [];

  syn_vars_itemFocus: {
    id: string;
    tag: HTMLElement;
    context: { delete: boolean, copy: boolean, import: boolean, syns: any };
  } = { id: '', tag: undefined, context: { delete: true, copy: true, import: false, syns: {} } };

  syn_vars_undo_change: {
    type: string, elements: any[]
  };
  syn_vars_undo_changes: any[] = [];
  syn_vars_undo_actual: number = -1;
  syn_vars_undo_total: number = -1;

  syn_vars_timers: any = {};

  syn_vars_divMask: {
    hidden: any;
    left: any;
    top: any;
    width: any;
    height: any;
    move: any;
    resizeOn: boolean;
    xIni: any;
    yIni: any;
    leftIni: any;
    topIni: any;
    moveMode: any;
    selectingAdd: boolean;
    selectingAll: boolean;
    alignShow: any;
    alignX1: any;
    alignY1: any;
    alignX2: any;
    alignY2: any;
    resize: boolean;
    resize1: boolean;
    resize2: boolean;
    resize3: boolean;
    resize4: boolean;
    resize5: boolean;
    resize6: boolean;
    resize7: boolean;
    resize8: boolean;
    resize_xIni: number;
    resize_yIni: number;
    resize_leftIni: any;
    resize_topIni: any;
    resize_widthIni: any;
    resize_heightIni: any;
    resizeMode: number;
  } = {
      hidden: true, resize1: false, resize2: false, resize3: false, resize4: false,
      resize5: false, resize6: false, resize7: false, resize8: false,
      left: '100px', top: '100px', width: '200px', height: '200px', selectingAdd: false, selectingAll: false,
      move: false, xIni: 0, yIni: 0, leftIni: 0, topIni: 0, moveMode: '',
      alignShow: 0, alignX1: 200, alignY1: 685, alignX2: 500, alignY2: 685,
      resize: false, resize_xIni: 0, resize_yIni: 0, resize_leftIni: '', resize_topIni: '',
      resize_widthIni: '', resize_heightIni: '', resizeMode: 0, resizeOn: false
    };

  syn_vars_headButtons: {
    play: boolean;
    undo: boolean;
    redo: boolean;
  } = { play: false, undo: false, redo: false };

  syn_vars_divMain: HTMLElement;
  syn_vars_divMain_height: number;
  syn_vars_divMain_width: number;

  /*
  cosas a borrar, a continuación... lo de antes de esta línea es lo nuevo
  */

  /*
  language: any;

  itemParams: {
    idSynItem: string;
  }

  content: any = {};

  synoptics: any = [];
  reports: any = [];
  lineas: any;

  synItems: any = {};
  tagd: any = {};
  systemSynoptic: any;
  systemDataDesign: any;
  systemModelizer: any;
  tree: HTMLElement;
  elemMove: HTMLElement;
  movIni = false;
  despX = 0;
  despY = 0;
  tblCnt = 0;
  inDiv = false;
  ps: object[];
  divMain: HTMLElement;
  tagParams: {};
  headboard: any;
  footer: any;
  detail: any;
  parameters: {};
  type: any;
  parentAdd: any;
  synoptic: HTMLElement;


  intervalStart: any;

  intervalOption: any;

  deleteItemButtonEvent = false;

  // DataDesign
  DSSelected: any;
  modalText: any;
  TypeDataDesignFields: any;
  TypeDataDesignContent: any;
  TypeDataDesign: any;
  DDFields: any = {};
  stateParameters: any;

  navSystem = true;
  navSynoptic = false;
  navItem = false;
  navScripts = false;

  selectedItemId = '';
  selectedSynopticId = '';
  selectedSynopticName = '';

  navActive = 'linkNavSystem';

  deleteFieldId = '';

  deleteInfo: any;
  updateInfo: any;
  addnewInfo: any;

  selectedItems: any = [];
  selectedItemsOther: any = [];

  util: utils;
  */


  constructor(
    private elementRef: ElementRef,
    private dataServ: DatatableService,
    private syn_serv_systemServ: SystemService,
    private syn_serv_sharServ: SharedService,
    private itemsServ: ModelizerItemsService,
    private router: Router,
    private langServ: LanguageService,
  ) {


    // this.itemParams = { idSynItem: '' };

    this.syn_vars_subscriptions = [];
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {
      mode: string,
      synopticId: string
    };

    // this.addnewInfo = { mode: 'none' };
    // this.updateInfo = { mode: 'none' };
    // this.deleteInfo = { mode: 'none' };
    // this.stateParameters = state;
  }

  syn_func_a4_report(format) {
    this.syn_vars_a4 = format;
  }

  async ngOnDestroy(): Promise<any> {

    delete this.syn_vars_system;
    this.syn_vars_itemsInSynoptic = [];
    this.elementRef.nativeElement.remove();
    document.onresize = null;
    document.onmousemove = null;
    document.onmouseup = null;
    this.syn_vars_undo_changes = [];
    this.syn_vars_undo_actual = -1;
    this.syn_vars_undo_total = -1;
    this.syn_func_unsubscribe();
    this.syn_vars_divMain.style.width = 'unset';
    this.syn_vars_divMain.style.height = 'unset';
  }

  syn_func_cloneSynoptic(synId) {
    this.syn_func_configSynoptic(synId, true);
  }

  syn_func_subscribe(subs: any) {
    this.syn_vars_subscriptions.push(subs);
  }

  helpDoc() {

  }

  syn_func_unsubscribe() {
    this.syn_vars_subscriptions.forEach(subs => {
      try {
        subs.unsubscribe();
      } catch (error) {
      }
    });
  }

  syn_func_resizeOn(mode: number, event) {

    const opts = document.getElementById('optionsdyn');
    opts.style.left = '32px';

    this.syn_vars_divMask.move = false;
    this.syn_vars_divMask.moveMode = '';

    const gz = this.syn_func_getPosZoom(event);

    this.syn_vars_divMask.resize_xIni = gz.clientX;
    this.syn_vars_divMask.resize_yIni = gz.clientY;
    this.syn_vars_divMask.resize_widthIni = parseInt(this.syn_vars_itemSelecteds[0].width);
    this.syn_vars_divMask.resize_heightIni = parseInt(this.syn_vars_itemSelecteds[0].height);
    this.syn_vars_divMask.resize_leftIni = parseInt(this.syn_vars_itemSelecteds[0].left);
    this.syn_vars_divMask.resize_topIni = parseInt(this.syn_vars_itemSelecteds[0].top);
    this.syn_vars_divMask.resizeOn = true;
    this.syn_vars_divMask.resizeMode = mode;
  }

  async syn_func_menuFichaSynoptic() {
    this.syn_func_configSynoptic(this.syn_vars_SynopticId, false);
  }

  async syn_func_menuSelectAll() {

  }

  async syn_func_menuItemRemove() {

    const focus = this.syn_vars_contextFocus;

    //voy a ver si contextFocus está dentro de los itemselecteds
    if (this.syn_vars_itemSelecteds.find(items => items.id === focus)) {
      this.syn_vars_contextSelects = [];
      for (const sel of this.syn_vars_itemSelecteds) {
        this.syn_vars_contextSelects.push(sel.id);
      }
    } else {
      this.syn_vars_contextSelects = [focus];
    }
    const ff = this.syn_vars_contextSelects;
    this.syn_func_off();
    this.syn_vars_contextSelects = ff;

    const itemsUpd = [];
    const itemsUnd = [];

    const tree = $('#treeblItems').jstree(true);
    tree.refresh();
    for (const selId of this.syn_vars_contextSelects) {
      const item = this.syn_func_getItem(selId);

      //chart
      if (item.type === this.syn_vars_itemTypes.chart) {
        item.data = [];
      }
      const oldItem = JSON.stringify(item);

      let typeToFilter = this.syn_func_getType(selId);
      this.syn_vars_system.items[typeToFilter] = this.syn_vars_system.items[typeToFilter].filter(item => item._id !== selId);
      this.syn_vars_itemsInSynoptic = this.syn_vars_itemsInSynoptic.filter(item => item !== `it${selId}`);

      if (typeToFilter === 'Free') {
        this.removeItem(`it${selId}`);
      }

      //tengo que mirar si el item es un sinóptico para quitar los controles
      if (item.type === this.syn_vars_itemTypes.synoptic) {
        const items = this.syn_vars_itemsInSynoptic.filter(itin => itin.includes(`it${item._id}_`));
        for (const itt of items) {
          const itDel = this.syn_func_getItem(itt.substring(2));
          const typeToRemove = this.syn_func_getType(itDel._id);
          this.syn_vars_system.items[typeToRemove] = this.syn_vars_system.items[typeToRemove].filter(item => item._id !== itDel._id);
          this.syn_vars_itemsInSynoptic = this.syn_vars_itemsInSynoptic.filter(item => item !== itt);

          const tree = $('#treeblItems').jstree(true);
          tree.refresh();

          this.syn_func_treeItem_del(tree, itDel._id);
          tree.refresh();
        }
      }

      this.syn_func_treeItem_del(tree, selId);
      itemsUpd.push({ type: 'deleteitem', id: selId });
      itemsUnd.push({ type: 'restoreitem', id: selId, item: JSON.parse(oldItem) });
    }
    tree.refresh();

    await this.itemsServ.updateItems(itemsUpd);
    this.syn_vars_undo_actual = this.syn_vars_undo_actual + 1;
    this.syn_vars_undo_total = this.syn_vars_undo_actual;
    this.syn_vars_undo_changes[this.syn_vars_undo_actual] = { prev: itemsUnd, actual: itemsUpd };

    this.syn_func_off();
  }

  syn_func_treeItem_update(tree, id, name) {
    // const tree = $('#treeblItems').jstree(true);
    tree.settings.core.data.find(node => node.id === id).text = name;
    // tree.refresh();
  }

  syn_func_treeItem_add(tree, item) {
    // const tree = $('#treeblItems').jstree(true);
    tree.settings.core.data.push({ id: item._id, parent: 'items', state: { selected: false, hidden: false }, text: item.parameters.namegeneral });
    // tree.refresh();
  }

  syn_func_treeItem_restore(tree, id) {
    // const tree = $('#treeblItems').jstree(true);
    tree.settings.core.data.find(node => node.id === id)['state'] = { 'hidden': false };
    // tree.refresh();
  }

  syn_func_treeItem_del(tree, id) {
    // const tree = $('#treeblItems').jstree(true);
    tree.settings.core.data.find(node => node.id === id)['state'] = { 'hidden': true };
    // tree.refresh();
  }

  syn_func_menuItemCopy() {
    const focus = this.syn_vars_contextFocus;

    //voy a ver si contextFocus está dentro de os itemselecteds
    if (this.syn_vars_itemSelecteds.find(items => items.id === focus)) {
      this.syn_vars_contextSelects = [];
      for (const sel of this.syn_vars_itemSelecteds) {
        this.syn_vars_contextSelects.push(sel.id);
      }
    } else {
      this.syn_vars_contextSelects = [focus];
    }
    const ff = this.syn_vars_contextSelects;
    this.syn_func_off();
    this.syn_vars_contextSelects = ff;
  }
  syn_func_menuItemClone() {
    this.syn_func_off();
  }

  syn_func_hb_playSynoptic() {
    let name = '';
    if (this.syn_vars_isType === 'report') {
      name = this.syn_vars_system.reports.find(syn => syn._id === this.syn_vars_SynopticId).name;
    } else {
      name = this.syn_vars_system.synoptics.find(syn => syn._id === this.syn_vars_SynopticId).name;
    }

    const navigationExtras: NavigationExtras = {
      state: {
        mode: 'play',
        synopticId: this.syn_vars_SynopticId
      }
    };
    // this.router.navigate(['synopticplayer'], navigationExtras);

    if (this.syn_vars_isType === 'report') {
      const newurl = location.href.replace('/synopticsnew', '/synopticReport/' + name);
      window.open(newurl);
    } else {
      const newurl = location.href.replace('/synopticsnew', '/synopticplayer/' + name);
    window.open(newurl);
    }

  }

  async syn_func_hb_alignLeft() {
    if (this.syn_vars_itemSelecteds.length > 1) {
      const itemsUpd = [];
      const itemsUnd = [];

      let item = this.syn_vars_itemSelecteds[0];
      for (let nit = 1; nit < this.syn_vars_itemSelecteds.length; nit++) {
        const xOld = parseInt(this.syn_vars_itemSelecteds[nit].left);
        const yOld = parseInt(this.syn_vars_itemSelecteds[nit].top);

        this.syn_vars_itemSelecteds[nit].left = item.left;
        this.syn_vars_itemSelecteds[nit].leftIni = parseInt(item.left);

        itemsUpd.push({ type: 'positionsInSynoptic', id: this.syn_vars_itemSelecteds[nit].id, x: parseInt(item.left), y: parseInt(this.syn_vars_itemSelecteds[nit].top) });
        itemsUnd.push({
          type: 'positionsInSynoptic', id: this.syn_vars_itemSelecteds[nit].id,
          x: xOld,
          y: yOld
        });

        const itemClick = this.syn_func_getItem(this.syn_vars_itemSelecteds[nit].id);
        itemClick.parameters.xgeneral = parseInt(item.left);
      }
      await this.itemsServ.updateItems(itemsUpd);
      this.syn_vars_undo_actual = this.syn_vars_undo_actual + 1;
      this.syn_vars_undo_total = this.syn_vars_undo_actual;
      this.syn_vars_undo_changes[this.syn_vars_undo_actual] = { prev: itemsUnd, actual: itemsUpd };
    }
  }
  async syn_func_hb_alignRight() {
    if (this.syn_vars_itemSelecteds.length > 1) {
      const itemsUpd = [];
      const itemsUnd = [];

      let item = this.syn_vars_itemSelecteds[0];
      for (let nit = 1; nit < this.syn_vars_itemSelecteds.length; nit++) {
        const xOld = parseInt(this.syn_vars_itemSelecteds[nit].left);
        const yOld = parseInt(this.syn_vars_itemSelecteds[nit].top);

        const newLeft = parseInt(item.left) + (parseInt(item.width) - parseInt(this.syn_vars_itemSelecteds[nit].width));

        this.syn_vars_itemSelecteds[nit].left = newLeft + 'px';
        this.syn_vars_itemSelecteds[nit].leftIni = newLeft;

        itemsUpd.push({ type: 'positionsInSynoptic', id: this.syn_vars_itemSelecteds[nit].id, x: newLeft, y: parseInt(this.syn_vars_itemSelecteds[nit].top) });
        itemsUnd.push({
          type: 'positionsInSynoptic', id: this.syn_vars_itemSelecteds[nit].id,
          x: xOld,
          y: yOld
        });

        const itemClick = this.syn_func_getItem(this.syn_vars_itemSelecteds[nit].id);
        itemClick.parameters.xgeneral = newLeft;
      }
      await this.itemsServ.updateItems(itemsUpd);
      this.syn_vars_undo_actual = this.syn_vars_undo_actual + 1;
      this.syn_vars_undo_total = this.syn_vars_undo_actual;
      this.syn_vars_undo_changes[this.syn_vars_undo_actual] = { prev: itemsUnd, actual: itemsUpd };
    }
  }

  async syn_func_hb_alignTop() {
    if (this.syn_vars_itemSelecteds.length > 1) {
      const itemsUpd = [];
      const itemsUnd = [];

      let item = this.syn_vars_itemSelecteds[0];
      for (let nit = 1; nit < this.syn_vars_itemSelecteds.length; nit++) {
        const xOld = parseInt(this.syn_vars_itemSelecteds[nit].left);
        const yOld = parseInt(this.syn_vars_itemSelecteds[nit].top);

        this.syn_vars_itemSelecteds[nit].top = item.top;
        this.syn_vars_itemSelecteds[nit].topIni = parseInt(item.top);

        itemsUpd.push({ type: 'positionsInSynoptic', id: this.syn_vars_itemSelecteds[nit].id, x: parseInt(this.syn_vars_itemSelecteds[nit].left), y: parseInt(item.top) });
        itemsUnd.push({
          type: 'positionsInSynoptic', id: this.syn_vars_itemSelecteds[nit].id,
          x: xOld,
          y: yOld
        });

        const itemClick = this.syn_func_getItem(this.syn_vars_itemSelecteds[nit].id);
        itemClick.parameters.ygeneral = parseInt(item.top);
      }
      await this.itemsServ.updateItems(itemsUpd);
      this.syn_vars_undo_actual = this.syn_vars_undo_actual + 1;
      this.syn_vars_undo_total = this.syn_vars_undo_actual;
      this.syn_vars_undo_changes[this.syn_vars_undo_actual] = { prev: itemsUnd, actual: itemsUpd };
    }
  }

  async syn_func_hb_alignBottom() {
    if (this.syn_vars_itemSelecteds.length > 1) {
      const itemsUpd = [];
      const itemsUnd = [];

      let item = this.syn_vars_itemSelecteds[0];
      for (let nit = 1; nit < this.syn_vars_itemSelecteds.length; nit++) {
        const xOld = parseInt(this.syn_vars_itemSelecteds[nit].left);
        const yOld = parseInt(this.syn_vars_itemSelecteds[nit].top);

        const newTop = parseInt(item.top) + (parseInt(item.height) - parseInt(this.syn_vars_itemSelecteds[nit].height));

        this.syn_vars_itemSelecteds[nit].top = newTop + 'px';
        this.syn_vars_itemSelecteds[nit].topIni = newTop;

        itemsUpd.push({ type: 'positionsInSynoptic', id: this.syn_vars_itemSelecteds[nit].id, x: parseInt(this.syn_vars_itemSelecteds[nit].left), y: newTop });
        itemsUnd.push({
          type: 'positionsInSynoptic', id: this.syn_vars_itemSelecteds[nit].id,
          x: xOld,
          y: yOld
        });

        const itemClick = this.syn_func_getItem(this.syn_vars_itemSelecteds[nit].id);
        itemClick.parameters.ygeneral = newTop;
      }
      await this.itemsServ.updateItems(itemsUpd);
      this.syn_vars_undo_actual = this.syn_vars_undo_actual + 1;
      this.syn_vars_undo_total = this.syn_vars_undo_actual;
      this.syn_vars_undo_changes[this.syn_vars_undo_actual] = { prev: itemsUnd, actual: itemsUpd };
    }
  }

  async syn_func_hb_sameWidth() {
    if (this.syn_vars_itemSelecteds.length > 1) {
      const itemsUpd = [];
      const itemsUnd = [];

      let item = this.syn_vars_itemSelecteds[0];
      for (let nit = 1; nit < this.syn_vars_itemSelecteds.length; nit++) {
        const wOld = parseInt(this.syn_vars_itemSelecteds[nit].width);
        const hOld = parseInt(this.syn_vars_itemSelecteds[nit].height);

        this.syn_vars_itemSelecteds[nit].width = item.width;

        itemsUpd.push({ type: 'sizesInSynoptic', id: this.syn_vars_itemSelecteds[nit].id, width: parseInt(item.width), height: parseInt(this.syn_vars_itemSelecteds[nit].height) });
        itemsUnd.push({ type: 'sizesInSynoptic', id: this.syn_vars_itemSelecteds[nit].id, width: wOld, height: hOld });

        const itemClick = this.syn_func_getItem(this.syn_vars_itemSelecteds[nit].id);
        itemClick.parameters.widthgeneral = parseInt(item.width);
      }
      await this.itemsServ.updateItems(itemsUpd);
      this.syn_vars_undo_actual = this.syn_vars_undo_actual + 1;
      this.syn_vars_undo_total = this.syn_vars_undo_actual;
      this.syn_vars_undo_changes[this.syn_vars_undo_actual] = { prev: itemsUnd, actual: itemsUpd };

      this.syn_vars_gridPossible = this.syn_func_gridPossible(true);
    }
  }
  async syn_func_hb_sameHeight() {
    if (this.syn_vars_itemSelecteds.length > 1) {
      const itemsUpd = [];
      const itemsUnd = [];

      let item = this.syn_vars_itemSelecteds[0];
      for (let nit = 1; nit < this.syn_vars_itemSelecteds.length; nit++) {
        const wOld = parseInt(this.syn_vars_itemSelecteds[nit].width);
        const hOld = parseInt(this.syn_vars_itemSelecteds[nit].height);

        this.syn_vars_itemSelecteds[nit].height = item.height;

        itemsUpd.push({ type: 'sizesInSynoptic', id: this.syn_vars_itemSelecteds[nit].id, width: parseInt(this.syn_vars_itemSelecteds[nit].width), height: parseInt(item.height) });
        itemsUnd.push({ type: 'sizesInSynoptic', id: this.syn_vars_itemSelecteds[nit].id, width: wOld, height: hOld });

        const itemClick = this.syn_func_getItem(this.syn_vars_itemSelecteds[nit].id);
        itemClick.parameters.heightgeneral = parseInt(item.height);
      }
      await this.itemsServ.updateItems(itemsUpd);
      this.syn_vars_undo_actual = this.syn_vars_undo_actual + 1;
      this.syn_vars_undo_total = this.syn_vars_undo_actual;
      this.syn_vars_undo_changes[this.syn_vars_undo_actual] = { prev: itemsUnd, actual: itemsUpd };

      this.syn_func_gridPossible(true);
      //this.syn_vars_gridPossible = this.syn_func_gridPossible(true);
    }
  }

  async syn_func_hb_distGrid() {
    const filas = this.syn_func_gridPossible(false);
    const item0 = filas[0][0].item;
    const leftIni = parseInt(item0.left);
    const topIni = parseInt(item0.top);
    const sizeW = parseInt(item0.width) + parseInt(this.syn_vars_distHorInput);
    const sizeH = parseInt(item0.height) + parseInt(this.syn_vars_distVerInput);

    let nfila = 0;
    let ncol = 0;
    const itemsUpd = [];
    const itemsUnd = [];

    for (const fila of filas) {
      const items = [];
      for (const its of fila) {
        items.push({ id: its.id, left: its.item.left });
      }

      items.sort(function (a, b) { return parseInt(a.left) - parseInt(b.left) });
      ncol = 0;

      for (const its of items) {
        const its_ = this.syn_vars_itemSelecteds.find(it => it.id === its.id);
        const xOld = parseInt(its_.left);
        const yOld = parseInt(its_.top);
        const itm_ = this.syn_func_getItem(its.id);

        itemsUpd.push({ type: 'positionsInSynoptic', id: its.id, x: (leftIni + (ncol * sizeW)), y: (topIni + (nfila * sizeH)) });
        itemsUnd.push({ type: 'positionsInSynoptic', id: its.id, x: xOld, y: yOld });

        its_.left = (leftIni + (ncol * sizeW)) + 'px';
        itm_.parameters.xgeneral = (leftIni + (ncol * sizeW));
        its_.leftIni = its_.left;
        its_.top = (topIni + (nfila * sizeH)) + 'px';
        itm_.parameters.ygeneral = (topIni + (nfila * sizeH));
        its_.topIni = its_.top;
        ncol = ncol + 1;
      }
      nfila = nfila + 1;
    }
    await this.itemsServ.updateItems(itemsUpd);
    this.syn_vars_undo_actual = this.syn_vars_undo_actual + 1;
    this.syn_vars_undo_total = this.syn_vars_undo_actual;
    this.syn_vars_undo_changes[this.syn_vars_undo_actual] = { prev: itemsUnd, actual: itemsUpd };
  }

  async syn_func_hb_distHorizontal() {
    if (this.syn_vars_itemSelecteds.length > 1 && parseInt(document.getElementById('distHor')['value']) > 0) {
      const width = parseInt(document.getElementById('distHor')['value']);

      this.syn_vars_itemSelecteds.sort((a, b) => a.leftIn > b.leftIn);
      let leftIn = this.syn_vars_itemSelecteds[0].leftIni + parseInt(this.syn_vars_itemSelecteds[0].width);
      const itemsUpd = [];
      const itemsUnd = [];

      for (let nit = 1; nit < this.syn_vars_itemSelecteds.length; nit++) {
        const xOld = parseInt(this.syn_vars_itemSelecteds[nit].left);
        const yOld = parseInt(this.syn_vars_itemSelecteds[nit].top);

        leftIn = leftIn + width;
        this.syn_vars_itemSelecteds[nit].left = leftIn + 'px';
        this.syn_vars_itemSelecteds[nit].leftIni = leftIn;

        itemsUpd.push({ type: 'positionsInSynoptic', id: this.syn_vars_itemSelecteds[nit].id, x: leftIn, y: parseInt(this.syn_vars_itemSelecteds[nit].top) });
        itemsUnd.push({
          type: 'positionsInSynoptic', id: this.syn_vars_itemSelecteds[nit].id,
          x: xOld,
          y: yOld
        });

        const itemClick = this.syn_func_getItem(this.syn_vars_itemSelecteds[nit].id);
        itemClick.parameters.xgeneral = leftIn;
        this.syn_vars_itemSelecteds[nit].leftIn = leftIn;

        leftIn = leftIn + parseInt(this.syn_vars_itemSelecteds[nit].width)
      }
      await this.itemsServ.updateItems(itemsUpd);
      this.syn_vars_undo_actual = this.syn_vars_undo_actual + 1;
      this.syn_vars_undo_total = this.syn_vars_undo_actual;
      this.syn_vars_undo_changes[this.syn_vars_undo_actual] = { prev: itemsUnd, actual: itemsUpd };
    }
  }



  async syn_func_hb_distVertical() {
    if (this.syn_vars_itemSelecteds.length > 1 && parseInt(document.getElementById('distVer')['value']) > 0) {
      const height = parseInt(document.getElementById('distVer')['value']);

      this.syn_vars_itemSelecteds.sort((a, b) => a.topIn > b.topIn);
      let topIn = this.syn_vars_itemSelecteds[0].topIni + parseInt(this.syn_vars_itemSelecteds[0].height);
      const itemsUpd = [];
      const itemsUnd = [];

      for (let nit = 1; nit < this.syn_vars_itemSelecteds.length; nit++) {
        const xOld = parseInt(this.syn_vars_itemSelecteds[nit].left);
        const yOld = parseInt(this.syn_vars_itemSelecteds[nit].top);

        topIn = topIn + height;
        this.syn_vars_itemSelecteds[nit].top = topIn + 'px';
        this.syn_vars_itemSelecteds[nit].topIni = topIn;

        itemsUpd.push({ type: 'positionsInSynoptic', id: this.syn_vars_itemSelecteds[nit].id, x: parseInt(this.syn_vars_itemSelecteds[nit].left), y: topIn });
        itemsUnd.push({
          type: 'positionsInSynoptic', id: this.syn_vars_itemSelecteds[nit].id,
          x: xOld,
          y: yOld
        });

        const itemClick = this.syn_func_getItem(this.syn_vars_itemSelecteds[nit].id);
        itemClick.parameters.ygeneral = topIn;
        this.syn_vars_itemSelecteds[nit].topIn = topIn;
        topIn = topIn + parseInt(this.syn_vars_itemSelecteds[nit].height)
      }
      await this.itemsServ.updateItems(itemsUpd);
      this.syn_vars_undo_actual = this.syn_vars_undo_actual + 1;
      this.syn_vars_undo_total = this.syn_vars_undo_actual;
      this.syn_vars_undo_changes[this.syn_vars_undo_actual] = { prev: itemsUnd, actual: itemsUpd };
    }
  }

  async syn_func_menuItemPaste() {
    let xIni = 10000;
    let yIni = 10000;
    for (const selId of this.syn_vars_contextSelects) {
      const it = document.getElementById(`it${selId}`);
      const os = this.getOffSets(it);
      const dmX = os.offsetLeft;
      const dmY = os.offsetTop;
      if (dmX < xIni) {
        xIni = dmX;
      }
      if (dmY < yIni) {
        yIni = dmY;
      }
    }

    const itemsUpd = [];
    const itemsUnd = [];

    const tree = $('#treeblItems').jstree(true);
    tree.refresh();
    for (const selId of this.syn_vars_contextSelects) {
      const item = this.syn_func_getItem(selId);
      // console.log(item);
      const type = this.syn_vars_system.controls.find(control => control._id === item.type);
      const itemName = await this.syn_serv_systemServ.getName({ name: type.name, parent: this.syn_vars_SynopticId });
      const parameters = JSON.parse(JSON.stringify(item.parameters));

      parameters.xgeneral = this.syn_vars_contextX + (parameters.xgeneral - xIni);
      parameters.ygeneral = this.syn_vars_contextY + (parameters.ygeneral - yIni);

      parameters.xgeneral = this.syn_func_aprox(parameters.xgeneral, { type: item.type });
      parameters.ygeneral = this.syn_func_aprox(parameters.ygeneral, { type: item.type });

      parameters.namegeneral = itemName.name;
      const newIt = { _id: '', description: item.parameters.descriptiongeneral, name: itemName.name, order: 10, parent: this.syn_vars_SynopticId, type: type._id, parameters: parameters };
      const itemUpdated = await this.syn_serv_systemServ.newItem(newIt);
      const itemN = itemUpdated.data;
      this.syn_func_treeItem_add(tree, itemN);
      itemN['id'] = itemN._id;
      const newItem = JSON.stringify(itemN);

      //es un free
      if (item.free === true) {
        // itemN['free'] = true;
        itemN['freetype'] = item.freetype;

        // this.syn_vars_system.items["Free"].push(itemN);
        // this.syn_serv_systemServ.emitItemChanged([{ item: itemN }]);

        this.syn_serv_systemServ.emitItemChanged([{ type: 'createItem', id: itemN._id, item: itemN }]);
      } else {
        this.syn_vars_system.items[type.name].push(itemN);
        this.syn_vars_util.itemRebuild(this.syn_vars_system.items, itemN);
      }

      this.syn_vars_itemsInSynoptic.push(`it${itemN._id}`);
      this.syn_vars_util.StyleRules({ id: itemN._id, type: item.type, typeName: type.name, iditem: itemN._id, parameters: item.parameters });

      itemsUpd.push({ type: 'restoreitem', id: itemN._id, item: JSON.parse(newItem) });
      itemsUnd.push({ type: 'deleteitem', id: itemN._id });
    }
    tree.refresh();

    this.syn_vars_undo_actual = this.syn_vars_undo_actual + 1;
    this.syn_vars_undo_total = this.syn_vars_undo_actual;
    this.syn_vars_undo_changes[this.syn_vars_undo_actual] = { prev: itemsUnd, actual: itemsUpd };
  }

  async syn_func_hb_undo() {
    this.syn_func_undo_restore(this.syn_vars_undo_changes[this.syn_vars_undo_actual].prev);
    this.syn_vars_undo_actual = this.syn_vars_undo_actual - 1;
  }

  async syn_func_hb_redo() {
    this.syn_vars_undo_actual = this.syn_vars_undo_actual + 1;
    this.syn_func_undo_restore(this.syn_vars_undo_changes[this.syn_vars_undo_actual].actual);
  }

  removeItem(id) {
    let element: HTMLElement = document.getElementById(id);
    while (true) {
      if (element.parentElement.id === 'main') {
        element.parentElement.removeChild(element);
        break;
      } else {
        element = element.parentElement;
      }
    }
  }

  async syn_func_undo_restore(changes) {

    const tree = $('#treeblItems').jstree(true);
    tree.refresh();
    for (const item of changes) {
      switch (item.type) {
        case 'updateitem':
          this.syn_func_off();
          try {
            this.syn_func_treeItem_update(tree, item.id, item.item.parameters.namegeneral);
            let itemClick = this.syn_func_getItem(item.id);
            itemClick.parameters = item.item.parameters;

            //tengo que mirar si el item es un sinóptico para posicionar todos los vínculos y actualizar los parámetros del item
            if (itemClick.type === this.syn_vars_itemTypes.synoptic) {
              //si ahora no tiene synoptico asociado, me cargo los items por si acaso
              if (itemClick.parameters.synopticgeneral === 'none') {
                this.syn_vars_itemFocus.context.syns[item.id] = false;
                const items = this.syn_vars_itemsInSynoptic.filter(itin => itin.includes(`it${item.id}_`));
                for (const itt of items) {
                  const itDel = this.syn_func_getItem(itt.substring(2));
                  const typeToRemove = this.syn_func_getType(itDel._id);

                  this.syn_vars_system.items[typeToRemove] = this.syn_vars_system.items[typeToRemove].filter(item => item._id !== itDel._id);
                  this.syn_vars_itemsInSynoptic = this.syn_vars_itemsInSynoptic.filter(item => item !== itt);

                  const tree = $('#treeblItems').jstree(true);
                  tree.refresh();

                  this.syn_func_treeItem_del(tree, itDel._id);
                  tree.refresh();
                }
              } else {
                const items = this.syn_vars_itemsInSynoptic.filter(itin => itin.includes(`it${item._id}_`));
                if (items.length === 0) {
                  //no hay items, miro a ver si tengo que cargarlos

                  const itsSyn = await this.syn_serv_systemServ.getItemsBy({ parent: itemClick.parameters.synopticgeneral });
                  for (const it of itsSyn) {
                    this.syn_vars_itemFocus.context.syns[item.id] = true;
                    const itc = { ...it };
                    const pars = JSON.stringify(it.parameters);
                    itc.name = itemClick.name + ':' + it.name;
                    itc.parameters['namegeneral'] = itc.name;
                    itc.parameters['orig'] = JSON.parse(pars);
                    itc._id = item.id + '_' + itc._id;
                    itc.parameters.xgeneral = parseInt(it.parameters.xgeneral) + parseInt(itemClick.parameters.xgeneral);
                    itc.parameters.ygeneral = parseInt(it.parameters.ygeneral) + parseInt(itemClick.parameters.ygeneral);

                    const control = this.syn_vars_system.controls.find(control => control._id === itc.type);
                    if (this.syn_vars_system.items.hasOwnProperty(control.name) === false) {
                      this.syn_vars_system.items[control.name] = [];
                    }
                    itc['id'] = itc._id;

                    if (control.name === 'Chart') {
                      const props = this.syn_func_buildChart(itc);
                      itc['options'] = {};
                      itc['typeLine'] = props['typeLine'];
                      itc['data'] = props['data'];
                      itc['typeChart'] = props['typeChart'];
                    }

                    const tree = $('#treeblItems').jstree(true);
                    tree.refresh();

                    this.syn_func_treeItem_restore(tree, itc._id);
                    tree.refresh();

                    this.syn_vars_system.items[control.name].push(itc);
                    this.syn_vars_itemsInSynoptic.push(`it${itc._id}`);
                    this.syn_vars_util.StyleRules({ id: itc._id, type: itc.type, typeName: control.name, iditem: itc._id, parameters: itc.parameters });
                  }
                }
              }

              const items = this.syn_vars_itemsInSynoptic.filter(itin => itin.includes(`it${itemClick._id}_`));

              for (const it of items) {
                const itemCl = this.syn_func_getItem(it.substring(2));
                itemCl.parameters.xgeneral = parseInt(itemClick.parameters.xgeneral) + parseInt(itemCl.parameters['orig'].xgeneral);
                itemCl.parameters.ygeneral = parseInt(itemClick.parameters.ygeneral) + parseInt(itemCl.parameters['orig'].ygeneral);

                if (itemClick.parameters.hasOwnProperty('items')) {
                  if (itemClick.parameters.items[itemCl._id.substring(25)] !== undefined) {
                    for (const tgs of Object.entries(itemClick.parameters.items[itemCl._id.substring(25)])) {
                      itemCl.parameters[tgs[0]] = tgs[1];
                    }
                  }
                }
              }
            }
          } catch (error) {
            console.log(error);
          }
          break;
        case 'positionsInSynoptic':
          try {
            const itemClick = this.syn_func_getItem(item.id);
            itemClick.parameters.xgeneral = parseInt(item.x);
            itemClick.parameters.ygeneral = parseInt(item.y);

            //tengo que mirar si el item es un sinóptico para posicionar todos los vínculos
            if (itemClick.type === this.syn_vars_itemTypes.synoptic) {
              const items = this.syn_vars_itemsInSynoptic.filter(itin => itin.includes(`it${itemClick._id}_`));

              for (const it of items) {
                const itemCl = this.syn_func_getItem(it.substring(2));
                itemCl.parameters.xgeneral = parseInt(itemClick.parameters.xgeneral) + parseInt(itemCl.parameters['orig'].xgeneral);
                itemCl.parameters.ygeneral = parseInt(itemClick.parameters.ygeneral) + parseInt(itemCl.parameters['orig'].ygeneral);
              }
            }

          } catch (error) {
            console.log(error);
          }
          const dnfp = this.syn_vars_itemSelecteds.find(it => it.id === item.id);
          if (dnfp !== undefined) {
            dnfp.left = parseInt(item.x) + 'px';
            dnfp.leftIni = parseInt(item.x);
            dnfp.top = parseInt(item.y) + 'px';
            dnfp.topIni = parseInt(item.y);
          }
          break;
        case 'sizesInSynoptic':
          try {
            const itemClick = this.syn_func_getItem(item.id);
            itemClick.parameters.widthgeneral = parseInt(item.width);
            itemClick.parameters.heightgeneral = parseInt(item.height);

          } catch (error) {
            console.log(error);
          }

          const dnfs = this.syn_vars_itemSelecteds.find(it => it.id === item.id);
          if (dnfs !== undefined) {
            dnfs.width = parseInt(item.width) + 'px';
            dnfs.top = parseInt(item.height) + 'px';
          }
          break;
        case 'restoreitem':
          this.syn_func_off();
          const _type = this.syn_vars_system.controls.find(control => control._id === item.item.type);

          let free = false;
          try {
            if (item.item.parameters.stylegeneral !== 'none' && item.item.parameters.stylegeneral !== undefined) {
              free = true;
            }
          } catch (error) {
          }

          if (free) {
            item.item.free = true;
            item.item.freetype = _type.name;
            item.item.copy = 'new';
            this.syn_vars_system.items['Free'].push(item.item);
          } else {
            this.syn_vars_system.items[_type.name].push(item.item);
          }

          this.syn_vars_itemsInSynoptic.push(`it${item.id}`);
          this.syn_func_treeItem_restore(tree, item.id);

          //es un item synoptic (recupero los items)
          if (item.item.type === this.syn_vars_itemTypes.synoptic) {
            const itsSyn = await this.syn_serv_systemServ.getItemsBy({ parent: item.item.parameters.synopticgeneral });
            try {
              for (const itChangeds of Object.entries(item.item.parameters.items)) {
                const it = itsSyn.find(it => it._id === itChangeds[0]);
                if (it !== undefined) {
                  for (const parms of Object.entries(itChangeds[1])) {
                    it.parameters[parms[0]] = parms[1];
                  }
                }
              }
            } catch (error) {
            }


            for (const it of itsSyn) {
              const itc = { ...it };
              const pars = JSON.stringify(it.parameters);
              itc.parameters['orig'] = JSON.parse(pars);
              itc._id = item.item._id + '_' + itc._id;
              itc.id = itc._id;
              itc.parameters.xgeneral = parseInt(it.parameters.xgeneral) + parseInt(item.item.parameters.xgeneral);
              itc.parameters.ygeneral = parseInt(it.parameters.ygeneral) + parseInt(item.item.parameters.ygeneral);

              //items.push(itc);
              const control = this.syn_vars_system.controls.find(control => control._id === itc.type);
              if (this.syn_vars_system.items.hasOwnProperty(control.name) === false) {
                this.syn_vars_system.items[control.name] = [];
              }
              this.syn_vars_system.items[control.name].push(itc);
              this.syn_vars_itemsInSynoptic.push(`it${itc._id}`);

              const tree = $('#treeblItems').jstree(true);
              tree.refresh();
              this.syn_func_treeItem_restore(tree, itc.id);
              tree.refresh();


            }
          }
          break;
        case 'deleteitem':
          this.syn_func_off();
          let typeToFilter = this.syn_func_getType(item.id);
          this.syn_vars_system.items[typeToFilter] = this.syn_vars_system.items[typeToFilter].filter(itemF => itemF._id !== item.id);
          this.syn_vars_itemsInSynoptic = this.syn_vars_itemsInSynoptic.filter(itemF => itemF !== `it${item.id}`);
          this.syn_func_treeItem_del(tree, item.id);

          //tengo que mirar si el item es un sinóptico para quitar los controles
          if (typeToFilter === 'Synoptic') {
            const items = this.syn_vars_itemsInSynoptic.filter(itin => itin.includes(`it${item.id}_`));
            for (const itt of items) {
              const itDel = this.syn_func_getItem(itt.substring(2));
              const typeToRemove = this.syn_func_getType(itDel._id);
              this.syn_vars_system.items[typeToRemove] = this.syn_vars_system.items[typeToRemove].filter(item => item.id !== itDel._id);
              this.syn_vars_itemsInSynoptic = this.syn_vars_itemsInSynoptic.filter(item => item !== itt);

              const tree = $('#treeblItems').jstree(true);
              tree.refresh();
              this.syn_func_treeItem_del(tree, itDel._id);
              tree.refresh();
            }
          }

          break;
      }
    }
    tree.refresh();

    await this.itemsServ.updateItems(changes);
  }

  syn_func_parseInt(str: string): number {
    let ret: number = parseInt(str);
    return ret;
  }

  async syn_func_deleteItems(): Promise<any> {
  }

  async syn_func_saveNewReport(itemInfo, clone): Promise<any> {

    const newIt = { _id: '', description: itemInfo.parameters['descriptiongeneral'], name: itemInfo.parameters['namegeneral'], order: 10, parent: '', type: this.syn_vars_system.reportType._id, parameters: itemInfo.parameters };
    const itemUpdated = await this.syn_serv_systemServ.newItem(newIt);

    const tree = $('#treeblSynoptics').jstree(true);

    tree.settings.core.data.push({ id: itemUpdated.data._id, parent: 'reports', type: 'rep', a_attr: { type: 'rep' }, li_attr: { id: itemUpdated.data._id, type: 'rep' }, text: itemUpdated.data.parameters.namegeneral });
    tree.refresh();



    setTimeout((id) => {
      try {
        $('#treeblSynoptics').jstree('deselect_node', id);
      } catch (error) {
      }
    }, 50, itemUpdated.data._id);

    this.syn_vars_SynopticId = itemUpdated.data._id;
    setTimeout(() => {
      $('#treeblSynoptics').jstree('select_node', this.syn_vars_SynopticId);
    }, 250);

    this.syn_vars_undo_changes = [];
    this.syn_vars_undo_actual = -1;
    this.syn_vars_undo_total - 1;

    this.syn_func_treeItems({});
    if (clone) {

      const itemToClone = [... this.syn_vars_itemsInSynoptic];
      const itemToCloneItems = { ... this.syn_vars_system.items };
      this.syn_vars_system.items = {};
      this.syn_vars_itemsInSynoptic = [];

      const tree = $('#treeblItems').jstree(true);
      tree.refresh();
      for (const idit of itemToClone) {
        if (idit.length === 26) {
          const id = idit.substring(2);
          const item = itemToCloneItems[Object.keys(itemToCloneItems).find(kt => itemToCloneItems[kt].find(t => t._id === id))].find(t => t._id === id);
          const type = this.syn_vars_system.controls.find(control => control._id === item.type);
          const parameters = JSON.parse(JSON.stringify(item.parameters));
          const newIt = { _id: '', description: item.parameters.descriptiongeneral, name: item.parameters.namegeneral, order: 10, parent: this.syn_vars_SynopticId, type: type._id, parameters: parameters };
          const itemUpdated = await this.syn_serv_systemServ.newItem(newIt);

        }
      }
      tree.refresh();

    } else {
      this.syn_vars_system.items = {};
      this.syn_vars_itemsInSynoptic = [];
    }

    this.syn_vars_itemSelecteds = [];
    this.syn_vars_gridPossible = false;
    this.syn_vars_itemSelectedOthers = [];
    this.syn_serv_systemServ.emitUpdateSyn({ items: { "Report": [this.syn_vars_system.items] }, player: false });
    this.syn_vars_system.synoptics.push(itemUpdated.data);
    this.syn_func_leftSection('blSynoptics');
  }

  async syn_func_saveNewSynoptic(itemInfo, clone): Promise<any> {

    const newIt = { _id: '', description: itemInfo.parameters['descriptiongeneral'], name: itemInfo.parameters['namegeneral'], order: 10, parent: '', type: this.syn_vars_system.synopticType._id, parameters: itemInfo.parameters };
    const itemUpdated = await this.syn_serv_systemServ.newItem(newIt);

    const tree = $('#treeblSynoptics').jstree(true);
    tree.settings.core.data.push({ id: itemUpdated.data._id, parent: 'synoptics', type: 'syn', li_attr: { id: itemUpdated.data._id, type: 'syn' }, text: itemUpdated.data.parameters.namegeneral });
    tree.refresh();

    this.syn_vars_backcolor = itemUpdated.data.parameters.colorgeneral;
    setTimeout((id) => {
      try {
        $('#treeblSynoptics').jstree('deselect_node', id);
      } catch (error) {
      }
    }, 50, this.syn_vars_SynopticId);

    this.syn_vars_SynopticId = itemUpdated.data._id;
    setTimeout(() => {
      $('#treeblSynoptics').jstree('select_node', this.syn_vars_SynopticId);
    }, 250);

    this.syn_vars_undo_changes = [];
    this.syn_vars_undo_actual = -1;
    this.syn_vars_undo_total - 1;

    this.syn_func_treeItems({});
    if (clone) {

      const itemToClone = [... this.syn_vars_itemsInSynoptic];
      const itemToCloneItems = { ... this.syn_vars_system.items };
      this.syn_vars_system.items = {};
      this.syn_vars_itemsInSynoptic = [];

      const tree = $('#treeblItems').jstree(true);
      tree.refresh();
      for (const idit of itemToClone) {
        if (idit.length === 26) {
          const id = idit.substring(2);
          const item = itemToCloneItems[Object.keys(itemToCloneItems).find(kt => itemToCloneItems[kt].find(t => t._id === id))].find(t => t._id === id);
          const type = this.syn_vars_system.controls.find(control => control._id === item.type);
          const parameters = JSON.parse(JSON.stringify(item.parameters));
          const newIt = { _id: '', description: item.parameters.descriptiongeneral, name: item.parameters.namegeneral, order: 10, parent: this.syn_vars_SynopticId, type: type._id, parameters: parameters };
          const itemUpdated = await this.syn_serv_systemServ.newItem(newIt);

        }
      }
      tree.refresh();

    } else {
      this.syn_vars_system.items = {};
      this.syn_vars_itemsInSynoptic = [];
    }

    this.syn_vars_itemSelecteds = [];
    this.syn_vars_gridPossible = false;
    this.syn_vars_itemSelectedOthers = [];
    this.syn_serv_systemServ.emitUpdateSyn({ items: { "Synoptic": [this.syn_vars_system.items] }, player: false });
    this.syn_vars_system.synoptics.push(itemUpdated.data);
    this.syn_func_leftSection('blSynoptics');

    /*

    const newIt _ { _id: '', description: data.parameters['descriptiongeneral'], name: parameters['namegeneral'], order: 10, parent: '', type: this.type, parameters: parameters };
    const itemUpdated = await this.systemServ.newItem(newIt);

    this.systemSynoptic.items.push(itemUpdated.data);

    const obj = this.parentAdd;

    const li: HTMLElement = document.createElement('LI');
    li.id = itemUpdated.data._id;
    li.className = 'ulil';
    const sp: HTMLElement = document.createElement('SPAN');
    sp.style.cursor = 'pointer';
    sp.className = 'spn';
    sp.innerHTML = itemUpdated.data.name;
    sp.style.userSelect = 'none';
    sp.style.backgroundColor = 'blue';
    sp.style.color = 'white';
    sp.setAttribute('idSynoptic', itemUpdated.data._id);
    sp.setAttribute('nameSynoptic', itemUpdated.data.name);
    sp.addEventListener('click', this.clickInOption.bind(this));
    this.synoptic = sp;
    li.appendChild(sp);
    obj.appendChild(li);

    this.backcolor = itemUpdated.data.parameters.colorgeneral;
    this.util.synoptics.push(itemUpdated.data);

    this.synItems = [];
    this.systemServ.emitUpdateSyn({ items: this.synItems, player: false });
    */

  }

  //cpc003
  async syn_func_saveEdtItem(mode, id, parameters, parent): Promise<any> {
    if (mode === 'item') {
      /*
      if (item.hasOwnProperty('imagePath')) {
        item.parameters['imagePathgeneral'] = item['imagePath'];
      }
      */

      let item: any;
      try {
        item = this.syn_func_getItem(id);
      } catch (error) {
      }

      let ok = true;
      let clone = false;

      //
      if (parent !== this.syn_vars_SynopticId) {
        const itemLink = this.syn_func_getItem(id);
        //estoy modificando un item clonado
        //cargo el item contenedor
        //copia de los parametros
        // const paramToUpdate = { ...parameters };

        const tags = id.split('_');

        item = this.syn_func_getItem(tags[0]);

        const oldItem = JSON.stringify(item);

        const parsOrig = itemLink.parameters['orig'];
        if (!item.parameters.hasOwnProperty('items')) {
          item.parameters.items = {};
        }
        let paramsNew = {};
        const keys = Object.keys(parsOrig);

        keys.forEach(key => {
          if (key !== 'namegeneral' && key !== 'descriptiongeneral' && key !== 'widthgeneral' && key !== 'heightgeneral' && key !== 'xgeneral' && key !== 'ygeneral') {
            if (parameters.hasOwnProperty(key)) {
              if (JSON.stringify(parameters[key]) !== JSON.stringify(parsOrig[key])) {
                paramsNew[key] = parameters[key];
                itemLink.parameters[key] = parameters[key];
              }
              delete parameters[key];
            } else {
              paramsNew[key] = '';
              itemLink.parameters[key] = '';
            }
          } else {
            delete parameters[key];
          }
        });
        for (const key of Object.keys(parameters)) {
          paramsNew[key] = parameters[key];
          itemLink.parameters[key] = parameters[key];
        }

        item.parameters.items[tags[1]] = paramsNew;
        parent = item.parent;
        id = item._id;
        parameters = item.parameters;
        const itemsUpd = [];
        const itemsUnd = [];
        const newItem = JSON.stringify(item);
        itemsUpd.push({ type: 'updateitem', id: id, item: JSON.parse(newItem) });
        itemsUnd.push({ type: 'updateitem', id: id, item: JSON.parse(oldItem) });

        await this.itemsServ.updateItems(itemsUpd);
        this.syn_serv_systemServ.emitItemChanged(itemsUpd);

        this.syn_vars_undo_actual = this.syn_vars_undo_actual + 1;
        this.syn_vars_undo_total = this.syn_vars_undo_actual;
        this.syn_vars_undo_changes[this.syn_vars_undo_actual] = { prev: itemsUnd, actual: itemsUpd };
        this.syn_vars_util.itemRebuild(this.syn_vars_system.items, item);

        ok = false;
      }

      if (ok) {
        //esto del olddata lo hago por el chart
        if (item.type === this.syn_vars_itemTypes.chart) {
          item.data = [];
        }
        const oldItem = JSON.stringify(item);
        const itemsUpd = [];
        const itemsUnd = [];
        item.name = parameters['namegeneral'];
        item.description = parameters['descriptiongeneral'];
        item.parameters = parameters;
        if (item.type === this.syn_vars_itemTypes.synoptic) {
          if (!item.parameters.synopticgeneral) {
            item.parameters.synopticgeneral = 'none';
          }
        }
        const newItem = JSON.stringify(item);

        //type chart
        if (item.type === this.syn_vars_itemTypes.chart) {
          const props = this.syn_func_buildChart(item);
          item['options'] = {};
          item['typeLine'] = props['typeLine'];
          item['data'] = props['data'];
          item['typeChart'] = props['typeChart'];

          this.syn_vars_system.items['Chart'] = this.syn_vars_system.items['Chart'].filter(itt => itt._id !== id);
          this.syn_vars_system.items['Chart'].push({ ...item });
        }

        const tree = $('#treeblItems').jstree(true);
        tree.settings.core.data.find(node => node.id === id).text = item.name;
        tree.refresh();
        itemsUpd.push({ type: 'updateitem', id: id, item: JSON.parse(newItem) });
        itemsUnd.push({ type: 'updateitem', id: id, item: JSON.parse(oldItem) });
        await this.itemsServ.updateItems(itemsUpd);
        this.syn_serv_systemServ.emitItemChanged(itemsUpd);

        //tengo que mirar si el item es un sinóptico para posicionar todos los vínculos
        if (item.type === this.syn_vars_itemTypes.synoptic) {
          const items = this.syn_vars_itemsInSynoptic.filter(itin => itin.includes(`it${item._id}_`));

          if (items.length === 0) {
            //no hay items, miro a ver si tengo que cargarlos

            const itsSyn = await this.syn_serv_systemServ.getItemsBy({ parent: item.parameters.synopticgeneral });
            for (const it of itsSyn) {
              this.syn_vars_itemFocus.context.syns[id] = true;
              const itc = { ...it };
              const pars = JSON.stringify(it.parameters);
              itc.name = item.name + ':' + it.name;
              itc.parameters.namegeneral = itc.name;
              itc.parameters['orig'] = JSON.parse(pars);
              itc._id = item._id + '_' + itc._id;
              itc.parameters.xgeneral = parseInt(it.parameters.xgeneral) + parseInt(item.parameters.xgeneral);
              itc.parameters.ygeneral = parseInt(it.parameters.ygeneral) + parseInt(item.parameters.ygeneral);

              const control = this.syn_vars_system.controls.find(control => control._id === itc.type);
              if (this.syn_vars_system.items.hasOwnProperty(control.name) === false) {
                this.syn_vars_system.items[control.name] = [];
              }
              itc['id'] = itc._id;

              if (control.name === 'Chart') {
                const props = this.syn_func_buildChart(itc);
                itc['options'] = {};
                itc['typeLine'] = props['typeLine'];
                itc['data'] = props['data'];
                itc['typeChart'] = props['typeChart'];
              }

              const tree = $('#treeblItems').jstree(true);
              tree.refresh();
              this.syn_func_treeItem_add(tree, itc);
              tree.refresh();

              this.syn_vars_system.items[control.name].push(itc);
              this.syn_vars_itemsInSynoptic.push(`it${itc._id}`);
              this.syn_vars_util.StyleRules({ id: itc._id, type: itc.type, typeName: control.name, iditem: itc._id, parameters: itc.parameters });
            }
          }

          for (const it of items) {
            const itemCl = this.syn_func_getItem(it.substring(2));
            itemCl.parameters.xgeneral = parseInt(item.parameters.xgeneral) + parseInt(itemCl.parameters['orig'].xgeneral);
            itemCl.parameters.ygeneral = parseInt(item.parameters.ygeneral) + parseInt(itemCl.parameters['orig'].ygeneral);
          }
        }

        this.syn_vars_undo_actual = this.syn_vars_undo_actual + 1;
        this.syn_vars_undo_total = this.syn_vars_undo_actual;
        this.syn_vars_undo_changes[this.syn_vars_undo_actual] = { prev: itemsUnd, actual: itemsUpd };
        this.syn_vars_util.itemRebuild(this.syn_vars_system.items, item);
      }

      // this.syn_serv_systemServ.emitUpdateSyn({ items: { "Synoptic": [this.syn_vars_system.items] }, player: false });
    }

    // this.syn_func_ClickTreeSynoptic();

    if (mode === 'synoptic' || mode === 'synoptic_template') {
      const synoptic = this.syn_vars_system.synoptics.find(syn => syn._id === id);

      const tempConfigs = synoptic.parameters?.configs ?? [];
      synoptic.parameters = parameters;
      synoptic.parameters.configs = tempConfigs;
      synoptic.name = parameters['namegeneral'];
      synoptic.description = parameters['descriptiongeneral'];
      const tree = $('#treeblSynoptics').jstree(true);
      tree.settings.core.data.find(node => node.id === this.syn_vars_SynopticId).text = synoptic.name;
      tree.refresh();
      const itemUpdated = await this.syn_serv_systemServ.updItem(synoptic);
      this.syn_vars_backcolor = parameters.colorgeneral;
    }

    if (mode === 'report' || mode === 'report_template') {
      const synoptic = this.syn_vars_system.reports.find(syn => syn._id === id);

      synoptic.parameters = parameters;
      synoptic.name = parameters['namegeneral'];
      synoptic.description = parameters['descriptiongeneral'];
      const tree = $('#treeblReports').jstree(true);
      tree.settings.core.data.find(node => node.id === this.syn_vars_SynopticId).text = synoptic.name;
      tree.refresh();
      const itemUpdated = await this.syn_serv_systemServ.updItem(synoptic);
      this.syn_vars_backcolor = parameters.colorgeneral;
    }
  }

  async syn_func_deleteByData(data) {

    if (data.item.mode === 'item') {
      const id = data.item.id;
      const item = this.syn_func_getItem(data.item.id);
      //chart
      if (item.type === this.syn_vars_itemTypes.chart) {
        item.data = [];
      }
      const oldItem = JSON.stringify(item);
      const tree = $('#treeblItems').jstree(true);
      tree.refresh();
      this.syn_func_treeItem_del(tree, data.item.id);
      tree.refresh();
      let typeToFilter = this.syn_func_getType(id);
      this.syn_vars_system.items[typeToFilter] = this.syn_vars_system.items[typeToFilter].filter(item => item._id !== id);
      this.syn_vars_itemsInSynoptic = this.syn_vars_itemsInSynoptic.filter(item => item !== `it${id}`);

      //tengo que mirar si el item es un sinóptico para quitar los controles
      if (item.type === this.syn_vars_itemTypes.synoptic) {
        const items = this.syn_vars_itemsInSynoptic.filter(itin => itin.includes(`it${item._id}_`));
        for (const itt of items) {
          const itDel = this.syn_func_getItem(itt.substring(2));
          const typeToRemove = this.syn_func_getType(itDel._id);
          this.syn_vars_system.items[typeToRemove] = this.syn_vars_system.items[typeToRemove].filter(item => item._id !== itDel._id);
          this.syn_vars_itemsInSynoptic = this.syn_vars_itemsInSynoptic.filter(item => item !== itt);
          const tree = $('#treeblItems').jstree(true);
          tree.refresh();
          this.syn_func_treeItem_del(tree, itDel._id);
          tree.refresh();
        }
      }

      const itemsUpd = [{ type: 'deleteitem', id: id }];
      const itemsUnd = [{ type: 'restoreitem', id: id, item: JSON.parse(oldItem) }];
      await this.itemsServ.updateItems(itemsUpd);
      this.syn_vars_undo_actual = this.syn_vars_undo_actual + 1;
      this.syn_vars_undo_total = this.syn_vars_undo_actual;
      this.syn_vars_undo_changes[this.syn_vars_undo_actual] = { prev: itemsUnd, actual: itemsUpd };

      // typeToFilter = typeToFilter.filter(item => item._id !== data.item.id);

      // delete itemToDel;

      /*
      this.controlBuild(itemToDel, true, true);
      await this.systemServ.delItem(itemToDel._id);

      this.itemsInSynoptic = this.itemsInSynoptic.filter(item => item !== `it${data.item.id}`);
      this.systemSynoptic.items = this.systemSynoptic.items.filter(item => item._id !== `it${data.item.id}`);

      this.divMask.hidden = true;
      this.divMask.options = false;
      this.selectedItems = [];

      this.configSynoptic(this.selectedSynopticId);
      */
    }

    if (data.item.mode === 'synoptic' || data.item.mode === 'report' || data.item.mode === 'synoptic_template' || data.item.mode === 'report_template') {

      this.syn_func_off();
      this.syn_vars_undo_changes = [];
      this.syn_vars_undo_actual = -1;
      this.syn_vars_undo_total = -1;
      const itemsUpd = [{ type: 'deleteitem', id: this.syn_vars_SynopticId }];
      for (const selId of this.syn_vars_itemsInSynoptic) {
        if (selId.length === 26) {
          itemsUpd.push({ type: 'deleteitem', id: selId.substring(2) });
        }
      }
      await this.itemsServ.updateItems(itemsUpd);
      delete this.syn_vars_nodeSelected;
      const tree = $('#treeblSynoptics').jstree(true);
      const temp = tree.settings.core.data.find(node => node.id === this.syn_vars_SynopticId);
      if (temp != null) {
        temp.state = { 'hidden': true };
      }
      tree.refresh();
      this.syn_vars_backcolor = '';
      const treeItems = $('#treeblItems').jstree(true);
      treeItems.settings.core.data = [];
      treeItems.refresh();
      this.syn_vars_SynopticId = '';
      const types = Object.keys(this.syn_vars_system.items);
      for (const type of types) {
        delete this.syn_vars_system.items[type];
      }
      this.syn_vars_system.items = {};
      this.syn_vars_itemsInSynoptic = [];
    }

    /*
     if (data.item.mode === 'synoptic') {
          this.itemsInSynoptic.forEach(itemInId => {
            const itemIn: HTMLElement = document.getElementById(itemInId);
            const itemId = itemIn.getAttribute('idItem');

            const itemToDel = this.systemSynoptic.items.find(item => item._id === itemId);
            this.controlBuild(itemToDel, true, true);
            this.systemServ.delItem(itemToDel._id);

            this.systemSynoptic.items = this.systemSynoptic.items.filter(item => item._id !== `it${itemId}`);
          });
          // idSynoptic
          this.synoptic.parentNode.removeChild(this.synoptic);
          await this.systemServ.delItem(this.selectedSynopticId);

          this.divMask.hidden = true;
          this.divMask.options = false;
          this.selectedItems = [];
          this.itemsInSynoptic = [];

          await this.configSystem();
          this.headButtons.play = false;
          this.backcolor = '#FFFFFF';
        }

        if (data.item.mode === 'item') {
          const itemToDel = this.systemSynoptic.items.find(item => item._id === data.item.id);

          this.controlBuild(itemToDel, true, true);
          await this.systemServ.delItem(itemToDel._id);

          this.itemsInSynoptic = this.itemsInSynoptic.filter(item => item !== `it${data.item.id}`);
          this.systemSynoptic.items = this.systemSynoptic.items.filter(item => item._id !== `it${data.item.id}`);

          this.divMask.hidden = true;
          this.divMask.options = false;
          this.selectedItems = [];

          this.configSynoptic(this.selectedSynopticId);
        }
    */
  }


  async syn_func_configSynoptic(idSynoptic, clone) {

    if (idSynoptic === 'new') {
      this.syn_func_closeItem();
      setTimeout(() => {
        document.getElementById('namegeneral').focus();
      }, 500);
    }

    let caption = "";
    let mode = "";
    let synoptic: any = false;
    let synopticType: any = false;

    if (this.syn_vars_isType === 'report') {
      caption = `${this.syn_vars_language.report_properties}`;
      mode = "report";
      synopticType = this.syn_vars_system.reportType;
    } else {
      caption = `${this.syn_vars_language.synoptic_properties}`;
      mode = "synoptic";
      synopticType = this.syn_vars_system.synopticType;
    }

    this.syn_serv_systemServ.emitItemSelected('');

    if (idSynoptic !== 'new') {
      if (this.syn_vars_isType === 'report') {
        synoptic = this.syn_vars_system.reports.find(syn => syn._id === idSynoptic);
      } else {
        synoptic = this.syn_vars_system.synoptics.find(syn => syn._id === idSynoptic);
      }
    } else {
      if (this.syn_vars_isType === 'report') {
        synoptic = { parameters: { namegeneral: 'reportname', descriptiongeneral: 'reportname', colorgeneral: '#FFFFFF', readIntervalgeneral: '2000' } };
      } else {
        synoptic = { parameters: { namegeneral: 'synopticname', descriptiongeneral: 'synopticname', colorgeneral: '#FFFFFF', readIntervalgeneral: '2000' } };
      }
    }

    const itemOptions = {
      id: idSynoptic,
      parent: '',
      name: '',
      description: '',
      type: { parameters: synopticType.parameters },
      synopticType: synopticType._id,
      headboard: '',
      detail: '',
      footer: '',
      mode: mode,
      caption: caption,
      synoptics: this.syn_vars_system.synoptics,
      reports: this.syn_vars_system.reports,
      synItems: this.syn_vars_system.items,
      values: synoptic.parameters,
      items_tags: this.syn_vars_system.tags,
      items_things: this.syn_vars_system.things
    };

    if (clone) {
      itemOptions['clone'] = true;
    }

    this.syn_func_off();
    this.syn_serv_sharServ.emitChange('synoptics', itemOptions);

  }

  async ngOnInit(): Promise<any> {
    /* Se inicializa el language y el texto de los leftButtons primeramente, dado que si no se generan incorrectamente. */
    this.syn_vars_util = new utils(this.syn_serv_systemServ);
    this.syn_vars_language = await this.langServ.getVal();
    this.syn_vars_language = this.syn_vars_language.option;
    this.syn_vars_textoFichaxxx = `${this.syn_vars_language.synoptic_properties}`;

    /* Iteramos los botones de sidebarDiv modificando el texto en función del idioma. */
    for (const buttonLeft of this.syn_vars_buttonsLeft) {
      buttonLeft.text = `${this.syn_vars_language[buttonLeft.text]}`;
    }

    const divL = document.getElementById('divLeftSynoptic');


    // divL.addEventListener("dragstart", function (e) { console.log(e); });
    // divL.addEventListener("dragend", function (e) { console.log(e); });

    this.syn_vars_system = await this.syn_serv_systemServ.getSystem('synoptics');

    /*
    const stl = this.syn_vars_system.cssEntitiesGlobal.parameters.elements[0].content;
    const style = document.createElement('style');
    style.textContent = stl;
    document.head.appendChild(style);
    */

    const thgs = {};
    for (const thing of this.syn_vars_system['things']) {
      thgs[thing._id.toString()] = thing;
    }
    for (const tag of this.syn_vars_system['tags']) {
      tag['thing'] = thgs[tag['thing'].toString()];
    }


    this.syn_func_treeCreate_byType('synoptics');
    this.syn_func_treeCreate_byType('reports');

    document.onkeydown = this.syn_func_onWindowKeyDown.bind(this);
    // document.onkeypress = this.onWindowKeyPress.bind(this);
    // document.onkeyup = this.onWindowKeyUp.bind(this);
    document.onresize = this.syn_func_onWindowLoadOrResize.bind(this);
    this.syn_func_onWindowLoadOrResize();
    document.onmousemove = this.syn_func_mouseMove.bind(this);
    document.onmouseup = this.syn_func_mouseUp.bind(this);

    this.syn_func_subscribe(this.syn_serv_sharServ.changeEmitted$.subscribe(async data => {

      if (data.val === 'action') {
        if (data.item.id === 'new') {
          if (data.item.mode === 'report') {
            // es un report nuevo
            this.syn_func_saveNewReport(data.item, false);
          }

          if (data.item.mode === 'synoptic') {
            // es un synoptic nuevo
            this.syn_func_saveNewSynoptic(data.item, false);
          }
        } else {
          // edit

          if (data.item.clone && data.item.mode === 'synoptic') {
            this.syn_func_saveNewSynoptic(data.item, true);
          } else {
            this.syn_vars_divMask.resize = false;
            this.syn_vars_itemSelecteds = [];
            this.syn_vars_gridPossible = false;
            this.syn_vars_itemSelectedOthers = [];
            this.syn_serv_systemServ.emitItemSelected('');
            this.syn_func_saveEdtItem(data.item.mode, data.item.id, data.item.parameters, data.item.parent);
          }
        }
      } else if (data.val === 'delete') {
        //eliminar item o sinóptico
        this.syn_vars_divMask.resize = false;
        this.syn_vars_itemSelecteds = [];
        this.syn_vars_gridPossible = false;
        this.syn_vars_itemSelectedOthers = [];
        this.syn_serv_systemServ.emitItemSelected('');
        this.syn_func_deleteByData(data);
      } else if (data.val === 'delete2') {
        // if (this.deleteInfo.mode === 'item') {
        // recorremos items seleccionados y borramos cada uno
        // this.syn_func_deleteItems();
        // }
      }
    }));

    this.syn_func_subscribe(this.syn_serv_systemServ.changeEmitted$.subscribe(async data => {
      if (data.val === 'action') {
        if (data.item.id === 'new') {
          // add
        } else {
        }
      } else if (data.val === 'delete') {
        // this.deleteItem(data.item.id);
      }
    }));
  }

  syn_func_zoom_auto(target) {
    if (target.checked) {
      //asumo que el panel derecho ocupa 300pixels
      //miro a ver donde está el left del main
      const left = this.syn_vars_divMain.offsetLeft;
      const top = this.syn_vars_divMain.offsetTop;
      const widthPanel = document.getElementById("divLeftSynoptic").offsetWidth + 20;

      const totWidth = window.innerWidth - left - widthPanel;
      const totHeight = window.innerHeight - top;

      const factorW = this.syn_vars_divMain_width / totWidth;
      const factorH = this.syn_vars_divMain_height / totHeight;

      if (factorW > 1 || factorH > 1) {
        if (factorW > factorH) {
          this.syn_vars_zoom = Math.floor(100 / factorW);
        } else {
          this.syn_vars_zoom = Math.floor(100 / factorH);
        }
        this.syn_func_zoom({ value: this.syn_vars_zoom });

      }
    }
  }

  async syn_func_divMainMouseUp(event): Promise<any> {

    this.syn_vars_timers.mouseup = setTimeout((event) => {
      this.syn_func_divMainClick(event);
    }, 75, event);

    try {
      clearTimeout(this.syn_vars_timers.gridPossible);
    } catch (error) {

    }

    this.syn_vars_timers.gridPossible = setTimeout(() => {
      this.syn_func_gridPossible(true);
    }, 200);
  }

  async syn_func_divMainClick(event): Promise<any> {

    try {
      clearTimeout(this.syn_vars_timers.mouseup);
    } catch (error) {
    }
    // this.syn_func_gridPossible(false);

    const left = event.clientX;
    const top = event.clientY;

    if (this.syn_vars_divMask.hidden === false && this.syn_vars_divMask.move && this.syn_vars_divMask.moveMode === 'selecting') {
      this.syn_vars_divMask.move = false;
      this.syn_vars_divMask.moveMode = '';
      this.syn_vars_divMask.hidden = true;
      if (this.syn_vars_divMask.selectingAdd === false && this.syn_vars_itemSelectedOthers.length > 0) {
        this.syn_vars_itemSelecteds = [];
        this.syn_vars_gridPossible = false;
      }
      for (const it of this.syn_vars_itemSelectedOthers) {
        const id = it.id.substring(2);

        const padding = this.syn_func_getPadding(id);
        const its = { id, item: this.syn_func_getItem(id), left: it.left, top: it.top, leftIni: parseInt(it.left), topIni: parseInt(it.top), width: it.width, height: it.height, padding: padding };

        this.syn_vars_itemSelecteds.push(its);
        // this.syn_vars_gridPossible = this.syn_func_gridPossible(true);
        this.syn_vars_divMask.resize = false;
        $('#optionsdyn').hide();
      }
      // this.syn_vars_gridPossible = this.syn_func_gridPossible(true);

      if (this.syn_vars_itemSelecteds.length === 1) {
        await this.syn_func_configItem(this.syn_vars_itemSelecteds[0].id, document.getElementById(`it${this.syn_vars_itemSelecteds[0].id}`));
      }

      this.syn_vars_itemSelectedOthers = [];
    }
  }

  syn_func_divMainMouseDown(event) {
    const gz = this.syn_func_getPosZoom(event);
    //hemos picado con el botón derecho, guardar posición para menu contextual
    if (event.button === 2) {
      const os = this.getOffSets(this.syn_vars_divMain); //tomo los offsets del main
      this.syn_vars_contextX = gz.clientX + this.scrollX() - (os.offsetLeft * (100 / this.syn_vars_zoom));
      this.syn_vars_contextY = gz.clientY + this.scrollY() - (os.offsetTop * (100 / this.syn_vars_zoom));
      this.syn_vars_contextFocus = this.syn_vars_itemFocus.id;

      const evClientY = gz.clientY + window.scrollY - (os.offsetTop);
    }

    //hemos picado en ningún sitio y con el botón izquierdo
    if (this.syn_vars_divMask.hidden && event.button === 0) {
      const os = this.getOffSets(this.syn_vars_divMain); //tomo los offsets del main
      const evClientX = (gz.clientX + this.scrollX()) - (os.offsetLeft * (100 / this.syn_vars_zoom));
      const evClientY = (gz.clientY + this.scrollY()) - (os.offsetTop * (100 / this.syn_vars_zoom));

      //vamos a seleccionar una zona
      this.syn_vars_divMask.move = true;
      this.syn_vars_divMask.moveMode = 'selecting';
      this.syn_vars_itemSelectedOthers = [];
      this.syn_vars_divMask.selectingAdd = false;
      this.syn_vars_divMask.selectingAll = false;
      if (event.shiftKey) {
        this.syn_vars_divMask.selectingAdd = true;
      }
      if (event.altKey) {
        this.syn_vars_divMask.selectingAll = true;
      }
      this.syn_vars_divMask.xIni = evClientX;
      this.syn_vars_divMask.yIni = evClientY;
      this.syn_vars_divMask.left = evClientX + 'px';
      this.syn_vars_divMask.top = evClientY + 'px';
      this.syn_vars_divMask.width = '0px';
      this.syn_vars_divMask.height = '0px';
      this.syn_vars_divMask.hidden = false;
    }

  }

  divMaskSet() {

  }

  async syn_func_divMainMouseMove(event): Promise<any> {
    const gz = this.syn_func_getPosZoom(event);

    //cpc005 estamos seleccionando
    if (this.syn_vars_divMask.move && this.syn_vars_divMask.moveMode === 'selecting' && event.button === 0) {

      let targ: HTMLElement = event.target;
      while (targ.id !== 'main') {
        targ = targ.parentElement;
      }
      const os = this.getOffSets(targ);

      let _x1 = this.syn_vars_divMask.xIni;
      let _y1 = this.syn_vars_divMask.yIni;
      let _x2 = gz.clientX + this.scrollX() - (os.offsetLeft * (100 / this.syn_vars_zoom));
      let _y2 = gz.clientY + this.scrollY() - (os.offsetTop * (100 / this.syn_vars_zoom));
      if (_x2 < _x1) {
        let __x2 = _x1;
        _x1 = _x2;
        _x2 = __x2;
        this.syn_vars_divMask.left = _x1 + 'px';
      }
      if (_y2 < _y1) {
        let __y2 = _y1;
        _y1 = _y2;
        _y2 = __y2;
        this.syn_vars_divMask.top = _y1 + 'px';
      }

      this.syn_vars_divMask.width = (_x2 - _x1) + 'px';
      this.syn_vars_divMask.height = (_y2 - _y1) + 'px';
      let others: any = [];
      this.syn_vars_itemsInSynoptic.forEach(itemId => {
        if (itemId.length === 26) {
          const item: HTMLElement = document.getElementById(itemId);
          const os = this.getOffSets(item);
          const x1 = os.offsetLeft;
          const y1 = os.offsetTop;
          const x2 = x1 + os.offsetWidth;
          const y2 = y1 + os.offsetHeight;
          if (this.syn_vars_divMask.selectingAll) {
            if (this.syn_func_PointIn(x1, y1, _x1, _y1, _x2, _y2) ||
              this.syn_func_PointIn(x2, y1, _x1, _y1, _x2, _y2) ||
              this.syn_func_PointIn(x2, y2, _x1, _y1, _x2, _y2) ||
              this.syn_func_PointIn(x1, y2, _x1, _y1, _x2, _y2)) {
              const oth: any = {
                id: item.id,
                left: x1 + 'px', leftIni: x1,
                top: y1 + 'px', topIni: y1,
                width: os.offsetWidth + 'px', height: os.offsetHeight + 'px'
              };
              others.push(oth);
            }
          } else {
            if (_x1 <= x1 && _x2 >= x2 && _y1 <= y1 && _y2 >= y2) {
              const oth: any = {
                id: item.id,
                left: x1 + 'px', leftIni: x1,
                top: y1 + 'px', topIni: y1,
                width: os.offsetWidth + 'px', height: os.offsetHeight + 'px'
              };
              others.push(oth);
            }
          }
        }
      });

      let change = false;
      if (others.length === this.syn_vars_itemSelectedOthers) {
      } else {
        change = true;
      }
      if (change) {
        this.syn_vars_itemSelectedOthers = others;
      }
    }

    //estamos haciendo resize en el item
    if (this.syn_vars_divMask.resizeOn) {
      const evClientX = gz.clientX + this.scrollX();
      const evClientY = gz.clientY + this.scrollY();
      const xAct: number = evClientX;
      const yAct: number = evClientY;
      const padding = this.syn_vars_itemSelecteds[0].padding;
      let new_left: number = this.syn_vars_divMask.resize_leftIni;
      let new_top: number = this.syn_vars_divMask.resize_topIni;
      let new_width: number = this.syn_vars_divMask.resize_widthIni;
      let new_height: number = this.syn_vars_divMask.resize_heightIni;

      let incX = this.syn_func_aprox(xAct - this.syn_vars_divMask.resize_xIni, padding);
      let incY = this.syn_func_aprox(yAct - this.syn_vars_divMask.resize_yIni, padding);
      switch (this.syn_vars_divMask.resizeMode) {
        case 1: // top-left

          new_left += incX;
          new_top += incY;
          new_width -= incX;
          new_height -= incY;
          break;
        case 2: // top

          new_top += incY;
          new_height -= incY;
          break;
        case 3: // top-right

          new_top += incY;
          new_height -= incY;
          new_width += incX;
          break;
        case 4: // left
          new_left += incX;
          new_width -= incX;
          break;
        case 5: // right
          new_width += incX;
          break;
        case 6: // bottom-left
          new_left += incX;
          new_width -= incX;
          new_height += incY;
          break;
        case 7: // bottom
          new_height += incY;
          break;
        case 8: // bottom-right
          new_width += incX;
          new_height += incY;
          break;
      }
      this.syn_vars_itemSelecteds[0].left = new_left + 'px';
      this.syn_vars_itemSelecteds[0].top = new_top + 'px';
      this.syn_vars_itemSelecteds[0].width = new_width + 'px';
      this.syn_vars_itemSelecteds[0].height = new_height + 'px';
      try {
        document.getElementById('xgeneral')['value'] = parseInt(this.syn_vars_itemSelecteds[0].left);
        document.getElementById('ygeneral')['value'] = parseInt(this.syn_vars_itemSelecteds[0].top);
        document.getElementById('widthgeneral')['value'] = parseInt(this.syn_vars_itemSelecteds[0].width);
        document.getElementById('heightgeneral')['value'] = parseInt(this.syn_vars_itemSelecteds[0].height);
      } catch (err) {}
    }

  }

  syn_func_onWindowKeyDown(event): void {
    if (event.keyCode === 27) {
      try {
        if (this.syn_vars_divMask.move) {
          this.syn_vars_divMask.move = false;
          this.syn_vars_divMask.left = this.syn_vars_divMask.leftIni + 'px';
          this.syn_vars_divMask.top = this.syn_vars_divMask.topIni + 'px';
          for (const item of this.syn_vars_itemSelecteds) {
            item.left = item.leftIni + 'px';
            item.top = item.topIni + 'px';
          }
        } else {
          const tree = $('#treeblItems').jstree(true);
          tree.refresh();
          for (const it of tree.settings.core.data) {
            it.state.selected = false;
          }
          tree.refresh();

          $('#optionsdyn').hide();
          this.syn_vars_divMask.hidden = true;
          this.syn_vars_divMask.move = false;
          this.syn_vars_divMask.resize = false;
          this.syn_vars_itemSelecteds = [];
          this.syn_vars_gridPossible = false;
          this.syn_vars_itemSelectedOthers = [];
        }
      } catch (error) {
      }
    }
  }

  syn_func_off() {
    try {
      const tree = $('#treeblItems').jstree(true);
      tree.refresh();
      for (const it of tree.settings.core.data) {
        it.state.selected = false;
      }
      tree.refresh();
      this.syn_vars_contextFocus = null;
      this.syn_vars_contextSelects = [];
      this.syn_vars_divMask.hidden = true;
      this.syn_vars_divMask.resize = false;
      this.syn_vars_itemSelecteds = [];
      this.syn_vars_gridPossible = false;
      this.syn_vars_itemSelectedOthers = [];
      this.syn_vars_divMask.move = false;
      $('#optionsdyn').hide();
    } catch (error) {
      // console.log(error);
    }
  }

  syn_func_getPadding(itemId) {
    let padding = 0;
    try {
      const itemSel = this.syn_func_getItem(itemId); //this.syn_vars_itemFocus.id);
      const typeSel = this.syn_vars_system.controls.find(ctrl => ctrl._id === itemSel.type);
      padding = typeSel.parameters.padding;
      if (padding === undefined) {
        padding = 0;
      }
    } catch (error) {
    }
    return padding;
  }

  syn_func_PointIn(x, y, inX1, inY1, inX2, inY2) {
    if (x < inX1) { return false; }
    if (x > inX2) { return false; }
    if (y < inY1) { return false; }
    if (y > inY2) { return false; }
    return true;
  }

  syn_func_buttonsLeft_position(ini) {
    let incy = 0;
    let y = 65;
    // let start = false;
    let lleft = 0;
    for (const butt of this.syn_vars_buttonsLeft) {
      if (butt.visible) {
        const bt = document.getElementById(butt.id);
        const width = bt.offsetWidth;

        bt.style.top = (y + width) + incy + 'px';
        bt.style.left = lleft + 'px';
        incy += (width + 5);
        // start = true;
      }
    }
  }

  syn_func_onWindowLoadOrResize(): void {

    const divL = document.getElementById('divLeftSynoptic');
    const left = divL.offsetWidth + 32;
    const top = 50;
    const innerWidth = window.innerWidth;

    this.syn_func_buttonsLeft_position(true);

    this.syn_vars_divMain = document.getElementById('main');
    this.syn_vars_divMain.style.backgroundColor = '#EEEEEE';
    this.syn_vars_divMain.style.borderStyle = 'solid';
    this.syn_vars_divMain.style.borderWidth = '0px';
    this.syn_vars_divMain.style.position = 'absolute';
    this.syn_vars_divMain.style.left = left + 'px';
    this.syn_vars_divMain.style.top = top + 'px';


    this.syn_vars_divMain.style.width = (window.innerWidth - left) + 'px';
    this.syn_vars_divMain.style.height = (window.innerHeight - top) + 'px';

    const childs = this.syn_vars_divMain.childNodes;
    let maxHeight = 0;
    let maxWidth = 0;

    this.syn_vars_divMain_width = (window.innerWidth - left - 10);
    this.syn_vars_divMain_height = (window.innerHeight - top - 10);

    // this.syn_vars_divMain.style.width = (window.innerWidth - left - 10) + 'px';
    // this.syn_vars_divMain.style.width = (window.innerWidth - left - 10) + 'px';

    this.syn_vars_divMain.addEventListener('click', this.syn_func_divMainClick.bind(this), true);
    this.syn_vars_divMain.addEventListener('mouseup', this.syn_func_divMainMouseUp.bind(this), true);
    this.syn_vars_divMain.addEventListener('mousedown', this.syn_func_divMainMouseDown.bind(this), true);
    this.syn_vars_divMain.addEventListener('mousemove', this.syn_func_divMainMouseMove.bind(this), true);
    const dleft = document.getElementById('divLeftSynoptic');
    dleft.style.height = (window.innerHeight - top) + 'px';

  }

  syn_func_leftSection_open() {
    for (const each of this.syn_vars_buttonsLeft) {
      if (each.hasOwnProperty('open') === false) {
        each.visible = false;
      } else {
        if (each.id !== this.syn_vars_buttonsLeftSelecc) {
          each.visible = false;
        }
      }
    }
    const open = this.syn_vars_buttonsLeft.find(each => each.id === this.syn_vars_buttonsLeftSelecc).open;
    this.syn_vars_buttonsLeft.map(function (element) {
      if (open.includes(element.id)) {
        element.visible = true;
      }
    }
    );
  }

  syn_func_leftSection_close() {
    for (const each of this.syn_vars_buttonsLeft) {
      if (!each.open) {
        each.visible = false;
      } else {
        each.visible = true;
      }
    }
    if (this.syn_vars_isType === 'report') {
      this.syn_func_leftSection('blReports');
    } else {
      this.syn_func_leftSection('blSynoptics');
    }

  }

  syn_func_leftSection(idSection) {

    //oculto todo
    for (const each of this.syn_vars_buttonsLeft) {
      $('#tree' + each.id).hide();
    }
    this.syn_vars_buttonsLeftSelecc = idSection;
    $('#tree' + idSection).show();

    setTimeout(() => {
      this.syn_func_buttonsLeft_position(false);
    }, 100);
  }

  async syn_func_mouseUp(event): Promise<any> {
    const gz = this.syn_func_getPosZoom(event);

    //cpc007 estábamos cambiando el tamaño de un item
    if (this.syn_vars_divMask.resizeOn) {
      if (this.syn_vars_divMask.resize_xIni !== gz.clientX || this.syn_vars_divMask.resize_yIni !== gz.clientY) {
        // const itemToUpdate = this.syn_func_getItem(this.syn_vars_itemFocus.id);
        const itemToUpdate = this.syn_func_getItem(this.syn_vars_itemSelecteds[0].id);
        if (itemToUpdate.type === this.syn_vars_itemTypes.chart) {
          itemToUpdate.data = [];
        }
        const oldItem = JSON.stringify(itemToUpdate);
        try {
          this.syn_vars_itemSelecteds[0].leftIni = document.getElementById('xgeneral')['value'];
          this.syn_vars_itemSelecteds[0].topIni = document.getElementById('ygeneral')['value'];
          itemToUpdate.parameters.xgeneral = document.getElementById('xgeneral')['value'];
          itemToUpdate.parameters.ygeneral = document.getElementById('ygeneral')['value'];
          itemToUpdate.parameters.widthgeneral = document.getElementById('widthgeneral')['value'];
          itemToUpdate.parameters.heightgeneral = document.getElementById('heightgeneral')['value'];
        } catch (error) {}
        // this.controlBuild(itemToUpdate, true, false);

        const itemUpdated = await this.syn_serv_systemServ.updItem(itemToUpdate);
        this.syn_vars_util.StyleRules(itemToUpdate);

        const newItem = JSON.stringify(itemToUpdate);
        //type chart
        if (itemToUpdate.type === this.syn_vars_itemTypes.chart) {
          const props = this.syn_func_buildChart(itemToUpdate);
          itemToUpdate['options'] = {};
          itemToUpdate['typeLine'] = props['typeLine'];
          itemToUpdate['data'] = props['data'];
          itemToUpdate['typeChart'] = props['typeChart'];

          this.syn_vars_system.items['Chart'] = this.syn_vars_system.items['Chart'].filter(itt => itt._id !== itemToUpdate._id);
          this.syn_vars_system.items['Chart'].push({ ...itemToUpdate });
        }

        this.syn_vars_undo_actual = this.syn_vars_undo_actual + 1;
        this.syn_vars_undo_total = this.syn_vars_undo_actual;
        this.syn_vars_undo_changes[this.syn_vars_undo_actual] = { prev: [{ type: 'updateitem', id: this.syn_vars_itemSelecteds[0], item: JSON.parse(oldItem) }], actual: [{ type: 'updateitem', id: this.syn_vars_itemFocus.id, item: JSON.parse(newItem) }] };
      }
      this.syn_vars_divMask.resizeOn = false;
    }
  }

  async syn_func_deleteSyn() {
    (<any>$$('#deleteSyn')).modal('hide');
    this.syn_func_deleteByData({ item: { mode: 'synoptic' } });
  }

  syn_func_getPosZoom(event) {
    const ev = {
      clientX: event.clientX * (100 / this.syn_vars_zoom),
      clientY: event.clientY * (100 / this.syn_vars_zoom),
      zoom: (100 / this.syn_vars_zoom)
    }

    /*
    const ev = {
      clientX: event.clientX * (100 / this.syn_vars_zoom),
      clientY: event.clientY * (100 / this.syn_vars_zoom),
      zoom: (100 / this.syn_vars_zoom)
    }
    */
    return ev;
  }

  syn_func_mouseMove(event): void {
    const gz = this.syn_func_getPosZoom(event);

    const evClientX = gz.clientX + this.scrollX();
    const evClientY = gz.clientY + this.scrollY();

    // console.log(gz.clientX, this.scrollX(), gz.clientY, this.scrollY());

    let canSelect: boolean = true;

    //estamos moviendo un elemento o varios
    if (this.syn_vars_divMask.move) {
      //colocamos el optionsdyn en left 0
      const opts = document.getElementById('optionsdyn');
      opts.style.left = '32px';
      canSelect = false;
      switch (this.syn_vars_divMask.moveMode) {
        case 'control'://cpc009
          let xnew = this.syn_vars_divMask.leftIni + (evClientX - this.syn_vars_divMask.xIni);
          let ynew = this.syn_vars_divMask.topIni + (evClientY - this.syn_vars_divMask.yIni);

          for (const item of this.syn_vars_itemSelecteds) {
            const incX = this.syn_func_aprox(evClientX - this.syn_vars_divMask.xIni, this.syn_func_getPadding(item.id));
            const incY = this.syn_func_aprox(evClientY - this.syn_vars_divMask.yIni, this.syn_func_getPadding(item.id));

            item.left = parseInt(item.leftIni) + incX + 'px';
            item.top = parseInt(item.topIni) + incY + 'px';
          }

          if (this.syn_vars_itemSelecteds.length === 1) {
            xnew = this.syn_func_aprox(xnew, this.syn_func_getPadding(this.syn_vars_itemSelecteds[0].id));
            ynew = this.syn_func_aprox(ynew, this.syn_func_getPadding(this.syn_vars_itemSelecteds[0].id));

            document.getElementById('xgeneral')['value'] = xnew;
            document.getElementById('ygeneral')['value'] = ynew;
          }

          this.syn_vars_divMask.left = xnew + 'px';
          this.syn_vars_divMask.top = ynew + 'px';
          break;
      }
    }

    //Cuando movemos, mirar si estamos en un item
    //cpc011
    if (canSelect) {
      let noSel = true;
      const tx = this.syn_vars_divMain.offsetLeft * gz.zoom;
      const ty = this.syn_vars_divMain.offsetTop * gz.zoom;
      if (evClientX >= tx && evClientY >= ty) {
        let founds: any = [];

        this.syn_vars_itemsInSynoptic.forEach(itemId => {
          const item: HTMLElement = document.getElementById(itemId);
          if (item) {
            if (item.id.length !== 51 || item?.getAttribute('noselect') !== "true") {
              const os = this.getOffSets(item);
              if (((evClientX - tx) >= os.offsetLeft) && ((evClientY - ty) >= os.offsetTop)) {
                if ((evClientX - tx) <= (os.offsetLeft + os.offsetWidth) && (evClientY - ty) <= (os.offsetTop + os.offsetHeight)) {
                  noSel = false;
                  founds.push(item);
                }
              }
            }
          }
        });

        if (this.syn_vars_divMask.resize) {
          if (!founds.find(found => found.getAttribute("iditem") === this.syn_vars_itemSelecteds[0].id)) {
            //estoy en un item, pero no es el seleccionado
            //voy a ver si estoy en la zona del marco de los resizes
            const x0 = this.syn_func_parseInt(this.syn_vars_itemSelecteds[0].left) - 10;
            const y0 = this.syn_func_parseInt(this.syn_vars_itemSelecteds[0].top) - 10;
            const x1 = this.syn_func_parseInt(this.syn_vars_itemSelecteds[0].left) + this.syn_func_parseInt(this.syn_vars_itemSelecteds[0].width) + 10;
            const y1 = this.syn_func_parseInt(this.syn_vars_itemSelecteds[0].top) + this.syn_func_parseInt(this.syn_vars_itemSelecteds[0].height) + 10;

            if (evClientX - tx > x0 && evClientX - tx < x1 && evClientY - ty > y0 && evClientY - ty < y1) {
              noSel = true;
              founds = [];
            }
          }
        }

        if (founds.length > 0) {
          let size = 10000000;
          let itFound: any = false;
          for (const found of founds) {
            const os = this.getOffSets(found);
            if (os.offsetWidth + os.offsetHeight < size) {
              size = os.offsetWidth + os.offsetHeight;
              itFound = found;
            }
          }
          const os = this.getOffSets(itFound);
          this.syn_vars_divMask.left = os.offsetLeft + 'px';
          this.syn_vars_divMask.top = os.offsetTop + 'px';

          this.syn_vars_divMask.width = os.offsetWidth + 'px';
          this.syn_vars_divMask.height = os.offsetHeight + 'px';

          this.syn_vars_itemFocus.tag = itFound;
          if (itFound.id === '') {
            const idd = itFound.childNodes[0]['id'];
            this.syn_vars_itemFocus.id = idd.replace('_wrapper', '');
          } else {
            this.syn_vars_itemFocus.id = itFound.id;
            this.syn_vars_itemFocus.id = this.syn_vars_itemFocus.id.replace('tblDSDiv_', '');
            this.syn_vars_itemFocus.id = this.syn_vars_itemFocus.id.replace('tblDiv_', '');

            if (this.syn_vars_itemFocus.id.substring(0, 2) === 'it') {
              this.syn_vars_itemFocus.id = this.syn_vars_itemFocus.id.substring(2);
            }

            this.syn_vars_itemFocus.context.copy = true;
            this.syn_vars_itemFocus.context.delete = true;


            if (itFound.id.length > 26) {
              this.syn_vars_itemFocus.context.copy = false;
              this.syn_vars_itemFocus.context.delete = false;
            }
          }
        }

        if (noSel) {
          this.syn_vars_divMask.hidden = true;
        } else {
          this.syn_vars_divMask.hidden = false;
        }
      }
    }
  }

  syn_func_treeCreate_byType(type) {
    switch (type) {
      case 'synoptics':




        let dataSyn: any = [{ id: 'synoptics', parent: '#', state: { opened: true, selected: false }, text: `${this.syn_vars_language.synoptics_text}`, li_attr: { type: 'synoptics' } }, { id: 'templates', parent: '#', li_attr: { type: 'templates' }, state: { opened: true, selected: false }, text: `${this.syn_vars_language.templates_text}` }];

        for (const syn of this.syn_vars_system.synoptics) {
          if (syn.parameters.folder === true) {
            dataSyn.push({ id: 'syn' + syn._id, parent: 'synoptics', type: 'folder', a_attr: { id: syn._id, type: 'folder' }, state: { selected: false }, li_attr: { id: syn._id, type: 'folder' }, text: syn.parameters.namegeneral });
          }
        }

        for (const syn of this.syn_vars_system.synoptics) {
          if (syn.parameters.folder !== true) {
            let parent = 'synoptics';
            if (syn.parent !== '') {
              parent = 'syn' + syn.parent;
            }
            dataSyn.push({ id: syn._id, parent: parent, type: 'syn', a_attr: { id: syn._id, type: 'syn' }, state: { selected: false }, li_attr: { id: syn._id, type: 'syn' }, text: syn.parameters.namegeneral });
          }
          // dataSyn.push({ id: syn._id, parent: 'synoptics', type: 'syn', a_attr: { id: syn._id, type: 'syn' }, state: { selected: false }, li_attr: { id: syn._id, type: 'syn' }, text: syn.parameters.namegeneral });
        }

        for (const tpl of this.syn_vars_system.synoptics_templates) {
          dataSyn.push({ id: tpl._id, parent: 'templates', type: 'tpl', a_attr: { id: tpl._id, type: 'tpl' }, state: { selected: false }, li_attr: { id: tpl._id, type: 'tpl' }, text: tpl.parameters.namegeneral });
        }

        $('#treeblSynoptics').jstree({
          core: {
            data: dataSyn
          },
          types: {
            "default": {

            },
            "syn": {
              "icon": "assets/img/modelizer2/baseline_dashboard_black_24dp.png"
            }
            ,
            "tpl": {
              "icon": "assets/img/modelizer2/baseline_summarize_black_24dp.png"
            }
          }
          ,
          conditionalselect: function (node, event) {
            if (node.li_attr.type === 'syn' || node.li_attr.type === 'tpl') {
              return true;
            } else {
              return false;
            }
          }
          ,
          contextmenu: {
            items: (node) => {
              const items: any = {}; //$.jstree.defaults.contextmenu.items();
              switch (node.li_attr.type) {
                case 'syn':
                  if (this.syn_vars_SynopticId === node.li_attr.id) {
                    items.synoptic_info = {
                      label: `${this.syn_vars_language.properties}`,
                      separator_after: true,
                      action: async () => {
                        this.syn_func_configSynoptic(this.syn_vars_SynopticId, false);
                      }
                    };

                    items.synoptic_close = {
                      label: `${this.syn_vars_language.close}`,
                      separator_after: true,
                      action: async () => {
                        this.syn_func_closeItem();
                      }
                    };

                    items.synoptic_clone = {
                      label: `${this.syn_vars_language.clone_button}`,
                      separator_after: true,
                      action: async () => {
                        this.syn_func_cloneSynoptic(this.syn_vars_SynopticId);
                        // $('#treeblSynoptics').jstree(true).delete_node(node);
                        //
                      }
                    };

                    items.synoptic_remove = {
                      label: `${this.syn_vars_language.delete}`,
                      separator_after: true,
                      action: async () => {
                        (<any>$$('#deleteSyn')).modal('show');
                      }
                    };
                  } else {
                    setTimeout((node) => {

                      try {
                        $('#treeblSynoptics').jstree('deselect_node', node);
                        $('#treeblSynoptics').jstree('select_node', this.syn_vars_SynopticId);

                      } catch (error) {
                        console.log(error);
                      }
                    }, 100, node);
                    return items;
                  }
                  break;
                case 'tpl':
                  if (this.syn_vars_SynopticId === node.li_attr.id) {
                    items.synoptic_info = {
                      label: `${this.syn_vars_language.properties}`,
                      separator_after: true,
                      action: async () => {
                        this.syn_func_configSynoptic(this.syn_vars_SynopticId, false);
                      }
                    };

                    items.synoptic_close = {
                      label: `${this.syn_vars_language.close}`,
                      separator_after: true,
                      action: async () => {
                        this.syn_func_closeItem();
                      }
                    };

                    items.synoptic_clone = {
                      label: `${this.syn_vars_language.clone_button}`,
                      separator_after: true,
                      action: async () => {
                        this.syn_func_cloneSynoptic(this.syn_vars_SynopticId);
                        // $('#treeblSynoptics').jstree(true).delete_node(node);
                        //
                      }
                    };

                    items.synoptic_remove = {
                      label: `${this.syn_vars_language.delete}`,
                      separator_after: true,
                      action: async () => {
                        (<any>$$('#deleteSyn')).modal('show');
                      }
                    };
                  } else {
                    setTimeout((node) => {

                      try {
                        $('#treeblSynoptics').jstree('deselect_node', node);
                        $('#treeblSynoptics').jstree('select_node', this.syn_vars_SynopticId);

                      } catch (error) {
                        console.log(error);
                      }
                    }, 100, node);
                    return items;
                  }
                  break;
                case 'synoptics':
                  items.synoptic_new = {
                    label: `${this.syn_vars_language.synoptic_new}`,
                    separator_after: true,
                    action: async () => {
                      this.syn_vars_isType = 'synoptic';
                      this.syn_func_configSynoptic('new', false);
                    }
                  };
                  /*
                  items.folder_create = {
                    label: `${this.syn_vars_language.create_folder}`,
                    separator_after: true,
                    action: async () => {
                      const folderName = window.prompt(`${this.syn_vars_language.label_create_folder}`);
                      await this.syn_func_folderCreate(folderName);
                    }
                  };
                  */
                  break;
                case 'templates':
                  items.synoptic_new = {
                    label: `${this.syn_vars_language.template_new}`,
                    separator_after: true,
                    action: async () => {
                      this.syn_vars_isType = 'synoptic_template';
                      this.syn_func_configSynoptic('new', false);
                    }
                  };
                  /*
                  items.folder_create = {
                    label: `${this.syn_vars_language.create_folder}`,
                    separator_after: true,
                    action: async () => {
                      //
                    }
                  };
                  */
                  break;
              }

              /*
              delete items.rename;
              delete items.remove;
              delete items.create;
              delete items.ccp;
              delete items.Create;
              */
              return items;
            }
          }, plugins: ["types", "sort", "core", "contextmenu", "conditionalselect"]
        });

        $("#treeblSynoptics").on('click', 'li > a', async (node) => {
          this.syn_vars_nodeSelected = node;
          const target = node.currentTarget.id;
          const id = target.replace('_anchor', '');
          if (id !== 'synoptics' && id !== 'templates') {

            if (node.currentTarget.type === 'syn') {
              this.syn_vars_isType = 'synoptic';
              await this.syn_func_ClickTreeSynoptic(id);
            }

            if (node.currentTarget.type === 'tpl') {
              this.syn_vars_isType = 'synoptic_template';
              await this.syn_func_ClickTreeSynoptic(id);
            }

          }
        });

        break;
      case 'reports':

        //primero sinópticos
        let dataRep: any = [{ id: 'reports', parent: '#', state: { opened: true, selected: false }, text: `${this.syn_vars_language.reports_text}`, li_attr: { type: 'reports' } }, { id: 'templates', parent: '#', li_attr: { type: 'templates' }, state: { opened: true, selected: false }, text: `${this.syn_vars_language.templates_text}` }];
        for (const syn of this.syn_vars_system.reports) {
          dataRep.push({ id: syn._id, parent: 'reports', type: 'rep', a_attr: { id: syn._id, type: 'rep' }, state: { selected: false }, li_attr: { id: syn._id, type: 'rep' }, text: syn.parameters.namegeneral });
        }
        for (const tpl of this.syn_vars_system.reports_templates) {
          dataRep.push({ id: tpl._id, parent: 'templates', type: 'tpl', a_attr: { id: tpl._id, type: 'tpl' }, state: { selected: false }, li_attr: { id: tpl._id, type: 'tpl' }, text: tpl.parameters.namegeneral });
        }
        $('#treeblReports').jstree({
          core: {
            data: dataRep
          },
          types: {
            "default": {

            },
            "rep": {
              "icon": "assets/img/modelizer2/baseline_dashboard_black_24dp.png"
            }
            ,
            "tpl": {
              "icon": "assets/img/modelizer2/baseline_summarize_black_24dp.png"
            }
          }
          ,
          conditionalselect: function (node, event) {
            if (node.li_attr.type === 'rep' || node.li_attr.type === 'tpl') {
              return true;
            } else {
              return false;
            }
          }
          ,
          contextmenu: {
            items: (node) => {
              const items: any = {}; //$.jstree.defaults.contextmenu.items();
              switch (node.li_attr.type) {
                case 'rep':
                  if (this.syn_vars_SynopticId === node.li_attr.id) {
                    items.synoptic_info = {
                      label: `${this.syn_vars_language.properties}`,
                      separator_after: true,
                      action: async () => {
                        this.syn_func_configSynoptic(this.syn_vars_SynopticId, false);
                      }
                    };

                    items.synoptic_close = {
                      label: `${this.syn_vars_language.close}`,
                      separator_after: true,
                      action: async () => {
                        this.syn_func_closeItem();
                      }
                    };

                    items.synoptic_clone = {
                      label: `${this.syn_vars_language.clone_button}`,
                      separator_after: true,
                      action: async () => {
                        this.syn_func_cloneSynoptic(this.syn_vars_SynopticId);
                        // $('#treeblSynoptics').jstree(true).delete_node(node);
                        //
                      }
                    };

                    items.synoptic_remove = {
                      label: `${this.syn_vars_language.delete}`,
                      separator_after: true,
                      action: async () => {
                        (<any>$$('#deleteSyn')).modal('show');
                      }
                    };
                  } else {
                    setTimeout((node) => {

                      try {
                        $('#treeblReports').jstree('deselect_node', node);
                        $('#treeblReports').jstree('select_node', this.syn_vars_SynopticId);

                      } catch (error) {
                        console.log(error);
                      }
                    }, 100, node);
                    return items;
                  }
                  break;
                case 'tpl':
                  if (this.syn_vars_SynopticId === node.li_attr.id) {
                    items.synoptic_info = {
                      label: `${this.syn_vars_language.properties}`,
                      separator_after: true,
                      action: async () => {
                        this.syn_func_configSynoptic(this.syn_vars_SynopticId, false);
                      }
                    };

                    items.synoptic_close = {
                      label: `${this.syn_vars_language.close}`,
                      separator_after: true,
                      action: async () => {
                        this.syn_func_closeItem();
                      }
                    };

                    items.synoptic_clone = {
                      label: `${this.syn_vars_language.clone_button}`,
                      separator_after: true,
                      action: async () => {
                        this.syn_func_cloneSynoptic(this.syn_vars_SynopticId);
                        // $('#treeblSynoptics').jstree(true).delete_node(node);
                        //
                      }
                    };

                    items.synoptic_remove = {
                      label: `${this.syn_vars_language.delete}`,
                      separator_after: true,
                      action: async () => {
                        (<any>$$('#deleteSyn')).modal('show');
                      }
                    };
                  } else {
                    setTimeout((node) => {

                      try {
                        $('#treeblReports').jstree('deselect_node', node);
                        $('#treeblReports').jstree('select_node', this.syn_vars_SynopticId);

                      } catch (error) {
                        console.log(error);
                      }
                    }, 100, node);
                    return items;
                  }
                  break;
                case 'reports':
                  items.synoptic_new = {
                    label: `${this.syn_vars_language.report_new}`,
                    separator_after: true,
                    action: async () => {
                      this.syn_vars_isType = 'report';
                      this.syn_func_configSynoptic('new', false);
                    }
                  };
                  /*
                  items.folder_create = {
                    label: `${this.syn_vars_language.create_folder}`,
                    separator_after: true,
                    action: async () => {
                      //
                    }
                  };
                  */
                  break;
                case 'templates':
                  items.synoptic_new = {
                    label: `${this.syn_vars_language.template_new}`,
                    separator_after: true,
                    action: async () => {
                      this.syn_vars_isType = 'report_template';
                      this.syn_func_configSynoptic('new', false);
                    }
                  };
                  /*
                  items.folder_create = {
                    label: `${this.syn_vars_language.create_folder}`,
                    separator_after: true,
                    action: async () => {
                      //
                    }
                  };
                  */
                  break;
              }

              /*
              delete items.rename;
              delete items.remove;
              delete items.create;
              delete items.ccp;
              delete items.Create;
              */
              return items;
            }
          }, plugins: ["types", "sort", "core", "contextmenu", "conditionalselect"]
        });

        $("#treeblReports").on('click', 'li > a', async (node) => {
          this.syn_vars_nodeSelected = node;
          const target = node.currentTarget.id;
          const id = target.replace('_anchor', '');
          if (id !== 'reports' && id !== 'templates') {

            if (node.currentTarget.type === 'rep') {
              this.syn_vars_isType = 'report';
              await this.syn_func_ClickTreeSynoptic(id);
            }

            if (node.currentTarget.type === 'tpl') {
              this.syn_vars_isType = 'report_template';
              await this.syn_func_ClickTreeSynoptic(id);
            }

          }
        });

        $("#treeblReports").hide();
        break;
    }
  }

  async syn_func_folderCreate(folderName) {
    const newIt = { _id: '', description: folderName, name: folderName, order: 10, parent: '', type: this.syn_vars_system.synopticType._id, parameters: { folder: true } };
    const itemUpdated = await this.syn_serv_systemServ.newItem(newIt);

    const tree = $('#treeblSynoptics').jstree(true);

    tree.settings.core.data.push({ id: itemUpdated.data._id, parent: 'synoptics', type: 'folder', a_attr: { type: 'folder' }, li_attr: { id: itemUpdated.data._id, type: 'folder' }, text: itemUpdated.data.parameters.namegeneral });
    tree.refresh();
  }

  syn_func_closeItem() {
    let type = '';
    try {
      switch (this.syn_vars_isType) {
        case 'synoptic':
        case 'synoptic_template':
          type = '#treeblSynoptics'
          break;
        case 'report':
        case 'report_template':
          type = '#treeblReports'
          break;
      }
      $(type).jstree('deselect_node', this.syn_vars_SynopticId);
    } catch (error) {
      console.log(error);
    }

    this.syn_vars_SynopticId = '';
    this.syn_func_off();
    this.syn_vars_backcolor = "";
    this.syn_vars_itemsInSynoptic = [];

    this.syn_serv_systemServ.emitUpdateSyn(
      {
        reset: true
      });

    this.syn_func_leftSection_close();


  }

  async syn_func_ClickTreeSynoptic(idSyn) {
    //cpc001
    this.syn_func_closeItem();

    this.syn_vars_SynopticId = idSyn;

    this.syn_vars_undo_changes = [];
    this.syn_vars_undo_actual = -1;
    this.syn_vars_undo_total = -1;

    let synoptic: any = "";
    switch (this.syn_vars_isType) {
      case 'synoptic':
        synoptic = this.syn_vars_system.synoptics.find(syn => syn._id === idSyn);
        this.syn_vars_textoFichaxxx = `${this.syn_vars_language.synoptic_properties}`;
        break;
      case 'report':
        synoptic = this.syn_vars_system.reports.find(syn => syn._id === idSyn);
        this.syn_vars_textoFichaxxx = `${this.syn_vars_language.report_properties}`;
        break;
      case 'synoptic_template':
        this.syn_vars_textoFichaxxx = `${this.syn_vars_language.synoptic_properties}`;
        break;
      case 'report_template':
        synoptic = this.syn_vars_system.reports_templates.find(syn => syn._id === idSyn);
        this.syn_vars_textoFichaxxx = `${this.syn_vars_language.report_properties}`;
        break;
    }

    this.syn_vars_backcolor = synoptic.parameters.colorgeneral;

    const items = await this.syn_serv_systemServ.getItemsBy({ parent: idSyn });
    this.syn_vars_itemsInSynoptic = [];

    //miro a ver los controles que son synoptics (contenedores de otros items)
    const syns = items.filter(it => it.type === this.syn_vars_itemTypes.synoptic);
    try {
      for (const syn of syns) {
        if (syn.parameters.synopticgeneral !== 'none') {
          this.syn_vars_itemFocus.context.syns[syn._id] = true;
        }
        const itsSyn = await this.syn_serv_systemServ.getItemsBy({ parent: syn.parameters.synopticgeneral });
        try {
          for (const itChangeds of Object.entries(syn.parameters.items)) {
            const it = itsSyn.find(it => it._id === itChangeds[0]);
            if (it !== undefined) {
              for (const parms of Object.entries(itChangeds[1])) {
                it.parameters[parms[0]] = parms[1];
              }
            }
          }
        } catch (error) {
        }
        for (const it of itsSyn) {
          const itc = { ...it };
          const pars = JSON.stringify(it.parameters);
          itc.name = syn.name + ':' + it.name;
          itc.parameters['namegeneral'] = itc.name;
          itc.parameters['orig'] = JSON.parse(pars);
          itc._id = syn._id + '_' + itc._id;
          itc.parameters.xgeneral = parseInt(it.parameters.xgeneral) + parseInt(syn.parameters.xgeneral);
          itc.parameters.ygeneral = parseInt(it.parameters.ygeneral) + parseInt(syn.parameters.ygeneral);
          items.push(itc);
        }
      }
    } catch (error) {
    }

    this.syn_vars_divMain_width = 0;
    this.syn_vars_divMain_height = 0;

    this.syn_vars_system.items = {};
    for (const item of items) {

      if (this.syn_vars_free_types.includes(item.type)) {
        item.parameters.stylegeneral = 'line';
      }

      const control = this.syn_vars_system.controls.find(control => control._id === item.type);
      if (this.syn_vars_system.items.hasOwnProperty(control.name) === false) {
        this.syn_vars_system.items[control.name] = [];
      }
      item['id'] = item._id;

      if (control.name === 'Chart') {
        const props = this.syn_func_buildChart(item);
        item['options'] = {};
        item['typeLine'] = props['typeLine'];
        item['data'] = props['data'];
        item['typeChart'] = props['typeChart'];
      }

      try {
        if (parseInt(item.parameters.xgeneral) + parseInt(item.parameters.widthgeneral) > this.syn_vars_divMain_width) {
          this.syn_vars_divMain_width = parseInt(item.parameters.xgeneral) + parseInt(item.parameters.widthgeneral);
        }

        if (parseInt(item.parameters.ygeneral) + parseInt(item.parameters.heightgeneral) > this.syn_vars_divMain_height) {
          this.syn_vars_divMain_height = parseInt(item.parameters.ygeneral) + parseInt(item.parameters.heightgeneral);
        }
      } catch (error) {
      }

      this.syn_vars_system.items[control.name].push(item);

      if (item.parameters?.backgroundgeneral !== true) {
        this.syn_vars_itemsInSynoptic.push(`it${item._id}`);
      }

      this.syn_vars_util.StyleRules({ id: item._id, type: item.type, typeName: control.name, iditem: item._id, parameters: item.parameters });
    }

    this.syn_func_treeItems(this.syn_vars_system.items);

    this.syn_func_treeControls(this.syn_vars_system.controls);

    const mmain = document.getElementById('main');

    mmain.addEventListener('drop', this.syn_func_addControlByDrop.bind(this));
    mmain.addEventListener("dragover", function (e) { e.preventDefault(); });

    this.syn_serv_systemServ.emitUpdateSyn(
      {
        scripting: this.syn_vars_system.scriptsElements,
        synoptic: synoptic.parameters,
        items: { "Synoptic": [this.syn_vars_system.items] },
        isReport: (this.syn_vars_isType === 'report'),
        player: false,
        tags: this.syn_vars_system.tags,
        protocols: this.syn_vars_system.protocols,
        things: this.syn_vars_system.things,
        scriptFunctionalities: this.syn_vars_system.scriptFunctionalities
      });


    this.syn_func_leftSection_open();

    this.syn_func_leftSection(this.syn_vars_buttonsLeftSelecc);
  }
  syn_func_treeControls(controls) {

    $('#treeblComm').show();
    $('#treeblControls').show();

    let data: any = [{ id: 'Controls', parent: '#', text: `${this.syn_vars_language.controls_text}`, state: { opened: true } }];

    let dataC: any = [{ id: 'comm', parent: '#', state: { opened: true, selected: false }, text: `${this.syn_vars_language.connections_text}`, li_attr: { type: 'connections' } }];

    for (const control of controls) {
      data.push({ id: control._id, parent: 'Controls', state: { selected: false }, text: control.name });
    }

    for (const thing of this.syn_vars_system.things) {
      dataC.push({ id: thing._id, parent: 'comm', state: { selected: false }, text: thing.name, type: 'thing', a_attr: { type: 'thing' } });
      // console.log(this.syn_vars_system.tags);
      const tags = this.syn_vars_system.tags.filter(ttag => ttag.thing?._id === thing._id);
      for (const tag of tags) {
        dataC.push({ id: tag._id, parent: thing._id, state: { selected: false }, text: tag.name, type: 'tag', a_attr: { type: 'tag' } });
      }

    }


    $('#treeblControls').jstree({
      core: {
        data
      }
    });

    $('#treeblComm').jstree({
      core: {
        dataC
      },
      types: {
        "default": {

        },
        "tag": {
          "icon": "assets/img/modelizer2/baseline_dashboard_black_24dp.png"
        }
        ,
        "thing": {
          "icon": "assets/img/modelizer2/baseline_summarize_black_24dp.png"
        }
      },
      plugins: ["types", "sort", "core"]
    });

    $("#treeblComm").on('dragstart', 'li > a', async (node) => {
      this.syn_vars_contextType = { mode: "comm", type: node.target.getAttribute("type"), id: node.target.id.replace('_anchor', '') };


      const mmain = document.getElementById('main');
      for (const each of this.syn_vars_itemsInSynoptic) {
        const itt = this.syn_func_getItem(each.replace('it', ''));
        const tt: any = document.createElement("div");
        tt.id = "pp_" + each.replace('it', '');
        tt.style.position = "absolute";
        tt.setAttribute("maskdrop", "1");
        tt.style.zIndex = 10000;
        tt.addEventListener("dragover", function (e) { e.target.style.backgroundColor = 'green'; })
        tt.addEventListener("dragleave", function (e) { e.target.style.backgroundColor = ''; })
        tt.style.left = itt.parameters.xgeneral + 'px';
        tt.style.top = itt.parameters.ygeneral + 'px';
        tt.style.width = itt.parameters.widthgeneral + 'px';
        tt.style.height = itt.parameters.heightgeneral + 'px';
        mmain.appendChild(tt);
      }

    });

    $("#treeblComm").on('dragend', 'li > a', async (node) => {

      this.syn_vars_contextX = 0;
      this.syn_vars_contextY = 0;
      this.syn_vars_contextType = {};
      //elimino los divs "maskdrop" si los hubiese
      const mmain = document.getElementById('main');
      const divs = mmain.getElementsByTagName("div");
      for (let nEach = divs.length - 1; nEach >= 0; nEach--) {
        if (divs[nEach].getAttribute("maskdrop") === "1") {
          mmain.removeChild(divs[nEach]);
        }
      }

    });
    $("#treeblControls").on('click', 'li > a', async (node) => {
      this.syn_vars_nodeItemSelected = node;

      const target = node.currentTarget.id;
      const id = target.replace('_anchor', '');

      if (id !== 'items') {
        this.syn_vars_itemFocus.id = id.replace('it', '');
        await this.syn_func_clickInControl(node.originalEvent);
      }
    });

    $("#treeblControls").on('hover', 'li > a', async (node) => {

    });




    $("#treeblControls").on('dragstart', 'li > a', async (node) => {
      const typeId = node.target.id.replace('_anchor', '');
      this.syn_vars_contextType = { mode: 'controls', id: typeId }; //this.syn_vars_system.controls.find(control => control._id === typeId).name;
      const mmain = document.getElementById('main');
      for (const each of this.syn_vars_itemsInSynoptic) {
        const itt = this.syn_func_getItem(each.replace('it', ''));
        const tt: any = document.createElement("div");
        tt.style.position = "absolute";
        tt.setAttribute("maskdrop", "1");
        tt.style.zIndex = 10000;
        tt.addEventListener("dragover", function (e) { e.target.style.backgroundColor = 'red'; })
        tt.addEventListener("dragleave", function (e) { e.target.style.backgroundColor = ''; })
        tt.style.left = itt.parameters.xgeneral + 'px';
        tt.style.top = itt.parameters.ygeneral + 'px';
        tt.style.width = itt.parameters.widthgeneral + 'px';
        tt.style.height = itt.parameters.heightgeneral + 'px';
        mmain.appendChild(tt);
      }

    });

    $("#treeblControls").on('dragend', 'li > a', async (node) => {

      this.syn_vars_contextX = 0;
      this.syn_vars_contextY = 0;
      this.syn_vars_contextType = {};
      //elimino los divs "maskdrop" si los hubiese
      const mmain = document.getElementById('main');
      const divs = mmain.getElementsByTagName("div");
      for (let nEach = divs.length - 1; nEach >= 0; nEach--) {
        if (divs[nEach].getAttribute("maskdrop") === "1") {
          mmain.removeChild(divs[nEach]);
        }
      }

    });

    const tree = $('#treeblControls').jstree(true);
    const treeC = $('#treeblComm').jstree(true);

    $('#treeblControls').on('hover_node.jstree', async (e, data) => {
      this.syn_func_selectItem(data.node);
    });

    tree.settings.core.data = data;
    tree.refresh();
    tree.redraw(true);

    treeC.settings.core.data = dataC;
    treeC.refresh();
    treeC.redraw(true);


  }

  syn_func_treeRefresh() {
    // this.system[]
  }

  syn_func_downInControl(event) {
    const gz = this.syn_func_getPosZoom(event);

    /*
      iniciamos movimiento si estamos encima de un control seleccionado
      ... si estábamos picando en shift implica que queríamos quitar ese control de la selección
    */
    if (event.button === 0) { //hemos picado con el botón izquierdo
      //cpc008
      if (this.syn_vars_divMask.move === false && this.syn_vars_itemSelecteds.find(item => item.id === this.syn_vars_itemFocus.id) !== undefined && this.syn_vars_itemFocus.id.length === 24) {
        if (event.shiftKey !== true) {
          this.syn_vars_divMask.leftIni = parseInt(this.syn_vars_divMask.left);
          this.syn_vars_divMask.topIni = parseInt(this.syn_vars_divMask.top);
          this.syn_vars_divMask.xIni = gz.clientX + this.scrollX();
          this.syn_vars_divMask.yIni = gz.clientY + this.scrollY();

          this.syn_vars_divMask.moveMode = 'control';
          this.syn_vars_divMask.move = true;
        }
      }
    }
  }

  scrollX() {
    return window.scrollX + this.syn_vars_divMain.scrollLeft;
  }

  scrollY() {
    return window.scrollY + this.syn_vars_divMain.scrollTop;
  }

  async syn_func_clickInControl(event) {
    if (this.syn_vars_divMask.move === false) {
      this.syn_serv_systemServ.emitItemSelected('');
      const tag = document.getElementById('it' + this.syn_vars_itemFocus.id);
      const os = this.getOffSets(tag);

      const padding = this.syn_func_getPadding(this.syn_vars_itemFocus.id);

      var iSel: any = {
        id: this.syn_vars_itemFocus.id, item: this.syn_vars_itemFocus,
        left: os.offsetLeft + 'px', leftIni: os.offsetLeft,
        top: os.offsetTop + 'px', topIni: os.offsetTop,
        width: os.offsetWidth + 'px', height: os.offsetHeight + 'px',
        padding: padding
      };

      if (event.shiftKey === false) {
        this.syn_vars_itemSelecteds = [];
        this.syn_vars_gridPossible = false;
        this.syn_vars_itemSelectedOthers = [];
      }

      if (this.syn_vars_itemSelecteds.find(item => item.id === this.syn_vars_itemFocus.id) !== undefined && this.syn_vars_itemSelecteds.length > 1) {

        this.syn_vars_itemSelecteds = this.syn_vars_itemSelecteds.filter(item => item.id !== this.syn_vars_itemFocus.id);
      } else {
        this.syn_vars_itemSelecteds.push(iSel);

        // this.syn_vars_gridPossible = this.syn_func_gridPossible(true);

        if (this.syn_vars_itemFocus.id.length === 24) {

          this.syn_vars_divMask.resize = true;
        } else {
          this.syn_vars_divMask.resize = false;
        }

        if (this.syn_vars_itemSelecteds.length > 1) {
          this.syn_vars_divMask.resize = false;
          $('#optionsdyn').hide();
        } else {
          await this.syn_func_configItem(this.syn_vars_itemFocus.id, tag);
        }
      }
    } else {

      this.syn_vars_divMask.move = false;
      switch (this.syn_vars_divMask.moveMode) {
        case 'control':
          //cpc010
          const itemsUpd = [];
          const itemsUnd = [];

          for (const item of this.syn_vars_itemSelecteds) {
            const xOld = parseInt(item.leftIni);
            const yOld = parseInt(item.topIni);

            item.leftIni = parseInt(item.left);
            item.topIni = parseInt(item.top);
            itemsUpd.push({ type: 'positionsInSynoptic', id: item.id, x: parseInt(item.left), y: parseInt(item.top) });
            itemsUnd.push({
              type: 'positionsInSynoptic', id: item.id,
              x: xOld,
              y: yOld
            });

            const itemClick = this.syn_func_getItem(item.id);
            itemClick.parameters.xgeneral = parseInt(item.left);
            itemClick.parameters.ygeneral = parseInt(item.top);

            //tengo que mirar si el item es un sinóptico para posicionar todos los vínculos
            if (itemClick.type === this.syn_vars_itemTypes.synoptic) {
              const items = this.syn_vars_itemsInSynoptic.filter(itin => itin.includes(`it${itemClick._id}_`));

              for (const it of items) {
                const itemCl = this.syn_func_getItem(it.substring(2));
                itemCl.parameters.xgeneral = parseInt(itemClick.parameters.xgeneral) + parseInt(itemCl.parameters['orig'].xgeneral);
                itemCl.parameters.ygeneral = parseInt(itemClick.parameters.ygeneral) + parseInt(itemCl.parameters['orig'].ygeneral);
              }
            }
          }
          await this.itemsServ.updateItems(itemsUpd);
          this.syn_vars_undo_actual = this.syn_vars_undo_actual + 1;
          this.syn_vars_undo_total = this.syn_vars_undo_actual;
          this.syn_vars_undo_changes[this.syn_vars_undo_actual] = { prev: itemsUnd, actual: itemsUpd };

          break;
      }
    }
  }

  async syn_func_configItem(idItem, tag) {
    this.syn_serv_systemServ.emitItemSelected(idItem);

    const tree = $('#treeblItems').jstree(true);
    tree.refresh();
    for (const it of tree.settings.core.data) {
      it.state.selected = (it.id === idItem);
    }
    tree.refresh();

    const itemClick = this.syn_vars_system.items[Object.keys(this.syn_vars_system.items).find(kt => this.syn_vars_system.items[kt].find(t => t._id === idItem))].find(t => t._id === idItem);
    const type = this.syn_vars_system.controls.find(control => control._id === itemClick.type);

    // itemClick.options['highlights'][0]['color'] = "#FFFF00";

    let parametersToChange = itemClick.parameters;

    let idSynItem = '';
    if (idItem.indexOf('_') !== -1) {
      const tags = idItem.split('_');
      idSynItem = tags[1];
      // idItem = tags[1];
    }

    /**
     * el item viene de un synoptico
     */

    /*
    if (this.itemParams.idSynItem) {
      const clon = JSON.stringify(parametersToChange);
      parametersToChange = JSON.parse(clon);

      const itemsSyn = this.systemSynoptic.items.find(item => item._id === this.itemParams.idSynItem);
      let itemsIn = {};
      itemsIn = itemsSyn.parameters.items;
      if (itemsIn) {
        if (itemsIn.hasOwnProperty(idItem)) {
          const keys = Object.keys(itemsIn[idItem]);
          keys.forEach(key => {
            parametersToChange[key] = itemsIn[idItem][key];
          });
        }
      }
    }
    */

    const params = JSON.stringify(type.parameters.form);
    let parameters: any = JSON.parse(params);

    try {
      if (parameters['general'].hasOwnProperty('keyboard')) {
        const keyboards = this.syn_vars_system.items['Keyboard'];
        for (const keyb of keyboards) {
          parameters['general']['keyboard']['options'].push({ key: keyb._id, value: keyb.name });
        }
      }
    } catch (error) {

    }

    // control campos (datadesign y otros)
    Object.keys(parameters).forEach(key => {
      const keyTab = key;
      Object.keys(parameters[keyTab]).forEach(field => {

        if (field === 'DataDesign') {
          const ddesign = this.syn_vars_system['datadesign'];
          ddesign.forEach(element => {
            parameters[keyTab][field].options.push({ key: element._id, value: element.name });
          });
        }
      });
    });

    let syns = [... this.syn_vars_system.synoptics];

    if (this.syn_vars_isType === 'report') {
      syns = [... this.syn_vars_system.reports];
    }


    if (type.name === 'Synoptic' || type.name === 'Report') {
      syns = syns.filter(syn => syn._id !== this.syn_vars_SynopticId);
    }

    const itemOptions = {
      zoom: (100 / this.syn_vars_zoom),
      id: idItem,
      isClone: (idSynItem !== ''),
      parent: itemClick['parent'],
      globals: type.parameters?.globals,
      name: '',
      description: '',
      type: { parameters: parameters },
      headboard: '',
      detail: '',
      footer: '',
      mode: 'item',
      values: parametersToChange,
      caption: `${this.syn_vars_language.item_properties}`,
      typeName: type.name,
      synoptics: syns,
      reports: this.syn_vars_system.reports,
      synItems: this.syn_vars_system.items,
      tag: tag,
      items_tags: this.syn_vars_system.tags,
      items_things: this.syn_vars_system.things,
      scriptProperties: this.syn_vars_system.scriptProperties
    };

    this.syn_serv_sharServ.emitChange('synoptics', itemOptions);
  }

  syn_func_selectItem(id) {


    this.syn_vars_divMask.hidden = true;
    try {
      const tag = document.getElementById('it' + id);
      const os = this.getOffSets(tag);
      this.syn_vars_divMask.left = os.offsetLeft + 'px';
      this.syn_vars_divMask.top = os.offsetTop + 'px';
      this.syn_vars_divMask.width = os.offsetWidth + 'px';
      this.syn_vars_divMask.height = os.offsetHeight + 'px';

      this.syn_vars_divMask.hidden = false;
    } catch (error) {
    }

  }

  syn_func_treeItems(items) {

    $('#treeblItems').show();

    let data: any = [{ id: 'items', parent: '#', text: `${this.syn_vars_language.items_text}`, state: { opened: true } }];

    for (const type of Object.keys(items)) {
      for (const item of items[type]) {
        data.push({ id: item._id, parent: 'items', state: { selected: false }, text: item.parameters.namegeneral });
      }
    }

    $('#treeblItems').jstree({
      core: {
        data
      }
    });

    $("#treeblItems").on('click', 'li > a', async (node) => {
      this.syn_vars_nodeItemSelected = node;

      const target = node.currentTarget.id;
      const id = target.replace('_anchor', '');

      if (id !== 'items') {
        this.syn_vars_itemFocus.id = id.replace('it', '');
        await this.syn_func_clickInControl(node.originalEvent);
      }
    });

    const tree = $('#treeblItems').jstree(true);

    $('#treeblItems').on('hover_node.jstree', async (e, data) => {
      this.syn_func_selectItem(data.node.id);
    });

    tree.settings.core.data = data;
    tree.refresh();
    tree.redraw(true);
  }

  view(event) {
    // console.log(event);
  }

  syn_func_aprox(_valor, _pad) {
    if (typeof _pad === 'object') {
      const type = this.syn_vars_system.controls.find(control => control._id === _pad.type);
      _pad = 0;
      try {
        _pad = type.parameters.padding;
      } catch (error) {

      }
    }

    const valor = typeof _valor === 'string' ? parseInt(_valor) : _valor;
    const pad = typeof _pad === 'string' ? parseInt(_pad) : _pad;

    if (pad === 0) {
      return _valor;
    }

    let rr = valor - (pad * Math.floor(valor / pad));
    if (rr > pad / 2) {
      rr = pad * Math.ceil(valor / pad);
    } else {
      rr = pad * Math.floor(valor / pad);
    }

    if (isNaN(rr)) {
      rr = _valor;
    }
    return rr;
  }

  async syn_func_addControlByDrop(e) {

    if (e.target.id === 'main') //hemos soltado el elemento en el main (sinóptico)
    {
      switch (this.syn_vars_contextType.mode) {
        case 'controls':
          this.syn_vars_contextX = e.layerX;
          this.syn_vars_contextY = e.layerY;
          await this.syn_func_addControl(this.syn_vars_system.controls.find(control => control._id === this.syn_vars_contextType.id).name);
          break;
        case 'comm':
          switch (this.syn_vars_contextType.type) {
            case 'tag':
              this.syn_vars_contextX = e.layerX;
              this.syn_vars_contextY = e.layerY;
              await this.syn_func_addControl(this.syn_vars_contextType);
              break;
          }
          break;
      }
    } else {  //hemos soltado el elemento en un item
      switch (this.syn_vars_contextType.mode) {
        case 'comm':
          switch (this.syn_vars_contextType.type) {
            case 'tag':
              /**
               * e.target.id es "pp_9789797"
               * quitando "pp_" tenemos el id del item donde estamos "soltando" el tag
               * el id del tag está en vars_contextType.id
               */
              const item = this.syn_func_getItem(e.target.id.replace("pp_", ""));
              const idType = item.type;
              const type = this.syn_vars_system.controls.find(control => control._id === idType);
              // console.log(type.name);
              const script = this.syn_vars_system.scriptProperties.parameters.elements.find(elem => elem.name === type.name + '.js');
              if (script) {
                const tt = "const app=this;" + script.content;
                eval(tt);
                this.globals['props'][type.name].setParameter(item, this.syn_vars_contextType.id);
              }

              break;
          }
          break;
      }
    }
  }

  /**
   *
   * cpc012
   * @param typeControl
   *
   */
  async syn_func_addControl(info) {
    let typeControl = '';

    if (typeof info === 'string') {
      typeControl = info;
    } else {
      typeControl = "Field";
      const tg = this.syn_vars_system.tags.find(each => each._id === info.id);
      if (this.syn_vars_typeTag[tg.type] === "boolean") {
        typeControl = "Check";
      }
    }

    //voy a crear un control
    this.syn_func_off();
    try {
      //tipo control
      const type = this.syn_vars_system.controls.find(_control => _control.name === typeControl);

      //parametros iniciales
      let parameters = { widthgeneral: 100, heightgeneral: 100, xgeneral: this.syn_vars_contextX, ygeneral: this.syn_vars_contextY, namegeneral: typeControl, descriptiongeneral: typeControl };

      if (type.parameters.initialvalues != null) {
        parameters = { ...parameters, ...type.parameters.initialvalues };
      }

      if (typeof info === 'object') {
        switch (info.type) {
          case 'tag':
            parameters['variable'] = { tag: info.id };
            if (typeControl === 'Check') {
              parameters['variable']['v_off'] = 0;
              parameters['variable']['v_on'] = 1;
            }
            break;
          case 'thing':

            break;
        }
      }

      if (type.parameters?.padding) {
        if (type.parameters?.padding > 0) {
          const pad = type.parameters.padding;
          parameters.xgeneral = this.syn_func_aprox(parameters.xgeneral, pad);
          parameters.ygeneral = this.syn_func_aprox(parameters.ygeneral, pad);
          parameters.widthgeneral = this.syn_func_aprox(parameters.widthgeneral, pad);
          parameters.xgeneral = this.syn_func_aprox(parameters.xgeneral, pad);
        }
      }

      const itemName = await this.syn_serv_systemServ.getName({ name: typeControl, parent: this.syn_vars_SynopticId });
      parameters.namegeneral = itemName.name;

      const newIt = { _id: '', description: typeControl, name: itemName.name, order: 10, parent: this.syn_vars_SynopticId, type: type._id, parameters: parameters };

      const itemUpdated = await this.syn_serv_systemServ.newItem(newIt);

      const tree = $('#treeblItems').jstree(true);
      tree.refresh();
      this.syn_func_treeItem_add(tree, itemUpdated.data);
      tree.refresh();

      const item = itemUpdated.data;

      item['id'] = item._id;

      const newItem = JSON.stringify(item);

      if (this.syn_vars_system.items.hasOwnProperty(typeControl) === false) {
        this.syn_vars_system.items[typeControl] = [];
      }

      if (typeControl === 'Chart') {
        const props = this.syn_func_buildChart(item);
        item['options'] = {};
        item['typeLine'] = props['typeLine'];
        item['data'] = props['data'];
        item['typeChart'] = props['typeChart'];
      }




      this.syn_vars_system.items[typeControl].push(item);

      this.syn_vars_itemsInSynoptic.push(`it${item._id}`);
      this.syn_vars_util.StyleRules({ id: item._id, type: item.type, typeName: typeControl, iditem: item._id, parameters: item.parameters });

      const itemsUpd = [{ type: 'restoreitem', id: item._id, item: JSON.parse(newItem) }];
      const itemsUnd = [{ type: 'deleteitem', id: item._id }];

      this.syn_vars_undo_actual = this.syn_vars_undo_actual + 1;
      this.syn_vars_undo_total = this.syn_vars_undo_actual;
      this.syn_vars_undo_changes[this.syn_vars_undo_actual] = { prev: itemsUnd, actual: itemsUpd };



      if (item.parameters.hasOwnProperty('stylegeneral') === false) {
        item.parameters.stylegeneral = 'none';
      }

      if (item.parameters.stylegeneral !== 'none') {
        item.copy = '';
        item.freetype = typeControl;
        this.syn_serv_systemServ.emitItemChanged([{ type: 'updateitem', id: item._id, item: item }]);
      }

      setTimeout(async () => {
        this.syn_func_configItem(item._id, document.getElementById(`it${item._id}`));
      }, 200);

    } catch (error) {
      console.log(error);
    }
  }

  getOffSets(item) {
    const offSet = { offsetLeft: item?.offsetLeft, offsetTop: item?.offsetTop, offsetWidth: item?.offsetWidth, offsetHeight: item?.offsetHeight };
    try {
      let op = item?.offsetParent;
      while (op.id !== 'main') {
        offSet.offsetLeft = offSet.offsetLeft + op.offsetLeft;
        offSet.offsetTop = offSet.offsetTop + op.offsetTop;
        op = op.offsetParent;
      }
    } catch (error) {
    }

    return offSet;
  }

  syn_func_getItem(id) {

    return this.syn_vars_system.items[Object.keys(this.syn_vars_system.items).find(kt => this.syn_vars_system.items[kt].find(t => t._id === id))].find(t => t._id === id);
  }

  syn_func_getType(id) {
    return Object.keys(this.syn_vars_system.items).find(kt => this.syn_vars_system.items[kt].find(t => t._id === id));
  }

  offsetParent(rr) {
    rr.x = rr.x + rr.tget.offsetLeft;
    rr.y = rr.y + rr.tget.offsetTop;
    if (rr.tget.offsetParent !== null) {
      rr = this.offsetParent({ tget: rr.tget.offsetParent, x: rr.x, y: rr.y });
    }
    return rr;
  }

  syn_func_isNaN(value) {
    if (value.trim() === '') {
      return true;
    }
    return isNaN(value);
  }

  syn_func_zoom(tget) {

    this.syn_vars_zoom_auto = false;

    const dm = document.getElementById("main");

    dm.style.transform = "scale(" + parseInt(tget['value']) / 100 + ")"; //(50 %)
    dm.style.transformOrigin = "left top";

    // dm.style.width = this.syn_vars_divMain_width + 'px';
    // dm.style.height = this.syn_vars_divMain_height + 'px';

    if (tget['value'] !== "100") {
      if (dm.offsetWidth < this.syn_vars_divMain_width) {
        dm.style.width = (this.syn_vars_divMain_width + 10) + 'px';
      }

      if (dm.offsetHeight < this.syn_vars_divMain_height) {
        dm.style.height = (this.syn_vars_divMain_height + 10) + 'px';
      }
    } else {

      dm.style.width = (window.innerWidth - dm.offsetLeft) + 'px';
      dm.style.height = (window.innerHeight - dm.offsetTop) + 'px';

      // dm.style.width = this.syn_vars_divMain_width + 'px';
      // dm.style.height = this.syn_vars_divMain_height + 'px';
    }

    const ww = (window.innerWidth - dm.offsetLeft) / (tget.value / 100);
    const hh = (window.innerHeight - dm.offsetTop) / (tget.value / 100);

    dm.style.width = ww + 'px';
    dm.style.height = hh + 'px';

    const width = this.syn_vars_divMain_width * (100 / tget['value']);
    const height = this.syn_vars_divMain_height * (100 / tget['value']);

    // this.syn_vars_divMain.style.width = '100%'; //width + 'px';
    // this.syn_vars_divMain.style.height = '100%'; //height + 'px';

    // dm.style.transformOrigin = "50 % 50 %";

    // dm.style.width = '100%';
    // dm.style.height = '100%';

  }

  syn_func_buildChart(item) {
    const options = {};

    const data: any = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'LogiPlatform Dataset',
          data: [65, 59, 80, 81, 56, 55, 45],
          fill: false,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 205, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(101, 103, 107, 0.2)',
          ],
          borderColor: [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)',
          ],
          borderWidth: 1,
        },
      ],
    };

    options['typeChart'] = 'line';
    options['typeLine'] = 'line';

    try {
      options['typeChart'] = item.parameters['typechart'];
      if (options['typeChart'] === 'stepped') {
        options['typeLine'] = 'stepped';
        options['typeChart'] = 'line';
        data.datasets[0].steppedLine = true;
      }

    } catch (error) {

    }
    options['data'] = data;
    return options;
  }

  syn_func_gridPossible(control: boolean) {
    let ret = false;
    let filas: any = [];

    //hay más de 3 elementos seleccionados
    ret = (this.syn_vars_itemSelecteds.length > 3);

    if (ret) {

      //localizo el primer item
      let tot = 10000;
      const compW = this.syn_vars_itemSelecteds[0].width;
      const compH = this.syn_vars_itemSelecteds[0].height;

      let idFirst: any = {};
      for (const sel of this.syn_vars_itemSelecteds) {
        if (compW !== sel.width || compH !== sel.height) {
          ret = false;
          break;
        }
        if (parseInt(sel.left) + parseInt(sel.top) < tot) {
          idFirst = { id: sel.id, item: sel };
          tot = parseInt(sel.left) + parseInt(sel.top);
        }
      }

      let xSep = 0;
      if (ret) {
        filas = [[idFirst]];
        let arrControl = [idFirst.id];
        let ok = true;
        while (true) {

          //en este while relleno la fila

          const itInicio = filas[filas.length - 1][0].item;
          while (true) {
            let found = false;
            for (const it of this.syn_vars_itemSelecteds) {
              if (arrControl.includes(it.id) === false) {
                if (parseInt(it.left) > (parseInt(itInicio.left) + parseInt(itInicio.width))
                  && Math.abs(parseInt(it.top) - parseInt(itInicio.top)) < parseInt(itInicio.height)) {
                  filas[filas.length - 1].push({ id: it.id, item: it });
                  found = true;
                  arrControl.push(it.id);
                  break;
                }
              }
            }
            if (found === false) {
              break;
            }
          }

          //miro a ver si la fila tiene un sólo elemento y doy error
          if (filas[filas.length - 1].length === 1) {
            ok = false;
            break;
          }

          //miro a ver si la fila tiene un número de elementos diferente a la anterior
          if (filas.length > 1 && filas[filas.length - 1].length !== filas[filas.length - 2].length) {
            ok = false;
            break;
          }

          //ordeno la fila
          filas[filas.length - 1].sort(function (a, b) {
            return a.item.left - b.item.left;
          });

          //si no hay elementos que revisar, controlamos las separaciones y salimos con un ok
          if (arrControl.length === this.syn_vars_itemSelecteds.length) {
            ok = true;

            let sepX = 0;
            let sepY = 0;
            for (let nf = 0; nf < filas.length; nf++) {
              for (let nc = 0; nc < filas[nf].length; nc++) {
                if (nc > 0) {
                  const sX = parseInt(filas[nf][nc].item.left) - (parseInt(filas[nf][nc - 1].item.left) + parseInt(filas[nf][nc - 1].item.width));
                  if (sepX === 0) {
                    sepX = sX;
                  } else {
                    if (sepX !== -1) {
                      if (sX !== sepX) {
                        sepX = -1;
                      }
                    }
                  }
                }
                if (nf > 0) {
                  const sY = parseInt(filas[nf][nc].item.top) - (parseInt(filas[nf - 1][nc].item.top) + parseInt(filas[nf - 1][nc].item.height));
                  if (sepY === 0) {
                    sepY = sY;
                  } else {
                    if (sepY !== -1) {
                      if (sY !== sepY) {
                        sepY = -1;
                      }
                    }
                  }
                }
              }
            }

            if (sepX !== -1 && this.syn_vars_distHorInput === '') {
              this.syn_vars_distHorInput = sepX.toString();
            }

            if (sepY !== -1 && this.syn_vars_distVerInput === '') {
              this.syn_vars_distVerInput = sepY.toString();
            }

            break;
          }

          //si en este punto no hemos salido, voy a buscar si hay un elemento valido como primer elemento de la siguiente fila
          let haySiguiente = false;

          //voy a buscar el primer elemento de la siguiente fila
          const itFind = filas[filas.length - 1][0].item;
          let total = 100000;

          let found: any = {};
          let arrControlCopy = JSON.parse(JSON.stringify(arrControl));
          while (true) {
            let newF = false;
            for (const it of this.syn_vars_itemSelecteds) {
              if (arrControl.includes(it.id) === false) {
                if (parseInt(it.top) > (parseInt(itFind.top) + parseInt(itFind.height))
                  && Math.abs(parseInt(it.left) - parseInt(itFind.left)) < parseInt(itFind.width)) {
                  if (parseInt(it.left) + parseInt(it.top) < total) {
                    newF = true;
                    found = { id: it.id, item: it };
                    total = parseInt(it.left) + parseInt(it.top);
                  }
                }
              }
            }

            if (newF === false) {
              if (found !== '') {
                filas.push([found]);
                arrControl = JSON.parse(JSON.stringify(arrControlCopy));
                arrControl.push(found.id);
              } else {
                // no hemos encontrado elemento, salimos mal
                ok = false;
              }
              break;
            }
          }
          if (ok === false) {
            break;
          }
        }
        ret = ok;
      }

    }

    if (ret) {
      let sep = 0;
      for (const fila of filas) {
      }
    }

    this.syn_vars_gridPossible = ret;
    if (control) {
      return ret;
    } else {
      if (ret) {
        return filas;
      } else {
        return [];
      }
    }
  }

  goHome(e: Event) {
    e.preventDefault();
    window.location.href = window.location.origin;
  }
}
