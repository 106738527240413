import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private BASE_URL: string = environment.base_url;
  languages: any;
  userLanguage: string = localStorage.language ?? 'es';

  /**
   * Constructor
   * @param http http client
   */
  constructor(private http: HttpClient) {}

  getLanguages() {
    // get all languages from the api and load it in this.languages
    this.languages = this.http
      .get(`${this.BASE_URL}/v1/api/languages`)
      .toPromise();
  }

  async getVal() {
    // get localStorage val
    this.userLanguage = localStorage.language;
    // get all languages and equals in languages
    const languages = await this.languages;
    // language switch
    switch (this.userLanguage) {
      case 'es':
        return languages[0].spanish;
      case 'ca':
        return languages[0].catalan;
      case 'en':
        return languages[0].english;
      default:
        return languages[0].spanish;
    }
  }

  setDefaultLanguage(): void {
    localStorage.language = 'es';
  }

  changeLanguage(val: string): void {
    localStorage.language = val;
    document.documentElement.lang = val;
  }

  getUserLanguage() {
    return localStorage.language;
  }
}
