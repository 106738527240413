import { FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms"

export const validatePasswords: ValidatorFn = (
  control: FormGroup
): ValidationErrors | null => {
  // get password
  const password = control.get('password');
  // get repeatPassword
  const repeatPassword = control.get('repeatPassword');
  // return true if password and repeatPassword are equals
  return password.value === repeatPassword.value
    ? null
    : { noSonIguales: true };
}
