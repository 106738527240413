import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'src/app/interfaces/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private BASE_URL; // = environment.base_url;

  /**
   * Constructor
   * @param http httpClient
   * @param createHeaders headers service
   */
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.base_url;
  }

  /**
   * Get users
   */
  getUsers(): Promise<any> {
    return this.http.get<User>(`${this.BASE_URL}/v1/api/users`).toPromise();
  }

  /**
   * Add users
   * @param user user
   */
  addUser(user: Promise<User>) {
    return this.http
      .post<User>(`${this.BASE_URL}/v1/api/auth/signup`, user)
      .toPromise();
  }

  /**
   * Update user
   * @param user user
   * @param id user id
   */
  editUser(user: User, id) {
    return this.http
      .put<User>(`${this.BASE_URL}/v1/api/users/${id}`, user)
      .toPromise();
  }

  /**
   * Delete user
   * @param id id of user
   */
  deleteUser(id) {
    return this.http.delete(`${this.BASE_URL}/v1/api/users/${id}`).toPromise();
  }
}
