<div class="config-title">{{ language?.backups }}</div>
<div class="config-container">
  <div class="backup-container" *ngIf="!restoring">
    <h6>{{ language?.make_backup }}</h6>
    <button mat-icon-button (click)="getBackup()">
      <mat-icon>file_download</mat-icon>
    </button>
  </div>

  <div class="restore-container" *ngIf="!restoring">
    <h6>{{ language?.restore_backup }}</h6>
    <div>
      <button mat-icon-button (click)="fileInput.click()">
        <mat-icon fontIcon="material-icons-round">upload</mat-icon>
      </button>
      <input #fileInput hidden (change)="onFileSelected($event)" type="file">
      <span class="file-name">{{selectedFile?.name}}</span>
      <button mat-flat-button color="logi" (click)="handleClick()" type="submit" form="restoreForm"
        [disabled]="!selectedFile?.name.includes('.zip')">{{ language?.restore }}</button>
    </div>
  </div>

  <ng-container *ngIf="restoring">
    <div class="d-flex flex-column align-items-center my-3" style="gap: 5px;">
      <strong>{{ language?.restoring }}</strong>
      <br>
      <strong>{{ language?.restoring_warning }}</strong>
      <div class="spinner-border" role="status" aria-hidden="true"></div>
    </div>
  </ng-container>
</div>
