<div class="container mt-5">
    <div class="row">
        <div class="col-4 mt-4">
            <div class="form-group">
                <i class="fa fa-plus fa-lg icon" (click)="addFolder('#')"></i>
            </div>
            <div id="jstree"></div>
        </div>
        <div class="col-8 hidden" id="codemirror">
            <ngx-codemirror [(ngModel)]="content" [options]="codeMirrorOptions"></ngx-codemirror>
            <div class="form-group mt-2 ml-4">
                <button class="btn btn-primary mr-3" data-toggle="modal" data-target=".bd-modal-scriptInterval"><i
                        class="fa fa-history fa-lg"></i></button>
                <button class="btn btn-primary mr-5" (click)="saveScript($event)">{{ languages?.save }}</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal to script interval -->
<div class="modal fade bd-modal-scriptInterval mt-5" tabindex="-1" role="dialog" aria-labelledby="Script interval"
    aria-hidden="true" id="scriptInterval">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalScriptIntervalTitle">{{ languages?.script_interval }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="intervalForm" (ngSubmit)="intervalSubmit()" method="POST">
                    <div class="form-group">
                        <label for="interval">{{ languages?.interval_label }}</label>
                        <input type="text" class="form-control" id="interval" formControlName="interval">
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ languages?.close
                            }}</button>
                        <button type="submit" class="btn btn-primary" [disabled]="!intervalForm.valid">{{ languages?.add
                            }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>