<div style="position: absolute; width: 100%; height: 100%; padding: 0px; top: 0px; left: 0px; z-index: -1000;" id="main">
  <app-synoptic-core></app-synoptic-core>
</div>


<div class="col-2">
  <app-optionsdyn_v2></app-optionsdyn_v2>
</div>

<!-- DSItem Modal -->
<div class="modal" tabindex="-1" role="dialog" aria-labelledby="DataDesign Item" aria-hidden="true" id="DSitemForm">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="dsitemFormTitle">Ficha Elemento</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="modalDSitemForm">
        <app-optionsdynmodal></app-optionsdynmodal>
      </div>
    </div>
  </div>
</div>

<div class="modal bd-modal-KeyboardModal mt-8" tabindex="-1" role="dialog" aria-labelledby="Keyboard" aria-hidden="true"
  id="KeyboardModal">
  <div class="modal-dialog mw-100" style="width: 95%; height: 95%">

    <div class="modal-content" style="height: 95%;">

      <div class="modal-header">
        <h5 class="modal-title" id="amodalviewValuesTitle">
          KEYBOARD
        </h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeKBModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="keybBody" style="height: 95%; width: 100%; text-align: center;">
        <h1 id="modalDescription"></h1><br>
        <input id="modalInput">
        <div id="keyboardModal" style="position:relative; height: 95%">
        </div>
      </div>
    </div>


  </div>
</div>

<div class="modal fade" id="dataModal" tabindex="-1" aria-labelledby="dataModalLabel" aria-hidden="true">

  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="dataModalLabel">Ficha Registro</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container" style="background-color: rgb(230, 230, 230);">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a id="generalmod-tab" class="nav-link active" data-toggle="tab" role="tab" href="#generalmod">General</a>
            </li>
            <li class="nav-item" style="display: none;">
              <a id="newmod-tab" class="nav-link" data-toggle="tab" role="tab" href="#newmod">New</a>
            </li>
          </ul>

          <div class="tab-content">
            <div class="tab-pane fade mt-3 show active" role="tab-panel" id="generalmod">
              <table id='tblgeneralmod'>
              </table>
            </div>
            <div class="tab-pane fade mt-3" role="tab-panel" id="newmod">
              a2
            </div>
          </div>
        </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" id="btn_save_changes" onclick="lpC['submit']();" class="btn btn-primary"
          data-dismiss="modal">Save changes</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal delete item -->
<div class="modal fade bd-modal-deleteItem mt-5" tabindex="-1" role="dialog" aria-labelledby="Eliminar item"
  aria-hidden="true" id="deleteItemDD">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalDeleteItemTitle">
          language?.confirmation
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3>
          language?.confirmation_message <span id="itemToDelete"></span> ?
        </h3>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          language?.close
        </button>
        <button type="button" class="btn btn-danger" data-dismiss="modal" onclick="lpC['delete']();">
          language?.delete
        </button>
      </div>
    </div>
  </div>
</div>