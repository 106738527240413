import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModuleIotService {
  BASE_URL: any;
  thingTypes: any;
  token: any;
  /* token = 'XqzN6+he5naKJp1i7neIX2s0s8Bokt32IT++TSHZ+COiGW69e9r+P6oqcV19lhH0'; */

  constructor(private http: HttpClient) {}

  setBaseUrl(ip) {
    this.BASE_URL = ip;
    return this.loginModule();
  }

  async loginModule() {
    const token: any = await this.http.post(`${this.BASE_URL}/api/login?user=admin&password=admN2iN4sf33`,
      { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) }).toPromise().catch(err => {
        return err;
      });
    this.token = token.token;

    return true;
  }

  getBaseUrl() {
    return this.BASE_URL;
  }

  getToken() {
    return this.token;
  }

  createDocumentDefinition(document) {
    return this.http.post(`${this.BASE_URL}/api/documentDefinition/create`, document,
      { headers: new HttpHeaders({ token: this.token }) }).toPromise();
  }

  updateDocumentDefinition(document) {
    return this.http.put(`${this.BASE_URL}/api/documentDefinition/update`, document,
      { headers: new HttpHeaders({ token: this.token, 'Content-Type': 'application/json' }) }).toPromise();
  }

  async searchDocumentDefinition(document) {
    const params = {
      pageSize: -1,
      offset: 0,
      sortAsc: false,
      filters: {
        lastVersion: true
      }
    };
    const documents: any = await this.http.post(`${this.BASE_URL}/api/documentDefinition/page`, params,
      { headers: new HttpHeaders({ token: this.token, 'Content-Type': 'application/json' }) }).toPromise();
    const index = documents.entities.map(element => element.id).indexOf(document.toString());

    return documents.entities[index];
  }

  getThingTypes() {
    return this.http.post(`${this.BASE_URL}/api/thingType/page`, { pageSize: -1, offset: 0, sortBy: 'id' },
      { headers: new HttpHeaders({ token: this.token, 'Content-Type': 'application/json' }) }).toPromise();
  }

  createThingType(thingType) {
    return this.http.post(`${this.BASE_URL}/api/thingType/create`, thingType,
      { headers: new HttpHeaders({ token: this.token }) }).toPromise();
  }

  async searchByName(name) {
    let index = -1;
    let thingTypes: any;
    do {
      thingTypes = await this.getThingTypes();
      index = thingTypes.entities.map(element => element.name).indexOf(name);
    } while (index === -1);

    return thingTypes.entities[index].id;
  }

  getThingType(id) {
    return this.http.get(`${this.BASE_URL}/api/thingType/${id}`, { headers: new HttpHeaders({ token: this.token }) }).toPromise();
  }

  async getThingByExternalId(externalId) {
    try {
      const thing: any = await this.http.post(`${this.BASE_URL}/api/thing/withLastDocuments/externalId/${externalId}`, {},
        { headers: new HttpHeaders({ token: this.token, 'Content-Type': 'application/json' }) }).toPromise();
      return thing.id;
    } catch (err) {
      if (err.error.message === 'Unknown external Id') {
        return false;
      }
    }
  }

  updateThingType(thingType) {
    return this.http.put(`${this.BASE_URL}/api/thingType/update`, thingType, { headers: new HttpHeaders({ token: this.token, 'Content-Type': 'application/json' }) }).toPromise();
  }

  async createThing(thing) {

    try {
      const newThing: any = await this.http.post(`${this.BASE_URL}/api/thing/create`, thing, { headers: new HttpHeaders({ token: this.token, 'Content-Type': 'application/json' }) }).toPromise();
      return newThing.id;
    } catch (err) {
      return false;
    }
  }

  async searchThing(name) {
    const things: any = await this.http.post(`${this.BASE_URL}/api/thing/pageWithLastDocuments`, { offset: 0, pageSize: -1 },
      { headers: new HttpHeaders({ token: this.token, 'Content-Type': 'application/json' }) }).toPromise();
    const index = things.entities.map(element => element.name).indexOf(name);
    if (index === -1) {
      return false;
    }
    return things.entities[index].id;
  }

  async getThings(): Promise<any> {
    const params = {
      offset: 0,
      pageSize: -1
    };
    return await this.http.post(`${this.BASE_URL}/api/thing/pageWithLastDocuments`, params,
      { headers: new HttpHeaders({ token: this.token, 'Content-Type': 'application/json' }) }).toPromise();
  }

  updateThing(thing) {
    try {
      this.http.post(`${this.BASE_URL}/api/thing/update`, thing, { headers: new HttpHeaders({ token: this.token, 'Content-Type': 'application/json' }) }).toPromise();
      return true;
    } catch (err) {
      return false;
    }
  }

  updateThingRequest(id) {
    const obj = JSON.stringify({ thingId: id, tag: '-', body: 'updateThing' });
    return this.http.post(`${this.BASE_URL}/api/requests/send`, obj, { headers: new HttpHeaders({ token: this.token, 'Content-Type': 'application/json' }) }).toPromise();
  }

  publishNewValue(id, body) {
    const newBody = JSON.stringify(body);
    const obj = { thingId: id, tag: '-', body: newBody }
    return this.http.post(`${this.BASE_URL}/api/requests/send`, obj, { headers: new HttpHeaders({ token: this.token, 'Content-Type': 'application/json' }) }).toPromise();
  }

  getDocumentDefinitions() {
    const params = {
      pageSize: -1,
      offset: 0,
      sortAsc: false,
      filters: {
        lastVersion: true
      }
    };
    return this.http.post(`${this.BASE_URL}/api/documentDefinition/page`, params, { headers: new HttpHeaders({ token: this.token, 'Content-Type': 'application/json' }) }).toPromise();
  }

  async getDocumentQuery(item: any) {

    let h = false;
    let hobj = {};

    const result = [];
    const start = `${item.parameters.start_date} ${item.parameters.start_time}`;
    const startDate = Date.parse(start);
    const end = `${item.parameters.end_date} ${item.parameters.end_time}`;
    const endDate = Date.parse(end);
    const params = {
      start: startDate,
      end: endDate,
      documents: {
        [item.parameters.document]: {}
      },
      thingsExternalIds: [
        item.parameters.thing
      ]
    };
    const query: any = await this.http.post(`${this.BASE_URL}/api/documents/query`, params, { headers: new HttpHeaders({ token: this.token, 'Content-Type': 'application/json' }) }).toPromise();
    query[0].documents.map(elem => {
      elem.tt = elem.t;
      const date = new Date(elem.t);
      elem.t =
        `${date.toLocaleDateString()} ${date.getHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}`;

      if (h) {


      } else {
        result.push(elem);
      }

    });

    return result;
  }

  async existsDocumentDefinition(document) {
    const allDocuments: any = await this.getDocumentDefinitions();
    const index = allDocuments.entities.map(elem => elem.id).indexOf(document.id);
    if (index != -1) return true;

    return false;
  }
}
