import { Injectable } from '@angular/core';
import { LanguageService } from 'src/app/service/system/language.service';

@Injectable({
  providedIn: 'root',
})
export class DatatableService {
  options: any;
  language: any;

  constructor(private langServ: LanguageService) {}

  /**
   * Return a JSON with the datatable options
   */
  optionsDatatable() {
    // set datable options with language
    return (this.options = {
      pagingType: 'full_numbers',
      pageResize: true,
      lengthChange: false,
      paging: true,
      scrollX: false,
      autoWidth: true,
      language: this.language.datatable,
      ordering: true,
    });
  }

  async setOptions() {
    //get languages
    this.language = await this.langServ.getVal();
  }
}
