import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DatatableService } from 'src/app/service/datatable.service';
import { RouterService } from 'src/app/service/router.service';
import { LanguageService } from 'src/app/service/system/language.service';
import { LoginService } from 'src/app/service/system/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'logiplatform-ft';
  languages: any;
  time: any;
  t: any;

  constructor(
    private router: Router,
    private langServ: LanguageService,
    private dataServ: DatatableService,
    private loginServ: LoginService,
    private refresh: RouterService,
  ) {

  }

  async ngOnInit(): Promise<any> {
    // this.inactiveTime();
    this.reSize();
    // get languages
    await this.langServ.getLanguages();
    // set datatable options
    this.dataServ.setOptions();
    // this.inactiveTime2();
  }

  // Resize window when change components
  reSize(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  // When the user are inactive change to guest mode
  inactiveTime(): void {
    window.onload = this.resetTimer.bind(this);
    window.onmousemove = this.resetTimer.bind(this);
    window.onmousedown = this.resetTimer.bind(this);  // catches touchscreen presses as well
    window.ontouchstart = this.resetTimer.bind(this); // catches touchscreen swipes as well
    window.onclick = this.resetTimer.bind(this);      // catches touchpad clicks as well
    window.onkeypress = this.resetTimer.bind(this);
    window.addEventListener('scroll', this.resetTimer.bind(this), true); // improved; see comments
  }

  // Change to guest mode
  async timeOut(): Promise<any> {

    // if user are logged with token do this
    if (sessionStorage.token !== 'invitado' && sessionStorage.token !== undefined) {
      await this.loginServ.timeOut();
      // Different token than normal user
      sessionStorage.setItem('token', 'invitado');
      // isLogged false
      sessionStorage.isLogged = false;
      this.refresh.refresh();
    }
  }

  // Timer for call timeOut function
  resetTimer(): void {
    clearTimeout(this.t);
    this.t = setTimeout(this.timeOut.bind(this), 300000);  // time is in milliseconds (5 mins)
  }
}
