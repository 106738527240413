import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LanguageService } from 'src/app/service/system/language.service';

interface MatHeader {
  key: string;
  label?: string;
}

interface defaultSort {
  header: string;
  direction: 'asc' | 'desc';
}

type checkCell = (column: string, row: object) => string;
type checkRow = (row: object) => string;

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements AfterViewInit {
  @Input() headers: MatHeader[] = [];
  @Input() data: object[] = [];
  @Input() sortable: boolean = false;
  @Input() sortDefault: defaultSort | null = null;
  @Input() filter: string = '';
  @Input() filterFunction?: (data: any, filter: string) => boolean;
  @Input() paginable: boolean = false;
  @Input() pageSizeOptions: number[] = [10];
  @Input() showFirstLastButtons: boolean = false;
  @Input() cellStyle: checkCell = () => '';
  @Input() rowStyle: checkRow = () => '';
  @Input() headerRowStyleStatic: string = '';
  @Input() rowStyleStatic: string = '';

  language: any;

  selection = new SelectionModel<any>(true, []);
  dataHeaders: string[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);

  tableHeight: number | null = null;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private cdr: ChangeDetectorRef,
    private langServ: LanguageService
  ) {}

  /**
   * Initializes the table and updates it upon any data change.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.headers || changes.data) {
      this.selection.clear();
      this.dataHeaders = this.headers.map((header) => header.key);
      this.dataSource.data = this.data;
    }

    if (changes.paginable && changes.paginator) {
      this.dataSource.paginator = this.paginator;
      this.tableHeight = this.paginator.pageSize * 54 + 54;
      this.paginator.length = this.data.length - 1;
    }

    if (changes.sortable && changes.sort) {
      this.dataSource.sort = this.sort;
    }

    if (changes.filter) {
      this.dataSource.filter = this.filter;
    }

    if (changes.filterFunction && this.filterFunction != null) {
      this.dataSource.filterPredicate = this.filterFunction;
    }

    this.cdr.detectChanges();
  }

  async ngAfterViewInit(): Promise<void> {
    this.language = (await this.langServ.getVal()).option;

    if (this.paginable) {
      if (this.language != null) {
        this.paginator._intl.itemsPerPageLabel = this.language?.items_per_page;
        this.paginator._intl.nextPageLabel = this.language?.next_page;
        this.paginator._intl.previousPageLabel = this.language?.previous_page;
        this.paginator._intl.lastPageLabel = this.language?.last_page;
        this.paginator._intl.firstPageLabel = this.language?.first_page;
        this.paginator._intl.getRangeLabel = this.paginatorRangeLabel;
      }

      this.dataSource.paginator = this.paginator;
      this.tableHeight = this.paginator.pageSize * 52 + 54;
    }

    if (this.sortable) {
      this.dataSource.sort = this.sort;

      /**
       * Sort columns with nested data
       *
       * He uses indexOf() and substring() to optimize
       * performance with respect to includes() and split()
       */
      this.dataSource.sortingDataAccessor = (item, property) => {
        const dotIndex = property.indexOf('.');
        let value;
        if (dotIndex !== -1) {
          const parent = property.substring(0, dotIndex);
          const header = property.substring(dotIndex + 1);
          value = item[parent][header];
        } else {
          value = item[property];
        }

        // Convert the value to a number if it's numeric
        return isNaN(Number(value)) ? value : Number(value);
      };
    }

    this.cdr.detectChanges();
  }

  /**
   * Checks is all rows are selected
   */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /**
   * Toggle to select or deselect all
   */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /**
   * Gets the value from the data object using
   * the header key and checks if it is a nested object on depth 1.
   *
   * @param headerKey Key to find the value in the data object. It can be a nested
   * key on depth 1 using the format "key1.key2".
   * @param element Data object from which the value will be extracted.
   * @returns Value of the header key, or `undefined` if the key does not exist.
   */
  getValue(headerKey: string, element: any): any {
    /**
     * Only admits a depth of 1 for the header key by performance reasons.
     */
    try {
      if (headerKey.includes('.')) {
        const keys = headerKey.split('.');
        return element[keys[0]][keys[1]];
      } else {
        return element[headerKey];
      }
    } catch (err) {
      return null;
    }
  }

  paginatorRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) {
      return `0 ${this.language.of} ${length}`;
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${this.language.of} ${length}`;
  };

  isFloat(value: string): boolean {
    const numValue = Number(value);
    return (
      !Number.isNaN(numValue) &&
      isFinite(numValue) &&
      !Number.isInteger(numValue)
    );
  }

  isISODate(value: string): boolean {
    try {
      const isoDateRegex =
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(?::\d{2})?(?:\.\d+)?(?:Z)?$/;
      return isoDateRegex.test(value);
    } catch (err) {
      return false;
    }
  }
}
