import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  // Observable string sources
  private emitChangeSource = new Subject<any>();
  // Observable string streams
  changeEmitted$ = this.emitChangeSource.asObservable();
  // Observable report item
  // Service message commands
  emitChange(fromComponent: any, change: any) {
    this.emitChangeSource.next({ component: fromComponent, item: change });
  }

  emitItem(action, obj) {
    this.emitChangeSource.next({ val: action, item: obj });
  }

  updateSpecialItem(name, obj, actio) {
    this.emitChangeSource.next({ val: name, item: obj, action: actio });
  }

  emitComponentItem(item: any) {
    this.emitChangeSource.next(item);
  }

  emitDeleteItem(action, obj) {
    this.emitChangeSource.next({ val: action, item: obj });
  }
}
